import axios from "axios";
import { GET_CITIES_LISTS, GET_HOTEL_DETAILS, GET_HOTEL_FACILITIES, GET_HOTELS_LISTS, GET_ROOMS_LISTS, GET_SEARCHED_GRAPH, GET_SIMILAR_TOP_HOTELS, GET_TOP_HOTELS, GET_USER_BOOKING, GET_USER_UPCOMMING_BOOKING } from "./bookingTypes";
import { start_loading, stop_loading } from "../loader/loaderActions";
import { catch_errors_handle } from "../User/userActions";


const { REACT_APP_OTA_URL, REACT_APP_OTA_HOTEL_URL, REACT_APP_OTA_DIDA_URL, REACT_APP_OTA_GDS_URL } = process.env;

export const get_top_hotels_lists = async (dispatch, userLocation, toast) => {
    dispatch(start_loading());
    try {
        const url = `${REACT_APP_OTA_GDS_URL}home-content`;
        const HEADERS = {
            headers: {
                "Content-Type": "application/json",
            }
        };
        const res = await axios.post(url, JSON.stringify(userLocation), HEADERS);
        if (res.data && res.data.status) {
            localStorage.setItem("tophotels", JSON.stringify(res.data.topHotels));
            dispatch({ type: GET_TOP_HOTELS, payload: res.data.topHotels });
            
            localStorage.setItem("topcities", JSON.stringify(res.data.topCities));
            dispatch({ type: GET_CITIES_LISTS, payload: res.data.topCities });
        }
    } catch (error) {
        catch_errors_handle(error, dispatch, toast);
        // console.log("Error in Hotels Lists", error);
    } finally {
        dispatch(stop_loading());
    }
};

export const get_hotel_detail = async (dispatch, data) => {
    dispatch(start_loading());
    try {
        const url = `${REACT_APP_OTA_GDS_URL}Hotel-Details`;
        const HEADERS = {
            headers: {
                "Content-Type": "application/json",
            }
        };
        const res = await axios.post(url, JSON.stringify(data), HEADERS);
        if (res.data && res.data.status) {
            dispatch({ type: GET_HOTEL_DETAILS, payload: res.data });
        }
    } catch (error) {
        console.log("Error in Hotels Detail", error);
    } finally {
        dispatch(stop_loading());
    }
};

export const get_search_hotel_graphs = async (dispatch, data) => {
    // dispatch(start_loading());
    try {
        const url = `${REACT_APP_OTA_GDS_URL}graphql`;
        const HEADERS = {
            headers: {
                "Content-Type": "application/json",
            }
        };
        const res = await axios.post(url, JSON.stringify(data), HEADERS);
        if (res?.data?.data) {
            // console.log("Graph Data ---->", res.data.data.getCities);
            dispatch({ type: GET_SEARCHED_GRAPH, payload: res?.data?.data?.getCities });
        }
    } catch (error) {
        console.log("Error in Hotels Detail", error);
    } finally {
        // dispatch(stop_loading());
    }
};

export const get_hotel_data_formatter = async (data) => {
    // console.log("Previous data ----->", data);
    return data.map((hotel) => {
        const commonFields = {
            HotelID: hotel.HotelID || null,
            GiataId: hotel.GiataId || null,
            Name: hotel.Name || null,
            HotelCategory: hotel.HotelCategory || null,
            Gds: hotel.provider || null,
            Images: hotel.imageLinks || null,
            facilities: hotel.facilities || [],
            Latitude: hotel.Latitude || null,
            Longitude: hotel.Longitude || null,
            HotelPrice: {
                Currency: hotel?.provider === "Juniper" ? hotel.rooms.Prices?.Price?.$?.Currency : 'USD',
                Price: hotel?.provider === "Juniper" ? Number(hotel.rooms.Prices?.Price?.TotalFixAmounts?.$?.Gross) : hotel?.price,
            },
            place_id: hotel?.place_id,
            rating: hotel?.rating,
            address: hotel?.address,
            category: hotel?.category ? Number(hotel?.category) : null,
            total_reviews: hotel?.total_reviews ? hotel?.total_reviews : null
        };

        if (hotel?.provider === "Juniper" && hotel?.rooms && !Array.isArray(hotel?.rooms)) {
            // Format rooms when not in array format
            const roomData = hotel.rooms;
            const prices = roomData.Prices?.Price;

            return {
                ...commonFields,
                rooms: [
                    {
                        RatePlanCode: roomData.$?.RatePlanCode || null,
                        Status: roomData.$?.Status || null,
                        NonRefundable: roomData.$?.NonRefundable === "true",
                        Board: roomData.Board?._ || null,
                        BoardType: roomData.Board?.$?.Type || null,
                        Price: {
                            Type: prices?.$?.Type || null,
                            Currency: prices?.$?.Currency || null,
                            Gross: prices?.TotalFixAmounts?.$?.Gross || null,
                            Nett: prices?.TotalFixAmounts?.$?.Nett || null,
                        },
                        ServiceAmount: prices?.TotalFixAmounts?.Service?.$?.Amount || null,
                        HotelRoom: {
                            Units: roomData.HotelRooms?.HotelRoom?.$?.Units || null,
                            Source: roomData.HotelRooms?.HotelRoom?.$?.Source || null,
                            AvailRooms: roomData.HotelRooms?.HotelRoom?.$?.AvailRooms || null,
                            Name: roomData.HotelRooms?.HotelRoom?.Name || null,
                            RoomCategoryType: roomData.HotelRooms?.HotelRoom?.RoomCategory?.$?.Type || null,
                            Occupancy: roomData.HotelRooms?.HotelRoom?.RoomOccupancy?.$?.Occupancy || null,
                            MaxOccupancy: roomData.HotelRooms?.HotelRoom?.RoomOccupancy?.$?.MaxOccupancy || null,
                            Adults: roomData.HotelRooms?.HotelRoom?.RoomOccupancy?.$?.Adults || null,
                            Children: roomData.HotelRooms?.HotelRoom?.RoomOccupancy?.$?.Children || null,
                        },
                    },
                ],
            };
        } else if (hotel?.provider === "DIDA" && hotel.rooms && Array.isArray(hotel.rooms)) {
            // Format rooms when in array format
            return {
                ...commonFields,
                rooms: hotel.rooms.map((room) => ({
                    TotalPrice: room.TotalPrice || null,
                    RoomStatus: room.RoomStatus || null,
                    BreakfastType: room.BreakfastType || null,
                    BedType: room.BedType || null,
                    RoomOccupancy: {
                        ChildCount: room.RoomOccupancy?.ChildCount || null,
                        AdultCount: room.RoomOccupancy?.AdultCount || null,
                        RoomNum: room.RoomOccupancy?.RoomNum || null,
                    },
                    PriceList: room.PriceList?.map((price) => ({
                        StayDate: price.StayDate || null,
                        Price: price.Price || null,
                        MealAmount: price.MealAmount || null,
                        MealType: price.MealType || null,
                    })),
                    RatePlanCancellationPolicyList: room.RatePlanCancellationPolicyList?.map((policy) => ({
                        Amount: policy.Amount || null,
                        FromDate: policy.FromDate || null,
                    })),
                    StandardOccupancy: room.StandardOccupancy || null,
                    InventoryCount: room.InventoryCount || null,
                    MaxOccupancy: room.MaxOccupancy || null,
                    RoomTypeID: room.RoomTypeID || null,
                    Currency: room.Currency || null,
                    RatePlanName: room.RatePlanName || null,
                    RatePlanID: room.RatePlanID || null,
                    RoomName: room.RoomName || null,
                })),
            };
        } else {
            // Handle case with no room data
            return {
                ...commonFields,
                rooms: [],
            };
        }
    });
};

export const get_search_hotel_results = async (dispatch, hoteldata, toast) => {
    dispatch(start_loading());
    try {
        const url = `${REACT_APP_OTA_GDS_URL}hotels`;
        const HEADERS = {
            headers: {
                "Content-Type": "application/json",
            }
        };
        const res = await axios.post(url, JSON.stringify(hoteldata), HEADERS);
        if (res?.data && res?.data?.status) {
            // console.log("formdated data ====>", await get_hotel_data_formatter(res.data.result));
            // filter((item) => item?.GiataId)
            dispatch({ type: GET_HOTELS_LISTS, payload: await get_hotel_data_formatter(res.data.result) });
            return true;
        } else {
            return false;
        }
    } catch (error) {
        // console.log("Error in Hotels Searchess", error);
        toast({ title: error?.response?.data?.error?.Message ? error?.response?.data?.error?.Message : error?.response?.data?.error ? error?.response?.data?.error : error?.response?.data?.error?.Message, status: "error", duration: 2000, position: "top-right", isClosable: true, });
    } finally {
        dispatch(stop_loading());
    }
};

export const hotel_room_price_confirm = async (dispatch, roomdata, toast) => {
    dispatch(start_loading());
    try {
        const url = `${REACT_APP_OTA_GDS_URL}price-confirm`;
        const HEADERS = {
            headers: {
                "Content-Type": "application/json",
            }
        };
        const res = await axios.post(url, JSON.stringify(roomdata), HEADERS);
        if (res?.data && res?.data?.status) {
            localStorage.setItem("BookingData", JSON.stringify(res?.data?.result));
            return res.data.status;
        } else {
            toast({ title: res?.data?.error, status: "error", duration: 2000, position: "top-right", isClosable: true, });
        }
    } catch (error) {
        toast({ title: error?.response?.data?.error?.Message ? error?.response?.data?.error?.Message : error?.response?.data?.error ? error?.response?.data?.error : error?.response?.data?.error?.Message, status: "error", duration: 2000, position: "top-right", isClosable: true, });
    } finally {
        dispatch(stop_loading());
    }
};

export const hotel_booking_confirm = async (dispatch, toast, vendor_id, data, token) => {
    dispatch(start_loading());
    try {
        const url = `${REACT_APP_OTA_URL}booking/book-hotel/${vendor_id}`;
        const HEADERS = {
            headers: {
                "Content-Type": "application/json",
                "x-access-token": token
            }
        };
        const res = await axios.post(url, JSON.stringify(data), HEADERS);
        if (res.data && res.data.status) {
            console.log("response of booking =========>", res.data);
            return true;
        }
    } catch (error) {
        // console.log("error in booking confirm", error);
        toast({ title: error.response.data.error.error ? error.response.data.error.error.Message : error.response.data.error, status: "error", duration: 2000, position: "top-right", isClosable: true, });
        // catch_errors_handle(error,dispatch,toast);
    } finally {
        dispatch(stop_loading());
    }
};

export const get_user_all_booking_lists = async (dispatch, vendor_id, token, page, limit, statusval) => {
    dispatch(start_loading());
    try {
        const url = `${REACT_APP_OTA_URL}booking/booking-list/${vendor_id}?page=${page}&limti=${limit}${statusval ? `&status=${statusval}` : ''}`;
        const HEADERS = {
            headers: {
                "x-access-token": token,
            }
        };
        const res = await axios.get(url, HEADERS);
        if (res.data && res.data.status) {
            dispatch({ type: GET_USER_BOOKING, payload: res.data });
        }
    } catch (error) {
        console.log("Error in Booking List ------>", error);
    } finally {
        dispatch(stop_loading());
    }
};

export const hotel_booking_cancel_by_user = async (dispatch, vendor_id, booking_no, Gds, toast) => {
    dispatch(start_loading());
    try {
        const url = `${REACT_APP_OTA_URL}booking/cancel-booking/${vendor_id}?booking_no=${booking_no}&Gds=${Gds}`;
        const res = await axios.get(url);
        if (res.data && res.data.status) {
            toast({ title: res.data.message, status: "success", duration: 2000, position: "top-right", isClosable: true, });
            return true;
        } else {
            return false;
        }
    } catch (error) {
        toast({ title: error.response.data.error, status: "error", duration: 2000, position: "top-right", isClosable: true, });
    } finally {
        dispatch(stop_loading());
    }
};

export const hotel_booking_details = async (dispatch, vendor_id, booking_no, Gds, bookigId) => {
    dispatch(start_loading());
    try {
        const url = `${REACT_APP_OTA_URL}booking/booking-details/${vendor_id}?booking_no=${booking_no}&provider=${Gds}&booking_Id=${bookigId}`;
        const res = await axios.get(url);
        if (res.data && res.data.status) {
            return res.data;
        }
    } catch (error) {
        console.log("Error in hotel details ------>", error);
    } finally {
        dispatch(stop_loading());
    }
};

export const get_hotel_room_lists = async (dispatch, roomdata) => {
    dispatch(start_loading());
    try {
        const url = `${REACT_APP_OTA_GDS_URL}rooms`;
        const HEADERS = {
            headers: {
                "Content-Type": "application/json",
            }
        };
        const res = await axios.post(url, JSON.stringify(roomdata), HEADERS);
        if (res.data && res.data.status) {
            dispatch({ type: GET_ROOMS_LISTS, payload: res.data.result });
        }
    } catch (error) {
        console.log("Error in hotel room lists ------>", error);
    } finally {
        dispatch(stop_loading());
    }
};

export const get_user_upcomming_booking_lists = async (dispatch, vendor_id, token, page, limit) => {
    dispatch(start_loading());
    try {
        const url = `${REACT_APP_OTA_URL}profile/upcomming/${vendor_id}?page=${page}&limti=${limit}`;
        const HEADERS = {
            headers: {
                "x-access-token": token,
            }
        };
        const res = await axios.get(url, HEADERS);
        if (res.data && res.data.status) {
            dispatch({ type: GET_USER_UPCOMMING_BOOKING, payload: res.data });
        }
    } catch (error) {
        console.log("Error in Booking List ------>", error);
    } finally {
        dispatch(stop_loading());
    }
};

export const after_booking = async () => {
    localStorage.removeItem("searchedData");
    localStorage.removeItem("BookingData");
    localStorage.removeItem("roomsData");
    localStorage.removeItem("holderDetail");
    localStorage.removeItem("selectedHotelGDS");
    localStorage.removeItem("hotelDetail");
    localStorage.removeItem("totalrooms");
    localStorage.removeItem("totalOccupancies");
    localStorage.removeItem("hotelDetail");
    localStorage.removeItem("AllPersons");
    localStorage.removeItem("guestlists");
    localStorage.removeItem("selectedRoom");
    localStorage.removeItem("hotelId");
    localStorage.removeItem("Destinationdata");
    localStorage.removeItem("hotelData");
};
export const get_hotel_all_facilities = async (dispatch) => {
    dispatch(start_loading());
    try {
        const url = `${REACT_APP_OTA_GDS_URL}facilites`;
        const res = await axios.get(url);
        if (res.data && res.data.status) {
            dispatch({ type: GET_HOTEL_FACILITIES, payload: res.data });
        }
    } catch (error) {
        console.log("Error in Hotels facilities", error);
    } finally {
        dispatch(stop_loading());
    }
};

export const get_similar_top_hotels_lists = async (dispatch, data) => {
    dispatch(start_loading());
    try {
        const url = `${REACT_APP_OTA_GDS_URL}similar-hotel`;
        const HEADERS = {
            headers: {
                "Content-Type": "application/json",
            }
        };
        const res = await axios.post(url, JSON.stringify(data), HEADERS);
        if (res.data && res.data.status){
            dispatch({ type: GET_SIMILAR_TOP_HOTELS, payload: res.data.result });
        }
    } catch (error) {
        console.log("Error in Top Hotels", error);
    } finally {
        dispatch(stop_loading());
    }
};
