import React, { useEffect } from 'react'
import LocationCard from '../../shared/LocationCard';
import { useTranslation } from 'react-i18next';
import Slider from 'react-slick';
import { useDispatch, connect } from 'react-redux';
import { get_top_hotels_lists } from '../../reduxStore/booking/bookingActions';
import { useToast } from '@chakra-ui/react';
import debounce from 'lodash.debounce';

const TopHotels = ({ tophotels, userLocation }) => {
    const arr = [1, 2, 3, 4];
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const toast = useToast();
    const settings = {
        dots: false,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 2,
        infinite: true,
        autoplay: true,
        autoplaySpeed: 3000,
        centerMode: false,
        adaptiveHeight: false,
        responsive: [
            {
                breakpoint: 576,
                settings: {
                    slidesToShow: 1,
                },
            },
            {
                breakpoint: 769,
                settings: {
                    slidesToShow: 2,
                },
            },
            {
                breakpoint: 1025,
                settings: {
                    slidesToShow: 3,
                },
            },
        ]
    };

    // useEffect(() => {
    //     if (!userLocation) return;
    //     const fetchData = debounce(async () => {
    //         await get_top_hotels_lists(dispatch, { countryCode: userLocation?.short_name, countryName: userLocation?.long_name }, toast);
    //     }, 1000);
    //     fetchData();
    //     return () => {
    //         fetchData.cancel();
    //     };
    // }, [userLocation]);

    return (
        <div className=''>
            <div className='top-hotels-slider-wrap'>
                <div className='container'>
                    <h2 className='mb-3 position-relative z-index-1 '>{t('top_hotels')}</h2>

                    {/* {arr.map((item, index) =>
                            <div className='col-lg-3 col-md-6 col-sm-12' key={index}>
                                <LocationCard isHotel={true} />
                            </div>)} */}

                    {tophotels?.length > 0 && <Slider {...settings}>
                        {(tophotels)?.map((item, index) => <div key={index}>
                            <div class="">
                                <LocationCard item={item} isHotel={true} />
                            </div>
                        </div>
                        )}
                    </Slider>}

                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        tophotels: state.bookings.tophotels,
        userLocation: state.auth.userLocation,
    }
};
export default connect(mapStateToProps)(TopHotels);