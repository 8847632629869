import React, { useEffect, useState } from 'react'
import { get_vender_id, login_user } from '../reduxStore/User/userActions';
import { connect, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useToast } from '@chakra-ui/react';
import OTALogin from '../shared/OTALogin';
import OTPLogin from './OTPLogin';
import SocialLogin from './SocialLogin';
import { useTranslation } from 'react-i18next';

const Login = ({ user, vendor_id, isMobile }) => {
  const { t } = useTranslation();
  const [formdata, setFormdata] = useState({
    email: '',
    password: '',
  });

  const [errors, setErrors] = useState({
    email: '',
    password: '',
  });
  const [cookieData, setCookieData] = useState(null);
  const exptest = /^(?!.*\.\.)(?!.*-@)(?!.*#)([A-Z0-9._%+-]+)@([A-Z0-9.-]+\.[A-Z]{2,})$/i;
  const [isShow, setIsShow] = useState(false);
  const [isRedirect, setIsRedirect] = useState(false);
  const [isRemember, setIsRemember] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const toast = useToast();

  const handleChange = (label, value) => {
    switch (label) {
      case "email":
        setFormdata((prev) => ({ ...prev, [label]: value }));
        setErrors((prev) => ({ ...prev, [label]: value === "" ? `${t('email_valid')}` : !exptest.test(value) ? `${t('email_check')}` : null }));
        break;
      case "password":
        setFormdata((prev) => ({ ...prev, [label]: value }));
        setErrors((prev) => ({ ...prev, [label]: value === "" ? `${t('password_valid')}` : value.length <= 5 ? `${t('password_valid_check')}` : null }));
        break;

      default:
        setFormdata((prev) => ({ ...prev, [label]: value }));
        break;
    }
  };

  const handleValid = () => {
    let err = false;

    const Errors = {
      email: formdata.email === "" ? `${t('email_valid')}` : !exptest.test(formdata.email) ? `${t('email_check')}` : null,
      password: formdata.password === "" ? `${t('password_valid')}` : formdata.password.length <= 5 ? `${t('password_valid_check')}` : null,
    };

    let newErrors = Object.entries(Errors);
    newErrors.forEach(([key, value]) => {
      if (value) {
        err = true;
        setErrors((prev) => ({ ...prev, [key]: value }));
      }
    });

    return err;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (handleValid()) return;

    const userdata = { username: formdata.email.toLocaleLowerCase(), password: formdata.password, type: 'user' };
    // console.log("formdata submit --->", formdata);
    await login_user(userdata, dispatch, toast, navigate, vendor_id);
  };

  useEffect(() => {
    get_vender_id("superadmin", dispatch);
  }, []);

  // useEffect(() => {
  //   const fetchResult = async () => {
  //     try {
  //       const result = await getRedirectResult(auth);
  //       console.log("google ----->", result);
  //     } catch (error) {
  //       console.log("Error in reddirect ---->", error);
  //     }
  //   };

  //   // fetchResult();
  //   window.addEventListener("load", fetchResult);
  // }, []);

  // console.log(isMobile);

  // const handleRedirectResult = async () => {
  //   try {
  //     let result = await getRedirectResult(auth);
  //     if (result) {
  //       console.log("Mobile Data ------>", result);
  //     } else {
  //       console.log("No Result Found ----->");
  //     }
  //   } catch (error) {
  //     console.log(error)
  //   }
  // };

  // useEffect(() => {
  //   handleRedirectResult();
  // }, []);

  // useEffect(() => {
  //   console.log("rrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrr");
  //   const unsubscribe = onAuthStateChanged(auth, async (user) => {
  //     if (user) {
  //       console.log("User logged in after redirect:", user);
  //       const userdata = {
  //         google_id: user?.providerData[0]?.uid,
  //         name: user?.providerData[0]?.displayName,
  //         email: user?.providerData[0]?.email,
  //       };

  //       await handle_user_social_login(vendor_id, userdata, toast, navigate, dispatch);
  //     }
  //   });
  //   return () => unsubscribe();
  // }, []);

  const handleRemberMe = (event) => {
    const { checked } = event.target;
    if (checked) {
      setIsRemember(() => true);
      document.cookie = `email=${encodeURIComponent(formdata?.email)}; path=/`;
      document.cookie = `password=${encodeURIComponent(formdata?.password)}; path=/`;
    }

    else if (!checked) {
      setIsRemember(() => false);
      document.cookie = `email=; path=/`;
      document.cookie = `password=; path=/`;
    }
  };

  useEffect(() => {
    const cookies = document.cookie.split('; ').reduce((acc, cookie) => {
      const [name, value] = cookie.split('=');
      acc[name] = value;
      return acc;
  }, {});

  if (cookies.email) {
      let encodeemail = cookies.email.replace(/%40/g, '@');
      if (cookies.email) setFormdata((prevState) => ({ ...prevState, email: encodeemail }));
      if (cookies.password) setFormdata((prevState) => ({ ...prevState, password: cookies.password }));
      setIsRemember(true);
  }
  }, []);

  // useEffect(() => {
  //   // console.log(cookieData);
  //   if (cookieData !== null && cookieData?.email?.trim()) {
  //     console.log(cookieData);
  //   }
  // }, [cookieData]);

  return (
    <div className=''>
      <div className='container'>
        <div className='row justify-content-end'>
          <div className='col-xl-5 col-lg-6 col-md-8'>
            <div className='login-form-wrap'>
              <h1 className='text-center text-white mb-0'>
                {t('welcome_login')}
              </h1>

              <p className='text-center text-white h5 mt-3 or-text fw-normal'>
              {t('sign_with')}
              </p>

              <SocialLogin vendor_id={vendor_id} />

              <p className='text-center text-white h5 mb-3 or-text'>
               {t('or')}
              </p>

              <OTALogin
                formdata={formdata}
                errors={errors}
                handleChange={handleChange}
                handleSubmit={handleSubmit}
                isShow={isShow}
                setIsShow={() => setIsShow(!isShow)}
                handleRemberMe={handleRemberMe}
                isRemember={isRemember}
              />

              {/* <div class="d-flex flex-column justify-content-start gap-2">
                <span className='text-white'>Login with OTP</span>
                <Link className="nav-link white-border-btn text-white w-100" href="javascript:void(0)" to="/user-verify">
                  <img src="/images/Download.png" class="me-2 d-inline-block"></img>
                  Mobile Number
                </Link>
              </div> */}

              <OTPLogin />

            </div>
          </div>
        </div>

      </div>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    vendor_id: state.auth.vendor_id
  }
}
export default connect(mapStateToProps)(Login);