import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { hotel_booking_details } from '../../reduxStore/booking/bookingActions';
import { connect, useDispatch } from 'react-redux';
import { get_vender_id } from '../../reduxStore/User/userActions';
import MapImg from '../../assets/map.png';
import UserImg from '../../assets/user.png';
import CalenderImg from '../../assets/calendar.png';
import { useTranslation } from 'react-i18next';
import StarRating from '../../shared/StarRating';
import CurrencyLogo from '../../shared/CurrencyLogo';
import DownloadFile from '../../shared/DownloadFile';

const BookingDetails = ({ user, vendor_id }) => {
    const [bookingData, setBookingData] = useState(null);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const details = JSON.parse(localStorage.getItem("hotelData"));
    const goBack = JSON.parse(localStorage.getItem("goBackPath"));

    useEffect(() => {
        if (details === null) {
            navigate('/dashboard/bookings');
        }
    }, []);

    useEffect(() => {
        if (vendor_id === null) return;

        const fetchData = async () => {
            let getdata = await hotel_booking_details(dispatch, vendor_id, details?.BookingID, details?.provider, details?._id);
            if (getdata) {
                setBookingData(getdata?.data);
            }
        };
        fetchData();
    }, [vendor_id]);

    // console.log(bookingData);

    const formatDate = (inputdate) => {
        const date = new Date(inputdate);

        // Get day, date, and month
        const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
        const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

        const day = days[date.getDay()];
        const dayOfMonth = date.getDate();
        const month = months[date.getMonth()];
        const year = date.getFullYear();

        return `${day} ${dayOfMonth} ${month} ${year}`;
    };

    useEffect(() => {
        get_vender_id("superadmin", dispatch);
    }, []);

    return (
        <div className='position-relative'>
            <div className='booking-detail-header d-flex align-items-center justify-content-between mb-5'>
                <div className='booking-detail-header-left d-flex align-items-center gap-2'>
                    <button type="button" className='w-auto text-purple' onClick={() => { navigate(`${goBack}`); }} >
                        <i class="bi bi-arrow-left h4 mb-0"></i>
                    </button>
                    <div className='d-flex align-items-center gap-3'>
                        <h2 className='mb-0'>Booking Details</h2>
                        <div className={`booking-detail-status-label ${bookingData?.Status === 1 ? 'completed' : bookingData?.Status === 2 ? 'confirmed' : (bookingData?.Status === 3 || bookingData?.Status === 4) ? 'cancel' : 'processing'}`}>
                            <span>
                                {bookingData?.Status === 1 ? 'Completed' :
                                    bookingData?.Status === 2 ? 'Confirmed' :
                                        bookingData?.Status === 3 ? 'Cancelled' :
                                            bookingData?.Status === 4 ? 'Failed' :
                                                'Pending'}
                            </span>
                        </div>
                    </div>
                </div>
                <div className='booking-detail-header-btns d-flex align-items-center gap-3'>
                    <button type="button" className='w-auto add-review-btn btn-gradient-purple text-purple d-flex align-items-center' >
                        <i class="bi bi-plus h4 mb-0"></i> Add Reviews
                    </button>

                    <DownloadFile invoiceUrl={bookingData?.invoicePath} user={user} isDetail={true} />
                    {/* <button type="button" className='w-auto download-btn btn-gradient-purple d-flex align-items-center gap-2' >
                        <i class="bi bi-download h5 mb-0"></i>
                         Download
                    </button> */}
                </div>
            </div>

            <div className='row row-gap-5 dashboard-booking-details-row'>
                <div className='col-lg-7 col-md-6'>
                    <div className='table-header-wrapper'>
                        <h5 className='text-white mb-0'>Booking Details</h5>
                    </div>
                    <div className='ryt-room-select-price'>
                        <h5 className='hd-spacing-room-selct mt-0'>
                            {bookingData?.Hotel_details?.Name || 'N/A'}
                        </h5>
                        <div class="reviews-box d-flex align-items-baseline mb-2 gap-2">
                            <p className='d-inline-block p-md mb-0'>
                                {/* 4.5 */}
                                {bookingData?.Hotel_details?.rating || 'N/A'}
                            </p>
                            {bookingData?.Hotel_details?.rating && <StarRating rating={Number(bookingData?.Hotel_details?.rating)} />}
                            {/* <ul class="d-inline-block star-rating p-0 mb-0">
                                {Array.from({ length: Math.round(hotelDetail?.rating) }).map((_, index) => (
                                    <li class="d-inline-block" key={index}><i class="fas fa-star"></i></li>
                                ))}
                                <li class="d-inline-block"><i class="fas fa-star"></i></li>
                                <li class="d-inline-block"><i class="fas fa-star"></i></li>
                                <li class="d-inline-block"><i class="fas fa-star"></i></li>
                                <li class="d-inline-block"><i class="fas fa-star"></i></li>
                                <li class="d-inline-block"><i class="fas fa-star"></i></li>
                            </ul> */}
                            <p className='d-inline-block p-md mb-0'>
                                {/* {hotelDetail?.reviews && `(${hotelDetail?.reviews?.length} reviews)`} */}
                                ({bookingData?.Hotel_details?.total_reviews || 'N/A'} reviews)
                            </p>
                        </div>
                        <p className='p-lg mb-2 font-size-15 font-weight-500 d-flex align-items-center gap-2'>
                            <img src={MapImg} className='d-inline-block' alt='' />
                            {bookingData?.Hotel_details?.address || 'N/A'}
                        </p>

                        <p className='p-lg mb-2 font-size-15 font-weight-500 d-flex align-items-center gap-2'>
                            <img src={UserImg} className='d-inline-block ' alt='' />
                            {/* {guestLists ? guestLists?.length + 1 : 0} */}
                            {/* {totalOccupancies ? totalOccupancies?.totalPersons : 0} */}
                            {bookingData?.GuestList && bookingData?.GuestList?.length} {" "}{t('person')}
                        </p>

                        <p className='p-lgmb-2 font-size-15 font-weight-500 d-flex align-items-center gap-2'><img src={CalenderImg} className='d-inline-block' alt='' />
                            {bookingData?.CheckInDate ? formatDate(bookingData?.CheckInDate) : 'N/A'}
                            <span className='arrow-right-icon-wrap'>
                                <i className="fas fa-arrow-right mx-2"></i>
                            </span>
                            {bookingData?.CheckOutDate ? formatDate(bookingData?.CheckOutDate) : 'N/A'}
                        </p>
                    </div>
                </div>

                <div className='col-lg-5 col-md-6'>
                    <div className='table-header-wrapper'>
                        <h5 className='text-white mb-0'>Summary</h5>
                    </div>
                    <div className='ryt-room-select-price'>
                        {bookingData?.roomData && bookingData?.roomData?.map((item, index) => (
                            <div className='price-line-dotted' key={index}>
                                <div className=''>
                                    <p className='p-md mb-0 text-start'>
                                        {item?.RoomName}
                                    </p>
                                    <p className='p-sm mb-0 text-start'>
                                        {item?.RoomOccupancy?.RoomNum}
                                        {t('room')}
                                    </p>
                                </div>
                                <div className=''>
                                    <p className='p-lg mb-0 text-color-theme font-weight-500'>
                                        {/* <strike className='p-sm me-2 text-grey-light font-weight-normal'>$41</strike> */}
                                        <CurrencyLogo currencyCode={item?.Currency} /> {" "}
                                        {item?.TotalPrice}
                                    </p>
                                </div>
                            </div>
                        ))}

                        <div className='price-line-dotted align-items-center border-0 pb-0 pt-3 last-payment'>
                            <div className=''>
                                <p className='p-large mb-0 font-weight-600 text-color-theme text-start'>{t('total_payment')}</p>
                            </div>
                            <div className='text-end'>
                                <p className='p-large mb-0 font-weight-500 text-color-theme last-txt'>
                                    ${bookingData?.totalAmount}
                                    {/* <CurrencyLogo currencyCode={currency} />{totalOccupancies?.totalRooms * convertedPrice} */}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='col-lg-7 col-md-6'>
                    <div className='table-header-wrapper'>
                        <h5 className='text-white mb-0'>Person Details</h5>
                    </div>
                    <div className='ryt-room-select-price booking-personal-details-wrap'>
                        <div class="booking-personal-details">
                            <div className='row'>
                                <div className='col-lg-1 col-md-2'>
                                    <div className='booking-user-profile-img-wrap'>
                                        <i class="bi bi-person-circle h3 mb-0 text-purple"></i>
                                    </div>
                                </div>
                                <div className='col-lg-11 col-md-10'>
                                    <div className='mb-3'>
                                        <h5 className='booking-user-name mt-0 mb-1'>
                                            {bookingData?.booking_data?.Name || 'N/A'}
                                        </h5>
                                        <p className='p-md mb-0 booking-user-email'>
                                            {bookingData?.booking_data?.Email || 'N/A'}
                                        </p>
                                        <p className='p-md mb-0 booking-user-ph'>
                                            {bookingData?.booking_data?.phone_number || 'N/A'}
                                        </p>
                                    </div>

                                    <div className='booking-personal-address'>
                                        <h5 className='mb-1'>Address</h5>
                                        <p className='d-inline-block p-md mb-0'>
                                            {bookingData?.booking_data?.Address || 'N/A'}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='col-lg-5 col-md-6'>
                    <div className='table-header-wrapper'>
                        <h5 className='text-white mb-0'>Payment Method</h5>
                    </div>
                    <div className='ryt-room-select-price'>
                        <div className='d-flex gap-3'>
                            <div className='travel-top-img'>
                                <i class="bi bi-credit-card-2-back h3 mb-0 text-purple"></i>
                            </div>
                            <div className=''>
                                <h5 className='font-family-poppins mb-1'>
                                    Card Number
                                </h5>
                                <p className='mb-0 p-md'>  {bookingData?.card?.last4 !== null ? `**** **** **** ${bookingData?.card?.last4}` : `**** **** **** ****`}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        user: state.auth.user,
        vendor_id: state.auth.vendor_id,
        bookingList: state.bookings.bookingList,
        totalBookings: state.bookings.totalBookings,
        currency: state.auth.currency
    }
}
export default connect(mapStateToProps)(BookingDetails);