import React, { useEffect, useRef, useState } from "react";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  Flex,
  Heading,
  Input,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Spinner,
  IconButton,
  Text,
  useToast,
} from "@chakra-ui/react";
import { DeleteIcon, SearchIcon } from "@chakra-ui/icons";
import ManagePages from "./manage-pages";
import { useDispatch, useSelector } from "react-redux";
import { del_cms_pages, get_cms_pages } from "../../../../reduxStore/vendors/vendorActions";
import debounce from "lodash.debounce";
import CustomPagination from "../../../../shared/CustomPagination";
import Cancel from "../../../../Modals/Cancel";
const CmsPages = () => {
  const user = useSelector(state => state.auth.user);
  const toast = useToast();
  const dispatch = useDispatch();
  const {cms_pages,cms_pages_total} = useSelector(state => state.vendor);
  const [cpage,setCurrentPage]=useState(1);
  const [limit, setLimit]=useState(10);
  const [search,setSearch]=useState(null);
  const [pagesArr,setPagesArr]  = useState([
    { name: "Privacy Policy", route: "privacy-policy", disabled: false },
    { name: "Terms and Conditions", route: "terms", disabled: false },
  ]);
  const [pages, setPages] = useState([]);
  const [deleteStorePages, setDeleteStorePages] = useState(null);
  useEffect(() => {
      if(cms_pages?.length>0){
        let pArr=[...pagesArr];
        cms_pages.forEach((page) => {
          let index = pArr.findIndex((p) => p.name === page.name);
          if (index >= 0) {
            pArr[index].disabled = true;
          }
          setPagesArr(pArr);
        });
        setPages(cms_pages);
      }
  }, [cms_pages]);
  useEffect(() => {
    const fetchPages = debounce(async () => {
      await get_cms_pages(dispatch,user?.token,cpage,limit,toast,search);
    }, 1000);
    fetchPages();
    return () => {
      fetchPages.cancel();
    };
  }, [user?.token, dispatch,toast,cpage,limit,search]);

  const deletePage = async() => {
    await del_cms_pages(dispatch,user?.token,toast,deleteStorePages?._id);
    setDeleteStorePages(null);
  };


  return (
    <Box p={6}>
      <Flex justify="space-between" align="center" mb={6}>
        <Heading size="lg">CMS Pages</Heading>
        <ManagePages pageArr={pagesArr}/>
      </Flex>

      <Flex align="center" mb={4}>
        <Input placeholder="Search by name" width="300px" onChange={(e)=>setSearch(e.target.value)}/>
        <IconButton icon={<SearchIcon />} aria-label="Search" />
      </Flex>

      <Box border="1px" borderColor="gray.200" borderRadius="md" overflow="hidden">
        <Table variant="simple">
          <Thead>
            <Tr>
              <Th>#</Th>
              <Th>Page Name</Th>
              <Th>Status</Th>
              <Th textAlign="center">Action</Th>
            </Tr>
          </Thead>
          <Tbody>
            {pages.length > 0 ? (
              pages.map((page, idx) => (
                <Tr key={page._id}>
                  <Td>{idx + 1}</Td>
                  <Td>{page.name}</Td>
                  <Td>{page.status ? "Active" : "Inactive"}</Td>
                  <Td textAlign="center">
                    <Flex justify="center" gap={4}>
                      <ManagePages pageArr={pages} page={page}/>
                      <IconButton
                        icon={<DeleteIcon />}
                        aria-label="Delete"
                        onClick={() => setDeleteStorePages(page)}
                      />
                    </Flex>
                  </Td>
                </Tr>
              ))
            ) : (
              <Tr>
                <Td colSpan="4" textAlign="center">
                  <Text color="gray.500">List is empty!</Text>
                </Td>
              </Tr>
            )}
          </Tbody>
        </Table>
        {(cms_pages_total > limit) &&
          <CustomPagination
            total={cms_pages_total}
            onPageChange={(page, perpage) => {
              setLimit(perpage);
              setCurrentPage(page);
            }}
        />}
      </Box>
      <Cancel
        show={deleteStorePages?true:false}
        handleClose={() => {
            setDeleteStorePages(null);
        }}
        content1={`Are you sure you want to delete ${deleteStorePages?.name}?`}
        handleCancel={()=>deletePage()}
      />
    </Box>
  );
};

export default CmsPages;