import React, { useEffect, useState } from 'react'
import ResultOne from '../../../src/assets/result-img.png'
import AirCondition from '../../assets/ac-icon.svg'
import BanqueRimg from '../../assets/banquet-icon.svg'
import GardenHotel from '../../assets/garden-icon.svg'
import PoolHotel from '../../assets/pool-icon.svg'
import WifiHotel from '../../assets/wifi-icon.svg'
import GymHotel from '../../assets/gym-icon.svg'
import ParkingHotel from '../../assets/parking-icon.svg'
import BuffetHotel from '../../assets/buffet-icon.svg'
import BeachHotel from '../../assets/beach-icon.svg'
import BarsHotel from '../../assets/bar-icon.svg'
import BarberHotel from '../../assets/barber-icon.svg'
import AtmDetail from '../../assets/ATM-icon.svg'
import ReceptionsIMG from '../../assets/reception-icon.svg'
import CafeHotel from '../../assets/cafe-icon.svg'
import { connect, useDispatch, useSelector } from 'react-redux'
import { get_hotel_all_facilities, get_search_hotel_results, get_search_hotels_lists } from '../../reduxStore/booking/bookingActions'
import SearchHotel from '../../shared/SearchHotel'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useToast } from '@chakra-ui/react'
import ReactStars from 'react-stars'
import debounce from 'lodash.debounce'

const HotelsResult = ({ hotels, currency }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const toast = useToast();
    const {hotelAllFacilities}=useSelector(state=>state.bookings);
    const { t } = useTranslation();
    
    const [allHotels, setAllHotels] = useState([]);
    const [selectedRatings, setSelectedRatings] = useState([]);
    const searchData = JSON.parse(localStorage.getItem("searchedData"));
    const [isTouch, setIsTouch] = useState(false);
    const [facilities, setFacilities] = useState([]);
    const [facilitiesCheck, setFacilitiesCheck] = useState([]);
    const ratings = [5, 4, 3, 2, 1];
    useEffect(() => {
        const fetchTheme = debounce(async () => {
            get_hotel_all_facilities(dispatch);
        }, 1000);
        fetchTheme();
        return () => {
          fetchTheme.cancel();
        };
      }, [dispatch]);
    useEffect(() => {
        if (searchData === null || searchData === undefined) {
            navigate("/");
        }
        
    }, []);
    useEffect(()=>{
      if(hotelAllFacilities?.length>0){
         let all=[];
         hotelAllFacilities?.forEach((fac)=>{
            all.push({name:fac.replace(' ','_'),value:fac});
         })
        //  console.log(all)
         setFacilities(all);
      }
      
    },[hotelAllFacilities])
    useEffect(()=>{
        if(hotels?.length>0){
            let filters=(facilitiesCheck?.length>0)?hotels?.filter(obj => 
              (obj?.facilities || [])?.some(value => facilitiesCheck?.includes(value))
            ):hotels;
            setAllHotels(filters);
        }
      },[hotels,facilitiesCheck,facilitiesCheck?.length])

    // useEffect(() => {
    //     get_search_hotels_lists(dispatch);
    // }, []);

    const formatDate = (inputdate) => {
        const date = new Date(inputdate);

        // Get day, date, and month
        const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
        const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

        const day = days[date.getDay()];
        const dayOfMonth = date.getDate();
        const month = months[date.getMonth()];

        return `${day} ${dayOfMonth} ${month}`;
    };


    const handleRating = (target, value) => {
        setIsTouch(true);
        if (target) {
            setSelectedRatings((prev) => {
                if (!prev.includes(value)) {
                    return [...prev, value];
                }
                return prev;
            });
        } else {
            setSelectedRatings((prev) => prev.filter((item) => item !== value));
        }
    };

    useEffect(() => {
        if (isTouch === false) return;

        const handleFilter = debounce(async () => {
            const newData = {
                ...searchData,
                star_rating: selectedRatings?.length === 0 ? null : selectedRatings
            };
            console.log(newData);
            await get_search_hotel_results(dispatch, newData, toast);
        }, 1000);
        handleFilter();
        return () => {
            handleFilter.cancel();
        };
    }, [selectedRatings]);

    useEffect(() => {
        if (selectedRatings?.length > 0) return;

        if (searchData === null || searchData === undefined) return;
        const fetch_data = debounce(async () => {
            const data = {...searchData};
            data.Currency = currency;
            await get_search_hotel_results(dispatch, data, toast);
        }, 1000);
        fetch_data();
        return () => {
            fetch_data.cancel();
        };
    }, [currency]);
    return (
        <div>
            <div className='srch-result-wrapper'>
                <div className='container'>
                    <div class="row align-items-start">
                        <div className='col-lg-3 col-md-12 col-sm-12'>
                            <div className='left-filter-wrapper'>
                                <div className=''>
                                    <div className='d-flex align-items-center justify-content-between bottom-border-filter'>
                                        <h3 className='mb-0'>{t('filter')}</h3>
                                        <p className='p-sm mb-0 text-purple font-weight-600 cursor-pointer'
                                            onClick={() => {
                                                setSelectedRatings([]);
                                                setFacilitiesCheck([]);
                                            }}>{t('clear_filter')}</p>
                                    </div>
                                    <div className=''>
                                        <div class="accordion accordion-flush" id="accordionFlushExample">
                                            <div class="accordion-item">
                                                <h2 class="accordion-header" id="flush-headingOne">
                                                    <button class="accordion-button collapsed acoordian-mine-button p-0 align-items-center justify-content-between" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                                                        {t('hotel_category')}
                                                    </button>
                                                </h2>
                                                <div id="flush-collapseOne" class="accordion-collapse collapse show" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                                                    <div class="accordion-body p-0">
                                                        <form className='filter-checkobx-form'>
                                                            {ratings?.map((item, idx) => (
                                                                <div class="form-check" key={idx}>
                                                                    <input
                                                                        className="form-check-input form-check-border"
                                                                        type="checkbox" value={item}
                                                                        id="flexCheckDefault"
                                                                        onChange={(e) => {
                                                                            handleRating(e.target.checked, e.target.value);
                                                                        }}
                                                                        checked={selectedRatings?.find(i => Number(i) === item)}
                                                                    />
                                                                    <label className="form-check-label lh-1" for="flexCheckDefault">
                                                                        <div class="reviews-box d-flex align-items-center mb-2 gap-2">
                                                                            <p className='d-inline-block p-md mb-0 lh-1'>{item}</p>
                                                                            <ul class="d-inline-block star-rating p-0 mb-0">
                                                                                {Array.from({ length: item }).map((_, idex) => (
                                                                                    <li class="d-inline-block p-sm lh-1" key={idex}><i class="fas fa-star"></i></li>
                                                                                ))}
                                                                            </ul>
                                                                        </div>
                                                                    </label>
                                                                </div>
                                                            ))}
                                                        </form>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="accordion-item">
                                                <h2 class="accordion-header" id="flush-headingTwo">
                                                    <button class="accordion-button acoordian-mine-button p-0 collapsed align-items-center justify-content-between" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
                                                        {t('hotel_amenties')}
                                                    </button>
                                                </h2>
                                                <div id="flush-collapseTwo" class="accordion-collapse collapse show" aria-labelledby="flush-headingTwo" data-bs-parent="#accordionFlushExample">
                                                    <div class="accordion-body p-0">
                                                       <form>
                                                            {facilities?.map(fac=><div key={fac?.value} class="form-check" 
                                                               onClick={()=>setFacilitiesCheck((prev) =>
                                                                 prev.includes(fac?.value) ? prev.filter((v) => v !== fac?.value) : [...prev, fac?.value]
                                                               )}
                                                            >
                                                                <input class="form-check-input form-check-border" type="checkbox" value={fac?.value} checked={facilitiesCheck.includes(fac?.value)} id={fac?.value} />
                                                                <label class="form-check-label p-md text-grey-light" for={fac?.value}>
                                                                  {t(`hotel_facilities.${fac.name}`)}
                                                                </label>
                                                            </div>)}
                                                        </form>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="accordion-item">
                                                <h2 class="accordion-header" id="flush-headingThree">
                                                    <button class="accordion-button collapsed acoordian-mine-button p-0 mb-1 justify-content-between align-items-center" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree">
                                                        {t('cancel_policy')}
                                                    </button>
                                                </h2>
                                                <div id="flush-collapseThree" class="accordion-collapse collapse show" aria-labelledby="flush-headingThree" data-bs-parent="#accordionFlushExample">
                                                    <div class="accordion-body p-0">
                                                        <form>
                                                            <div class="form-check">
                                                                <input class="form-check-input form-check-border" type="checkbox" value="" id="flexCheckDefault" />
                                                                <label class="form-check-label p-md text-grey-light" for="flexCheckDefault">
                                                                    {t('free_policy')}
                                                                </label>
                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-9 col-md-12 col-sm-12">
                            <div class="tab-content" id="v-pills-tabContent">
                                <div class="tab-pane fade show active" id="v-pills-air" role="tabpanel" aria-labelledby="v-pills-air-tab">
                                    <div className='ryt-resultshow-wrapper'>
                                        <div className='show_results-text-wrap d-flex align-items-center justify-content-between gap-3 flex-wrap mb-4'>
                                            <h3 className='text-grey-light spacing-result-txt mb-0'>
                                                {t("show_results")}
                                                <span className='font-weight-600 text-color-theme'>
                                                    {searchData && searchData?.cityName ? ` "${searchData?.cityName}" ` : '-'}
                                                    {/* “New Delhi” */}
                                                </span>
                                                {/* to */}
                                                {/* <span className='font-weight-600 text-color-theme'>
                                                “Dubai”
                                            </span> */}
                                                {formatDate(searchData?.CheckInDate)} -  {formatDate(searchData?.CheckOutDate)}
                                            </h3>
                                            <div className='show_results-filter-wrap'>
                                                <select class="form-control form-select" aria-label="Default select example">
                                                    {/* <option selected>Open this select menu</option> */}
                                                    <option value="1">Reviews</option>
                                                    <option value="2">Two</option>
                                                    <option value="3">Three</option>
                                                </select>
                                            </div>
                                        </div>

                                        {allHotels?.map((item, index) => (
                                            <SearchHotel item={item} index={index} />
                                        ))}
                                    </div>
                                </div>


                                <div class="tab-pane fade" id="v-pills-recep" role="tabpanel" aria-labelledby="v-pills-recep-tab">
                                    <div className='ryt-resultshow-wrapper'>
                                        <h3 className='text-grey-light mb-3'>Showing result for <span className='font-weight-600 text-color-theme'>“Saudi Arabia”</span></h3>
                                        <div className='w-100 d-grid grid-resultshow'>
                                            <div className='result-img-rounded'>
                                                <img src={ResultOne} alt='' />
                                            </div>

                                            <div className='srch-details-box'>
                                                <h3 className='mb-0'>Movenpick Hotel And Residences Riyadh</h3>
                                                <div class="reviews-box d-flex align-items-baseline mb-1 gap-2">
                                                    <p className='d-inline-block text-grey-light  p-all-small mb-0'>4.3</p>
                                                    <ul class="d-inline-block star-rating p-0 mb-0">
                                                        <li class="d-inline-block"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li><li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                    </ul>
                                                    <p className='d-inline-block p-all-small text-grey-light mb-0'>(144 reviews)</p>
                                                </div>
                                                <div className='row'>
                                                    <div className='col-lg-12 col-md-12 col-sm-12 width-mobile-adjust'>
                                                        <div className='room-feature'>
                                                            <ul className='p-0'>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={AirCondition} className='d-inline-block' alt='' />Air Condition </li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={ReceptionsIMG} className='d-inline-block' alt='' />24H. Reception</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={AtmDetail} className='d-inline-block' alt='' />ATM</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={BanqueRimg} className='d-inline-block' alt='' />Gym</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div className='col-lg-12 col-md-12 col-sm-12 width-mobile-adjust'>
                                                        <div className='room-feature'>
                                                            <ul className='p-0'>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={ParkingHotel} className='d-inline-block' alt='' />Parking</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={WifiHotel} className='d-inline-block' alt='' />Free Wifi</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='srch-price-box'>
                                                <div className='inner-srch-spacing'>
                                                    <p className='d-inline-block size-big mb-0 tab-0-content-sp'><span className='yellow-big-txt'>$55</span><strike>$66</strike></p>
                                                    <p className='p-md'>1 Night(incl.VAT)</p>
                                                    <a href='' className='btn btn-gradient-purple text-white d-inline-block h-auto w-100'>Book Now</a>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='w-100 d-grid grid-resultshow'>
                                            <div className='result-img-rounded'>
                                                <img src={ResultOne} alt='' />
                                            </div>

                                            <div className='srch-details-box'>
                                                <h3 className='mb-0'>Movenpick Hotel And Residences Riyadh</h3>
                                                <div class="reviews-box d-flex align-items-baseline mb-1 gap-2">
                                                    <p className='d-inline-block text-grey-light  p-all-small mb-0'>4.3</p>
                                                    <ul class="d-inline-block star-rating p-0 mb-0">
                                                        <li class="d-inline-block"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li><li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                    </ul>
                                                    <p className='d-inline-block p-all-small text-grey-light mb-0'>(144 reviews)</p>
                                                </div>
                                                <div className='row'>
                                                    <div className='col-lg-12 col-md-12 col-sm-12 width-mobile-adjust'>
                                                        <div className='room-feature'>
                                                            <ul className='p-0'>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={AirCondition} className='d-inline-block' alt='' />Air Condition </li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={ReceptionsIMG} className='d-inline-block' alt='' />24H. Reception</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={AtmDetail} className='d-inline-block' alt='' />ATM</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={BanqueRimg} className='d-inline-block' alt='' />Gym</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div className='col-lg-12 col-md-12 col-sm-12 width-mobile-adjust'>
                                                        <div className='room-feature'>
                                                            <ul className='p-0'>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={ParkingHotel} className='d-inline-block' alt='' />Parking</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={WifiHotel} className='d-inline-block' alt='' />Free Wifi</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='srch-price-box'>
                                                <div className='inner-srch-spacing'>
                                                    <p className='d-inline-block size-big mb-0 tab-0-content-sp'>
                                                        <span className='yellow-big-txt'>$55</span>
                                                        <strike>$66</strike>
                                                    </p>
                                                    <p className='p-md'>1 Night(incl.VAT)</p>
                                                    <a href='' className='btn btn-gradient-purple text-white d-inline-block h-auto w-100'>Book Now</a>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='w-100 d-grid grid-resultshow'>
                                            <div className='result-img-rounded'>
                                                <img src={ResultOne} alt='' />
                                            </div>

                                            <div className='srch-details-box'>
                                                <h3 className='mb-0'>Movenpick Hotel And Residences Riyadh</h3>
                                                <div class="reviews-box d-flex align-items-baseline mb-1 gap-2">
                                                    <p className='d-inline-block text-grey-light  p-all-small mb-0'>4.3</p>
                                                    <ul class="d-inline-block star-rating p-0 mb-0">
                                                        <li class="d-inline-block"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li><li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                    </ul>
                                                    <p className='d-inline-block p-all-small text-grey-light mb-0'>(144 reviews)</p>
                                                </div>
                                                <div className='row'>
                                                    <div className='col-lg-12 col-md-12 col-sm-12 width-mobile-adjust'>
                                                        <div className='room-feature'>
                                                            <ul className='p-0'>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={AirCondition} className='d-inline-block' alt='' />Air Condition </li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={ReceptionsIMG} className='d-inline-block' alt='' />24H. Reception</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={AtmDetail} className='d-inline-block' alt='' />ATM</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={BanqueRimg} className='d-inline-block' alt='' />Gym</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div className='col-lg-12 col-md-12 col-sm-12 width-mobile-adjust'>
                                                        <div className='room-feature'>
                                                            <ul className='p-0'>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={ParkingHotel} className='d-inline-block' alt='' />Parking</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={WifiHotel} className='d-inline-block' alt='' />Free Wifi</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='srch-price-box'>
                                                <div className='inner-srch-spacing'>
                                                    <p className='d-inline-block size-big mb-0 tab-0-content-sp'><span className='yellow-big-txt'>$55</span><strike>$66</strike></p>
                                                    <p className='p-md'>1 Night(incl.VAT)</p>
                                                    <a href='' className='btn btn-gradient-purple text-white d-inline-block h-auto w-100'>Book Now</a>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='w-100 d-grid grid-resultshow'>
                                            <div className='result-img-rounded'>
                                                <img src={ResultOne} alt='' />
                                            </div>

                                            <div className='srch-details-box'>
                                                <h3 className='mb-0'>Movenpick Hotel And Residences Riyadh</h3>
                                                <div class="reviews-box d-flex align-items-baseline mb-1 gap-2">
                                                    <p className='d-inline-block text-grey-light  p-all-small mb-0'>4.3</p>
                                                    <ul class="d-inline-block star-rating p-0 mb-0">
                                                        <li class="d-inline-block"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li><li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                    </ul>
                                                    <p className='d-inline-block p-all-small text-grey-light mb-0'>(144 reviews)</p>
                                                </div>
                                                <div className='row'>
                                                    <div className='col-lg-12 col-md-12 col-sm-12 width-mobile-adjust'>
                                                        <div className='room-feature'>
                                                            <ul className='p-0'>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={AirCondition} className='d-inline-block' alt='' />Air Condition </li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={ReceptionsIMG} className='d-inline-block' alt='' />24H. Reception</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={AtmDetail} className='d-inline-block' alt='' />ATM</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={BanqueRimg} className='d-inline-block' alt='' />Gym</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div className='col-lg-12 col-md-12 col-sm-12 width-mobile-adjust'>
                                                        <div className='room-feature'>
                                                            <ul className='p-0'>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={ParkingHotel} className='d-inline-block' alt='' />Parking</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={WifiHotel} className='d-inline-block' alt='' />Free Wifi</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='srch-price-box'>
                                                <div className='inner-srch-spacing'>
                                                    <p className='d-inline-block size-big mb-0 tab-0-content-sp'><span className='yellow-big-txt'>$55</span><strike>$66</strike></p>
                                                    <p className='p-md'>1 Night(incl.VAT)</p>
                                                    <a href='' className='btn btn-gradient-purple text-white d-inline-block h-auto w-100'>Book Now</a>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='w-100 d-grid grid-resultshow'>
                                            <div className='result-img-rounded'>
                                                <img src={ResultOne} alt='' />
                                            </div>

                                            <div className='srch-details-box'>
                                                <h3 className='mb-0'>Movenpick Hotel And Residences Riyadh</h3>
                                                <div class="reviews-box d-flex align-items-baseline mb-1 gap-2">
                                                    <p className='d-inline-block text-grey-light  p-all-small mb-0'>4.3</p>
                                                    <ul class="d-inline-block star-rating p-0 mb-0">
                                                        <li class="d-inline-block"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li><li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                    </ul>
                                                    <p className='d-inline-block p-all-small text-grey-light mb-0'>(144 reviews)</p>
                                                </div>
                                                <div className='row'>
                                                    <div className='col-lg-12 col-md-12 col-sm-12 width-mobile-adjust'>
                                                        <div className='room-feature'>
                                                            <ul className='p-0'>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={AirCondition} className='d-inline-block' alt='' />Air Condition </li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={ReceptionsIMG} className='d-inline-block' alt='' />24H. Reception</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={AtmDetail} className='d-inline-block' alt='' />ATM</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={BanqueRimg} className='d-inline-block' alt='' />Gym</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div className='col-lg-12 col-md-12 col-sm-12 width-mobile-adjust'>
                                                        <div className='room-feature'>
                                                            <ul className='p-0'>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={ParkingHotel} className='d-inline-block' alt='' />Parking</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={WifiHotel} className='d-inline-block' alt='' />Free Wifi</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='srch-price-box'>
                                                <div className='inner-srch-spacing'>
                                                    <p className='d-inline-block size-big mb-0 tab-0-content-sp'><span className='yellow-big-txt'>$55</span><strike>$66</strike></p>
                                                    <p className='p-md'>1 Night(incl.VAT)</p>
                                                    <a href='' className='btn btn-gradient-purple text-white d-inline-block h-auto w-100'>Book Now</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="tab-pane fade" id="v-pills-atm" role="tabpanel" aria-labelledby="v-pills-atm-tab">
                                    <div className='ryt-resultshow-wrapper'>
                                        <h3 className='text-grey-light mb-3'>Showing result for <span className='font-weight-600 text-color-theme'>“Saudi Arabia”</span></h3>
                                        <div className='w-100 d-grid grid-resultshow'>
                                            <div className='result-img-rounded'>
                                                <img src={ResultOne} alt='' />
                                            </div>

                                            <div className='srch-details-box'>
                                                <h3 className='mb-0'>Movenpick Hotel And Residences Riyadh</h3>
                                                <div class="reviews-box d-flex align-items-baseline mb-1 gap-2">
                                                    <p className='d-inline-block text-grey-light  p-all-small mb-0'>4.3</p>
                                                    <ul class="d-inline-block star-rating p-0 mb-0">
                                                        <li class="d-inline-block"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li><li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                    </ul>
                                                    <p className='d-inline-block p-all-small text-grey-light mb-0'>(144 reviews)</p>
                                                </div>
                                                <div className='row'>
                                                    <div className='col-lg-12 col-md-12 col-sm-12 width-mobile-adjust'>
                                                        <div className='room-feature'>
                                                            <ul className='p-0'>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={AirCondition} className='d-inline-block' alt='' />Air Condition </li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={ReceptionsIMG} className='d-inline-block' alt='' />24H. Reception</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={AtmDetail} className='d-inline-block' alt='' />ATM</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={BanqueRimg} className='d-inline-block' alt='' />Gym</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div className='col-lg-12 col-md-12 col-sm-12 width-mobile-adjust'>
                                                        <div className='room-feature'>
                                                            <ul className='p-0'>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={ParkingHotel} className='d-inline-block' alt='' />Parking</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={WifiHotel} className='d-inline-block' alt='' />Free Wifi</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='srch-price-box'>
                                                <div className='inner-srch-spacing'>
                                                    <p className='d-inline-block size-big mb-0 tab-0-content-sp'><span className='yellow-big-txt'>$55</span><strike>$66</strike></p>
                                                    <p className='p-md'>1 Night(incl.VAT)</p>
                                                    <a href='' className='btn btn-gradient-purple text-white d-inline-block h-auto w-100'>Book Now</a>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='w-100 d-grid grid-resultshow'>
                                            <div className='result-img-rounded'>
                                                <img src={ResultOne} alt='' />
                                            </div>

                                            <div className='srch-details-box'>
                                                <h3 className='mb-0'>Movenpick Hotel And Residences Riyadh</h3>
                                                <div class="reviews-box d-flex align-items-baseline mb-1 gap-2">
                                                    <p className='d-inline-block text-grey-light  p-all-small mb-0'>4.3</p>
                                                    <ul class="d-inline-block star-rating p-0 mb-0">
                                                        <li class="d-inline-block"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li><li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                    </ul>
                                                    <p className='d-inline-block p-all-small text-grey-light mb-0'>(144 reviews)</p>
                                                </div>
                                                <div className='row'>
                                                    <div className='col-lg-12 col-md-12 col-sm-12 width-mobile-adjust'>
                                                        <div className='room-feature'>
                                                            <ul className='p-0'>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={AirCondition} className='d-inline-block' alt='' />Air Condition </li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={ReceptionsIMG} className='d-inline-block' alt='' />24H. Reception</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={AtmDetail} className='d-inline-block' alt='' />ATM</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={BanqueRimg} className='d-inline-block' alt='' />Gym</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div className='col-lg-12 col-md-12 col-sm-12 width-mobile-adjust'>
                                                        <div className='room-feature'>
                                                            <ul className='p-0'>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={ParkingHotel} className='d-inline-block' alt='' />Parking</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={WifiHotel} className='d-inline-block' alt='' />Free Wifi</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='srch-price-box'>
                                                <div className='inner-srch-spacing'>
                                                    <p className='d-inline-block size-big mb-0 tab-0-content-sp'><span className='yellow-big-txt'>$55</span><strike>$66</strike></p>
                                                    <p className='p-md'>1 Night(incl.VAT)</p>
                                                    <a href='' className='btn btn-gradient-purple text-white d-inline-block h-auto w-100'>Book Now</a>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='w-100 d-grid grid-resultshow'>
                                            <div className='result-img-rounded'>
                                                <img src={ResultOne} alt='' />
                                            </div>

                                            <div className='srch-details-box'>
                                                <h3 className='mb-0'>Movenpick Hotel And Residences Riyadh</h3>
                                                <div class="reviews-box d-flex align-items-baseline mb-1 gap-2">
                                                    <p className='d-inline-block text-grey-light  p-all-small mb-0'>4.3</p>
                                                    <ul class="d-inline-block star-rating p-0 mb-0">
                                                        <li class="d-inline-block"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li><li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                    </ul>
                                                    <p className='d-inline-block p-all-small text-grey-light mb-0'>(144 reviews)</p>
                                                </div>
                                                <div className='row'>
                                                    <div className='col-lg-12 col-md-12 col-sm-12 width-mobile-adjust'>
                                                        <div className='room-feature'>
                                                            <ul className='p-0'>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={AirCondition} className='d-inline-block' alt='' />Air Condition </li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={ReceptionsIMG} className='d-inline-block' alt='' />24H. Reception</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={AtmDetail} className='d-inline-block' alt='' />ATM</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={BanqueRimg} className='d-inline-block' alt='' />Gym</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div className='col-lg-12 col-md-12 col-sm-12 width-mobile-adjust'>
                                                        <div className='room-feature'>
                                                            <ul className='p-0'>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={ParkingHotel} className='d-inline-block' alt='' />Parking</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={WifiHotel} className='d-inline-block' alt='' />Free Wifi</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='srch-price-box'>
                                                <div className='inner-srch-spacing'>
                                                    <p className='d-inline-block size-big mb-0 tab-0-content-sp'><span className='yellow-big-txt'>$55</span><strike>$66</strike></p>
                                                    <p className='p-md'>1 Night(incl.VAT)</p>
                                                    <a href='' className='btn btn-gradient-purple text-white d-inline-block h-auto w-100'>Book Now</a>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='w-100 d-grid grid-resultshow'>
                                            <div className='result-img-rounded'>
                                                <img src={ResultOne} alt='' />
                                            </div>

                                            <div className='srch-details-box'>
                                                <h3 className='mb-0'>Movenpick Hotel And Residences Riyadh</h3>
                                                <div class="reviews-box d-flex align-items-baseline mb-1 gap-2">
                                                    <p className='d-inline-block text-grey-light  p-all-small mb-0'>4.3</p>
                                                    <ul class="d-inline-block star-rating p-0 mb-0">
                                                        <li class="d-inline-block"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li><li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                    </ul>
                                                    <p className='d-inline-block p-all-small text-grey-light mb-0'>(144 reviews)</p>
                                                </div>
                                                <div className='row'>
                                                    <div className='col-lg-12 col-md-12 col-sm-12 width-mobile-adjust'>
                                                        <div className='room-feature'>
                                                            <ul className='p-0'>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={AirCondition} className='d-inline-block' alt='' />Air Condition </li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={ReceptionsIMG} className='d-inline-block' alt='' />24H. Reception</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={AtmDetail} className='d-inline-block' alt='' />ATM</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={BanqueRimg} className='d-inline-block' alt='' />Gym</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div className='col-lg-12 col-md-12 col-sm-12 width-mobile-adjust'>
                                                        <div className='room-feature'>
                                                            <ul className='p-0'>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={ParkingHotel} className='d-inline-block' alt='' />Parking</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={WifiHotel} className='d-inline-block' alt='' />Free Wifi</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='srch-price-box'>
                                                <div className='inner-srch-spacing'>
                                                    <p className='d-inline-block size-big mb-0 tab-0-content-sp'><span className='yellow-big-txt'>$55</span><strike>$66</strike></p>
                                                    <p className='p-md'>1 Night(incl.VAT)</p>
                                                    <a href='' className='btn btn-gradient-purple text-white d-inline-block h-auto w-100'>Book Now</a>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='w-100 d-grid grid-resultshow'>
                                            <div className='result-img-rounded'>
                                                <img src={ResultOne} alt='' />
                                            </div>

                                            <div className='srch-details-box'>
                                                <h3 className='mb-0'>Movenpick Hotel And Residences Riyadh</h3>
                                                <div class="reviews-box d-flex align-items-baseline mb-1 gap-2">
                                                    <p className='d-inline-block text-grey-light  p-all-small mb-0'>4.3</p>
                                                    <ul class="d-inline-block star-rating p-0 mb-0">
                                                        <li class="d-inline-block"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li><li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                    </ul>
                                                    <p className='d-inline-block p-all-small text-grey-light mb-0'>(144 reviews)</p>
                                                </div>
                                                <div className='row'>
                                                    <div className='col-lg-12 col-md-12 col-sm-12 width-mobile-adjust'>
                                                        <div className='room-feature'>
                                                            <ul className='p-0'>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={AirCondition} className='d-inline-block' alt='' />Air Condition </li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={ReceptionsIMG} className='d-inline-block' alt='' />24H. Reception</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={AtmDetail} className='d-inline-block' alt='' />ATM</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={BanqueRimg} className='d-inline-block' alt='' />Gym</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div className='col-lg-12 col-md-12 col-sm-12 width-mobile-adjust'>
                                                        <div className='room-feature'>
                                                            <ul className='p-0'>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={ParkingHotel} className='d-inline-block' alt='' />Parking</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={WifiHotel} className='d-inline-block' alt='' />Free Wifi</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='srch-price-box'>
                                                <div className='inner-srch-spacing'>
                                                    <p className='d-inline-block size-big mb-0 tab-0-content-sp'><span className='yellow-big-txt'>$55</span><strike>$66</strike></p>
                                                    <p className='p-md'>1 Night(incl.VAT)</p>
                                                    <a href='' className='btn btn-gradient-purple text-white d-inline-block h-auto w-100'>Book Now</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="tab-pane fade" id="v-pills-banquet" role="tabpanel" aria-labelledby="v-pills-banquet-tab">
                                    <div className='ryt-resultshow-wrapper'>
                                        <h3 className='text-grey-light mb-3'>Showing result for <span className='font-weight-600 text-color-theme'>“Saudi Arabia”</span></h3>
                                        <div className='w-100 d-grid grid-resultshow'>
                                            <div className='result-img-rounded'>
                                                <img src={ResultOne} alt='' />
                                            </div>

                                            <div className='srch-details-box'>
                                                <h3 className='mb-0'>Movenpick Hotel And Residences Riyadh</h3>
                                                <div class="reviews-box d-flex align-items-baseline mb-1 gap-2">
                                                    <p className='d-inline-block text-grey-light  p-all-small mb-0'>4.3</p>
                                                    <ul class="d-inline-block star-rating p-0 mb-0">
                                                        <li class="d-inline-block"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li><li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                    </ul>
                                                    <p className='d-inline-block p-all-small text-grey-light mb-0'>(144 reviews)</p>
                                                </div>
                                                <div className='row'>
                                                    <div className='col-lg-12 col-md-12 col-sm-12 width-mobile-adjust'>
                                                        <div className='room-feature'>
                                                            <ul className='p-0'>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={AirCondition} className='d-inline-block' alt='' />Air Condition </li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={ReceptionsIMG} className='d-inline-block' alt='' />24H. Reception</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={AtmDetail} className='d-inline-block' alt='' />ATM</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={BanqueRimg} className='d-inline-block' alt='' />Gym</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div className='col-lg-12 col-md-12 col-sm-12 width-mobile-adjust'>
                                                        <div className='room-feature'>
                                                            <ul className='p-0'>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={ParkingHotel} className='d-inline-block' alt='' />Parking</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={WifiHotel} className='d-inline-block' alt='' />Free Wifi</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='srch-price-box'>
                                                <div className='inner-srch-spacing'>
                                                    <p className='d-inline-block size-big mb-0 tab-0-content-sp'><span className='yellow-big-txt'>$55</span><strike>$66</strike></p>
                                                    <p className='p-md'>1 Night(incl.VAT)</p>
                                                    <a href='' className='btn btn-gradient-purple text-white d-inline-block h-auto w-100'>Book Now</a>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='w-100 d-grid grid-resultshow'>
                                            <div className='result-img-rounded'>
                                                <img src={ResultOne} alt='' />
                                            </div>

                                            <div className='srch-details-box'>
                                                <h3 className='mb-0'>Movenpick Hotel And Residences Riyadh</h3>
                                                <div class="reviews-box d-flex align-items-baseline mb-1 gap-2">
                                                    <p className='d-inline-block text-grey-light  p-all-small mb-0'>4.3</p>
                                                    <ul class="d-inline-block star-rating p-0 mb-0">
                                                        <li class="d-inline-block"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li><li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                    </ul>
                                                    <p className='d-inline-block p-all-small text-grey-light mb-0'>(144 reviews)</p>
                                                </div>
                                                <div className='row'>
                                                    <div className='col-lg-12 col-md-12 col-sm-12 width-mobile-adjust'>
                                                        <div className='room-feature'>
                                                            <ul className='p-0'>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={AirCondition} className='d-inline-block' alt='' />Air Condition </li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={ReceptionsIMG} className='d-inline-block' alt='' />24H. Reception</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={AtmDetail} className='d-inline-block' alt='' />ATM</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={BanqueRimg} className='d-inline-block' alt='' />Gym</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div className='col-lg-12 col-md-12 col-sm-12 width-mobile-adjust'>
                                                        <div className='room-feature'>
                                                            <ul className='p-0'>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={ParkingHotel} className='d-inline-block' alt='' />Parking</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={WifiHotel} className='d-inline-block' alt='' />Free Wifi</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='srch-price-box'>
                                                <div className='inner-srch-spacing'>
                                                    <p className='d-inline-block size-big mb-0 tab-0-content-sp'><span className='yellow-big-txt'>$55</span><strike>$66</strike></p>
                                                    <p className='p-md'>1 Night(incl.VAT)</p>
                                                    <a href='' className='btn btn-gradient-purple text-white d-inline-block h-auto w-100'>Book Now</a>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='w-100 d-grid grid-resultshow'>
                                            <div className='result-img-rounded'>
                                                <img src={ResultOne} alt='' />
                                            </div>

                                            <div className='srch-details-box'>
                                                <h3 className='mb-0'>Movenpick Hotel And Residences Riyadh</h3>
                                                <div class="reviews-box d-flex align-items-baseline mb-1 gap-2">
                                                    <p className='d-inline-block text-grey-light  p-all-small mb-0'>4.3</p>
                                                    <ul class="d-inline-block star-rating p-0 mb-0">
                                                        <li class="d-inline-block"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li><li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                    </ul>
                                                    <p className='d-inline-block p-all-small text-grey-light mb-0'>(144 reviews)</p>
                                                </div>
                                                <div className='row'>
                                                    <div className='col-lg-12 col-md-12 col-sm-12 width-mobile-adjust'>
                                                        <div className='room-feature'>
                                                            <ul className='p-0'>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={AirCondition} className='d-inline-block' alt='' />Air Condition </li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={ReceptionsIMG} className='d-inline-block' alt='' />24H. Reception</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={AtmDetail} className='d-inline-block' alt='' />ATM</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={BanqueRimg} className='d-inline-block' alt='' />Gym</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div className='col-lg-12 col-md-12 col-sm-12 width-mobile-adjust'>
                                                        <div className='room-feature'>
                                                            <ul className='p-0'>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={ParkingHotel} className='d-inline-block' alt='' />Parking</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={WifiHotel} className='d-inline-block' alt='' />Free Wifi</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='srch-price-box'>
                                                <div className='inner-srch-spacing'>
                                                    <p className='d-inline-block size-big mb-0 tab-0-content-sp'><span className='yellow-big-txt'>$55</span><strike>$66</strike></p>
                                                    <p className='p-md'>1 Night(incl.VAT)</p>
                                                    <a href='' className='btn btn-gradient-purple text-white d-inline-block h-auto w-100'>Book Now</a>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='w-100 d-grid grid-resultshow'>
                                            <div className='result-img-rounded'>
                                                <img src={ResultOne} alt='' />
                                            </div>

                                            <div className='srch-details-box'>
                                                <h3 className='mb-0'>Movenpick Hotel And Residences Riyadh</h3>
                                                <div class="reviews-box d-flex align-items-baseline mb-1 gap-2">
                                                    <p className='d-inline-block text-grey-light  p-all-small mb-0'>4.3</p>
                                                    <ul class="d-inline-block star-rating p-0 mb-0">
                                                        <li class="d-inline-block"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li><li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                    </ul>
                                                    <p className='d-inline-block p-all-small text-grey-light mb-0'>(144 reviews)</p>
                                                </div>
                                                <div className='row'>
                                                    <div className='col-lg-12 col-md-12 col-sm-12 width-mobile-adjust'>
                                                        <div className='room-feature'>
                                                            <ul className='p-0'>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={AirCondition} className='d-inline-block' alt='' />Air Condition </li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={ReceptionsIMG} className='d-inline-block' alt='' />24H. Reception</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={AtmDetail} className='d-inline-block' alt='' />ATM</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={BanqueRimg} className='d-inline-block' alt='' />Gym</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div className='col-lg-12 col-md-12 col-sm-12 width-mobile-adjust'>
                                                        <div className='room-feature'>
                                                            <ul className='p-0'>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={ParkingHotel} className='d-inline-block' alt='' />Parking</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={WifiHotel} className='d-inline-block' alt='' />Free Wifi</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='srch-price-box'>
                                                <div className='inner-srch-spacing'>
                                                    <p className='d-inline-block size-big mb-0 tab-0-content-sp'><span className='yellow-big-txt'>$55</span><strike>$66</strike></p>
                                                    <p className='p-md'>1 Night(incl.VAT)</p>
                                                    <a href='' className='btn btn-gradient-purple text-white d-inline-block h-auto w-100'>Book Now</a>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='w-100 d-grid grid-resultshow'>
                                            <div className='result-img-rounded'>
                                                <img src={ResultOne} alt='' />
                                            </div>

                                            <div className='srch-details-box'>
                                                <h3 className='mb-0'>Movenpick Hotel And Residences Riyadh</h3>
                                                <div class="reviews-box d-flex align-items-baseline mb-1 gap-2">
                                                    <p className='d-inline-block text-grey-light  p-all-small mb-0'>4.3</p>
                                                    <ul class="d-inline-block star-rating p-0 mb-0">
                                                        <li class="d-inline-block"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li><li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                    </ul>
                                                    <p className='d-inline-block p-all-small text-grey-light mb-0'>(144 reviews)</p>
                                                </div>
                                                <div className='row'>
                                                    <div className='col-lg-12 col-md-12 col-sm-12 width-mobile-adjust'>
                                                        <div className='room-feature'>
                                                            <ul className='p-0'>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={AirCondition} className='d-inline-block' alt='' />Air Condition </li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={ReceptionsIMG} className='d-inline-block' alt='' />24H. Reception</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={AtmDetail} className='d-inline-block' alt='' />ATM</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={BanqueRimg} className='d-inline-block' alt='' />Gym</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div className='col-lg-12 col-md-12 col-sm-12 width-mobile-adjust'>
                                                        <div className='room-feature'>
                                                            <ul className='p-0'>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={ParkingHotel} className='d-inline-block' alt='' />Parking</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={WifiHotel} className='d-inline-block' alt='' />Free Wifi</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='srch-price-box'>
                                                <div className='inner-srch-spacing'>
                                                    <p className='d-inline-block size-big mb-0 tab-0-content-sp'><span className='yellow-big-txt'>$55</span><strike>$66</strike></p>
                                                    <p className='p-md'>1 Night(incl.VAT)</p>
                                                    <a href='' className='btn btn-gradient-purple text-white d-inline-block h-auto w-100'>Book Now</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="tab-pane fade" id="v-pills-salon" role="tabpanel" aria-labelledby="v-pills-salon-tab">
                                    <div className='ryt-resultshow-wrapper'>
                                        <h3 className='text-grey-light mb-3'>Showing result for <span className='font-weight-600 text-color-theme'>“Saudi Arabia”</span></h3>
                                        <div className='w-100 d-grid grid-resultshow'>
                                            <div className='result-img-rounded'>
                                                <img src={ResultOne} alt='' />
                                            </div>

                                            <div className='srch-details-box'>
                                                <h3 className='mb-0'>Movenpick Hotel And Residences Riyadh</h3>
                                                <div class="reviews-box d-flex align-items-baseline mb-1 gap-2">
                                                    <p className='d-inline-block text-grey-light  p-all-small mb-0'>4.3</p>
                                                    <ul class="d-inline-block star-rating p-0 mb-0">
                                                        <li class="d-inline-block"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li><li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                    </ul>
                                                    <p className='d-inline-block p-all-small text-grey-light mb-0'>(144 reviews)</p>
                                                </div>
                                                <div className='row'>
                                                    <div className='col-lg-12 col-md-12 col-sm-12 width-mobile-adjust'>
                                                        <div className='room-feature'>
                                                            <ul className='p-0'>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={AirCondition} className='d-inline-block' alt='' />Air Condition </li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={ReceptionsIMG} className='d-inline-block' alt='' />24H. Reception</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={AtmDetail} className='d-inline-block' alt='' />ATM</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={BanqueRimg} className='d-inline-block' alt='' />Gym</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div className='col-lg-12 col-md-12 col-sm-12 width-mobile-adjust'>
                                                        <div className='room-feature'>
                                                            <ul className='p-0'>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={ParkingHotel} className='d-inline-block' alt='' />Parking</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={WifiHotel} className='d-inline-block' alt='' />Free Wifi</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='srch-price-box'>
                                                <div className='inner-srch-spacing'>
                                                    <p className='d-inline-block size-big mb-0 tab-0-content-sp'><span className='yellow-big-txt'>$55</span><strike>$66</strike></p>
                                                    <p className='p-md'>1 Night(incl.VAT)</p>
                                                    <a href='' className='btn btn-gradient-purple text-white d-inline-block h-auto w-100'>Book Now</a>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='w-100 d-grid grid-resultshow'>
                                            <div className='result-img-rounded'>
                                                <img src={ResultOne} alt='' />
                                            </div>

                                            <div className='srch-details-box'>
                                                <h3 className='mb-0'>Movenpick Hotel And Residences Riyadh</h3>
                                                <div class="reviews-box d-flex align-items-baseline mb-1 gap-2">
                                                    <p className='d-inline-block text-grey-light  p-all-small mb-0'>4.3</p>
                                                    <ul class="d-inline-block star-rating p-0 mb-0">
                                                        <li class="d-inline-block"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li><li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                    </ul>
                                                    <p className='d-inline-block p-all-small text-grey-light mb-0'>(144 reviews)</p>
                                                </div>
                                                <div className='row'>
                                                    <div className='col-lg-12 col-md-12 col-sm-12 width-mobile-adjust'>
                                                        <div className='room-feature'>
                                                            <ul className='p-0'>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={AirCondition} className='d-inline-block' alt='' />Air Condition </li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={ReceptionsIMG} className='d-inline-block' alt='' />24H. Reception</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={AtmDetail} className='d-inline-block' alt='' />ATM</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={BanqueRimg} className='d-inline-block' alt='' />Gym</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div className='col-lg-12 col-md-12 col-sm-12 width-mobile-adjust'>
                                                        <div className='room-feature'>
                                                            <ul className='p-0'>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={ParkingHotel} className='d-inline-block' alt='' />Parking</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={WifiHotel} className='d-inline-block' alt='' />Free Wifi</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='srch-price-box'>
                                                <div className='inner-srch-spacing'>
                                                    <p className='d-inline-block size-big mb-0 tab-0-content-sp'><span className='yellow-big-txt'>$55</span><strike>$66</strike></p>
                                                    <p className='p-md'>1 Night(incl.VAT)</p>
                                                    <a href='' className='btn btn-gradient-purple text-white d-inline-block h-auto w-100'>Book Now</a>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='w-100 d-grid grid-resultshow'>
                                            <div className='result-img-rounded'>
                                                <img src={ResultOne} alt='' />
                                            </div>

                                            <div className='srch-details-box'>
                                                <h3 className='mb-0'>Movenpick Hotel And Residences Riyadh</h3>
                                                <div class="reviews-box d-flex align-items-baseline mb-1 gap-2">
                                                    <p className='d-inline-block text-grey-light  p-all-small mb-0'>4.3</p>
                                                    <ul class="d-inline-block star-rating p-0 mb-0">
                                                        <li class="d-inline-block"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li><li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                    </ul>
                                                    <p className='d-inline-block p-all-small text-grey-light mb-0'>(144 reviews)</p>
                                                </div>
                                                <div className='row'>
                                                    <div className='col-lg-12 col-md-12 col-sm-12 width-mobile-adjust'>
                                                        <div className='room-feature'>
                                                            <ul className='p-0'>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={AirCondition} className='d-inline-block' alt='' />Air Condition </li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={ReceptionsIMG} className='d-inline-block' alt='' />24H. Reception</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={AtmDetail} className='d-inline-block' alt='' />ATM</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={BanqueRimg} className='d-inline-block' alt='' />Gym</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div className='col-lg-12 col-md-12 col-sm-12 width-mobile-adjust'>
                                                        <div className='room-feature'>
                                                            <ul className='p-0'>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={ParkingHotel} className='d-inline-block' alt='' />Parking</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={WifiHotel} className='d-inline-block' alt='' />Free Wifi</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='srch-price-box'>
                                                <div className='inner-srch-spacing'>
                                                    <p className='d-inline-block size-big mb-0 tab-0-content-sp'><span className='yellow-big-txt'>$55</span><strike>$66</strike></p>
                                                    <p className='p-md'>1 Night(incl.VAT)</p>
                                                    <a href='' className='btn btn-gradient-purple text-white d-inline-block h-auto w-100'>Book Now</a>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='w-100 d-grid grid-resultshow'>
                                            <div className='result-img-rounded'>
                                                <img src={ResultOne} alt='' />
                                            </div>

                                            <div className='srch-details-box'>
                                                <h3 className='mb-0'>Movenpick Hotel And Residences Riyadh</h3>
                                                <div class="reviews-box d-flex align-items-baseline mb-1 gap-2">
                                                    <p className='d-inline-block text-grey-light  p-all-small mb-0'>4.3</p>
                                                    <ul class="d-inline-block star-rating p-0 mb-0">
                                                        <li class="d-inline-block"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li><li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                    </ul>
                                                    <p className='d-inline-block p-all-small text-grey-light mb-0'>(144 reviews)</p>
                                                </div>
                                                <div className='row'>
                                                    <div className='col-lg-12 col-md-12 col-sm-12 width-mobile-adjust'>
                                                        <div className='room-feature'>
                                                            <ul className='p-0'>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={AirCondition} className='d-inline-block' alt='' />Air Condition </li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={ReceptionsIMG} className='d-inline-block' alt='' />24H. Reception</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={AtmDetail} className='d-inline-block' alt='' />ATM</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={BanqueRimg} className='d-inline-block' alt='' />Gym</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div className='col-lg-12 col-md-12 col-sm-12 width-mobile-adjust'>
                                                        <div className='room-feature'>
                                                            <ul className='p-0'>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={ParkingHotel} className='d-inline-block' alt='' />Parking</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={WifiHotel} className='d-inline-block' alt='' />Free Wifi</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='srch-price-box'>
                                                <div className='inner-srch-spacing'>
                                                    <p className='d-inline-block size-big mb-0 tab-0-content-sp'><span className='yellow-big-txt'>$55</span><strike>$66</strike></p>
                                                    <p className='p-md'>1 Night(incl.VAT)</p>
                                                    <a href='' className='btn btn-gradient-purple text-white d-inline-block h-auto w-100'>Book Now</a>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='w-100 d-grid grid-resultshow'>
                                            <div className='result-img-rounded'>
                                                <img src={ResultOne} alt='' />
                                            </div>

                                            <div className='srch-details-box'>
                                                <h3 className='mb-0'>Movenpick Hotel And Residences Riyadh</h3>
                                                <div class="reviews-box d-flex align-items-baseline mb-1 gap-2">
                                                    <p className='d-inline-block text-grey-light  p-all-small mb-0'>4.3</p>
                                                    <ul class="d-inline-block star-rating p-0 mb-0">
                                                        <li class="d-inline-block"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li><li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                    </ul>
                                                    <p className='d-inline-block p-all-small text-grey-light mb-0'>(144 reviews)</p>
                                                </div>
                                                <div className='row'>
                                                    <div className='col-lg-12 col-md-12 col-sm-12 width-mobile-adjust'>
                                                        <div className='room-feature'>
                                                            <ul className='p-0'>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={AirCondition} className='d-inline-block' alt='' />Air Condition </li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={ReceptionsIMG} className='d-inline-block' alt='' />24H. Reception</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={AtmDetail} className='d-inline-block' alt='' />ATM</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={BanqueRimg} className='d-inline-block' alt='' />Gym</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div className='col-lg-12 col-md-12 col-sm-12 width-mobile-adjust'>
                                                        <div className='room-feature'>
                                                            <ul className='p-0'>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={ParkingHotel} className='d-inline-block' alt='' />Parking</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={WifiHotel} className='d-inline-block' alt='' />Free Wifi</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='srch-price-box'>
                                                <div className='inner-srch-spacing'>
                                                    <p className='d-inline-block size-big mb-0 tab-0-content-sp'><span className='yellow-big-txt'>$55</span><strike>$66</strike></p>
                                                    <p className='p-md'>1 Night(incl.VAT)</p>
                                                    <a href='' className='btn btn-gradient-purple text-white d-inline-block h-auto w-100'>Book Now</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="tab-pane fade" id="v-pills-bars" role="tabpanel" aria-labelledby="v-pills-bars-tab">
                                    <div className='ryt-resultshow-wrapper'>
                                        <h3 className='text-grey-light mb-3'>Showing result for <span className='font-weight-600 text-color-theme'>“Saudi Arabia”</span></h3>
                                        <div className='w-100 d-grid grid-resultshow'>
                                            <div className='result-img-rounded'>
                                                <img src={ResultOne} alt='' />
                                            </div>

                                            <div className='srch-details-box'>
                                                <h3 className='mb-0'>Movenpick Hotel And Residences Riyadh</h3>
                                                <div class="reviews-box d-flex align-items-baseline mb-1 gap-2">
                                                    <p className='d-inline-block text-grey-light  p-all-small mb-0'>4.3</p>
                                                    <ul class="d-inline-block star-rating p-0 mb-0">
                                                        <li class="d-inline-block"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li><li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                    </ul>
                                                    <p className='d-inline-block p-all-small text-grey-light mb-0'>(144 reviews)</p>
                                                </div>
                                                <div className='row'>
                                                    <div className='col-lg-12 col-md-12 col-sm-12 width-mobile-adjust'>
                                                        <div className='room-feature'>
                                                            <ul className='p-0'>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={AirCondition} className='d-inline-block' alt='' />Air Condition </li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={ReceptionsIMG} className='d-inline-block' alt='' />24H. Reception</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={AtmDetail} className='d-inline-block' alt='' />ATM</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={BanqueRimg} className='d-inline-block' alt='' />Gym</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div className='col-lg-12 col-md-12 col-sm-12 width-mobile-adjust'>
                                                        <div className='room-feature'>
                                                            <ul className='p-0'>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={ParkingHotel} className='d-inline-block' alt='' />Parking</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={WifiHotel} className='d-inline-block' alt='' />Free Wifi</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='srch-price-box'>
                                                <div className='inner-srch-spacing'>
                                                    <p className='d-inline-block size-big mb-0 tab-0-content-sp'><span className='yellow-big-txt'>$55</span><strike>$66</strike></p>
                                                    <p className='p-md'>1 Night(incl.VAT)</p>
                                                    <a href='' className='btn btn-gradient-purple text-white d-inline-block h-auto w-100'>Book Now</a>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='w-100 d-grid grid-resultshow'>
                                            <div className='result-img-rounded'>
                                                <img src={ResultOne} alt='' />
                                            </div>

                                            <div className='srch-details-box'>
                                                <h3 className='mb-0'>Movenpick Hotel And Residences Riyadh</h3>
                                                <div class="reviews-box d-flex align-items-baseline mb-1 gap-2">
                                                    <p className='d-inline-block text-grey-light  p-all-small mb-0'>4.3</p>
                                                    <ul class="d-inline-block star-rating p-0 mb-0">
                                                        <li class="d-inline-block"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li><li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                    </ul>
                                                    <p className='d-inline-block p-all-small text-grey-light mb-0'>(144 reviews)</p>
                                                </div>
                                                <div className='row'>
                                                    <div className='col-lg-12 col-md-12 col-sm-12 width-mobile-adjust'>
                                                        <div className='room-feature'>
                                                            <ul className='p-0'>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={AirCondition} className='d-inline-block' alt='' />Air Condition </li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={ReceptionsIMG} className='d-inline-block' alt='' />24H. Reception</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={AtmDetail} className='d-inline-block' alt='' />ATM</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={BanqueRimg} className='d-inline-block' alt='' />Gym</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div className='col-lg-12 col-md-12 col-sm-12 width-mobile-adjust'>
                                                        <div className='room-feature'>
                                                            <ul className='p-0'>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={ParkingHotel} className='d-inline-block' alt='' />Parking</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={WifiHotel} className='d-inline-block' alt='' />Free Wifi</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='srch-price-box'>
                                                <div className='inner-srch-spacing'>
                                                    <p className='d-inline-block size-big mb-0 tab-0-content-sp'><span className='yellow-big-txt'>$55</span><strike>$66</strike></p>
                                                    <p className='p-md'>1 Night(incl.VAT)</p>
                                                    <a href='' className='btn btn-gradient-purple text-white d-inline-block h-auto w-100'>Book Now</a>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='w-100 d-grid grid-resultshow'>
                                            <div className='result-img-rounded'>
                                                <img src={ResultOne} alt='' />
                                            </div>

                                            <div className='srch-details-box'>
                                                <h3 className='mb-0'>Movenpick Hotel And Residences Riyadh</h3>
                                                <div class="reviews-box d-flex align-items-baseline mb-1 gap-2">
                                                    <p className='d-inline-block text-grey-light  p-all-small mb-0'>4.3</p>
                                                    <ul class="d-inline-block star-rating p-0 mb-0">
                                                        <li class="d-inline-block"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li><li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                    </ul>
                                                    <p className='d-inline-block p-all-small text-grey-light mb-0'>(144 reviews)</p>
                                                </div>
                                                <div className='row'>
                                                    <div className='col-lg-12 col-md-12 col-sm-12 width-mobile-adjust'>
                                                        <div className='room-feature'>
                                                            <ul className='p-0'>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={AirCondition} className='d-inline-block' alt='' />Air Condition </li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={ReceptionsIMG} className='d-inline-block' alt='' />24H. Reception</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={AtmDetail} className='d-inline-block' alt='' />ATM</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={BanqueRimg} className='d-inline-block' alt='' />Gym</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div className='col-lg-12 col-md-12 col-sm-12 width-mobile-adjust'>
                                                        <div className='room-feature'>
                                                            <ul className='p-0'>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={ParkingHotel} className='d-inline-block' alt='' />Parking</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={WifiHotel} className='d-inline-block' alt='' />Free Wifi</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='srch-price-box'>
                                                <div className='inner-srch-spacing'>
                                                    <p className='d-inline-block size-big mb-0 tab-0-content-sp'><span className='yellow-big-txt'>$55</span><strike>$66</strike></p>
                                                    <p className='p-md'>1 Night(incl.VAT)</p>
                                                    <a href='' className='btn btn-gradient-purple text-white d-inline-block h-auto w-100'>Book Now</a>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='w-100 d-grid grid-resultshow'>
                                            <div className='result-img-rounded'>
                                                <img src={ResultOne} alt='' />
                                            </div>

                                            <div className='srch-details-box'>
                                                <h3 className='mb-0'>Movenpick Hotel And Residences Riyadh</h3>
                                                <div class="reviews-box d-flex align-items-baseline mb-1 gap-2">
                                                    <p className='d-inline-block text-grey-light  p-all-small mb-0'>4.3</p>
                                                    <ul class="d-inline-block star-rating p-0 mb-0">
                                                        <li class="d-inline-block"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li><li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                    </ul>
                                                    <p className='d-inline-block p-all-small text-grey-light mb-0'>(144 reviews)</p>
                                                </div>
                                                <div className='row'>
                                                    <div className='col-lg-12 col-md-12 col-sm-12 width-mobile-adjust'>
                                                        <div className='room-feature'>
                                                            <ul className='p-0'>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={AirCondition} className='d-inline-block' alt='' />Air Condition </li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={ReceptionsIMG} className='d-inline-block' alt='' />24H. Reception</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={AtmDetail} className='d-inline-block' alt='' />ATM</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={BanqueRimg} className='d-inline-block' alt='' />Gym</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div className='col-lg-12 col-md-12 col-sm-12 width-mobile-adjust'>
                                                        <div className='room-feature'>
                                                            <ul className='p-0'>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={ParkingHotel} className='d-inline-block' alt='' />Parking</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={WifiHotel} className='d-inline-block' alt='' />Free Wifi</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='srch-price-box'>
                                                <div className='inner-srch-spacing'>
                                                    <p className='d-inline-block size-big mb-0 tab-0-content-sp'><span className='yellow-big-txt'>$55</span><strike>$66</strike></p>
                                                    <p className='p-md'>1 Night(incl.VAT)</p>
                                                    <a href='' className='btn btn-gradient-purple text-white d-inline-block h-auto w-100'>Book Now</a>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='w-100 d-grid grid-resultshow'>
                                            <div className='result-img-rounded'>
                                                <img src={ResultOne} alt='' />
                                            </div>

                                            <div className='srch-details-box'>
                                                <h3 className='mb-0'>Movenpick Hotel And Residences Riyadh</h3>
                                                <div class="reviews-box d-flex align-items-baseline mb-1 gap-2">
                                                    <p className='d-inline-block text-grey-light  p-all-small mb-0'>4.3</p>
                                                    <ul class="d-inline-block star-rating p-0 mb-0">
                                                        <li class="d-inline-block"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li><li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                    </ul>
                                                    <p className='d-inline-block p-all-small text-grey-light mb-0'>(144 reviews)</p>
                                                </div>
                                                <div className='row'>
                                                    <div className='col-lg-12 col-md-12 col-sm-12 width-mobile-adjust'>
                                                        <div className='room-feature'>
                                                            <ul className='p-0'>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={AirCondition} className='d-inline-block' alt='' />Air Condition </li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={ReceptionsIMG} className='d-inline-block' alt='' />24H. Reception</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={AtmDetail} className='d-inline-block' alt='' />ATM</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={BanqueRimg} className='d-inline-block' alt='' />Gym</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div className='col-lg-12 col-md-12 col-sm-12 width-mobile-adjust'>
                                                        <div className='room-feature'>
                                                            <ul className='p-0'>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={ParkingHotel} className='d-inline-block' alt='' />Parking</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={WifiHotel} className='d-inline-block' alt='' />Free Wifi</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='srch-price-box'>
                                                <div className='inner-srch-spacing'>
                                                    <p className='d-inline-block size-big mb-0 tab-0-content-sp'><span className='yellow-big-txt'>$55</span><strike>$66</strike></p>
                                                    <p className='p-md'>1 Night(incl.VAT)</p>
                                                    <a href='' className='btn btn-gradient-purple text-white d-inline-block h-auto w-100'>Book Now</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="tab-pane fade" id="v-pills-beach" role="tabpanel" aria-labelledby="v-pills-beach-tab">
                                    <div className='ryt-resultshow-wrapper'>
                                        <h3 className='text-grey-light mb-3'>Showing result for <span className='font-weight-600 text-color-theme'>“Saudi Arabia”</span></h3>
                                        <div className='w-100 d-grid grid-resultshow'>
                                            <div className='result-img-rounded'>
                                                <img src={ResultOne} alt='' />
                                            </div>

                                            <div className='srch-details-box'>
                                                <h3 className='mb-0'>Movenpick Hotel And Residences Riyadh</h3>
                                                <div class="reviews-box d-flex align-items-baseline mb-1 gap-2">
                                                    <p className='d-inline-block text-grey-light  p-all-small mb-0'>4.3</p>
                                                    <ul class="d-inline-block star-rating p-0 mb-0">
                                                        <li class="d-inline-block"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li><li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                    </ul>
                                                    <p className='d-inline-block p-all-small text-grey-light mb-0'>(144 reviews)</p>
                                                </div>
                                                <div className='row'>
                                                    <div className='col-lg-12 col-md-12 col-sm-12 width-mobile-adjust'>
                                                        <div className='room-feature'>
                                                            <ul className='p-0'>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={AirCondition} className='d-inline-block' alt='' />Air Condition </li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={ReceptionsIMG} className='d-inline-block' alt='' />24H. Reception</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={AtmDetail} className='d-inline-block' alt='' />ATM</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={BanqueRimg} className='d-inline-block' alt='' />Gym</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div className='col-lg-12 col-md-12 col-sm-12 width-mobile-adjust'>
                                                        <div className='room-feature'>
                                                            <ul className='p-0'>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={ParkingHotel} className='d-inline-block' alt='' />Parking</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={WifiHotel} className='d-inline-block' alt='' />Free Wifi</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='srch-price-box'>
                                                <div className='inner-srch-spacing'>
                                                    <p className='d-inline-block size-big mb-0 tab-0-content-sp'><span className='yellow-big-txt'>$55</span><strike>$66</strike></p>
                                                    <p className='p-md'>1 Night(incl.VAT)</p>
                                                    <a href='' className='btn btn-gradient-purple text-white d-inline-block h-auto w-100'>Book Now</a>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='w-100 d-grid grid-resultshow'>
                                            <div className='result-img-rounded'>
                                                <img src={ResultOne} alt='' />
                                            </div>

                                            <div className='srch-details-box'>
                                                <h3 className='mb-0'>Movenpick Hotel And Residences Riyadh</h3>
                                                <div class="reviews-box d-flex align-items-baseline mb-1 gap-2">
                                                    <p className='d-inline-block text-grey-light  p-all-small mb-0'>4.3</p>
                                                    <ul class="d-inline-block star-rating p-0 mb-0">
                                                        <li class="d-inline-block"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li><li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                    </ul>
                                                    <p className='d-inline-block p-all-small text-grey-light mb-0'>(144 reviews)</p>
                                                </div>
                                                <div className='row'>
                                                    <div className='col-lg-12 col-md-12 col-sm-12 width-mobile-adjust'>
                                                        <div className='room-feature'>
                                                            <ul className='p-0'>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={AirCondition} className='d-inline-block' alt='' />Air Condition </li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={ReceptionsIMG} className='d-inline-block' alt='' />24H. Reception</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={AtmDetail} className='d-inline-block' alt='' />ATM</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={BanqueRimg} className='d-inline-block' alt='' />Gym</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div className='col-lg-12 col-md-12 col-sm-12 width-mobile-adjust'>
                                                        <div className='room-feature'>
                                                            <ul className='p-0'>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={ParkingHotel} className='d-inline-block' alt='' />Parking</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={WifiHotel} className='d-inline-block' alt='' />Free Wifi</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='srch-price-box'>
                                                <div className='inner-srch-spacing'>
                                                    <p className='d-inline-block size-big mb-0 tab-0-content-sp'><span className='yellow-big-txt'>$55</span><strike>$66</strike></p>
                                                    <p className='p-md'>1 Night(incl.VAT)</p>
                                                    <a href='' className='btn btn-gradient-purple text-white d-inline-block h-auto w-100'>Book Now</a>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='w-100 d-grid grid-resultshow'>
                                            <div className='result-img-rounded'>
                                                <img src={ResultOne} alt='' />
                                            </div>

                                            <div className='srch-details-box'>
                                                <h3 className='mb-0'>Movenpick Hotel And Residences Riyadh</h3>
                                                <div class="reviews-box d-flex align-items-baseline mb-1 gap-2">
                                                    <p className='d-inline-block text-grey-light  p-all-small mb-0'>4.3</p>
                                                    <ul class="d-inline-block star-rating p-0 mb-0">
                                                        <li class="d-inline-block"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li><li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                    </ul>
                                                    <p className='d-inline-block p-all-small text-grey-light mb-0'>(144 reviews)</p>
                                                </div>
                                                <div className='row'>
                                                    <div className='col-lg-12 col-md-12 col-sm-12 width-mobile-adjust'>
                                                        <div className='room-feature'>
                                                            <ul className='p-0'>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={AirCondition} className='d-inline-block' alt='' />Air Condition </li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={ReceptionsIMG} className='d-inline-block' alt='' />24H. Reception</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={AtmDetail} className='d-inline-block' alt='' />ATM</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={BanqueRimg} className='d-inline-block' alt='' />Gym</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div className='col-lg-12 col-md-12 col-sm-12 width-mobile-adjust'>
                                                        <div className='room-feature'>
                                                            <ul className='p-0'>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={ParkingHotel} className='d-inline-block' alt='' />Parking</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={WifiHotel} className='d-inline-block' alt='' />Free Wifi</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='srch-price-box'>
                                                <div className='inner-srch-spacing'>
                                                    <p className='d-inline-block size-big mb-0 tab-0-content-sp'><span className='yellow-big-txt'>$55</span><strike>$66</strike></p>
                                                    <p className='p-md'>1 Night(incl.VAT)</p>
                                                    <a href='' className='btn btn-gradient-purple text-white d-inline-block h-auto w-100'>Book Now</a>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='w-100 d-grid grid-resultshow'>
                                            <div className='result-img-rounded'>
                                                <img src={ResultOne} alt='' />
                                            </div>

                                            <div className='srch-details-box'>
                                                <h3 className='mb-0'>Movenpick Hotel And Residences Riyadh</h3>
                                                <div class="reviews-box d-flex align-items-baseline mb-1 gap-2">
                                                    <p className='d-inline-block text-grey-light  p-all-small mb-0'>4.3</p>
                                                    <ul class="d-inline-block star-rating p-0 mb-0">
                                                        <li class="d-inline-block"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li><li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                    </ul>
                                                    <p className='d-inline-block p-all-small text-grey-light mb-0'>(144 reviews)</p>
                                                </div>
                                                <div className='row'>
                                                    <div className='col-lg-12 col-md-12 col-sm-12 width-mobile-adjust'>
                                                        <div className='room-feature'>
                                                            <ul className='p-0'>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={AirCondition} className='d-inline-block' alt='' />Air Condition </li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={ReceptionsIMG} className='d-inline-block' alt='' />24H. Reception</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={AtmDetail} className='d-inline-block' alt='' />ATM</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={BanqueRimg} className='d-inline-block' alt='' />Gym</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div className='col-lg-12 col-md-12 col-sm-12 width-mobile-adjust'>
                                                        <div className='room-feature'>
                                                            <ul className='p-0'>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={ParkingHotel} className='d-inline-block' alt='' />Parking</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={WifiHotel} className='d-inline-block' alt='' />Free Wifi</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='srch-price-box'>
                                                <div className='inner-srch-spacing'>
                                                    <p className='d-inline-block size-big mb-0 tab-0-content-sp'><span className='yellow-big-txt'>$55</span><strike>$66</strike></p>
                                                    <p className='p-md'>1 Night(incl.VAT)</p>
                                                    <a href='' className='btn btn-gradient-purple text-white d-inline-block h-auto w-100'>Book Now</a>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='w-100 d-grid grid-resultshow'>
                                            <div className='result-img-rounded'>
                                                <img src={ResultOne} alt='' />
                                            </div>

                                            <div className='srch-details-box'>
                                                <h3 className='mb-0'>Movenpick Hotel And Residences Riyadh</h3>
                                                <div class="reviews-box d-flex align-items-baseline mb-1 gap-2">
                                                    <p className='d-inline-block text-grey-light  p-all-small mb-0'>4.3</p>
                                                    <ul class="d-inline-block star-rating p-0 mb-0">
                                                        <li class="d-inline-block"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li><li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                    </ul>
                                                    <p className='d-inline-block p-all-small text-grey-light mb-0'>(144 reviews)</p>
                                                </div>
                                                <div className='row'>
                                                    <div className='col-lg-12 col-md-12 col-sm-12 width-mobile-adjust'>
                                                        <div className='room-feature'>
                                                            <ul className='p-0'>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={AirCondition} className='d-inline-block' alt='' />Air Condition </li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={ReceptionsIMG} className='d-inline-block' alt='' />24H. Reception</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={AtmDetail} className='d-inline-block' alt='' />ATM</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={BanqueRimg} className='d-inline-block' alt='' />Gym</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div className='col-lg-12 col-md-12 col-sm-12 width-mobile-adjust'>
                                                        <div className='room-feature'>
                                                            <ul className='p-0'>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={ParkingHotel} className='d-inline-block' alt='' />Parking</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={WifiHotel} className='d-inline-block' alt='' />Free Wifi</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='srch-price-box'>
                                                <div className='inner-srch-spacing'>
                                                    <p className='d-inline-block size-big mb-0 tab-0-content-sp'><span className='yellow-big-txt'>$55</span><strike>$66</strike></p>
                                                    <p className='p-md'>1 Night(incl.VAT)</p>
                                                    <a href='' className='btn btn-gradient-purple text-white d-inline-block h-auto w-100'>Book Now</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="tab-pane fade" id="v-pills-buffet" role="tabpanel" aria-labelledby="v-pills-buffet-tab">
                                    <div className='ryt-resultshow-wrapper'>
                                        <h3 className='text-grey-light mb-3'>Showing result for <span className='font-weight-600 text-color-theme'>“Saudi Arabia”</span></h3>
                                        <div className='w-100 d-grid grid-resultshow'>
                                            <div className='result-img-rounded'>
                                                <img src={ResultOne} alt='' />
                                            </div>

                                            <div className='srch-details-box'>
                                                <h3 className='mb-0'>Movenpick Hotel And Residences Riyadh</h3>
                                                <div class="reviews-box d-flex align-items-baseline mb-1 gap-2">
                                                    <p className='d-inline-block text-grey-light  p-all-small mb-0'>4.3</p>
                                                    <ul class="d-inline-block star-rating p-0 mb-0">
                                                        <li class="d-inline-block"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li><li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                    </ul>
                                                    <p className='d-inline-block p-all-small text-grey-light mb-0'>(144 reviews)</p>
                                                </div>
                                                <div className='row'>
                                                    <div className='col-lg-12 col-md-12 col-sm-12 width-mobile-adjust'>
                                                        <div className='room-feature'>
                                                            <ul className='p-0'>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={AirCondition} className='d-inline-block' alt='' />Air Condition </li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={ReceptionsIMG} className='d-inline-block' alt='' />24H. Reception</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={AtmDetail} className='d-inline-block' alt='' />ATM</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={BanqueRimg} className='d-inline-block' alt='' />Gym</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div className='col-lg-12 col-md-12 col-sm-12 width-mobile-adjust'>
                                                        <div className='room-feature'>
                                                            <ul className='p-0'>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={ParkingHotel} className='d-inline-block' alt='' />Parking</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={WifiHotel} className='d-inline-block' alt='' />Free Wifi</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='srch-price-box'>
                                                <div className='inner-srch-spacing'>
                                                    <p className='d-inline-block size-big mb-0 tab-0-content-sp'><span className='yellow-big-txt'>$55</span><strike>$66</strike></p>
                                                    <p className='p-md'>1 Night(incl.VAT)</p>
                                                    <a href='' className='btn btn-gradient-purple text-white d-inline-block h-auto w-100'>Book Now</a>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='w-100 d-grid grid-resultshow'>
                                            <div className='result-img-rounded'>
                                                <img src={ResultOne} alt='' />
                                            </div>

                                            <div className='srch-details-box'>
                                                <h3 className='mb-0'>Movenpick Hotel And Residences Riyadh</h3>
                                                <div class="reviews-box d-flex align-items-baseline mb-1 gap-2">
                                                    <p className='d-inline-block text-grey-light  p-all-small mb-0'>4.3</p>
                                                    <ul class="d-inline-block star-rating p-0 mb-0">
                                                        <li class="d-inline-block"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li><li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                    </ul>
                                                    <p className='d-inline-block p-all-small text-grey-light mb-0'>(144 reviews)</p>
                                                </div>
                                                <div className='row'>
                                                    <div className='col-lg-12 col-md-12 col-sm-12 width-mobile-adjust'>
                                                        <div className='room-feature'>
                                                            <ul className='p-0'>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={AirCondition} className='d-inline-block' alt='' />Air Condition </li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={ReceptionsIMG} className='d-inline-block' alt='' />24H. Reception</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={AtmDetail} className='d-inline-block' alt='' />ATM</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={BanqueRimg} className='d-inline-block' alt='' />Gym</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div className='col-lg-12 col-md-12 col-sm-12 width-mobile-adjust'>
                                                        <div className='room-feature'>
                                                            <ul className='p-0'>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={ParkingHotel} className='d-inline-block' alt='' />Parking</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={WifiHotel} className='d-inline-block' alt='' />Free Wifi</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='srch-price-box'>
                                                <div className='inner-srch-spacing'>
                                                    <p className='d-inline-block size-big mb-0 tab-0-content-sp'><span className='yellow-big-txt'>$55</span><strike>$66</strike></p>
                                                    <p className='p-md'>1 Night(incl.VAT)</p>
                                                    <a href='' className='btn btn-gradient-purple text-white d-inline-block h-auto w-100'>Book Now</a>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='w-100 d-grid grid-resultshow'>
                                            <div className='result-img-rounded'>
                                                <img src={ResultOne} alt='' />
                                            </div>

                                            <div className='srch-details-box'>
                                                <h3 className='mb-0'>Movenpick Hotel And Residences Riyadh</h3>
                                                <div class="reviews-box d-flex align-items-baseline mb-1 gap-2">
                                                    <p className='d-inline-block text-grey-light  p-all-small mb-0'>4.3</p>
                                                    <ul class="d-inline-block star-rating p-0 mb-0">
                                                        <li class="d-inline-block"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li><li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                    </ul>
                                                    <p className='d-inline-block p-all-small text-grey-light mb-0'>(144 reviews)</p>
                                                </div>
                                                <div className='row'>
                                                    <div className='col-lg-12 col-md-12 col-sm-12 width-mobile-adjust'>
                                                        <div className='room-feature'>
                                                            <ul className='p-0'>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={AirCondition} className='d-inline-block' alt='' />Air Condition </li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={ReceptionsIMG} className='d-inline-block' alt='' />24H. Reception</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={AtmDetail} className='d-inline-block' alt='' />ATM</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={BanqueRimg} className='d-inline-block' alt='' />Gym</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div className='col-lg-12 col-md-12 col-sm-12 width-mobile-adjust'>
                                                        <div className='room-feature'>
                                                            <ul className='p-0'>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={ParkingHotel} className='d-inline-block' alt='' />Parking</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={WifiHotel} className='d-inline-block' alt='' />Free Wifi</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='srch-price-box'>
                                                <div className='inner-srch-spacing'>
                                                    <p className='d-inline-block size-big mb-0 tab-0-content-sp'><span className='yellow-big-txt'>$55</span><strike>$66</strike></p>
                                                    <p className='p-md'>1 Night(incl.VAT)</p>
                                                    <a href='' className='btn btn-gradient-purple text-white d-inline-block h-auto w-100'>Book Now</a>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='w-100 d-grid grid-resultshow'>
                                            <div className='result-img-rounded'>
                                                <img src={ResultOne} alt='' />
                                            </div>

                                            <div className='srch-details-box'>
                                                <h3 className='mb-0'>Movenpick Hotel And Residences Riyadh</h3>
                                                <div class="reviews-box d-flex align-items-baseline mb-1 gap-2">
                                                    <p className='d-inline-block text-grey-light  p-all-small mb-0'>4.3</p>
                                                    <ul class="d-inline-block star-rating p-0 mb-0">
                                                        <li class="d-inline-block"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li><li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                    </ul>
                                                    <p className='d-inline-block p-all-small text-grey-light mb-0'>(144 reviews)</p>
                                                </div>
                                                <div className='row'>
                                                    <div className='col-lg-12 col-md-12 col-sm-12 width-mobile-adjust'>
                                                        <div className='room-feature'>
                                                            <ul className='p-0'>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={AirCondition} className='d-inline-block' alt='' />Air Condition </li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={ReceptionsIMG} className='d-inline-block' alt='' />24H. Reception</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={AtmDetail} className='d-inline-block' alt='' />ATM</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={BanqueRimg} className='d-inline-block' alt='' />Gym</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div className='col-lg-12 col-md-12 col-sm-12 width-mobile-adjust'>
                                                        <div className='room-feature'>
                                                            <ul className='p-0'>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={ParkingHotel} className='d-inline-block' alt='' />Parking</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={WifiHotel} className='d-inline-block' alt='' />Free Wifi</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='srch-price-box'>
                                                <div className='inner-srch-spacing'>
                                                    <p className='d-inline-block size-big mb-0 tab-0-content-sp'><span className='yellow-big-txt'>$55</span><strike>$66</strike></p>
                                                    <p className='p-md'>1 Night(incl.VAT)</p>
                                                    <a href='' className='btn btn-gradient-purple text-white d-inline-block h-auto w-100'>Book Now</a>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='w-100 d-grid grid-resultshow'>
                                            <div className='result-img-rounded'>
                                                <img src={ResultOne} alt='' />
                                            </div>

                                            <div className='srch-details-box'>
                                                <h3 className='mb-0'>Movenpick Hotel And Residences Riyadh</h3>
                                                <div class="reviews-box d-flex align-items-baseline mb-1 gap-2">
                                                    <p className='d-inline-block text-grey-light  p-all-small mb-0'>4.3</p>
                                                    <ul class="d-inline-block star-rating p-0 mb-0">
                                                        <li class="d-inline-block"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li><li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                    </ul>
                                                    <p className='d-inline-block p-all-small text-grey-light mb-0'>(144 reviews)</p>
                                                </div>
                                                <div className='row'>
                                                    <div className='col-lg-12 col-md-12 col-sm-12 width-mobile-adjust'>
                                                        <div className='room-feature'>
                                                            <ul className='p-0'>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={AirCondition} className='d-inline-block' alt='' />Air Condition </li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={ReceptionsIMG} className='d-inline-block' alt='' />24H. Reception</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={AtmDetail} className='d-inline-block' alt='' />ATM</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={BanqueRimg} className='d-inline-block' alt='' />Gym</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div className='col-lg-12 col-md-12 col-sm-12 width-mobile-adjust'>
                                                        <div className='room-feature'>
                                                            <ul className='p-0'>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={ParkingHotel} className='d-inline-block' alt='' />Parking</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={WifiHotel} className='d-inline-block' alt='' />Free Wifi</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='srch-price-box'>
                                                <div className='inner-srch-spacing'>
                                                    <p className='d-inline-block size-big mb-0 tab-0-content-sp'><span className='yellow-big-txt'>$55</span><strike>$66</strike></p>
                                                    <p className='p-md'>1 Night(incl.VAT)</p>
                                                    <a href='' className='btn btn-gradient-purple text-white d-inline-block h-auto w-100'>Book Now</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="tab-pane fade" id="v-pills-parking" role="tabpanel" aria-labelledby="v-pills-parking-tab">
                                    <div className='ryt-resultshow-wrapper'>
                                        <h3 className='text-grey-light mb-3'>Showing result for <span className='font-weight-600 text-color-theme'>“Saudi Arabia”</span></h3>
                                        <div className='w-100 d-grid grid-resultshow'>
                                            <div className='result-img-rounded'>
                                                <img src={ResultOne} alt='' />
                                            </div>

                                            <div className='srch-details-box'>
                                                <h3 className='mb-0'>Movenpick Hotel And Residences Riyadh</h3>
                                                <div class="reviews-box d-flex align-items-baseline mb-1 gap-2">
                                                    <p className='d-inline-block text-grey-light  p-all-small mb-0'>4.3</p>
                                                    <ul class="d-inline-block star-rating p-0 mb-0">
                                                        <li class="d-inline-block"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li><li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                    </ul>
                                                    <p className='d-inline-block p-all-small text-grey-light mb-0'>(144 reviews)</p>
                                                </div>
                                                <div className='row'>
                                                    <div className='col-lg-12 col-md-12 col-sm-12 width-mobile-adjust'>
                                                        <div className='room-feature'>
                                                            <ul className='p-0'>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={AirCondition} className='d-inline-block' alt='' />Air Condition </li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={ReceptionsIMG} className='d-inline-block' alt='' />24H. Reception</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={AtmDetail} className='d-inline-block' alt='' />ATM</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={BanqueRimg} className='d-inline-block' alt='' />Gym</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div className='col-lg-12 col-md-12 col-sm-12 width-mobile-adjust'>
                                                        <div className='room-feature'>
                                                            <ul className='p-0'>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={ParkingHotel} className='d-inline-block' alt='' />Parking</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={WifiHotel} className='d-inline-block' alt='' />Free Wifi</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='srch-price-box'>
                                                <div className='inner-srch-spacing'>
                                                    <p className='d-inline-block size-big mb-0 tab-0-content-sp'><span className='yellow-big-txt'>$55</span><strike>$66</strike></p>
                                                    <p className='p-md'>1 Night(incl.VAT)</p>
                                                    <a href='' className='btn btn-gradient-purple text-white d-inline-block h-auto w-100'>Book Now</a>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='w-100 d-grid grid-resultshow'>
                                            <div className='result-img-rounded'>
                                                <img src={ResultOne} alt='' />
                                            </div>

                                            <div className='srch-details-box'>
                                                <h3 className='mb-0'>Movenpick Hotel And Residences Riyadh</h3>
                                                <div class="reviews-box d-flex align-items-baseline mb-1 gap-2">
                                                    <p className='d-inline-block text-grey-light  p-all-small mb-0'>4.3</p>
                                                    <ul class="d-inline-block star-rating p-0 mb-0">
                                                        <li class="d-inline-block"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li><li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                    </ul>
                                                    <p className='d-inline-block p-all-small text-grey-light mb-0'>(144 reviews)</p>
                                                </div>
                                                <div className='row'>
                                                    <div className='col-lg-12 col-md-12 col-sm-12 width-mobile-adjust'>
                                                        <div className='room-feature'>
                                                            <ul className='p-0'>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={AirCondition} className='d-inline-block' alt='' />Air Condition </li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={ReceptionsIMG} className='d-inline-block' alt='' />24H. Reception</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={AtmDetail} className='d-inline-block' alt='' />ATM</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={BanqueRimg} className='d-inline-block' alt='' />Gym</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div className='col-lg-12 col-md-12 col-sm-12 width-mobile-adjust'>
                                                        <div className='room-feature'>
                                                            <ul className='p-0'>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={ParkingHotel} className='d-inline-block' alt='' />Parking</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={WifiHotel} className='d-inline-block' alt='' />Free Wifi</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='srch-price-box'>
                                                <div className='inner-srch-spacing'>
                                                    <p className='d-inline-block size-big mb-0 tab-0-content-sp'><span className='yellow-big-txt'>$55</span><strike>$66</strike></p>
                                                    <p className='p-md'>1 Night(incl.VAT)</p>
                                                    <a href='' className='btn btn-gradient-purple text-white d-inline-block h-auto w-100'>Book Now</a>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='w-100 d-grid grid-resultshow'>
                                            <div className='result-img-rounded'>
                                                <img src={ResultOne} alt='' />
                                            </div>

                                            <div className='srch-details-box'>
                                                <h3 className='mb-0'>Movenpick Hotel And Residences Riyadh</h3>
                                                <div class="reviews-box d-flex align-items-baseline mb-1 gap-2">
                                                    <p className='d-inline-block text-grey-light  p-all-small mb-0'>4.3</p>
                                                    <ul class="d-inline-block star-rating p-0 mb-0">
                                                        <li class="d-inline-block"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li><li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                    </ul>
                                                    <p className='d-inline-block p-all-small text-grey-light mb-0'>(144 reviews)</p>
                                                </div>
                                                <div className='row'>
                                                    <div className='col-lg-12 col-md-12 col-sm-12 width-mobile-adjust'>
                                                        <div className='room-feature'>
                                                            <ul className='p-0'>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={AirCondition} className='d-inline-block' alt='' />Air Condition </li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={ReceptionsIMG} className='d-inline-block' alt='' />24H. Reception</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={AtmDetail} className='d-inline-block' alt='' />ATM</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={BanqueRimg} className='d-inline-block' alt='' />Gym</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div className='col-lg-12 col-md-12 col-sm-12 width-mobile-adjust'>
                                                        <div className='room-feature'>
                                                            <ul className='p-0'>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={ParkingHotel} className='d-inline-block' alt='' />Parking</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={WifiHotel} className='d-inline-block' alt='' />Free Wifi</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='srch-price-box'>
                                                <div className='inner-srch-spacing'>
                                                    <p className='d-inline-block size-big mb-0 tab-0-content-sp'><span className='yellow-big-txt'>$55</span><strike>$66</strike></p>
                                                    <p className='p-md'>1 Night(incl.VAT)</p>
                                                    <a href='' className='btn btn-gradient-purple text-white d-inline-block h-auto w-100'>Book Now</a>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='w-100 d-grid grid-resultshow'>
                                            <div className='result-img-rounded'>
                                                <img src={ResultOne} alt='' />
                                            </div>

                                            <div className='srch-details-box'>
                                                <h3 className='mb-0'>Movenpick Hotel And Residences Riyadh</h3>
                                                <div class="reviews-box d-flex align-items-baseline mb-1 gap-2">
                                                    <p className='d-inline-block text-grey-light  p-all-small mb-0'>4.3</p>
                                                    <ul class="d-inline-block star-rating p-0 mb-0">
                                                        <li class="d-inline-block"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li><li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                    </ul>
                                                    <p className='d-inline-block p-all-small text-grey-light mb-0'>(144 reviews)</p>
                                                </div>
                                                <div className='row'>
                                                    <div className='col-lg-12 col-md-12 col-sm-12 width-mobile-adjust'>
                                                        <div className='room-feature'>
                                                            <ul className='p-0'>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={AirCondition} className='d-inline-block' alt='' />Air Condition </li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={ReceptionsIMG} className='d-inline-block' alt='' />24H. Reception</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={AtmDetail} className='d-inline-block' alt='' />ATM</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={BanqueRimg} className='d-inline-block' alt='' />Gym</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div className='col-lg-12 col-md-12 col-sm-12 width-mobile-adjust'>
                                                        <div className='room-feature'>
                                                            <ul className='p-0'>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={ParkingHotel} className='d-inline-block' alt='' />Parking</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={WifiHotel} className='d-inline-block' alt='' />Free Wifi</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='srch-price-box'>
                                                <div className='inner-srch-spacing'>
                                                    <p className='d-inline-block size-big mb-0 tab-0-content-sp'><span className='yellow-big-txt'>$55</span><strike>$66</strike></p>
                                                    <p className='p-md'>1 Night(incl.VAT)</p>
                                                    <a href='' className='btn btn-gradient-purple text-white d-inline-block h-auto w-100'>Book Now</a>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='w-100 d-grid grid-resultshow'>
                                            <div className='result-img-rounded'>
                                                <img src={ResultOne} alt='' />
                                            </div>

                                            <div className='srch-details-box'>
                                                <h3 className='mb-0'>Movenpick Hotel And Residences Riyadh</h3>
                                                <div class="reviews-box d-flex align-items-baseline mb-1 gap-2">
                                                    <p className='d-inline-block text-grey-light  p-all-small mb-0'>4.3</p>
                                                    <ul class="d-inline-block star-rating p-0 mb-0">
                                                        <li class="d-inline-block"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li><li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                    </ul>
                                                    <p className='d-inline-block p-all-small text-grey-light mb-0'>(144 reviews)</p>
                                                </div>
                                                <div className='row'>
                                                    <div className='col-lg-12 col-md-12 col-sm-12 width-mobile-adjust'>
                                                        <div className='room-feature'>
                                                            <ul className='p-0'>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={AirCondition} className='d-inline-block' alt='' />Air Condition </li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={ReceptionsIMG} className='d-inline-block' alt='' />24H. Reception</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={AtmDetail} className='d-inline-block' alt='' />ATM</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={BanqueRimg} className='d-inline-block' alt='' />Gym</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div className='col-lg-12 col-md-12 col-sm-12 width-mobile-adjust'>
                                                        <div className='room-feature'>
                                                            <ul className='p-0'>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={ParkingHotel} className='d-inline-block' alt='' />Parking</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={WifiHotel} className='d-inline-block' alt='' />Free Wifi</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='srch-price-box'>
                                                <div className='inner-srch-spacing'>
                                                    <p className='d-inline-block size-big mb-0 tab-0-content-sp'><span className='yellow-big-txt'>$55</span><strike>$66</strike></p>
                                                    <p className='p-md'>1 Night(incl.VAT)</p>
                                                    <a href='' className='btn btn-gradient-purple text-white d-inline-block h-auto w-100'>Book Now</a>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='w-100 d-grid grid-resultshow'>
                                            <div className='result-img-rounded'>
                                                <img src={ResultOne} alt='' />
                                            </div>

                                            <div className='srch-details-box'>
                                                <h3 className='mb-0'>Movenpick Hotel And Residences Riyadh</h3>
                                                <div class="reviews-box d-flex align-items-baseline mb-1 gap-2">
                                                    <p className='d-inline-block text-grey-light  p-all-small mb-0'>4.3</p>
                                                    <ul class="d-inline-block star-rating p-0 mb-0">
                                                        <li class="d-inline-block"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li><li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                    </ul>
                                                    <p className='d-inline-block p-all-small text-grey-light mb-0'>(144 reviews)</p>
                                                </div>
                                                <div className='row'>
                                                    <div className='col-lg-12 col-md-12 col-sm-12 width-mobile-adjust'>
                                                        <div className='room-feature'>
                                                            <ul className='p-0'>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={AirCondition} className='d-inline-block' alt='' />Air Condition </li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={ReceptionsIMG} className='d-inline-block' alt='' />24H. Reception</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={AtmDetail} className='d-inline-block' alt='' />ATM</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={BanqueRimg} className='d-inline-block' alt='' />Gym</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div className='col-lg-12 col-md-12 col-sm-12 width-mobile-adjust'>
                                                        <div className='room-feature'>
                                                            <ul className='p-0'>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={ParkingHotel} className='d-inline-block' alt='' />Parking</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={WifiHotel} className='d-inline-block' alt='' />Free Wifi</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='srch-price-box'>
                                                <div className='inner-srch-spacing'>
                                                    <p className='d-inline-block size-big mb-0 tab-0-content-sp'><span className='yellow-big-txt'>$55</span><strike>$66</strike></p>
                                                    <p className='p-md'>1 Night(incl.VAT)</p>
                                                    <a href='' className='btn btn-gradient-purple text-white d-inline-block h-auto w-100'>Book Now</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="tab-pane fade" id="v-pills-cafe" role="tabpanel" aria-labelledby="v-pills-cafe-tab">
                                    <div className='ryt-resultshow-wrapper'>
                                        <h3 className='text-grey-light mb-3'>Showing result for <span className='font-weight-600 text-color-theme'>“Saudi Arabia”</span></h3>
                                        <div className='w-100 d-grid grid-resultshow'>
                                            <div className='result-img-rounded'>
                                                <img src={ResultOne} alt='' />
                                            </div>

                                            <div className='srch-details-box'>
                                                <h3 className='mb-0'>Movenpick Hotel And Residences Riyadh</h3>
                                                <div class="reviews-box d-flex align-items-baseline mb-1 gap-2">
                                                    <p className='d-inline-block text-grey-light  p-all-small mb-0'>4.3</p>
                                                    <ul class="d-inline-block star-rating p-0 mb-0">
                                                        <li class="d-inline-block"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li><li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                    </ul>
                                                    <p className='d-inline-block p-all-small text-grey-light mb-0'>(144 reviews)</p>
                                                </div>
                                                <div className='row'>
                                                    <div className='col-lg-12 col-md-12 col-sm-12 width-mobile-adjust'>
                                                        <div className='room-feature'>
                                                            <ul className='p-0'>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={AirCondition} className='d-inline-block' alt='' />Air Condition </li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={ReceptionsIMG} className='d-inline-block' alt='' />24H. Reception</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={AtmDetail} className='d-inline-block' alt='' />ATM</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={BanqueRimg} className='d-inline-block' alt='' />Gym</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div className='col-lg-12 col-md-12 col-sm-12 width-mobile-adjust'>
                                                        <div className='room-feature'>
                                                            <ul className='p-0'>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={ParkingHotel} className='d-inline-block' alt='' />Parking</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={WifiHotel} className='d-inline-block' alt='' />Free Wifi</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='srch-price-box'>
                                                <div className='inner-srch-spacing'>
                                                    <p className='d-inline-block size-big mb-0 tab-0-content-sp'><span className='yellow-big-txt'>$55</span><strike>$66</strike></p>
                                                    <p className='p-md'>1 Night(incl.VAT)</p>
                                                    <a href='' className='btn btn-gradient-purple text-white d-inline-block h-auto w-100'>Book Now</a>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='w-100 d-grid grid-resultshow'>
                                            <div className='result-img-rounded'>
                                                <img src={ResultOne} alt='' />
                                            </div>

                                            <div className='srch-details-box'>
                                                <h3 className='mb-0'>Movenpick Hotel And Residences Riyadh</h3>
                                                <div class="reviews-box d-flex align-items-baseline mb-1 gap-2">
                                                    <p className='d-inline-block text-grey-light  p-all-small mb-0'>4.3</p>
                                                    <ul class="d-inline-block star-rating p-0 mb-0">
                                                        <li class="d-inline-block"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li><li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                    </ul>
                                                    <p className='d-inline-block p-all-small text-grey-light mb-0'>(144 reviews)</p>
                                                </div>
                                                <div className='row'>
                                                    <div className='col-lg-12 col-md-12 col-sm-12 width-mobile-adjust'>
                                                        <div className='room-feature'>
                                                            <ul className='p-0'>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={AirCondition} className='d-inline-block' alt='' />Air Condition </li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={ReceptionsIMG} className='d-inline-block' alt='' />24H. Reception</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={AtmDetail} className='d-inline-block' alt='' />ATM</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={BanqueRimg} className='d-inline-block' alt='' />Gym</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div className='col-lg-12 col-md-12 col-sm-12 width-mobile-adjust'>
                                                        <div className='room-feature'>
                                                            <ul className='p-0'>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={ParkingHotel} className='d-inline-block' alt='' />Parking</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={WifiHotel} className='d-inline-block' alt='' />Free Wifi</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='srch-price-box'>
                                                <div className='inner-srch-spacing'>
                                                    <p className='d-inline-block size-big mb-0 tab-0-content-sp'><span className='yellow-big-txt'>$55</span><strike>$66</strike></p>
                                                    <p className='p-md'>1 Night(incl.VAT)</p>
                                                    <a href='' className='btn btn-gradient-purple text-white d-inline-block h-auto w-100'>Book Now</a>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='w-100 d-grid grid-resultshow'>
                                            <div className='result-img-rounded'>
                                                <img src={ResultOne} alt='' />
                                            </div>

                                            <div className='srch-details-box'>
                                                <h3 className='mb-0'>Movenpick Hotel And Residences Riyadh</h3>
                                                <div class="reviews-box d-flex align-items-baseline mb-1 gap-2">
                                                    <p className='d-inline-block text-grey-light  p-all-small mb-0'>4.3</p>
                                                    <ul class="d-inline-block star-rating p-0 mb-0">
                                                        <li class="d-inline-block"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li><li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                    </ul>
                                                    <p className='d-inline-block p-all-small text-grey-light mb-0'>(144 reviews)</p>
                                                </div>
                                                <div className='row'>
                                                    <div className='col-lg-12 col-md-12 col-sm-12 width-mobile-adjust'>
                                                        <div className='room-feature'>
                                                            <ul className='p-0'>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={AirCondition} className='d-inline-block' alt='' />Air Condition </li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={ReceptionsIMG} className='d-inline-block' alt='' />24H. Reception</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={AtmDetail} className='d-inline-block' alt='' />ATM</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={BanqueRimg} className='d-inline-block' alt='' />Gym</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div className='col-lg-12 col-md-12 col-sm-12 width-mobile-adjust'>
                                                        <div className='room-feature'>
                                                            <ul className='p-0'>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={ParkingHotel} className='d-inline-block' alt='' />Parking</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={WifiHotel} className='d-inline-block' alt='' />Free Wifi</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='srch-price-box'>
                                                <div className='inner-srch-spacing'>
                                                    <p className='d-inline-block size-big mb-0 tab-0-content-sp'><span className='yellow-big-txt'>$55</span><strike>$66</strike></p>
                                                    <p className='p-md'>1 Night(incl.VAT)</p>
                                                    <a href='' className='btn btn-gradient-purple text-white d-inline-block h-auto w-100'>Book Now</a>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='w-100 d-grid grid-resultshow'>
                                            <div className='result-img-rounded'>
                                                <img src={ResultOne} alt='' />
                                            </div>

                                            <div className='srch-details-box'>
                                                <h3 className='mb-0'>Movenpick Hotel And Residences Riyadh</h3>
                                                <div class="reviews-box d-flex align-items-baseline mb-1 gap-2">
                                                    <p className='d-inline-block text-grey-light  p-all-small mb-0'>4.3</p>
                                                    <ul class="d-inline-block star-rating p-0 mb-0">
                                                        <li class="d-inline-block"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li><li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                    </ul>
                                                    <p className='d-inline-block p-all-small text-grey-light mb-0'>(144 reviews)</p>
                                                </div>
                                                <div className='row'>
                                                    <div className='col-lg-12 col-md-12 col-sm-12 width-mobile-adjust'>
                                                        <div className='room-feature'>
                                                            <ul className='p-0'>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={AirCondition} className='d-inline-block' alt='' />Air Condition </li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={ReceptionsIMG} className='d-inline-block' alt='' />24H. Reception</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={AtmDetail} className='d-inline-block' alt='' />ATM</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={BanqueRimg} className='d-inline-block' alt='' />Gym</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div className='col-lg-12 col-md-12 col-sm-12 width-mobile-adjust'>
                                                        <div className='room-feature'>
                                                            <ul className='p-0'>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={ParkingHotel} className='d-inline-block' alt='' />Parking</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={WifiHotel} className='d-inline-block' alt='' />Free Wifi</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='srch-price-box'>
                                                <div className='inner-srch-spacing'>
                                                    <p className='d-inline-block size-big mb-0 tab-0-content-sp'><span className='yellow-big-txt'>$55</span><strike>$66</strike></p>
                                                    <p className='p-md'>1 Night(incl.VAT)</p>
                                                    <a href='' className='btn btn-gradient-purple text-white d-inline-block h-auto w-100'>Book Now</a>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='w-100 d-grid grid-resultshow'>
                                            <div className='result-img-rounded'>
                                                <img src={ResultOne} alt='' />
                                            </div>

                                            <div className='srch-details-box'>
                                                <h3 className='mb-0'>Movenpick Hotel And Residences Riyadh</h3>
                                                <div class="reviews-box d-flex align-items-baseline mb-1 gap-2">
                                                    <p className='d-inline-block text-grey-light  p-all-small mb-0'>4.3</p>
                                                    <ul class="d-inline-block star-rating p-0 mb-0">
                                                        <li class="d-inline-block"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li><li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                    </ul>
                                                    <p className='d-inline-block p-all-small text-grey-light mb-0'>(144 reviews)</p>
                                                </div>
                                                <div className='row'>
                                                    <div className='col-lg-12 col-md-12 col-sm-12 width-mobile-adjust'>
                                                        <div className='room-feature'>
                                                            <ul className='p-0'>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={AirCondition} className='d-inline-block' alt='' />Air Condition </li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={ReceptionsIMG} className='d-inline-block' alt='' />24H. Reception</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={AtmDetail} className='d-inline-block' alt='' />ATM</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={BanqueRimg} className='d-inline-block' alt='' />Gym</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div className='col-lg-12 col-md-12 col-sm-12 width-mobile-adjust'>
                                                        <div className='room-feature'>
                                                            <ul className='p-0'>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={ParkingHotel} className='d-inline-block' alt='' />Parking</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={WifiHotel} className='d-inline-block' alt='' />Free Wifi</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='srch-price-box'>
                                                <div className='inner-srch-spacing'>
                                                    <p className='d-inline-block size-big mb-0 tab-0-content-sp'><span className='yellow-big-txt'>$55</span><strike>$66</strike></p>
                                                    <p className='p-md'>1 Night(incl.VAT)</p>
                                                    <a href='' className='btn btn-gradient-purple text-white d-inline-block h-auto w-100'>Book Now</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="tab-pane fade" id="v-pills-gym" role="tabpanel" aria-labelledby="v-pills-gym-tab">
                                    <div className='ryt-resultshow-wrapper'>
                                        <h3 className='text-grey-light mb-3'>Showing result for <span className='font-weight-600 text-color-theme'>“Saudi Arabia”</span></h3>
                                        <div className='w-100 d-grid grid-resultshow'>
                                            <div className='result-img-rounded'>
                                                <img src={ResultOne} alt='' />
                                            </div>

                                            <div className='srch-details-box'>
                                                <h3 className='mb-0'>Movenpick Hotel And Residences Riyadh</h3>
                                                <div class="reviews-box d-flex align-items-baseline mb-1 gap-2">
                                                    <p className='d-inline-block text-grey-light  p-all-small mb-0'>4.3</p>
                                                    <ul class="d-inline-block star-rating p-0 mb-0">
                                                        <li class="d-inline-block"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li><li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                    </ul>
                                                    <p className='d-inline-block p-all-small text-grey-light mb-0'>(144 reviews)</p>
                                                </div>
                                                <div className='row'>
                                                    <div className='col-lg-12 col-md-12 col-sm-12 width-mobile-adjust'>
                                                        <div className='room-feature'>
                                                            <ul className='p-0'>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={AirCondition} className='d-inline-block' alt='' />Air Condition </li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={ReceptionsIMG} className='d-inline-block' alt='' />24H. Reception</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={AtmDetail} className='d-inline-block' alt='' />ATM</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={BanqueRimg} className='d-inline-block' alt='' />Gym</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div className='col-lg-12 col-md-12 col-sm-12 width-mobile-adjust'>
                                                        <div className='room-feature'>
                                                            <ul className='p-0'>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={ParkingHotel} className='d-inline-block' alt='' />Parking</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={WifiHotel} className='d-inline-block' alt='' />Free Wifi</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='srch-price-box'>
                                                <div className='inner-srch-spacing'>
                                                    <p className='d-inline-block size-big mb-0 tab-0-content-sp'><span className='yellow-big-txt'>$55</span><strike>$66</strike></p>
                                                    <p className='p-md'>1 Night(incl.VAT)</p>
                                                    <a href='' className='btn btn-gradient-purple text-white d-inline-block h-auto w-100'>Book Now</a>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='w-100 d-grid grid-resultshow'>
                                            <div className='result-img-rounded'>
                                                <img src={ResultOne} alt='' />
                                            </div>

                                            <div className='srch-details-box'>
                                                <h3 className='mb-0'>Movenpick Hotel And Residences Riyadh</h3>
                                                <div class="reviews-box d-flex align-items-baseline mb-1 gap-2">
                                                    <p className='d-inline-block text-grey-light  p-all-small mb-0'>4.3</p>
                                                    <ul class="d-inline-block star-rating p-0 mb-0">
                                                        <li class="d-inline-block"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li><li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                    </ul>
                                                    <p className='d-inline-block p-all-small text-grey-light mb-0'>(144 reviews)</p>
                                                </div>
                                                <div className='row'>
                                                    <div className='col-lg-12 col-md-12 col-sm-12 width-mobile-adjust'>
                                                        <div className='room-feature'>
                                                            <ul className='p-0'>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={AirCondition} className='d-inline-block' alt='' />Air Condition </li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={ReceptionsIMG} className='d-inline-block' alt='' />24H. Reception</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={AtmDetail} className='d-inline-block' alt='' />ATM</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={BanqueRimg} className='d-inline-block' alt='' />Gym</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div className='col-lg-12 col-md-12 col-sm-12 width-mobile-adjust'>
                                                        <div className='room-feature'>
                                                            <ul className='p-0'>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={ParkingHotel} className='d-inline-block' alt='' />Parking</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={WifiHotel} className='d-inline-block' alt='' />Free Wifi</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='srch-price-box'>
                                                <div className='inner-srch-spacing'>
                                                    <p className='d-inline-block size-big mb-0 tab-0-content-sp'><span className='yellow-big-txt'>$55</span><strike>$66</strike></p>
                                                    <p className='p-md'>1 Night(incl.VAT)</p>
                                                    <a href='' className='btn btn-gradient-purple text-white d-inline-block h-auto w-100'>Book Now</a>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='w-100 d-grid grid-resultshow'>
                                            <div className='result-img-rounded'>
                                                <img src={ResultOne} alt='' />
                                            </div>

                                            <div className='srch-details-box'>
                                                <h3 className='mb-0'>Movenpick Hotel And Residences Riyadh</h3>
                                                <div class="reviews-box d-flex align-items-baseline mb-1 gap-2">
                                                    <p className='d-inline-block text-grey-light  p-all-small mb-0'>4.3</p>
                                                    <ul class="d-inline-block star-rating p-0 mb-0">
                                                        <li class="d-inline-block"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li><li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                    </ul>
                                                    <p className='d-inline-block p-all-small text-grey-light mb-0'>(144 reviews)</p>
                                                </div>
                                                <div className='row'>
                                                    <div className='col-lg-12 col-md-12 col-sm-12 width-mobile-adjust'>
                                                        <div className='room-feature'>
                                                            <ul className='p-0'>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={AirCondition} className='d-inline-block' alt='' />Air Condition </li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={ReceptionsIMG} className='d-inline-block' alt='' />24H. Reception</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={AtmDetail} className='d-inline-block' alt='' />ATM</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={BanqueRimg} className='d-inline-block' alt='' />Gym</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div className='col-lg-12 col-md-12 col-sm-12 width-mobile-adjust'>
                                                        <div className='room-feature'>
                                                            <ul className='p-0'>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={ParkingHotel} className='d-inline-block' alt='' />Parking</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={WifiHotel} className='d-inline-block' alt='' />Free Wifi</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='srch-price-box'>
                                                <div className='inner-srch-spacing'>
                                                    <p className='d-inline-block size-big mb-0 tab-0-content-sp'><span className='yellow-big-txt'>$55</span><strike>$66</strike></p>
                                                    <p className='p-md'>1 Night(incl.VAT)</p>
                                                    <a href='' className='btn btn-gradient-purple text-white d-inline-block h-auto w-100'>Book Now</a>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='w-100 d-grid grid-resultshow'>
                                            <div className='result-img-rounded'>
                                                <img src={ResultOne} alt='' />
                                            </div>

                                            <div className='srch-details-box'>
                                                <h3 className='mb-0'>Movenpick Hotel And Residences Riyadh</h3>
                                                <div class="reviews-box d-flex align-items-baseline mb-1 gap-2">
                                                    <p className='d-inline-block text-grey-light  p-all-small mb-0'>4.3</p>
                                                    <ul class="d-inline-block star-rating p-0 mb-0">
                                                        <li class="d-inline-block"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li><li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                    </ul>
                                                    <p className='d-inline-block p-all-small text-grey-light mb-0'>(144 reviews)</p>
                                                </div>
                                                <div className='row'>
                                                    <div className='col-lg-12 col-md-12 col-sm-12 width-mobile-adjust'>
                                                        <div className='room-feature'>
                                                            <ul className='p-0'>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={AirCondition} className='d-inline-block' alt='' />Air Condition </li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={ReceptionsIMG} className='d-inline-block' alt='' />24H. Reception</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={AtmDetail} className='d-inline-block' alt='' />ATM</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={BanqueRimg} className='d-inline-block' alt='' />Gym</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div className='col-lg-12 col-md-12 col-sm-12 width-mobile-adjust'>
                                                        <div className='room-feature'>
                                                            <ul className='p-0'>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={ParkingHotel} className='d-inline-block' alt='' />Parking</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={WifiHotel} className='d-inline-block' alt='' />Free Wifi</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='srch-price-box'>
                                                <div className='inner-srch-spacing'>
                                                    <p className='d-inline-block size-big mb-0 tab-0-content-sp'><span className='yellow-big-txt'>$55</span><strike>$66</strike></p>
                                                    <p className='p-md'>1 Night(incl.VAT)</p>
                                                    <a href='' className='btn btn-gradient-purple text-white d-inline-block h-auto w-100'>Book Now</a>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='w-100 d-grid grid-resultshow'>
                                            <div className='result-img-rounded'>
                                                <img src={ResultOne} alt='' />
                                            </div>

                                            <div className='srch-details-box'>
                                                <h3 className='mb-0'>Movenpick Hotel And Residences Riyadh</h3>
                                                <div class="reviews-box d-flex align-items-baseline mb-1 gap-2">
                                                    <p className='d-inline-block text-grey-light  p-all-small mb-0'>4.3</p>
                                                    <ul class="d-inline-block star-rating p-0 mb-0">
                                                        <li class="d-inline-block"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li><li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                    </ul>
                                                    <p className='d-inline-block p-all-small text-grey-light mb-0'>(144 reviews)</p>
                                                </div>
                                                <div className='row'>
                                                    <div className='col-lg-12 col-md-12 col-sm-12 width-mobile-adjust'>
                                                        <div className='room-feature'>
                                                            <ul className='p-0'>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={AirCondition} className='d-inline-block' alt='' />Air Condition </li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={ReceptionsIMG} className='d-inline-block' alt='' />24H. Reception</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={AtmDetail} className='d-inline-block' alt='' />ATM</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={BanqueRimg} className='d-inline-block' alt='' />Gym</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div className='col-lg-12 col-md-12 col-sm-12 width-mobile-adjust'>
                                                        <div className='room-feature'>
                                                            <ul className='p-0'>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={ParkingHotel} className='d-inline-block' alt='' />Parking</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={WifiHotel} className='d-inline-block' alt='' />Free Wifi</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='srch-price-box'>
                                                <div className='inner-srch-spacing'>
                                                    <p className='d-inline-block size-big mb-0 tab-0-content-sp'><span className='yellow-big-txt'>$55</span><strike>$66</strike></p>
                                                    <p className='p-md'>1 Night(incl.VAT)</p>
                                                    <a href='' className='btn btn-gradient-purple text-white d-inline-block h-auto w-100'>Book Now</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div
                                    class="tab-pane fade" id="v-pills-wifi" role="tabpanel" aria-labelledby="v-pills-wifi-tab">
                                    <div className='ryt-resultshow-wrapper'>
                                        <h3 className='text-grey-light mb-3'>Showing result for <span className='font-weight-600 text-color-theme'>“Saudi Arabia”</span></h3>
                                        <div className='w-100 d-grid grid-resultshow'>
                                            <div className='result-img-rounded'>
                                                <img src={ResultOne} alt='' />
                                            </div>

                                            <div className='srch-details-box'>
                                                <h3 className='mb-0'>Movenpick Hotel And Residences Riyadh</h3>
                                                <div class="reviews-box d-flex align-items-baseline mb-1 gap-2">
                                                    <p className='d-inline-block text-grey-light  p-all-small mb-0'>4.3</p>
                                                    <ul class="d-inline-block star-rating p-0 mb-0">
                                                        <li class="d-inline-block"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li><li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                    </ul>
                                                    <p className='d-inline-block p-all-small text-grey-light mb-0'>(144 reviews)</p>
                                                </div>
                                                <div className='row'>
                                                    <div className='col-lg-12 col-md-12 col-sm-12 width-mobile-adjust'>
                                                        <div className='room-feature'>
                                                            <ul className='p-0'>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={AirCondition} className='d-inline-block' alt='' />Air Condition </li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={ReceptionsIMG} className='d-inline-block' alt='' />24H. Reception</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={AtmDetail} className='d-inline-block' alt='' />ATM</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={BanqueRimg} className='d-inline-block' alt='' />Gym</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div className='col-lg-12 col-md-12 col-sm-12 width-mobile-adjust'>
                                                        <div className='room-feature'>
                                                            <ul className='p-0'>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={ParkingHotel} className='d-inline-block' alt='' />Parking</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={WifiHotel} className='d-inline-block' alt='' />Free Wifi</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='srch-price-box'>
                                                <div className='inner-srch-spacing'>
                                                    <p className='d-inline-block size-big mb-0 tab-0-content-sp'><span className='yellow-big-txt'>$55</span><strike>$66</strike></p>
                                                    <p className='p-md'>1 Night(incl.VAT)</p>
                                                    <a href='' className='btn btn-gradient-purple text-white d-inline-block h-auto w-100'>Book Now</a>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='w-100 d-grid grid-resultshow'>
                                            <div className='result-img-rounded'>
                                                <img src={ResultOne} alt='' />
                                            </div>

                                            <div className='srch-details-box'>
                                                <h3 className='mb-0'>Movenpick Hotel And Residences Riyadh</h3>
                                                <div class="reviews-box d-flex align-items-baseline mb-1 gap-2">
                                                    <p className='d-inline-block text-grey-light  p-all-small mb-0'>4.3</p>
                                                    <ul class="d-inline-block star-rating p-0 mb-0">
                                                        <li class="d-inline-block"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li><li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                    </ul>
                                                    <p className='d-inline-block p-all-small text-grey-light mb-0'>(144 reviews)</p>
                                                </div>
                                                <div className='row'>
                                                    <div className='col-lg-12 col-md-12 col-sm-12 width-mobile-adjust'>
                                                        <div className='room-feature'>
                                                            <ul className='p-0'>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={AirCondition} className='d-inline-block' alt='' />Air Condition </li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={ReceptionsIMG} className='d-inline-block' alt='' />24H. Reception</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={AtmDetail} className='d-inline-block' alt='' />ATM</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={BanqueRimg} className='d-inline-block' alt='' />Gym</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div className='col-lg-12 col-md-12 col-sm-12 width-mobile-adjust'>
                                                        <div className='room-feature'>
                                                            <ul className='p-0'>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={ParkingHotel} className='d-inline-block' alt='' />Parking</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={WifiHotel} className='d-inline-block' alt='' />Free Wifi</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='srch-price-box'>
                                                <div className='inner-srch-spacing'>
                                                    <p className='d-inline-block size-big mb-0 tab-0-content-sp'><span className='yellow-big-txt'>$55</span><strike>$66</strike></p>
                                                    <p className='p-md'>1 Night(incl.VAT)</p>
                                                    <a href='' className='btn btn-gradient-purple text-white d-inline-block h-auto w-100'>Book Now</a>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='w-100 d-grid grid-resultshow'>
                                            <div className='result-img-rounded'>
                                                <img src={ResultOne} alt='' />
                                            </div>

                                            <div className='srch-details-box'>
                                                <h3 className='mb-0'>Movenpick Hotel And Residences Riyadh</h3>
                                                <div class="reviews-box d-flex align-items-baseline mb-1 gap-2">
                                                    <p className='d-inline-block text-grey-light  p-all-small mb-0'>4.3</p>
                                                    <ul class="d-inline-block star-rating p-0 mb-0">
                                                        <li class="d-inline-block"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li><li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                    </ul>
                                                    <p className='d-inline-block p-all-small text-grey-light mb-0'>(144 reviews)</p>
                                                </div>
                                                <div className='row'>
                                                    <div className='col-lg-12 col-md-12 col-sm-12 width-mobile-adjust'>
                                                        <div className='room-feature'>
                                                            <ul className='p-0'>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={AirCondition} className='d-inline-block' alt='' />Air Condition </li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={ReceptionsIMG} className='d-inline-block' alt='' />24H. Reception</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={AtmDetail} className='d-inline-block' alt='' />ATM</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={BanqueRimg} className='d-inline-block' alt='' />Gym</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div className='col-lg-12 col-md-12 col-sm-12 width-mobile-adjust'>
                                                        <div className='room-feature'>
                                                            <ul className='p-0'>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={ParkingHotel} className='d-inline-block' alt='' />Parking</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={WifiHotel} className='d-inline-block' alt='' />Free Wifi</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='srch-price-box'>
                                                <div className='inner-srch-spacing'>
                                                    <p className='d-inline-block size-big mb-0 tab-0-content-sp'><span className='yellow-big-txt'>$55</span><strike>$66</strike></p>
                                                    <p className='p-md'>1 Night(incl.VAT)</p>
                                                    <a href='' className='btn btn-gradient-purple text-white d-inline-block h-auto w-100'>Book Now</a>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='w-100 d-grid grid-resultshow'>
                                            <div className='result-img-rounded'>
                                                <img src={ResultOne} alt='' />
                                            </div>

                                            <div className='srch-details-box'>
                                                <h3 className='mb-0'>Movenpick Hotel And Residences Riyadh</h3>
                                                <div class="reviews-box d-flex align-items-baseline mb-1 gap-2">
                                                    <p className='d-inline-block text-grey-light  p-all-small mb-0'>4.3</p>
                                                    <ul class="d-inline-block star-rating p-0 mb-0">
                                                        <li class="d-inline-block"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li><li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                    </ul>
                                                    <p className='d-inline-block p-all-small text-grey-light mb-0'>(144 reviews)</p>
                                                </div>
                                                <div className='row'>
                                                    <div className='col-lg-12 col-md-12 col-sm-12 width-mobile-adjust'>
                                                        <div className='room-feature'>
                                                            <ul className='p-0'>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={AirCondition} className='d-inline-block' alt='' />Air Condition </li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={ReceptionsIMG} className='d-inline-block' alt='' />24H. Reception</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={AtmDetail} className='d-inline-block' alt='' />ATM</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={BanqueRimg} className='d-inline-block' alt='' />Gym</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div className='col-lg-12 col-md-12 col-sm-12 width-mobile-adjust'>
                                                        <div className='room-feature'>
                                                            <ul className='p-0'>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={ParkingHotel} className='d-inline-block' alt='' />Parking</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={WifiHotel} className='d-inline-block' alt='' />Free Wifi</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='srch-price-box'>
                                                <div className='inner-srch-spacing'>
                                                    <p className='d-inline-block size-big mb-0 tab-0-content-sp'><span className='yellow-big-txt'>$55</span><strike>$66</strike></p>
                                                    <p className='p-md'>1 Night(incl.VAT)</p>
                                                    <a href='' className='btn btn-gradient-purple text-white d-inline-block h-auto w-100'>Book Now</a>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='w-100 d-grid grid-resultshow'>
                                            <div className='result-img-rounded'>
                                                <img src={ResultOne} alt='' />
                                            </div>

                                            <div className='srch-details-box'>
                                                <h3 className='mb-0'>Movenpick Hotel And Residences Riyadh</h3>
                                                <div class="reviews-box d-flex align-items-baseline mb-1 gap-2">
                                                    <p className='d-inline-block text-grey-light  p-all-small mb-0'>4.3</p>
                                                    <ul class="d-inline-block star-rating p-0 mb-0">
                                                        <li class="d-inline-block"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li><li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                    </ul>
                                                    <p className='d-inline-block p-all-small text-grey-light mb-0'>(144 reviews)</p>
                                                </div>
                                                <div className='row'>
                                                    <div className='col-lg-12 col-md-12 col-sm-12 width-mobile-adjust'>
                                                        <div className='room-feature'>
                                                            <ul className='p-0'>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={AirCondition} className='d-inline-block' alt='' />Air Condition </li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={ReceptionsIMG} className='d-inline-block' alt='' />24H. Reception</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={AtmDetail} className='d-inline-block' alt='' />ATM</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={BanqueRimg} className='d-inline-block' alt='' />Gym</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div className='col-lg-12 col-md-12 col-sm-12 width-mobile-adjust'>
                                                        <div className='room-feature'>
                                                            <ul className='p-0'>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={ParkingHotel} className='d-inline-block' alt='' />Parking</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={WifiHotel} className='d-inline-block' alt='' />Free Wifi</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='srch-price-box'>
                                                <div className='inner-srch-spacing'>
                                                    <p className='d-inline-block size-big mb-0 tab-0-content-sp'><span className='yellow-big-txt'>$55</span><strike>$66</strike></p>
                                                    <p className='p-md'>1 Night(incl.VAT)</p>
                                                    <a href='' className='btn btn-gradient-purple text-white d-inline-block h-auto w-100'>Book Now</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="tab-pane fade" id="v-pills-garden" role="tabpanel" aria-labelledby="v-pills-garden-tab">
                                    <div className='ryt-resultshow-wrapper'>
                                        <h3 className='text-grey-light mb-3'>Showing result for <span className='font-weight-600 text-color-theme'>“Saudi Arabia”</span></h3>
                                        <div className='w-100 d-grid grid-resultshow'>
                                            <div className='result-img-rounded'>
                                                <img src={ResultOne} alt='' />
                                            </div>

                                            <div className='srch-details-box'>
                                                <h3 className='mb-0'>Movenpick Hotel And Residences Riyadh</h3>
                                                <div class="reviews-box d-flex align-items-baseline mb-1 gap-2">
                                                    <p className='d-inline-block text-grey-light  p-all-small mb-0'>4.3</p>
                                                    <ul class="d-inline-block star-rating p-0 mb-0">
                                                        <li class="d-inline-block"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li><li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                    </ul>
                                                    <p className='d-inline-block p-all-small text-grey-light mb-0'>(144 reviews)</p>
                                                </div>
                                                <div className='row'>
                                                    <div className='col-lg-12 col-md-12 col-sm-12 width-mobile-adjust'>
                                                        <div className='room-feature'>
                                                            <ul className='p-0'>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={AirCondition} className='d-inline-block' alt='' />Air Condition </li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={ReceptionsIMG} className='d-inline-block' alt='' />24H. Reception</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={AtmDetail} className='d-inline-block' alt='' />ATM</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={BanqueRimg} className='d-inline-block' alt='' />Gym</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div className='col-lg-12 col-md-12 col-sm-12 width-mobile-adjust'>
                                                        <div className='room-feature'>
                                                            <ul className='p-0'>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={ParkingHotel} className='d-inline-block' alt='' />Parking</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={WifiHotel} className='d-inline-block' alt='' />Free Wifi</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='srch-price-box'>
                                                <div className='inner-srch-spacing'>
                                                    <p className='d-inline-block size-big mb-0 tab-0-content-sp'><span className='yellow-big-txt'>$55</span><strike>$66</strike></p>
                                                    <p className='p-md'>1 Night(incl.VAT)</p>
                                                    <a href='' className='btn btn-gradient-purple text-white d-inline-block h-auto w-100'>Book Now</a>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='w-100 d-grid grid-resultshow'>
                                            <div className='result-img-rounded'>
                                                <img src={ResultOne} alt='' />
                                            </div>

                                            <div className='srch-details-box'>
                                                <h3 className='mb-0'>Movenpick Hotel And Residences Riyadh</h3>
                                                <div class="reviews-box d-flex align-items-baseline mb-1 gap-2">
                                                    <p className='d-inline-block text-grey-light  p-all-small mb-0'>4.3</p>
                                                    <ul class="d-inline-block star-rating p-0 mb-0">
                                                        <li class="d-inline-block"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li><li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                    </ul>
                                                    <p className='d-inline-block p-all-small text-grey-light mb-0'>(144 reviews)</p>
                                                </div>
                                                <div className='row'>
                                                    <div className='col-lg-12 col-md-12 col-sm-12 width-mobile-adjust'>
                                                        <div className='room-feature'>
                                                            <ul className='p-0'>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={AirCondition} className='d-inline-block' alt='' />Air Condition </li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={ReceptionsIMG} className='d-inline-block' alt='' />24H. Reception</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={AtmDetail} className='d-inline-block' alt='' />ATM</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={BanqueRimg} className='d-inline-block' alt='' />Gym</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div className='col-lg-12 col-md-12 col-sm-12 width-mobile-adjust'>
                                                        <div className='room-feature'>
                                                            <ul className='p-0'>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={ParkingHotel} className='d-inline-block' alt='' />Parking</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={WifiHotel} className='d-inline-block' alt='' />Free Wifi</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='srch-price-box'>
                                                <div className='inner-srch-spacing'>
                                                    <p className='d-inline-block size-big mb-0 tab-0-content-sp'><span className='yellow-big-txt'>$55</span><strike>$66</strike></p>
                                                    <p className='p-md'>1 Night(incl.VAT)</p>
                                                    <a href='' className='btn btn-gradient-purple text-white d-inline-block h-auto w-100'>Book Now</a>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='w-100 d-grid grid-resultshow'>
                                            <div className='result-img-rounded'>
                                                <img src={ResultOne} alt='' />
                                            </div>

                                            <div className='srch-details-box'>
                                                <h3 className='mb-0'>Movenpick Hotel And Residences Riyadh</h3>
                                                <div class="reviews-box d-flex align-items-baseline mb-1 gap-2">
                                                    <p className='d-inline-block text-grey-light  p-all-small mb-0'>4.3</p>
                                                    <ul class="d-inline-block star-rating p-0 mb-0">
                                                        <li class="d-inline-block"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li><li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                    </ul>
                                                    <p className='d-inline-block p-all-small text-grey-light mb-0'>(144 reviews)</p>
                                                </div>
                                                <div className='row'>
                                                    <div className='col-lg-12 col-md-12 col-sm-12 width-mobile-adjust'>
                                                        <div className='room-feature'>
                                                            <ul className='p-0'>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={AirCondition} className='d-inline-block' alt='' />Air Condition </li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={ReceptionsIMG} className='d-inline-block' alt='' />24H. Reception</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={AtmDetail} className='d-inline-block' alt='' />ATM</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={BanqueRimg} className='d-inline-block' alt='' />Gym</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div className='col-lg-12 col-md-12 col-sm-12 width-mobile-adjust'>
                                                        <div className='room-feature'>
                                                            <ul className='p-0'>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={ParkingHotel} className='d-inline-block' alt='' />Parking</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={WifiHotel} className='d-inline-block' alt='' />Free Wifi</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='srch-price-box'>
                                                <div className='inner-srch-spacing'>
                                                    <p className='d-inline-block size-big mb-0 tab-0-content-sp'><span className='yellow-big-txt'>$55</span><strike>$66</strike></p>
                                                    <p className='p-md'>1 Night(incl.VAT)</p>
                                                    <a href='' className='btn btn-gradient-purple text-white d-inline-block h-auto w-100'>Book Now</a>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='w-100 d-grid grid-resultshow'>
                                            <div className='result-img-rounded'>
                                                <img src={ResultOne} alt='' />
                                            </div>

                                            <div className='srch-details-box'>
                                                <h3 className='mb-0'>Movenpick Hotel And Residences Riyadh</h3>
                                                <div class="reviews-box d-flex align-items-baseline mb-1 gap-2">
                                                    <p className='d-inline-block text-grey-light  p-all-small mb-0'>4.3</p>
                                                    <ul class="d-inline-block star-rating p-0 mb-0">
                                                        <li class="d-inline-block"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li><li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                    </ul>
                                                    <p className='d-inline-block p-all-small text-grey-light mb-0'>(144 reviews)</p>
                                                </div>
                                                <div className='row'>
                                                    <div className='col-lg-12 col-md-12 col-sm-12 width-mobile-adjust'>
                                                        <div className='room-feature'>
                                                            <ul className='p-0'>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={AirCondition} className='d-inline-block' alt='' />Air Condition </li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={ReceptionsIMG} className='d-inline-block' alt='' />24H. Reception</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={AtmDetail} className='d-inline-block' alt='' />ATM</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={BanqueRimg} className='d-inline-block' alt='' />Gym</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div className='col-lg-12 col-md-12 col-sm-12 width-mobile-adjust'>
                                                        <div className='room-feature'>
                                                            <ul className='p-0'>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={ParkingHotel} className='d-inline-block' alt='' />Parking</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={WifiHotel} className='d-inline-block' alt='' />Free Wifi</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='srch-price-box'>
                                                <div className='inner-srch-spacing'>
                                                    <p className='d-inline-block size-big mb-0 tab-0-content-sp'><span className='yellow-big-txt'>$55</span><strike>$66</strike></p>
                                                    <p className='p-md'>1 Night(incl.VAT)</p>
                                                    <a href='' className='btn btn-gradient-purple text-white d-inline-block h-auto w-100'>Book Now</a>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='w-100 d-grid grid-resultshow'>
                                            <div className='result-img-rounded'>
                                                <img src={ResultOne} alt='' />
                                            </div>

                                            <div className='srch-details-box'>
                                                <h3 className='mb-0'>Movenpick Hotel And Residences Riyadh</h3>
                                                <div class="reviews-box d-flex align-items-baseline mb-1 gap-2">
                                                    <p className='d-inline-block text-grey-light  p-all-small mb-0'>4.3</p>
                                                    <ul class="d-inline-block star-rating p-0 mb-0">
                                                        <li class="d-inline-block"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li><li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                    </ul>
                                                    <p className='d-inline-block p-all-small text-grey-light mb-0'>(144 reviews)</p>
                                                </div>
                                                <div className='row'>
                                                    <div className='col-lg-12 col-md-12 col-sm-12 width-mobile-adjust'>
                                                        <div className='room-feature'>
                                                            <ul className='p-0'>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={AirCondition} className='d-inline-block' alt='' />Air Condition </li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={ReceptionsIMG} className='d-inline-block' alt='' />24H. Reception</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={AtmDetail} className='d-inline-block' alt='' />ATM</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={BanqueRimg} className='d-inline-block' alt='' />Gym</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div className='col-lg-12 col-md-12 col-sm-12 width-mobile-adjust'>
                                                        <div className='room-feature'>
                                                            <ul className='p-0'>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={ParkingHotel} className='d-inline-block' alt='' />Parking</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={WifiHotel} className='d-inline-block' alt='' />Free Wifi</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='srch-price-box'>
                                                <div className='inner-srch-spacing'>
                                                    <p className='d-inline-block size-big mb-0 tab-0-content-sp'><span className='yellow-big-txt'>$55</span><strike>$66</strike></p>
                                                    <p className='p-md'>1 Night(incl.VAT)</p>
                                                    <a href='' className='btn btn-gradient-purple text-white d-inline-block h-auto w-100'>Book Now</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="tab-pane fade" id="v-pills-pool" role="tabpanel" aria-labelledby="v-pills-pool-tab">
                                    <div className='ryt-resultshow-wrapper'>
                                        <h3 className='text-grey-light mb-3'>Showing result for <span className='font-weight-600 text-color-theme'>“Saudi Arabia”</span></h3>
                                        <div className='w-100 d-grid grid-resultshow'>
                                            <div className='result-img-rounded'>
                                                <img src={ResultOne} alt='' />
                                            </div>

                                            <div className='srch-details-box'>
                                                <h3 className='mb-0'>Movenpick Hotel And Residences Riyadh</h3>
                                                <div class="reviews-box d-flex align-items-baseline mb-1 gap-2">
                                                    <p className='d-inline-block text-grey-light  p-all-small mb-0'>4.3</p>
                                                    <ul class="d-inline-block star-rating p-0 mb-0">
                                                        <li class="d-inline-block"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li><li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                    </ul>
                                                    <p className='d-inline-block p-all-small text-grey-light mb-0'>(144 reviews)</p>
                                                </div>
                                                <div className='row'>
                                                    <div className='col-lg-12 col-md-12 col-sm-12 width-mobile-adjust'>
                                                        <div className='room-feature'>
                                                            <ul className='p-0'>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={AirCondition} className='d-inline-block' alt='' />Air Condition </li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={ReceptionsIMG} className='d-inline-block' alt='' />24H. Reception</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={AtmDetail} className='d-inline-block' alt='' />ATM</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={BanqueRimg} className='d-inline-block' alt='' />Gym</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div className='col-lg-12 col-md-12 col-sm-12 width-mobile-adjust'>
                                                        <div className='room-feature'>
                                                            <ul className='p-0'>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={ParkingHotel} className='d-inline-block' alt='' />Parking</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={WifiHotel} className='d-inline-block' alt='' />Free Wifi</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='srch-price-box'>
                                                <div className='inner-srch-spacing'>
                                                    <p className='d-inline-block size-big mb-0 tab-0-content-sp'><span className='yellow-big-txt'>$55</span><strike>$66</strike></p>
                                                    <p className='p-md'>1 Night(incl.VAT)</p>
                                                    <a href='' className='btn btn-gradient-purple text-white d-inline-block h-auto w-100'>Book Now</a>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='w-100 d-grid grid-resultshow'>
                                            <div className='result-img-rounded'>
                                                <img src={ResultOne} alt='' />
                                            </div>

                                            <div className='srch-details-box'>
                                                <h3 className='mb-0'>Movenpick Hotel And Residences Riyadh</h3>
                                                <div class="reviews-box d-flex align-items-baseline mb-1 gap-2">
                                                    <p className='d-inline-block text-grey-light  p-all-small mb-0'>4.3</p>
                                                    <ul class="d-inline-block star-rating p-0 mb-0">
                                                        <li class="d-inline-block"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li><li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                    </ul>
                                                    <p className='d-inline-block p-all-small text-grey-light mb-0'>(144 reviews)</p>
                                                </div>
                                                <div className='row'>
                                                    <div className='col-lg-12 col-md-12 col-sm-12 width-mobile-adjust'>
                                                        <div className='room-feature'>
                                                            <ul className='p-0'>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={AirCondition} className='d-inline-block' alt='' />Air Condition </li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={ReceptionsIMG} className='d-inline-block' alt='' />24H. Reception</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={AtmDetail} className='d-inline-block' alt='' />ATM</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={BanqueRimg} className='d-inline-block' alt='' />Gym</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div className='col-lg-12 col-md-12 col-sm-12 width-mobile-adjust'>
                                                        <div className='room-feature'>
                                                            <ul className='p-0'>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={ParkingHotel} className='d-inline-block' alt='' />Parking</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={WifiHotel} className='d-inline-block' alt='' />Free Wifi</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='srch-price-box'>
                                                <div className='inner-srch-spacing'>
                                                    <p className='d-inline-block size-big mb-0 tab-0-content-sp'><span className='yellow-big-txt'>$55</span><strike>$66</strike></p>
                                                    <p className='p-md'>1 Night(incl.VAT)</p>
                                                    <a href='' className='btn btn-gradient-purple text-white d-inline-block h-auto w-100'>Book Now</a>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='w-100 d-grid grid-resultshow'>
                                            <div className='result-img-rounded'>
                                                <img src={ResultOne} alt='' />
                                            </div>

                                            <div className='srch-details-box'>
                                                <h3 className='mb-0'>Movenpick Hotel And Residences Riyadh</h3>
                                                <div class="reviews-box d-flex align-items-baseline mb-1 gap-2">
                                                    <p className='d-inline-block text-grey-light  p-all-small mb-0'>4.3</p>
                                                    <ul class="d-inline-block star-rating p-0 mb-0">
                                                        <li class="d-inline-block"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li><li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                    </ul>
                                                    <p className='d-inline-block p-all-small text-grey-light mb-0'>(144 reviews)</p>
                                                </div>
                                                <div className='row'>
                                                    <div className='col-lg-12 col-md-12 col-sm-12 width-mobile-adjust'>
                                                        <div className='room-feature'>
                                                            <ul className='p-0'>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={AirCondition} className='d-inline-block' alt='' />Air Condition </li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={ReceptionsIMG} className='d-inline-block' alt='' />24H. Reception</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={AtmDetail} className='d-inline-block' alt='' />ATM</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={BanqueRimg} className='d-inline-block' alt='' />Gym</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div className='col-lg-12 col-md-12 col-sm-12 width-mobile-adjust'>
                                                        <div className='room-feature'>
                                                            <ul className='p-0'>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={ParkingHotel} className='d-inline-block' alt='' />Parking</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={WifiHotel} className='d-inline-block' alt='' />Free Wifi</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='srch-price-box'>
                                                <div className='inner-srch-spacing'>
                                                    <p className='d-inline-block size-big mb-0 tab-0-content-sp'><span className='yellow-big-txt'>$55</span><strike>$66</strike></p>
                                                    <p className='p-md'>1 Night(incl.VAT)</p>
                                                    <a href='' className='btn btn-gradient-purple text-white d-inline-block h-auto w-100'>Book Now</a>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='w-100 d-grid grid-resultshow'>
                                            <div className='result-img-rounded'>
                                                <img src={ResultOne} alt='' />
                                            </div>

                                            <div className='srch-details-box'>
                                                <h3 className='mb-0'>Movenpick Hotel And Residences Riyadh</h3>
                                                <div class="reviews-box d-flex align-items-baseline mb-1 gap-2">
                                                    <p className='d-inline-block text-grey-light  p-all-small mb-0'>4.3</p>
                                                    <ul class="d-inline-block star-rating p-0 mb-0">
                                                        <li class="d-inline-block"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li><li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                    </ul>
                                                    <p className='d-inline-block p-all-small text-grey-light mb-0'>(144 reviews)</p>
                                                </div>
                                                <div className='row'>
                                                    <div className='col-lg-12 col-md-12 col-sm-12 width-mobile-adjust'>
                                                        <div className='room-feature'>
                                                            <ul className='p-0'>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={AirCondition} className='d-inline-block' alt='' />Air Condition </li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={ReceptionsIMG} className='d-inline-block' alt='' />24H. Reception</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={AtmDetail} className='d-inline-block' alt='' />ATM</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={BanqueRimg} className='d-inline-block' alt='' />Gym</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div className='col-lg-12 col-md-12 col-sm-12 width-mobile-adjust'>
                                                        <div className='room-feature'>
                                                            <ul className='p-0'>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={ParkingHotel} className='d-inline-block' alt='' />Parking</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={WifiHotel} className='d-inline-block' alt='' />Free Wifi</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='srch-price-box'>
                                                <div className='inner-srch-spacing'>
                                                    <p className='d-inline-block size-big mb-0 tab-0-content-sp'><span className='yellow-big-txt'>$55</span><strike>$66</strike></p>
                                                    <p className='p-md'>1 Night(incl.VAT)</p>
                                                    <a href='' className='btn btn-gradient-purple text-white d-inline-block h-auto w-100'>Book Now</a>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='w-100 d-grid grid-resultshow'>
                                            <div className='result-img-rounded'>
                                                <img src={ResultOne} alt='' />
                                            </div>

                                            <div className='srch-details-box'>
                                                <h3 className='mb-0'>Movenpick Hotel And Residences Riyadh</h3>
                                                <div class="reviews-box d-flex align-items-baseline mb-1 gap-2">
                                                    <p className='d-inline-block text-grey-light  p-all-small mb-0'>4.3</p>
                                                    <ul class="d-inline-block star-rating p-0 mb-0">
                                                        <li class="d-inline-block"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li><li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                    </ul>
                                                    <p className='d-inline-block p-all-small text-grey-light mb-0'>(144 reviews)</p>
                                                </div>
                                                <div className='row'>
                                                    <div className='col-lg-12 col-md-12 col-sm-12 width-mobile-adjust'>
                                                        <div className='room-feature'>
                                                            <ul className='p-0'>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={AirCondition} className='d-inline-block' alt='' />Air Condition </li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={ReceptionsIMG} className='d-inline-block' alt='' />24H. Reception</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={AtmDetail} className='d-inline-block' alt='' />ATM</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={BanqueRimg} className='d-inline-block' alt='' />Gym</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div className='col-lg-12 col-md-12 col-sm-12 width-mobile-adjust'>
                                                        <div className='room-feature'>
                                                            <ul className='p-0'>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={ParkingHotel} className='d-inline-block' alt='' />Parking</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={WifiHotel} className='d-inline-block' alt='' />Free Wifi</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='srch-price-box'>
                                                <div className='inner-srch-spacing'>
                                                    <p className='d-inline-block size-big mb-0 tab-0-content-sp'><span className='yellow-big-txt'>$55</span><strike>$66</strike></p>
                                                    <p className='p-md'>1 Night(incl.VAT)</p>
                                                    <a href='' className='btn btn-gradient-purple text-white d-inline-block h-auto w-100'>Book Now</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="tab-pane fade" id="v-pills-pool" role="tabpanel" aria-labelledby="v-pills-beach-tab">
                                    <div className='ryt-resultshow-wrapper'>
                                        <h3 className='text-grey-light mb-3'>Showing result for <span className='font-weight-600 text-color-theme'>“Saudi Arabia”</span></h3>
                                        <div className='w-100 d-grid grid-resultshow'>
                                            <div className='result-img-rounded'>
                                                <img src={ResultOne} alt='' />
                                            </div>

                                            <div className='srch-details-box'>
                                                <h3 className='mb-0'>Movenpick Hotel And Residences Riyadh</h3>
                                                <div class="reviews-box d-flex align-items-baseline mb-1 gap-2">
                                                    <p className='d-inline-block text-grey-light  p-all-small mb-0'>4.3</p>
                                                    <ul class="d-inline-block star-rating p-0 mb-0">
                                                        <li class="d-inline-block"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li><li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                    </ul>
                                                    <p className='d-inline-block p-all-small text-grey-light mb-0'>(144 reviews)</p>
                                                </div>
                                                <div className='row'>
                                                    <div className='col-lg-12 col-md-12 col-sm-12 width-mobile-adjust'>
                                                        <div className='room-feature'>
                                                            <ul className='p-0'>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={AirCondition} className='d-inline-block' alt='' />Air Condition </li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={ReceptionsIMG} className='d-inline-block' alt='' />24H. Reception</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={AtmDetail} className='d-inline-block' alt='' />ATM</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={BanqueRimg} className='d-inline-block' alt='' />Gym</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div className='col-lg-12 col-md-12 col-sm-12 width-mobile-adjust'>
                                                        <div className='room-feature'>
                                                            <ul className='p-0'>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={ParkingHotel} className='d-inline-block' alt='' />Parking</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={WifiHotel} className='d-inline-block' alt='' />Free Wifi</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='srch-price-box'>
                                                <div className='inner-srch-spacing'>
                                                    <p className='d-inline-block size-big mb-0 tab-0-content-sp'><span className='yellow-big-txt'>$55</span><strike>$66</strike></p>
                                                    <p className='p-md'>1 Night(incl.VAT)</p>
                                                    <a href='' className='btn btn-gradient-purple text-white d-inline-block h-auto w-100'>Book Now</a>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='w-100 d-grid grid-resultshow'>
                                            <div className='result-img-rounded'>
                                                <img src={ResultOne} alt='' />
                                            </div>

                                            <div className='srch-details-box'>
                                                <h3 className='mb-0'>Movenpick Hotel And Residences Riyadh</h3>
                                                <div class="reviews-box d-flex align-items-baseline mb-1 gap-2">
                                                    <p className='d-inline-block text-grey-light  p-all-small mb-0'>4.3</p>
                                                    <ul class="d-inline-block star-rating p-0 mb-0">
                                                        <li class="d-inline-block"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li><li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                    </ul>
                                                    <p className='d-inline-block p-all-small text-grey-light mb-0'>(144 reviews)</p>
                                                </div>
                                                <div className='row'>
                                                    <div className='col-lg-12 col-md-12 col-sm-12 width-mobile-adjust'>
                                                        <div className='room-feature'>
                                                            <ul className='p-0'>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={AirCondition} className='d-inline-block' alt='' />Air Condition </li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={ReceptionsIMG} className='d-inline-block' alt='' />24H. Reception</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={AtmDetail} className='d-inline-block' alt='' />ATM</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={BanqueRimg} className='d-inline-block' alt='' />Gym</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div className='col-lg-12 col-md-12 col-sm-12 width-mobile-adjust'>
                                                        <div className='room-feature'>
                                                            <ul className='p-0'>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={ParkingHotel} className='d-inline-block' alt='' />Parking</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={WifiHotel} className='d-inline-block' alt='' />Free Wifi</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='srch-price-box'>
                                                <div className='inner-srch-spacing'>
                                                    <p className='d-inline-block size-big mb-0 tab-0-content-sp'><span className='yellow-big-txt'>$55</span><strike>$66</strike></p>
                                                    <p className='p-md'>1 Night(incl.VAT)</p>
                                                    <a href='' className='btn btn-gradient-purple text-white d-inline-block h-auto w-100'>Book Now</a>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='w-100 d-grid grid-resultshow'>
                                            <div className='result-img-rounded'>
                                                <img src={ResultOne} alt='' />
                                            </div>

                                            <div className='srch-details-box'>
                                                <h3 className='mb-0'>Movenpick Hotel And Residences Riyadh</h3>
                                                <div class="reviews-box d-flex align-items-baseline mb-1 gap-2">
                                                    <p className='d-inline-block text-grey-light  p-all-small mb-0'>4.3</p>
                                                    <ul class="d-inline-block star-rating p-0 mb-0">
                                                        <li class="d-inline-block"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li><li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                    </ul>
                                                    <p className='d-inline-block p-all-small text-grey-light mb-0'>(144 reviews)</p>
                                                </div>
                                                <div className='row'>
                                                    <div className='col-lg-12 col-md-12 col-sm-12 width-mobile-adjust'>
                                                        <div className='room-feature'>
                                                            <ul className='p-0'>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={AirCondition} className='d-inline-block' alt='' />Air Condition </li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={ReceptionsIMG} className='d-inline-block' alt='' />24H. Reception</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={AtmDetail} className='d-inline-block' alt='' />ATM</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={BanqueRimg} className='d-inline-block' alt='' />Gym</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div className='col-lg-12 col-md-12 col-sm-12 width-mobile-adjust'>
                                                        <div className='room-feature'>
                                                            <ul className='p-0'>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={ParkingHotel} className='d-inline-block' alt='' />Parking</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={WifiHotel} className='d-inline-block' alt='' />Free Wifi</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='srch-price-box'>
                                                <div className='inner-srch-spacing'>
                                                    <p className='d-inline-block size-big mb-0 tab-0-content-sp'><span className='yellow-big-txt'>$55</span><strike>$66</strike></p>
                                                    <p className='p-md'>1 Night(incl.VAT)</p>
                                                    <a href='' className='btn btn-gradient-purple text-white d-inline-block h-auto w-100'>Book Now</a>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='w-100 d-grid grid-resultshow'>
                                            <div className='result-img-rounded'>
                                                <img src={ResultOne} alt='' />
                                            </div>

                                            <div className='srch-details-box'>
                                                <h3 className='mb-0'>Movenpick Hotel And Residences Riyadh</h3>
                                                <div class="reviews-box d-flex align-items-baseline mb-1 gap-2">
                                                    <p className='d-inline-block text-grey-light  p-all-small mb-0'>4.3</p>
                                                    <ul class="d-inline-block star-rating p-0 mb-0">
                                                        <li class="d-inline-block"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li><li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                    </ul>
                                                    <p className='d-inline-block p-all-small text-grey-light mb-0'>(144 reviews)</p>
                                                </div>
                                                <div className='row'>
                                                    <div className='col-lg-12 col-md-12 col-sm-12 width-mobile-adjust'>
                                                        <div className='room-feature'>
                                                            <ul className='p-0'>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={AirCondition} className='d-inline-block' alt='' />Air Condition </li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={ReceptionsIMG} className='d-inline-block' alt='' />24H. Reception</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={AtmDetail} className='d-inline-block' alt='' />ATM</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={BanqueRimg} className='d-inline-block' alt='' />Gym</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div className='col-lg-12 col-md-12 col-sm-12 width-mobile-adjust'>
                                                        <div className='room-feature'>
                                                            <ul className='p-0'>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={ParkingHotel} className='d-inline-block' alt='' />Parking</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={WifiHotel} className='d-inline-block' alt='' />Free Wifi</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='srch-price-box'>
                                                <div className='inner-srch-spacing'>
                                                    <p className='d-inline-block size-big mb-0 tab-0-content-sp'><span className='yellow-big-txt'>$55</span><strike>$66</strike></p>
                                                    <p className='p-md'>1 Night(incl.VAT)</p>
                                                    <a href='' className='btn btn-gradient-purple text-white d-inline-block h-auto w-100'>Book Now</a>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='w-100 d-grid grid-resultshow'>
                                            <div className='result-img-rounded'>
                                                <img src={ResultOne} alt='' />
                                            </div>

                                            <div className='srch-details-box'>
                                                <h3 className='mb-0'>Movenpick Hotel And Residences Riyadh</h3>
                                                <div class="reviews-box d-flex align-items-baseline mb-1 gap-2">
                                                    <p className='d-inline-block text-grey-light  p-all-small mb-0'>4.3</p>
                                                    <ul class="d-inline-block star-rating p-0 mb-0">
                                                        <li class="d-inline-block"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li><li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                    </ul>
                                                    <p className='d-inline-block p-all-small text-grey-light mb-0'>(144 reviews)</p>
                                                </div>
                                                <div className='row'>
                                                    <div className='col-lg-12 col-md-12 col-sm-12 width-mobile-adjust'>
                                                        <div className='room-feature'>
                                                            <ul className='p-0'>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={AirCondition} className='d-inline-block' alt='' />Air Condition </li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={ReceptionsIMG} className='d-inline-block' alt='' />24H. Reception</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={AtmDetail} className='d-inline-block' alt='' />ATM</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={BanqueRimg} className='d-inline-block' alt='' />Gym</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div className='col-lg-12 col-md-12 col-sm-12 width-mobile-adjust'>
                                                        <div className='room-feature'>
                                                            <ul className='p-0'>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={ParkingHotel} className='d-inline-block' alt='' />Parking</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={WifiHotel} className='d-inline-block' alt='' />Free Wifi</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='srch-price-box'>
                                                <div className='inner-srch-spacing'>
                                                    <p className='d-inline-block size-big mb-0 tab-0-content-sp'><span className='yellow-big-txt'>$55</span><strike>$66</strike></p>
                                                    <p className='p-md'>1 Night(incl.VAT)</p>
                                                    <a href='' className='btn btn-gradient-purple text-white d-inline-block h-auto w-100'>Book Now</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        hotels: state.bookings.searchedhotels,
        currency: state.auth.currency
    }
};
export default connect(mapStateToProps)(HotelsResult);