import { CardCvcElement, CardExpiryElement, CardNumberElement } from '@stripe/react-stripe-js'
import React from 'react'
import { useTranslation } from 'react-i18next';

const CARD_ELEMENT_OPTIONS = {
    style: {
        base: {
            lineHeight: "27px",
            color: "#212529",
            fontSize: "1.1rem",
            padding: "10px",
            height: "40px",
            "::placeholder": {
                color: "#aab7c4",
            },
        },
        invalid: {
            color: "#fa755a",
            iconColor: "#fa755a",
        },
    },
};

const PaymentCard = ({ errors, handleCardElementChange, formData }) => {
    const { userName, cardNumber, cardExpiry, cardCvc } = errors;
    const { t } = useTranslation();
    
    return (
        <div>
            <div className='row'>
                <div className='col-md-6'>
                    <div className='form-group form-spacing'>
                        <label class="form-label p-md text-grey-light">{t('card_name')}</label>
                        <input
                            type='text'
                            className="form-control"
                            onChange={(e) => {
                                // e.target.value = e.target.value.replace(/[^a-zA-Z]/g, "");
                                handleCardElementChange(e, 'userName')
                            }}
                            placeholder="Enter Name"
                        />
                        {userName &&
                            <p className="d-flex flex-start text-danger error-msg mb-1 mb-md-0">
                                {userName}
                            </p>
                        }
                        {/* <input type="number" className='form-control' placeholder='Enter name' /> */}
                    </div>
                </div>
                <div className='col-md-6'>
                    <div className='form-group form-spacing'>
                        <label class="form-label p-md text-grey-light">{t('card_number')}</label>
                        <CardNumberElement
                            id="cc-number"
                            className="form-control"
                            options={CARD_ELEMENT_OPTIONS}
                            onChange={(e) => handleCardElementChange(e, 'cardNumber')}
                        />
                        {cardNumber &&
                            <p className="d-flex flex-start text-danger error-msg mb-1 mb-md-0">
                                {cardNumber}
                            </p>
                        }
                        {/* <input type="number" className='form-control' placeholder='Enter card number' /> */}
                    </div>
                </div>
                <div className='col-lg-6 col-md-6 col-sm-6'>
                    <div className='form-group form-spacing'>
                        <label class="form-label p-md text-grey-light">{t('expiry_date')}</label>
                        <CardExpiryElement
                            id="expiry"
                            className="form-control"
                            options={CARD_ELEMENT_OPTIONS}
                            onChange={(e) => handleCardElementChange(e, 'cardExpiry')}
                        />
                        {cardExpiry &&
                            <p className="d-flex flex-start text-danger error-msg mb-1 mb-md-0">
                                {cardExpiry}
                            </p>
                        }
                        {/* <input type="date" className='form-control' placeholder='Enter first name' /> */}
                    </div>
                </div>
                <div className='col-lg-6 col-md-6 col-sm-6'>
                    <div className='form-group form-spacing'>
                        <label class="form-label p-md text-grey-light">{t('cvv')}</label>
                        <CardCvcElement
                            id="cvc"
                            className="form-control"
                            options={CARD_ELEMENT_OPTIONS}
                            onChange={(e) => handleCardElementChange(e, 'cardCvc')}
                        />
                        {cardCvc &&
                            <p className="d-flex flex-start text-danger error-msg mb-1 mb-md-0">
                                {cardCvc}
                            </p>
                        }
                        {/* <input type="text" className='form-control' placeholder='...' /> */}
                    </div>
                </div>
            </div>


            {/* <div className='row'>
                <div className='col-lg-6 col-md-12 col-sm-12 form-group'>
                    <div className='row'>


                    </div>
                </div>
            </div> */}


        </div>
    )
}

export default PaymentCard