import { GET_CITIES_LISTS, GET_HOTEL_DETAILS, GET_HOTELS_LISTS, GET_SEARCHED_GRAPH, GET_SEARCHED_DATA, GET_TOP_HOTELS, GET_MODIFIED_DATA, GET_USER_BOOKING, GET_CITY_DATA, GET_HOTEL_ID, GET_ROOMS_LISTS, GET_USER_UPCOMMING_BOOKING, GET_HOTEL_FACILITIES, GET_SIMILAR_TOP_HOTELS } from "./bookingTypes";


const initialState = {
    hotel_id: JSON.parse(localStorage.getItem("hotelId")) || null,
    tophotels: JSON.parse(localStorage.getItem("tophotels")) || [],
    searchedhotels: [],
    cities: JSON.parse(localStorage.getItem("topcities")) || [],
    hotelDetail: null,
    hotelReviews: [],
    hotelrating: 0,
    graphData: [],
    searchedData: null,
    modifyData: null,
    bookingList: [],
    totalBookings: 0,
    cityData: null,
    roomsList: [],
    upcomingBookings: [],
    totalUpcoming: 0,
    hotelAllFacilities:[],
    similarTopHotels: [],
};

export const bookingReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_HOTEL_ID:
            return {
                ...state,
                hotel_id: action.payload,
            };
        case GET_TOP_HOTELS:
            return {
                ...state,
                tophotels: action.payload,
            };
        case GET_HOTELS_LISTS:
            return {
                ...state,
                searchedhotels: action.payload,
            };
        case GET_CITIES_LISTS:
            return {
                ...state,
                cities: action.payload,
            };
        case GET_HOTEL_DETAILS:
            return {
                ...state,
                hotelDetail: action.payload.result,
                hotelReviews: action.payload.reviews,
                hotelrating: action.payload.rating
            };
        case GET_SEARCHED_GRAPH:
            return {
                ...state,
                graphData: action.payload,
            };
        case GET_SEARCHED_DATA:
            return {
                ...state,
                searchedData: action.payload,
            };
        case GET_MODIFIED_DATA:
            return {
                ...state,
                modifyData: action.payload,
            };
        case GET_USER_BOOKING:
            return {
                ...state,
                bookingList: action.payload.data,
                totalBookings: action.payload.length,
            };
        case GET_CITY_DATA:
            return {
                ...state,
                cityData: action.payload,
            };
        case GET_ROOMS_LISTS:
            return {
                ...state,
                roomsList: action.payload,
            };
        case GET_USER_UPCOMMING_BOOKING:
            return {
                ...state,
                upcomingBookings: action.payload.data,
                totalUpcoming: action.payload.length,
            };
        case GET_HOTEL_FACILITIES:
            return {
                ...state,
                hotelAllFacilities: action.payload.result
            };
        case GET_SIMILAR_TOP_HOTELS:
            return {
                ...state,
                similarTopHotels: action.payload
            };
        default:
            return state;
    }
};