import React, { useRef } from 'react';
import { Box, Button, FormLabel, Image, Input, FormErrorMessage, useToast } from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';
import { theme_file_upload } from '../../../../reduxStore/vendors/vendorActions';

const FileUpload = ({ label, preview, onChange, accept = 'image/*',type }) => {
  const toast = useToast();
  const dispatch = useDispatch();
  const user=useSelector(state=>state.auth.user);
  const fileInputRef = useRef();
  const handleChange=async(e)=>{
    let file=e.target.files[0];
    if(file){
      const formData=new FormData();
      formData.append("type",type);
      formData.append("image",file);
      let res = await theme_file_upload(dispatch,user?.token, formData, toast);
      if(res?.status){
        onChange(res.data);
      }
    }else{
      onChange(null);
    }
  }
  return (
    <Box>
      <FormLabel className='d-flex align-item-center justify-content-between'>
        {label}{' '}
        <Button
          type="button"
          className="bg-white text-center w-10 ms-4 border rounded"
          style={{ color: '#696969' }}
          onClick={() => fileInputRef.current.click()}
        >
          <i className="bi bi-upload"></i>
        </Button>
      </FormLabel>
      <Input
        type="file"
        accept={accept}
        onChange={handleChange}
        ref={fileInputRef}
        display="none"
      />
      <Box className='d-flex align-items-center justify-content-center'>
        <Image
          src={preview}
          alt="Preview"
          sx={{height:250}}
          className="border rounded"
        />
      </Box>
    </Box>
  );
};

export default FileUpload;
