import React, { useEffect, useState } from 'react'
import HotelsImg from '../assets/hotel1.jpg';
import { useNavigate } from 'react-router-dom';
import { createApi } from 'unsplash-js';
import { useDispatch } from 'react-redux';
import { GET_CITY_DATA } from '../reduxStore/booking/bookingTypes';

const unsplash = createApi({
    accessKey: "faQ0y7crYQbDgscIPv6z6IO7YlCp-HPbquii8wIUsEI"
});

const CitiesCard = ({ item }) => {
    // const [image, setImage] = useState(null);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    // useEffect(() => {
    //     const handleImage = async () => {
    //         const res = await unsplash.search.getPhotos({
    //             query: item?.cityName,
    //             page: 1,
    //             perPage: 1
    //         });
    //         if (res.response.results.length > 0) {
    //             const imagePath = res.response.results[0]?.urls?.regular;
    //             setImage(imagePath);
    //         } else {
    //             setImage(null);
    //         }
    //         // console.log("image response ---->", res);
    //     };

    //     if (item?.cityName) {
    //         handleImage();
    //     }

    // }, [item?.cityName]);

    return (
        <div className="card card-hotel-wrapper border-0 position-relative" onClick={() => {
            dispatch({ type: GET_CITY_DATA, payload:item });
            // navigate('/detail')
        }}>
            <img
                // src={image !== null ? image : HotelsImg}
                src={item?.imageUrl || HotelsImg}
                class="card-img-hotel"
                alt="..."
            />

            <h6 className="card-text position-absolute text-center city-txt-wrap p-md font-weight-600">{item?.details?.cityName?.replace(/\s*\(.*?\)\s*/g, '')}</h6>
        </div>
    )
}

export default CitiesCard