import React, { useEffect, useState } from 'react'
import HotelImg from '../assets/result-img.png'
import AirCondition from '../assets/ac-icon.svg'
import MapImg from '../assets/map.svg'
import BanqueRimg from '../assets/banquet-icon.svg'
import GardenHotel from '../assets/garden-icon.svg'
import PoolHotel from '../assets/pool-icon.svg'
import WifiHotel from '../assets/wifi-icon.svg'
import GymHotel from '../assets/gym-icon.svg'
import ParkingHotel from '../assets/parking-icon.svg'
import BuffetHotel from '../assets/buffet-icon.svg'
import BeachHotel from '../assets/beach-icon.svg'
import BarsHotel from '../assets/bar-icon.svg'
import BarberHotel from '../assets/barber-icon.svg'
import AtmDetail from '../assets/ATM-icon.svg'
import ReceptionsIMG from '../assets/reception-icon.svg'
import CafeHotel from '../assets/cafe-icon.svg'
import { connect, useDispatch } from 'react-redux'
import { currency_converter } from '../reduxStore/User/userActions'
import { Link, useNavigate } from 'react-router-dom'
import { GET_HOTEL_ID } from '../reduxStore/booking/bookingTypes'
import { useTranslation } from 'react-i18next'
import CurrencyLogo from './CurrencyLogo'
import Amenities from '../shared/Amenities';
import NotFound from '../assets/NotFound.png';
import GreenCheck from '../assets/green-check.png';
import axios from 'axios'
import ReactStars from 'react-rating-stars-component';
import StarRating from './StarRating'

const SearchHotel = ({ item, index, currency }) => {
    // const [convertedPrice, setConvertedPrice] = useState(Number(item?.HotelPrice?.Price));
    const searchData = JSON.parse(localStorage.getItem("searchedData"));
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [hotelId, setHotelId] = useState(null);
    const [isShow, setIsShow] = useState(false);
    const [imageLink, setImageLink] = useState(null);
    const [imageUrl, setImageUrl] = useState(null);
    // const [hotelLocation, setHotelLocation] = useState(null);
    const [days, setDays] = useState(0);
    const { t } = useTranslation();

    // useEffect(() => {
    //     const fetchConvertedPrice = async () => {
    //         if (currency !== 'USD') {
    //             const data = {
    //                 amount: Number(item?.HotelPrice?.Price),
    //                 from: item?.HotelPrice?.Currency ? item?.HotelPrice?.Currency : 'USD',
    //                 to: currency
    //             };
    //             const conversion = await currency_converter(data);
    //             setConvertedPrice(Math.round(conversion?.data));
    //         } else {
    //             setConvertedPrice(Math.round(item?.HotelPrice?.Price));
    //         }
    //     };

    //     fetchConvertedPrice();
    // }, [currency]);

    const handleBooking = async (itemData) => {
        localStorage.setItem("selectedHotelGDS", JSON.stringify(itemData?.Gds));
        localStorage.setItem("roomsData", JSON.stringify(itemData?.rooms));
        localStorage.setItem("hotelData", JSON.stringify(itemData));
        localStorage.setItem("hotelId", JSON.stringify(itemData?.GiataId ? itemData?.GiataId : itemData?.HotelID));
        dispatch({ type: GET_HOTEL_ID, payload: itemData?.GiataId ? itemData?.GiataId : itemData?.HotelID });
        const url = `/detail/${itemData?.Name}`;
        window.open(url, '_blank');
        // navigate(`/detail/${itemData?.Name}`);
    };

    // useEffect(() => {
    //     if (!item) return;

    //     if (item?.Latitude === null || item?.Longitude === null) return;

    //     const fetchData = async () => {
    //         try {
    //             const url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${item?.Latitude},${item?.Longitude}&key=AIzaSyC5JwGGebkSRvbcbWsbg9bZjO7vNhI3loQ`;
    //             const res = await axios.get(url);
    //             const datas = res.data;
    //             setHotelLocation(datas?.plus_code?.compound_code);
    //         } catch (error) {
    //             setHotelLocation(null);
    //             console.log(error);
    //         }
    //     };
    //     fetchData();
    // }, [item]);

    // useEffect(() => {
    //     if (!item?.Images) return;
    //     setImageLink(item?.Images[0][item?.Images[0]?.length - 1]?.$["xlink:href"]);
    // }, [item?.Images]);

    // useEffect(() => {
    //     if (imageLink === null) return;
    //     const fetchImage = async () => {
    //         const username = "giata|ae4net.net";
    //         const password = "49664900cc*";
    //         const authToken = btoa(`${username}:${password}`);
    //         try {
    //             const response = await axios.get(imageLink, {
    //                 // headers: {

    //                 // },
    //                 auth:{
    //                     Username: username,
    //                     Password: password
    //                 },
    //                 responseType: "blob"
    //             });
    //             console.log("response ---->", response);
    //         } catch (error) {
    //             console.log("erorrr in response", error);
    //         }
    //     };
    //     fetchImage();
    // }, [imageLink]);

    useEffect(() => {
        const d1 = new Date(searchData?.CheckInDate);
        const d2 = new Date(searchData?.CheckOutDate);

        // Calculate the difference in time (milliseconds)
        const timeDifference = d2 - d1;

        // Convert the difference from milliseconds to days
        const differenceInDays = timeDifference / (1000 * 60 * 60 * 24);

        setDays(differenceInDays);
    }, []);

    return (
        <div className='w-100 d-grid grid-resultshow' key={index}>
            <div className='result-img-rounded'>
                <img
                    src={imageUrl ? imageUrl : HotelImg}
                    // src={!item?.Images ? HotelImg : item?.Images !== null ? item?.Images : HotelImg}
                    alt=''
                />
            </div>

            <div className='srch-details-box'>
                <div className=''>
                    <h3 className='mb-0 cursor-pointer' onClick={() => handleBooking(item)}>
                        {item?.Name}
                        <span className='text-purple google-hotel-rating'>{item?.category && <StarRating rating={Number(item?.category)} />}</span>
                    </h3>
                </div>
                <div class="reviews-box d-flex align-items-baseline mb-1 gap-2">

                    {/* {item?.category && <StarRating rating={Number(item?.category)} />} */}

                    {/* {item?.category && <p className='d-inline-block text-grey-light  p-all-small mb-0'>
                       ({item?.category})
                    </p>} */}
                    {/* <ul class="d-inline-block star-rating p-0 mb-0">
                       
                        {Array.from({ length: Math.round(item?.category) })?.map((_, index) => (
                            <li class="d-inline-block" key={index}><i class="fas fa-star"></i></li>
                        ))}
                    </ul> */}

                    {/* <div className='d-inline-block star-rating p-0 mb-0'>
                        <ReactStars
                            count={5}
                            value={Number(item?.category)}
                            size={19}
                            half={true}
                            edit={false}
                            color2={"#FFCE00"} // Color for filled stars
                            color1={"#e4e5e9"} // Color for empty stars
                        />
                    </div> */}
                    {/* <p className='d-inline-block p-all-small text-grey-light mb-0'>{item?.total_reviews} {t('hotel_review')}</p> */}
                </div>
                <div className='srch-details-rooms-location'>
                    <p className='font-size-15 font-weight-500 mb-3 d-flex align-items-start gap-2'>
                        <img src={MapImg} className='d-inline-block mt-1' alt='' />
                        {/* Diplomatic Enclave Chanakyapuri */}
                        {item?.address && item?.address}
                    </p>
                </div>
                <div className='row'>
                    {/* <div className={`${item?.facilities?.length > 0 ? 'col-lg-12' : 'col-lg-6'} col-md-12 col-sm-12 width-mobile-adjust`}>
                        <div className='room-feature'>
                            <ul className={`${item?.facilities?.length > 0 ? 'd-flex flex-wrap' : 'p-0'}`}>
                                {!isShow && item?.facilities?.slice(0, 6)?.map((itm, idx) => (
                                    <li className='col-md-6 p-sm text-grey-light d-flex align-items-center gap-2 mb-2' key={idx}>
                                        <img
                                            src={
                                                Amenities?.find((k) => k?.Description?.toLowerCase().includes(itm?.toLowerCase()))?.ImagePath ?
                                                    Amenities?.find((k) => k?.Description?.toLowerCase().includes(itm?.toLowerCase()))?.ImagePath :
                                                    NotFound
                                            }
                                            //  src={AirCondition} 
                                            className='d-inline-block'
                                            alt=''
                                        />
                                        {itm}
                                    </li>
                                ))}
                                {isShow && item?.facilities?.map((itm, idx) => (
                                    <li className='col-md-6 p-sm text-grey-light d-flex align-items-center gap-2 mb-2' key={idx}>
                                        <img
                                            src={
                                                Amenities?.find((k) => k?.Description?.toLowerCase().includes(itm?.toLowerCase()))?.ImagePath ?
                                                    Amenities?.find((k) => k?.Description?.toLowerCase().includes(itm?.toLowerCase()))?.ImagePath :
                                                    NotFound
                                            }
                                            // src={AirCondition}
                                            className='d-inline-block'
                                            alt=''
                                        />
                                        {itm}
                                    </li>
                                ))}
                                {!isShow && <li className='col-md-6 p-sm text-grey-light d-flex align-items-center gap-2 mb-2 cursor-pointer' onClick={() => setIsShow(!isShow)}>
                                    more +
                                </li>}
                            </ul>
                        </div>
                    </div> */}

                    {item?.facilities?.length > 0 && <div className='col-lg-12 col-md-12 col-sm-12 width-mobile-adjust'>
                        <div className='room-feature'>
                            <ul className='p-0'>
                                {item?.facilities?.slice(0, 6)?.map((itm, idx) => (
                                    <li className='p-sm text-grey-light d-flex align-items-start gap-2 mb-2' key={idx}>
                                        <img
                                            src={
                                                Amenities?.find((k) => k?.Description?.toLowerCase() === itm?.toLowerCase())?.ImagePath ?
                                                    Amenities?.find((k) => k?.Description?.toLowerCase() === itm?.toLowerCase())?.ImagePath :
                                                    GreenCheck
                                            }
                                            //  src={AirCondition} 
                                            className='d-inline-block mt-1'
                                            alt=''
                                        />
                                        {t(`hotel_facilities.${itm?.replace(' ','_')}`)}
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>}
                </div>
            </div>
            <div className='srch-price-box'>
                <div className='inner-srch-spacing'>
                    <div className='rating-with-reviews-wrap d-flex align-items-center gap-2 mb-4'>
                        {item?.rating && <p className='p-all-small mb-0 total-rating-container'>
                            {item?.rating} <i className="fas fa-star text-white"></i>
                        </p>}
                        <p className='d-inline-block p-all-small text-grey-light mb-0'>({item?.total_reviews} {t('hotel_review')})</p>
                    </div>
                    <p className='d-inline-block size-big mb-0 tab-0-content-sp'><span className='yellow-big-txt'>
                        <CurrencyLogo currencyCode={currency} /> {item?.HotelPrice?.Price}</span>
                        {/* <strike>$66</strike> */}
                    </p>
                    <p className='p-md'>{days} {t('night_incl')}
                        {/* Night(incl.VAT) */}
                    </p>
                    <span className='btn btn-gradient-purple text-white d-inline-block h-auto w-100' onClick={() => handleBooking(item)}>{t('book_now')}</span>
                    {/* <Link to={`/detail/${item?.HotelID}`} className='btn btn-gradient-purple text-white d-inline-block h-auto w-100'>Book Now</Link> */}
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        currency: state.auth.currency
    }
};

export default connect(mapStateToProps)(SearchHotel);