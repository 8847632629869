import React from 'react';
import { Box, Input } from '@chakra-ui/react';

const ColorPicker = ({ value, onChange }) => {
  return (
    <Box display="flex" alignItems="center" gap={4}>
      {/* Round Color Picker */}
      <Box
        as="label"
        display="block"
        borderRadius="full"
        boxSize="40px"
        bg={value || '#000000'}
        cursor="pointer"
        border="2px solid"
        borderColor="gray.300"
        boxShadow="sm"
      >
        <Input
          type="color"
          value={value || '#000000'}
          onChange={(e) => onChange(e.target.value)}
          position="absolute"
          opacity={0}
          cursor="pointer"
        />
      </Box>

      {/* Text Input for Color Value */}
      <Input
        type="text"
        value={value || '#000000'}
        placeholder="#000000"
        maxWidth="120px"
        onChange={(e) => onChange(e.target.value)}
      />
    </Box>
  );
};

export default ColorPicker;