import React, { useEffect } from 'react'
import LocationCard from '../shared/LocationCard'
import TopHotels from './HomePages/TopHotels';
import TopCities from './HomePages/TopCities';
import Offers from './HomePages/Offers';
import TrendingCar from './HomePages/TrendingCar';
import Img1 from '../assets/deal.png';
import Img2 from '../assets/book-pay.png';
import Img3 from '../assets/verify.png';
import Img4 from '../assets/securepay.png';
import TravelImg from '../assets/where-to-travel.png';
import Contact from './HomePages/Contact';
import EmailSubscribe from './HomePages/EmailSubscribe';
import BookingFilter from './BookingFilter';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { connect, useDispatch } from 'react-redux';
import debounce from 'lodash.debounce';
import { get_top_hotels_lists } from '../reduxStore/booking/bookingActions';
import { useToast } from '@chakra-ui/react';

const HomePage = ({userLocation}) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const toast = useToast();
    const tophotels = JSON.parse(localStorage.getItem("tophotels"));
    const topcities = JSON.parse(localStorage.getItem("topcities"));

    const arr = [
        { heading: `${t("best_deal")}`, title:`${t("other_text")}`, imgpath: Img1 },
        { heading: `${t("book_felxible")}`, title: `${t("other_text")}`, imgpath: Img2 },
        { heading: `${t("best_price")}`, title: `${t("other_text")}`, imgpath: Img3 },
        { heading: `${t("secure_pay")}`, title: `${t("other_text")}`, imgpath: Img4 },
    ];
    const location = useLocation();

    useEffect(() => {
        localStorage.removeItem("roomsData");
    }, []);

      useEffect(() => {
            if (!userLocation) return;

            if(tophotels || topcities) return;
    
            const fetchData = debounce(async () => {
                await get_top_hotels_lists(dispatch, { countryCode: userLocation?.short_name, countryName: userLocation?.long_name }, toast);
            }, 1000);
            fetchData();
            return () => {
                fetchData.cancel();
            };
        }, [userLocation]);

    return (
        <div className=''>
            {/* {(location.pathname !== "/signup" || location.pathname !== "/login") && <div className='booking-offers'>
                <BookingFilter />
            </div>} */}

            <div className='features-wrapper'>
                <div className='container'>
                    <div className='row'>
                        {arr.map((item, index) =>
                            <div className='col-lg-3 col-md-6 col-sm-6' key={index}>
                                <div className='inner-featues-box d-flex align-items-start gap-3'>
                                    <img src={item.imgpath} alt='' />
                                    <div className='right-features'>
                                        <h6 className='p-md mb-0'>{item.heading}</h6>
                                        <p className='p-sm'>{item.title}</p>
                                    </div>
                                </div>
                            </div>)}
                    </div>
                </div>
            </div>

            <div className='top-hotels position-relative'>
                <TopHotels />
            </div>

            <div className='top-cities'>
                <TopCities />
            </div>

            <div className='offers'>
                <Offers />
            </div>

            <div className='trending-cars section-padding'>
                <TrendingCar />
            </div>

            <div className='contact-with-travel'>
                <Contact />
            </div>

            <div className='email-subscribe'>
                <EmailSubscribe />
            </div>

        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        user: state.auth.user,
        cities: state.bookings.cities,
        userLocation: state.auth.userLocation,
    }
};
export default connect(mapStateToProps)(HomePage);