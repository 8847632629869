import React from 'react'
import HotelsImg from '../assets/hotel1.jpg';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { GET_HOTEL_ID, GET_SEARCHED_DATA } from '../reduxStore/booking/bookingTypes';
import StarRating from './StarRating';

const LocationCard = ({ item, isHotel }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    return (
        <div className="card card-hotel-wrapper border-0 position-relative"
            onClick={() => {
                localStorage.setItem("selectedHotelGDS", JSON.stringify(item?.provider));
                localStorage.setItem("hotelId", JSON.stringify(item?.HotelID));
                localStorage.setItem("hotelData", JSON.stringify(item));
                dispatch({ type: GET_HOTEL_ID, payload: item?.HotelID });
                // navigate(`/detail/${item?.Name}`);
                const url = `/detail/${item?.Name}`;
                window.open(url,'_blank');
                // dispatch({ type: GET_SEARCHED_DATA, payload: item });
                // navigate(`/detail/${item?.HotelID}`)
            }}
        >
            <img src={!item?.Images ? HotelsImg : item?.Images !== null ? (item?.Images?.FileName || item?.Images) : HotelsImg} class="card-img-hotel" alt="..." />
            {isHotel ?
                <div className="card-body card-content position-absolute bg-white text-center">
                    <h6 className='p-md mb-0'> {item?.Name}.</h6>
                    {/* <div className='reviews-box d-flex justify-content-between align-items-center'>
                        {item?.rating > 0 ? <StarRating rating={item?.rating} /> : <StarRating rating={0} />}
                        <p className='rating-txt mb-0'>({item?.rating > 0 ? item?.rating : 0})</p>
                        <ul className='star-rating p-0 mb-0'>
                            {Array.from({ length: Math.round(item?.rating) }).map((_,index) => (
                                <li className='d-inline-block' key={index}><i class="fas fa-star"></i></li>
                            ))}
                        </ul>
                        <p className='rating-txt mb-0'>{item?.total_reviews} reviews</p>
                    </div> */}
                </div>
                :
                <h6 className="card-text position-absolute text-center city-txt-wrap p-md font-weight-600">London</h6>
            }
        </div>
    )
}

export default LocationCard