import React, { useEffect, useState } from 'react'
import { connect, useDispatch } from 'react-redux'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { get_default_language_currency, get_profile_details, get_vender_id, logout, user_token_security_logout } from '../reduxStore/User/userActions';
import BgImg from '../assets/Banner.png';
import { header_theme_design } from '../reduxStore/themes/themeActions';
import LogoImg from '../assets/Logo-White-Yellow.svg';
import { useToast } from '@chakra-ui/react';
import { GET_CURRENCY, IS_EDIT, SET_USER_LOCATION } from '../reduxStore/User/userTypes';
import { signOut } from '@firebase/auth';
import { auth } from '../firebase/firebase';
import { get_all_languages_lists } from '../reduxStore/vendors/vendorActions';
import { useTranslation } from 'react-i18next';
import timeZonesData from '../locales/timeZones.json';
import currenciesData from '../locales/currencies.json';
import Cancel from '../Modals/Cancel';
import axios from 'axios';
import reverseGeocode from "reverse-geocode";
import ContactUs from '../Modals/ContactUs';
import { resources } from '../i18next';
import debounce from 'lodash.debounce';
const { REACT_APP_OTA_URL } = process.env;

const CurrencyDropdown = ({ currencies, currency, handleCurrencyChange, handleClose }) => {
    const dispatch = useDispatch();
    return <div className="currency-translater-header currency-translater-dropdown">
        <div className="dropdown">
            <button className="btn bg-transparent dropdown-toggle dropdown-mine text-white" type="button" id="Currencydropdown" data-bs-toggle="dropdown" aria-expanded="false" onClick={handleClose}>
                <i className="far fa-money-bill-alt"></i> <span className='currency_txt'>{currency}</span>
            </button>
            <ul className="dropdown-menu" aria-labelledby="dropdownMCurrencydropdownenuButton1">
                {[currencies.find(c => c === currency), ...currencies.filter(c => c !== currency)]?.map(cur => <li key={cur}><a className={`dropdown-item ${currency === cur ? 'active' : ''}`} href="javascript:void(0)" onClick={() => {
                    handleCurrencyChange(cur);
                    localStorage.setItem("toCurrency", JSON.stringify(cur));
                    dispatch({ type: GET_CURRENCY, payload: cur });
                    localStorage.setItem("currencySwitch", JSON.stringify("true"));
                }}>{cur}</a></li>)}
            </ul>
        </div>
    </div>
}

const LanguageDropdown = ({ languages, langLists, language, isMobile, handleLanguageToggle, handleClose }) => {
    return <div className="currency-translater-header language-translater-dropdown">
        <div className="dropdown">
            <button className="btn bg-transparent dropdown-toggle dropdown-mine text-white" type="button" id="Currencydropdown" data-bs-toggle="dropdown" aria-expanded="false" onClick={handleClose}>
                <img src={langLists?.find(l => l?.language === language)?.flag} alt='img not found' style={{ Height: 'auto', width: '25px' }} />
                <span className='currency_txt'>{langLists?.find(l => l?.language === language)?.language}</span>
            </button>
            <ul className="dropdown-menu" aria-labelledby="dropdownMCurrencydropdownenuButton1">
                {[langLists?.find(l => l?.language === language), ...langLists?.filter(l => l?.language !== language)]?.map(lang => <li key={lang?.language} className={`d-flex cursor-pointer ${isMobile ? 'mobile-toggle-spacing' : ''} ${lang?.language === language ? 'active-language' : ''}`} onClick={() => {
                    handleLanguageToggle(lang?.language);
                    localStorage.setItem("isSwitch", JSON.stringify("true"));
                }}>
                    <a href="javascript:void(0)" className={`dropdown-item d-flex align-items-center gap-2 ${lang?.language === language ? 'active' : ''}`}>
                        {/* <img src={lang?.flag} alt='img not found' style={{ Height: 'auto', width: '25px' }} /> */}
                        {lang?.language}
                    </a>
                </li>)}
            </ul>
        </div>
    </div>
}

// const LanguageToggle = ({ languages, language, isMobile, handleLanguageToggle }) => {
//     return <div class="language-translater">
//         <ul class="translator_list d-flex gap-2 m-0">
//             {languages?.map(lang => <li key={lang?.value} class={`text-white p-sm px-2 ${isMobile ? 'mobile-toggle-spacing' : ''} ${lang?.value === language ? 'active-language p-sm' : ''}`} onClick={handleLanguageToggle}>{lang?.label}</li>)}
//         </ul>
//     </div>
// }

const Header = ({ user, userDetails, userLocation, vendor_id, isEdit, langLists, currLists, defaultData, addEventListener, isMobile, adminHeadToggle, setAdminHeadToggle }) => {
    const currentTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const toast = useToast();
    const { t, i18n } = useTranslation();
    const [isSupport, setIsSupport] = useState(false);
    const [engData, setEngData] = useState(null);
    const [arData, setArData] = useState(null);
    const [isShow, setIsShow] = useState(false);
    const [isAdmin, setIsAdmin] = useState(false);
    const [isCancel, setIsCancel] = useState(false);
    const [isLocation, setIsLocation] = useState(false);
    // const currencies = ['USD', 'EUR', 'GBP', 'AUD', 'SAR'];
    const currencies = [
        'USD', 'BRL', 'ARS', 'CAD', 'GBP', 'EUR', 'CHF',
        'SEK', 'DKK', 'NOK', 'PLN', 'RON', 'TRY', 'ZAR', 'EGP',
        'NGN', 'KES', 'AED', 'ILS', 'INR', 'CNY', 'JPY', 'SGD',
        'HKD', 'KRW', 'THB', 'IDR', 'MYR', 'AUD', 'NZD', 'FJD', 'CLP', 'SAR'
    ];
    const languages = [
        { label: 'عربي', value: 'Arabic' },
        { label: 'ENG', value: 'English' },
    ];
    const [language, setLanguage] = useState('');
    const [localLang, setLocalLang] = useState('');
    const [currency, setCurrency] = useState('');
    const [isRTL, setIsRTL] = useState(false);

    const userItems = [
        { name: 'Dashboard', value: '/dashboard' },
        // { name: 'Profile', value: '/profile' },
        // { name: 'Change Password', value: '/change-password' },
        { name: 'Logout', value: '' },
    ];

    const smallAgencyItems = [
        { name: 'Dashboard', value: '/dashboard' },
        { name: 'Logout', value: '' },
    ];

    const vendorItems = [
        { name: 'Logout', value: 'Logout' },
    ];

    const [items, setItems] = useState([]);
    const isSwitch = JSON.parse(localStorage.getItem("isSwitch"));
    const currSwitch = JSON.parse(localStorage.getItem("currencySwitch"));
    const fromCurrency = JSON.parse(localStorage.getItem("fromCurrency"));
    const toCurrency = JSON.parse(localStorage.getItem("toCurrency"));

    const handleLogout = async () => {
        let user_logout = await user_token_security_logout(vendor_id, user?.token, toast, dispatch);
        if (user_logout) {
            signOut(auth).then(() => { console.log(""); }).catch((error) => { console.log("", error); })
            localStorage.removeItem('userData');
            localStorage.clear();
            dispatch(logout());
            if (isAdmin) {
                navigate('/admin');
                setIsShow(!isShow);
                setIsCancel(false);
            } else {
                navigate('/');
                setIsShow(!isShow);
                setIsCancel(false);
            }
        }
    };

    const filterCodes = Object.keys(resources);
    const filteredLangs = langLists.filter(item => filterCodes.includes(item.code));
    // console.log("timezone ===>", timeZonesData[currentTimeZone], langLists?.find((item) => item?.language === timeZonesData[currentTimeZone]?.languageName));

    useEffect(() => {
        if (user && user?.role === "user") {
            setItems(userItems);
        } else if (user && (user?.role === "superadmin" || user?.role === "Admin" || user?.role === "mediumAgency" || user?.role === "staff")) {
            setItems(vendorItems);
        } else if (user && user?.role === "smallAgency") {
            setItems(smallAgencyItems);
        } else if (!user) {
            setItems([]);
        }
    }, [user]);

    useEffect(() => {
        const fetchId = debounce(async () => {
            await get_vender_id("superadmin", dispatch);
        }, 1000);
        fetchId();
        return () => {
            fetchId.cancel();
        };
    }, []);

    // useEffect(() => {
    //     if (user && user?.role === "user") {
    //         setItems(userItems);
    //     } else if (user && (user?.role === "superadmin" || user?.role === "Admin" || user?.role === "smallAgency" || user?.role === "mediumAgency" || user?.role === "staff")) {
    //         setItems(vendorItems);
    //     } else if (!user) {
    //         setItems([]);
    //     }
    // }, [user]);

    useEffect(() => {
        getUserLocation()
            .then(locationData => {
                const userCountry = locationData.country;
                if (['SA', 'AE', 'EG', 'JO', 'QA', 'OM', 'KW', 'BH', 'LY'].includes(userCountry)) {
                    // setLanguage('Arabic');
                    setIsRTL(true);
                    // setCurrency('SAR');
                } else {
                    // setLanguage('English');
                    setIsRTL(false);
                    // setCurrency('USD');
                }
            })
            .catch(error => console.error('Error fetching location:', error));
    }, []);

    const getUserLocation = async () => {
        try {
            const response = await fetch('https://ipapi.co/json/');
            const data = await response.json();
            return data;
        } catch (error) {
            console.error('Failed to fetch geolocation data:', error);
            return { country: 'US' };
        }
    };

    const handleLanguageToggle = (val) => {
        if (val === 'English') {
            setLanguage('English');
            i18n.changeLanguage('en');
            localStorage.setItem("LangType", JSON.stringify('en'));
            setIsRTL(false);
        } else if (val === 'Arabic') {
            setLanguage('Arabic');
            i18n.changeLanguage('ar');
            localStorage.setItem("LangType", JSON.stringify('ar'));
            setIsRTL(true);
        } else {
            setLanguage('English');
            i18n.changeLanguage('en');
            localStorage.setItem("LangType", JSON.stringify('en'));
            setIsRTL(false);
        }
    };

    const handleCurrencyChange = (cur) => {
        setCurrency(cur);
    };

    useEffect(() => {
        const get_Data = async () => {
            await get_profile_details(user?.token, vendor_id, dispatch, toast);
            dispatch({ type: IS_EDIT, payload: false });
        };

        if (isEdit) {
            get_Data();
        }
    }, [isEdit]);

    useEffect(() => {
        if (vendor_id && user) {
            get_profile_details(user?.token, vendor_id, dispatch, toast);
        }
    }, [vendor_id, user]);

    useEffect(() => {
        if (i18n.language === 'ar') {
            document.documentElement.setAttribute('dir', 'rtl');
            document.body.style.textAlign = 'right';
        } else {
            document.documentElement.setAttribute('dir', 'ltr');
            document.body.style.textAlign = 'left';
        }
    }, [i18n.language]);

    useEffect(() => {
        let lang_type = JSON.parse(localStorage.getItem("LangType"));

        if (isSwitch) {
            if (lang_type !== "en") {
                setLanguage("Arabic");
                i18n.changeLanguage('ar');
            } else {
                localStorage.setItem("LangType", JSON.stringify('en'));
                setLanguage('English');
                i18n.changeLanguage('en');
            }
        }
    }, []);

    useEffect(() => {
        const handleLanguage = async () => {
            let currlang = await get_default_language_currency(user?.token, dispatch);
            if (currlang) {
               // localStorage.setItem("DefaultLang", JSON.stringify(currlang?.defaultLanguage?.language === "Arabic" ? "ar" : "en"));

                if (isSwitch) return;

                const checktime = timeZonesData[currentTimeZone];
                // i18n.changeLanguage(
                //     checktime === undefined && currlang?.defaultLanguage?.language === "English" ? 'en' :
                //         checktime === undefined && currlang?.defaultLanguage?.language === "Arabic" ? 'ar' :
                //             checktime?.languageCode
                // );
                // localStorage.setItem(
                //     "LangType",
                //     JSON.stringify(checktime === undefined ? (currlang?.defaultLanguage?.language === "Arabic" ? 'ar' : 'en') : checktime?.languageCode)
                // );
                setLanguage(
                    checktime === undefined && currlang?.defaultLanguage?.language === "English" ? 'English' :
                        checktime === undefined && currlang?.defaultLanguage?.language === "Arabic" ? 'Arabic' :
                            checktime?.languageCode === 'ar' ? 'Arabic' : 'English'
                );
            }
        };
        handleLanguage();
    }, []);

    useEffect(() => {
        const handleCurrency = async () => {
            let currlang = await get_default_language_currency(user?.token, dispatch);
            const checktime = currenciesData[currentTimeZone];
            if (currlang) {

                if (currSwitch) {
                    setCurrency(toCurrency);
                    dispatch({ type: GET_CURRENCY, payload: toCurrency });
                } else if (!currSwitch) {
                    setCurrency(currlang?.defaultCurrency ? currlang?.defaultCurrency : checktime !== undefined ? currenciesData[currentTimeZone] : currlang?.defaultCurrency);
                    dispatch({ type: GET_CURRENCY, payload: currlang?.defaultCurrency ? currlang?.defaultCurrency : checktime !== undefined ? currenciesData[currentTimeZone] : currlang?.defaultCurrency });
                }
            }
        };
        handleCurrency();
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            if (!user || (user?.role === "user" || user?.role === "smallAgency")) {
                await get_all_languages_lists(user?.token, dispatch);
            }
        };
        fetchData();
    }, [user]);

    useEffect(() => {
        if (user && user?.role === "superadmin") {
            setIsAdmin(true);
        }
    }, []);

    const [locationDetail, setLocationDetail] = useState({
        latitude: null,
        longitude: null,
        message: null,
    });


    useEffect(() => {
        if (userLocation !== null) return;

        const getCurrentLocation = () => {
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(
                    (position) => {
                        setLocationDetail({
                            latitude: position.coords.latitude,
                            longitude: position.coords.longitude,
                            message: "Location retrieved successfully!",
                        });
                        console.log(reverseGeocode.lookup(position.coords.latitude, position.coords.longitude));
                    },
                    (error) => {
                        if (error.code === error.PERMISSION_DENIED) {
                            getLocationFromIP();
                        } else {
                            console.log("Unable to retrieve location. Please try again.");
                        }
                    }
                );
            } else {
                console.log("Geolocation is not supported by your browser.");
                getLocationFromIP();
            }
        };
        getCurrentLocation();
    }, [userLocation]);

    const getLocationFromIP = async () => {
        try {
            const response = await axios.get("https://ipapi.co/json/");
            console.log("response =====>", response);
            setLocationDetail({
                latitude: response.data.latitude,
                longitude: response.data.longitude,
                message: "Location retrieved based on your IP address.",
            });
            console.log(reverseGeocode.lookup(response.data.latitude, response.data.longitude));
        } catch (error) {
            console.log("Failed to retrieve location from IP.");
        }
    };

    useEffect(() => {
        if (userLocation !== null) return;

        if (locationDetail?.latitude === null || locationDetail?.longitude === null) return;

        const fetchData = async () => {
            try {
                const url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${locationDetail?.latitude},${locationDetail?.longitude}&key=AIzaSyC5JwGGebkSRvbcbWsbg9bZjO7vNhI3loQ`;
                const res = await axios.get(url);
                const datas = res.data.results;
                const getData = datas.forEach(address => {
                    const countryDetail = address.address_components.find(component =>
                        component.types.includes("country")
                    ) || { long_name: 'United States', short_name: 'US' }; // default to "US" if not found

                    localStorage.setItem("userLocation", JSON.stringify(countryDetail));
                    dispatch({ type: SET_USER_LOCATION, payload: countryDetail });

                });
            } catch (error) {
                console.log(error);
            }
        };
        fetchData();
    }, [userLocation, locationDetail]);

    return (
        <div className={`${user && user?.role === "superadmin" ? 'admin-sticky-header-wrap' : ''}`}>
            <div
                className={
                    `header ${(user && user?.role !== "user" && adminHeadToggle && !location.pathname.includes('/dashboard')) ? 'open' :
                        (user && user?.role === "user" && location.pathname.includes('/dashboard')) && adminHeadToggle ? 'open' : ''}`
                }
                style={header_theme_design(BgImg)}
            >
                <nav className="navbar navbar-expand-lg">
                    <div className={`${user && user?.role === "superadmin" ? 'container-xxl' : 'container'} header-container justify-content-between`}>
                        <div className='admin-sidebar-toggle-wrap for-desktop'>
                            {user && user.role === "superadmin" && (
                                <a href="javascript:void(0);" className="sidebar-toggler flex-shrink-0" onClick={() => setAdminHeadToggle(!adminHeadToggle)}>
                                    <i className="fa fa-bars"></i>
                                </a>
                            )}
                            {user && user.role === "user" && location.pathname.includes('/dashboard') && !isMobile && (
                                <a href="javascript:void(0);" className="sidebar-toggler flex-shrink-0" onClick={() => setAdminHeadToggle(!adminHeadToggle)}>
                                    <i className="fa fa-bars"></i>
                                </a>
                            )}
                            <Link className="navbar-brand" to="/">
                                <img src={LogoImg} alt='img not found' className='img-fluid' />
                            </Link>

                        </div>
                        <div className='desktop_navbar_wrap d-flex gap-4 flex-wrap align-items-center'>
                            <div className='mobile-translator'>
                                {(!user || (user?.role === "user" || user?.role === "smallAgency")) && <LanguageDropdown languages={languages} langLists={filteredLangs} language={language} isMobile={true} handleLanguageToggle={(val) => handleLanguageToggle(val)} handleClose={() => setIsShow(false)} />}
                                {/* <LanguageToggle languages={languages} language={language} isMobile={true} handleLanguageToggle={handleLanguageToggle} /> */}
                                {(!user || user?.role === "user" || user?.role === "smallAgency") && <CurrencyDropdown currencies={currLists} currency={currency} handleCurrencyChange={handleCurrencyChange} handleClose={() => setIsShow(false)} />}
                            </div>

                            <div className='mobile-login-signup'>
                                <div className="dropdown">
                                    {/* {user && user?.role !== "superadmin" && <button className="btn user_btn dropdown-toggle dropdown-mine text-white" type="button"
                                        // id="login_signup"
                                        data-toggle="dropdown"
                                        aria-expanded="false"
                                        onClick={() => navigate("/profile")}
                                    >
                                        <i class="bi bi-person"></i>
                                    </button>} */}

                                    {/* <ul className="dropdown-menu dropdown-menu-end"
                                    // aria-labelledby="login_signup"
                                    > */}
                                    {/* {!user ? <>
                                            <li><a className="dropdown-item" href="#"><i class="bi bi-box-arrow-in-right me-2"></i>Login</a></li>
                                            <li><a className="dropdown-item" href="#"><i class="bi bi-person-plus me-2"></i> Signup</a></li>
                                        </>
                                            : <li onClick={handleLogout}><a className="dropdown-item" href="javascript:void(0)">Logout</a></li>} */}
                                    {/* </ul> */}
                                </div>
                            </div>

                            {(!user || user?.role !== "superadmin") && <button
                                className="navbar-toggler" type="button" data-bs-toggle="collapse"
                                // data-bs-target="#navbarScroll"
                                aria-controls="navbarScroll" aria-expanded="false" aria-label="Toggle navigation"
                                onClick={() => setIsShow(!isShow)}
                            >
                                <span className="navbar-toggler-icon"></span>
                            </button>}

                            {(user?.role === "superadmin" && !isMobile) && <button
                                className="navbar-toggler" type="button" data-bs-toggle="collapse"
                                // data-bs-target="#navbarScroll"
                                aria-controls="navbarScroll" aria-expanded="false" aria-label="Toggle navigation"
                                onClick={() => setIsShow(!isShow)}
                            >
                                <span className="navbar-toggler-icon"></span>
                            </button>}

                            {user && user?.role === "superadmin" && (
                                <div className='admin-sidebar-toggle-wrap for-mobile'>
                                    <a href="javascript:void(0);" className="sidebar-toggler flex-shrink-0" onClick={() => setAdminHeadToggle(!adminHeadToggle)}>
                                        <i className="fa fa-bars hem"></i>
                                        <i className="fas fa-times cross"></i>
                                    </a>
                                </div>
                            )}

                            {user && user?.role === "user" && (
                                <div className='admin-sidebar-toggle-wrap for-mobile'>
                                    <a href="javascript:void(0);" className="sidebar-toggler flex-shrink-0" onClick={() => setAdminHeadToggle(!adminHeadToggle)}>
                                        <i className="fa fa-bars hem"></i>
                                        <i class="fas fa-times cross"></i>
                                    </a>
                                </div>
                            )}

                            <div className={`header-menus-wrap collapse navbar-collapse justify-content-end align-items-center ${isShow ? 'show' : ''}`}
                            // id="navbarScroll"
                            >
                                <div className='d-flex desktop-translator align-items-center'>
                                    {(!user || (user?.role === "user" || user?.role === "smallAgency")) && <LanguageDropdown languages={languages} langLists={filteredLangs} language={language} isMobile={true} handleLanguageToggle={(val) => handleLanguageToggle(val)} handleClose={() => setIsShow(false)} />}
                                    {/* <LanguageToggle languages={languages} language={language} handleLanguageToggle={handleLanguageToggle} /> */}
                                    {(!user || (user?.role === "user" || user?.role === "smallAgency")) && <CurrencyDropdown currencies={currLists} currency={currency} handleCurrencyChange={handleCurrencyChange} handleClose={() => setIsShow(false)} />}
                                </div>
                                {(!user || user?.role === "user") && <ul className="navbar-nav navbar-nav-scroll align-items-center navbar-header">
                                    <li className="nav-item">
                                        <span className="nav-link text-white py-0 cursor-pointer" onClick={() => {
                                            setIsSupport(true);
                                            setIsShow(!isShow);
                                        }}>{t('support')}</span>
                                    </li>

                                    {!user && <>
                                        <li className="nav-item">
                                            <Link className="nav-link text-white py-0" to="/login" onClick={() => setIsShow(!isShow)}>{t('login')}</Link>
                                        </li>
                                        <li className="nav-item link-line-height">
                                            <Link className="ylw-theme-button d-inline-block" to="/signup" onClick={() => setIsShow(!isShow)}>{t('sign_up')}</Link>
                                        </li>
                                    </>}
                                    <li className="nav-item">
                                        <a className="nav-link white-border-btn text-white" href="https://play.google.com/store/games?utm_source=apac_med&hl=en-IN&utm_medium=hasem&utm_content=Jan0324&utm_campaign=Evergreen&pcampaignid=MKT-EDR-apac-in-1707570-med-hasem-py-Evergreen-Jan0324-Text_Search_BKWS-BKWS%7CONSEM_kwid_43700080171622879_creativeid_694609712112_device_c&gad_source=1&gclid=CjwKCAiA9IC6BhA3EiwAsbltOBqKK9vxx9qdUMItU-vI2mFynmQT_LyD81Wg5SJ-dGLyvoyyYqhC2BoC9soQAvD_BwE&gclsrc=aw.ds" target='_blank' onClick={() => setIsShow(!isShow)}><img src="/images/Download.png" class="me-2 d-inline-block"></img>{t('get_the_app')}</a>
                                    </li>
                                </ul>}
                                {user && user?.role !== "superadmin" && <div className="dropdown">
                                    <button className="btn bg-transparent dropdown-toggle dropdown-mine header-profile-dropdown text-white text-capitalize d-flex align-items-center" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                        {user?.profile_pic ? <img src={`${REACT_APP_OTA_URL}${user?.profile_pic}`} height="25" width="25" className='border border-dark rounded-circle me-1' /> : <i className="far fa-user me-1"></i>} {userDetails?.name}
                                    </button>
                                    <ul className="dropdown-menu dropdown-menu-end" aria-labelledby="dropdownMenuButton1">
                                        {items.map((item, index) => <>
                                            {item.name === "Logout" ?
                                                <li key={item.name} onClick={() => setIsCancel(true)}>
                                                    <a className="dropdown-item" href="#">{item.name}</a>
                                                </li>
                                                :
                                                <li key={item.name}>
                                                    <Link className="dropdown-item" to={`${item.value}`} onClick={() => setIsShow(!isShow)}>{item.name}</Link>
                                                </li>
                                            }
                                        </>)}
                                    </ul>

                                    <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                        <li onClick={handleLogout}><a className="dropdown-item" href="#">Logout</a></li>
                                    </ul>
                                </div>}
                            </div>
                        </div>
                    </div>
                </nav>
            </div >
            <ContactUs user={user} show={isSupport} handleClose={() => setIsSupport(false)} />
            <Cancel
                show={isCancel}
                handleClose={() => setIsCancel(!isCancel)}
                content1={`Are you sure you want to logout?`}
                //content2={"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text."}
                handleCancel={handleLogout}
            />
        </div >
    )
}

const mapStateToProps = (state) => {
    return {
        user: state.auth.user,
        userDetails: state.auth.userDetails,
        userLocation: state.auth.userLocation,
        vendor_id: state.auth.vendor_id,
        isEdit: state.auth.isEdit,
        langLists: state.vendor.langLists,
        currLists: state.vendor.currLists,
        defaultData: state.auth.defaultData
    }
}
export default connect(mapStateToProps)(Header);