import React, { useEffect, useRef, useState } from 'react'
import { Form, Modal } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { useToast } from '@chakra-ui/react';
import { user_contact_form } from '../reduxStore/User/userActions';
import Contact from '../shared/Contact';
import { useTranslation } from 'react-i18next';

const ContactUs = ({ user, show, handleClose }) => {
    const { t } = useTranslation();
    const [formdata, setFormdata] = useState({
        name: '',
        email: '',
        description: '',
        attachment: ''
    });
    const [errors, setErrors] = useState({
        name: '',
        email: '',
        description: '',
        attachment: ''
    });
    const exptest = /^(?!.*\.\.)(?!.*-@)(?!.*#)([A-Z0-9._%+-]+)@([A-Z0-9.-]+\.[A-Z]{2,})$/i;
    const fileRef = useRef();
    const dispatch = useDispatch();
    const toast = useToast();

    const handleChange = (label, value) => {

        switch (label) {
            case "name":
                setFormdata((prev) => ({ ...prev, [label]: value }));
                setErrors((prev) => ({ ...prev, [label]: value === "" ? `${t('name_valid')}` : null }));
                break;
            case "email":
                setFormdata((prev) => ({ ...prev, [label]: value }));
                setErrors((prev) => ({ ...prev, [label]: value === "" ? `${t('email_valid')}` : !exptest.test(value) ? `${t('email_check')}` : null }));
                break;
            case "description":
                setFormdata((prev) => ({ ...prev, [label]: value }));
                setErrors((prev) => ({ ...prev, [label]: value === "" ? `${t('description_valid')}` : null }));
                break;

            default:
                setFormdata((prev) => ({ ...prev, [label]: value }));
                break;
        }
    };

    const handleUpload = (val) => {
        setFormdata((prev) => ({ ...prev, attachment: val }));
        setErrors((prev) => ({ ...prev, attachment: null }));
    };

    const handleValid = () => {
        let err = false;

        const Errors = {
            description: formdata.description === "" ? `${t('description_valid')}` : null,
            name: formdata.name === "" ? `${t('name_valid')}` : null,
            email: formdata.email === "" ? `${t('email_valid')}` : !exptest.test(formdata.email) ? `${t('email_check')}` : null,
            attachment: formdata.attachment === "" ? `${t('document_valid')}` : null
        };

        let newErrors = Object.entries(Errors);
        newErrors.forEach(([key, value]) => {
            if (value) {
                err = true;
                setErrors((prev) => ({ ...prev, [key]: value }));
            }
        });

        return err;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (handleValid()) return;

        let isSend = await user_contact_form(dispatch, formdata, toast);
        if (isSend !== true) return;
        setFormdata({ name: '', email: '', description: '', attachment: '' });
    };

    useEffect(() => { 
        if (user !== null) {
            setFormdata((prev) => ({
                ...prev,
                email: user?.email
            }));
        } else if (user === null) {
            setFormdata({ name: '', email: '', description: '', attachment: '' });
        }
    }, [show]);

    return (
        <div>
            <Modal show={show} size='lg'>
                <Modal.Body className='p-4'>
                    <div className="modal-content position-relative">
                        <div class="modal-header border-0 cross-icon p-0 align-items-center mb-3">
                            <h1 className='mb-0 text-color-theme fw-bold'>{t('support')}</h1>
                            <button type="button" class="btn-close" style={{ color: 'white' }} data-bs-dismiss="modal" aria-label="Close" onClick={() => {
                                setErrors({ name: null, email: null, description: null, attachment: null });
                                handleClose();
                            }}></button>
                        </div>
                        <Contact
                            formdata={formdata}
                            errors={errors}
                            handleChange={handleChange}
                            handleSubmit={handleSubmit}
                            handleUpload={handleUpload}
                            fileRef={fileRef}
                        />
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default ContactUs;