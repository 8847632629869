import React, { useEffect, useState } from 'react'
import { connect, useDispatch } from 'react-redux';
import { change_password, get_profile_details, get_vender_id } from '../../reduxStore/User/userActions';
import { useToast } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

const ChangePassword = ({ user, vendor_id, userDetails }) => {
    const dispatch = useDispatch();
    const toast = useToast();
    const { t } = useTranslation();
    const [formdata, setFormdata] = useState({ password: '', newpassword: '', confirmpassword: '' });
    const [errors, setErrors] = useState({ password: '', newpassword: '', confirmpassword: '' });
    const [showPass, setshowPass] = useState(false);
    const [newPass, setnewPass] = useState(false);
    const [confirmPass, setconfirmPass] = useState(false);

    useEffect(() => {
        get_vender_id("superadmin", dispatch);
    }, []);

    const handleChange = (e) => {
        const { name, value } = e.target;
        switch (name) {
            case 'password':
                setErrors({ ...errors, password: value === '' ? `${t('current_password_checking')}` : '' });
                setFormdata({ ...formdata, password: value.replace(/\s/g, "") });
                break;
            case 'newpassword':
                setErrors({ ...errors, newpassword: value === '' ? `${t('new_password_valid')}` : value?.length < 6 ? `${t('password_valid_check')}` : value === formdata?.password ? `${t('new_password_check_valid')}` : '' });
                setFormdata({ ...formdata, newpassword: value.replace(/\s/g, "") });
                break;
            case 'confirmpassword':
                setErrors({ ...errors, confirmpassword: value === '' ? `${t('confirm_valid')}` : value !== formdata?.newpassword ? `${t('confirm_valid_check')}` : '' });
                setFormdata({ ...formdata, confirmpassword: value.replace(/\s/g, "") });
                break;

            default:
                break;
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const { password, newpassword, confirmpassword } = formdata;
        setErrors({
            password: (userDetails?.isPasswordSet || userDetails?.role === "superadmin") && !password ? `${t('password_checking')}` : "",
            newpassword: !newpassword ? `${t('new_password_valid')}` : newpassword === password ? `${t('new_password_check_valid')}` : "",
            confirmpassword: !confirmpassword ? `${t('confirm_valid')}` : confirmpassword !== newpassword ? `${t('confirm_valid_check')}` : "",
        });

        if (!userDetails?.isPasswordSet && (newpassword !=="" && confirmpassword!=="") && (newpassword === confirmpassword)) {
            const userdata = { currentPassword: password, newPassword: newpassword };
            // console.log("password data====>", userdata);
            let passupdated = await change_password(userdata, user?.token, dispatch, vendor_id, toast);
            if (passupdated) {
                get_profile_details(user?.token, vendor_id, dispatch, toast);
                setFormdata({ password: '', newpassword: '', confirmpassword: '' });
                setErrors({ password: '', newpassword: '', confirmpassword: '' });
            }
        }

        if (userDetails?.isPasswordSet && (password && password !== newpassword) && (newpassword === confirmpassword)) {
            const userdata = { currentPassword: password, newPassword: newpassword };
            // console.log("password data====>", userdata);
            let passupdated = await change_password(userdata, user?.token, dispatch, vendor_id, toast);
            if (passupdated) {
                get_profile_details(user?.token, vendor_id, dispatch, toast);
                setFormdata({ password: '', newpassword: '', confirmpassword: '' });
                setErrors({ password: '', newpassword: '', confirmpassword: '' });
            }
        }

        // if ((!userDetails?.isPasswordSet || (userDetails?.isPasswordSet && password && (password !== newpassword))) && newpassword === confirmpassword) {
        //     const userdata = { currentPassword: password, newPassword: newpassword };
        //     console.log("password data====>",userdata);
        //     // let passupdated = await change_password(userdata, user?.token, dispatch, vendor_id, toast);
        //     // if (passupdated) {
        //     //     get_profile_details(user?.token, vendor_id, dispatch, toast);
        //     //     setFormdata({ password: '', newpassword: '', confirmpassword: '' });
        //     //     setErrors({ password: '', newpassword: '', confirmpassword: '' });
        //     // }
        // }
    };

    useEffect(() => {
        if (user && vendor_id) {
            get_profile_details(user?.token, vendor_id, dispatch, toast);
        }

    }, [vendor_id]);

    return (
        <div className="admin-change-password-wrap">
            <h1 className="text-color-theme mb-3">{(userDetails?.isPasswordSet || userDetails?.role === "superadmin") ? t('change_password') : t('set_password')}</h1>
            <div className='admin-change-password-form box-with-shadow-bg-wrap'>

                <div className="profile-deatils">
                    <form onSubmit={handleSubmit}>

                        <div className='container-fluid px-0'>
                            <div className='row'>
                                {(userDetails?.isPasswordSet || userDetails?.role === "superadmin") && <div className='col-md-6'>
                                    <div className="form-group mb-3">
                                        <label className="form-label fw-medium">{t('current_password')}</label>
                                        <div className="password position-relative pwd-field">
                                            <input
                                                maxlength="45"
                                                type={showPass ? 'text' : 'password'}
                                                name="password"
                                                className="form-control"
                                                placeholder={`${t('current_placeholder')}`}
                                                value={formdata?.password}
                                                onChange={handleChange}
                                                onKeyDown={(e) => e.key === " " && e.preventDefault()}
                                            />
                                            {!showPass ? (<span className='pwd-eye-wrap'><i class="bi bi-eye-slash" onClick={() => setshowPass(!showPass)}></i></span>)
                                                : (<span className='pwd-eye-wrap'><i class="bi bi-eye" onClick={() => setshowPass(!showPass)}></i></span>)}
                                        </div>
                                        {errors.password && <p className="error-msg text-danger">{errors.password}</p>}

                                    </div>
                                </div>}
                                <div className='col-md-6'>
                                    <div className="form-group mb-3">
                                        <label className="form-label fw-medium">
                                            {t((userDetails?.isPasswordSet || userDetails?.role === "superadmin") ? 'new_password' : 'password_label' )}
                                            {/* {t('new_password')} */}
                                        </label>
                                        <div className="password position-relative pwd-field">
                                            <input
                                                type={newPass ? "text" : "password"}
                                                name="newpassword"
                                                maxlength="45"
                                                className="form-control"
                                                placeholder={`${t('new_password_placeholder')}`}
                                                value={formdata?.newpassword}
                                                onChange={handleChange}
                                                onKeyDown={(e) => e.key === " " && e.preventDefault()}
                                            />
                                            {!newPass ? (<span className='pwd-eye-wrap'><i class="bi bi-eye-slash" onClick={() => setnewPass(!newPass)}></i></span>)
                                                : (<span className='pwd-eye-wrap'><i class="bi bi-eye" onClick={() => setnewPass(!newPass)}></i></span>)}
                                        </div>
                                        {errors.newpassword && <p className="error-msg text-danger">{errors.newpassword}</p>}

                                    </div>
                                </div>
                                <div className='col-md-6'>
                                    <div className="form-group mb-3">
                                        <label className="form-label fw-medium">{t('confirm_password')}</label>
                                        <div className="password position-relative pwd-field">
                                            <input
                                                type={confirmPass ? 'text' : 'password'}
                                                name="confirmpassword"
                                                maxlength="45"
                                                className="form-control"
                                                placeholder={`${t('confirm_placeholder')}`}
                                                value={formdata?.confirmpassword}
                                                onChange={handleChange}
                                                onKeyDown={(e) => e.key === " " && e.preventDefault()}
                                            />
                                            {!confirmPass ? (<span className='pwd-eye-wrap'><i class="bi bi-eye-slash" onClick={() => setconfirmPass(!confirmPass)}></i></span>)
                                                : (<span className='pwd-eye-wrap'><i class="bi bi-eye" onClick={() => setconfirmPass(!confirmPass)}></i></span>)}
                                        </div>
                                        {errors.confirmpassword && <p className="error-msg text-danger">{errors.confirmpassword}</p>}

                                    </div>
                                </div>
                                <div className='col-md-12'>
                                    <div className='d-flex justify-content-end'>
                                        <button type="submit" className="btn-gradient-purple w-auto">
                                            {(userDetails?.isPasswordSet || userDetails?.role === "superadmin") ? `${t('change_password')}` : `${ t('set_password')}`}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        user: state.auth.user,
        userDetails: state.auth.userDetails,
        vendor_id: state.auth.vendor_id
    }
}
export default connect(mapStateToProps)(ChangePassword);