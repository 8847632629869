import React, { useEffect, useState } from 'react'
import { Link, useSearchParams } from 'react-router-dom';
import { check_user_verification, resend_email_verification_link } from '../reduxStore/User/userActions';
import { useToast } from '@chakra-ui/react';
import { useDispatch } from 'react-redux';

const Verified = () => {
    const checkStatus = JSON.parse(localStorage.getItem("emailVerified"));
    let [searchParams] = useSearchParams();
    const toast = useToast();
    const dispatch = useDispatch();
    const [checkVerify, setCheckVerify] = useState(checkStatus !== null ? true : false);

    useEffect(() => {

        if (checkStatus !== null) return;

        const handleUpdate = async () => {
           await check_user_verification(dispatch, searchParams.get("vendor_id"), searchParams.get("email"), searchParams.get("token"), toast);
            // if (result === true) {
            //     console.log("chck statusss444444---->", checkStatus);
            // }

            // if (result === false) {
            //     console.log("chck statusss5555555---->", checkStatus);
            // }
        };

        handleUpdate();
    }, []);

    const handleResend = async () => {
        await resend_email_verification_link(dispatch, searchParams.get("vendor_id"), searchParams.get("email"), searchParams.get("token"), toast);
        localStorage.removeItem("emailVerified");
    };

    return (
        <div className="login-signup-bg-wrapper">
            <div className='container'>
                <div className="row justify-content-end">
                    <div className="col-xl-5 col-lg-6 col-md-8">
                        <div className="login-form-wrap forgot-pwd-form">
                            <h4 className="text-center text-white mb-4">
                                {checkVerify === false ? 'Email Verification link expired' : 'Verified User'}
                            </h4>
                            {checkVerify === true ?
                                <div class="success-notice d-flex gap-2 mt-4">
                                    <div>
                                        <i class="bi bi-check2-circle h4 mb-0 text-success"></i>
                                    </div>
                                    <div>
                                        <h5 className="mb-0 fw-bold text-white">Success</h5>
                                        <p className='text-white'>Your account has been Verified successfully. You can now proceed and sign in to your account.</p>
                                    </div>
                                </div>
                                :
                                <div class="success-notice d-flex align-items-center justify-content-center flex-column gap-0 mt-4 mb-3">
                                    <div className=''>
                                        <p className='text-white'>Resend the Link again <i class="bi bi-arrow-down-circle"></i></p>
                                    </div>
                                    <div className=''>
                                        <button type='button' className='ylw-theme-button w-100' onClick={handleResend}>Resend verification link</button>
                                    </div>
                                </div>
                            }
                            <Link to="/login" className='text-decoration-underline mb-0 h6 link-color text-center d-flex justify-content-center fw-medium text-white'>
                                Back to Login
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Verified;