import React, { useEffect } from 'react'
import { connect, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { get_downpayments } from '../../reduxStore/vendors/vendorActions';
import debounce from 'lodash.debounce';
import { Table } from 'react-bootstrap';
import CustomPagination from '../../shared/CustomPagination';

const { REACT_APP_OTA_URL } = process.env;

const AgencyPayments = ({ user, allusers, vendor_id, total_payments }) => {
    const agencyId = JSON.parse(localStorage.getItem("agencyId"));

    const dispatch = useDispatch();
    const navigate = useNavigate();
    useEffect(() => {
        const fetchDownpayments = debounce(async () => {
            if (vendor_id && agencyId) {
                await get_downpayments(user?.token, dispatch, agencyId, vendor_id, '', 1, 10, '');
            }
        }, 1000);
        fetchDownpayments();
        return () => {
            fetchDownpayments.cancel();
        };
    }, [vendor_id, user?.token, dispatch, agencyId]);

    const formatDate = (date) => {
        const dated = new Date(date);

        const formattedDate = dated.toLocaleDateString("en-GB", {
            day: "2-digit",
            month: "long",
            year: "numeric"
        });
        return formattedDate;
    };

    return (
        <div>
            <button type="button" className='w-auto text-purple' onClick={() => { navigate("/downpayments"); }} >
                <i class="bi bi-arrow-left h4 mb-0"></i>
            </button>
            <div className='table-header-wrapper'>
                <h5 className='text-white mb-0'>Agency Payments</h5>
            </div>

            <div className='upcoming-list-table-wrapper'>
                <Table>
                    <thead>
                        <tr>
                            <th scope="col">Sr. No.</th>
                            <th scope="col">Name of Agency </th>
                            <th scope="col">Amount </th>
                            <th scope="col">Receipt</th>
                            <th scope="col">Created At </th>
                        </tr>
                    </thead>
                    <tbody>
                        {allusers?.map((item, index) => (
                            <tr key={index}>
                                <th>{index + 1}</th>
                                <td>{item?.agency_id?.name ? item?.agency_id?.name : 'N/A'}</td>
                                <td>${item?.advanceAmount ? item?.advanceAmount : '0'}</td>
                                <td>
                                    {item?.agency_id ?
                                        <img
                                            style={{ height: '2rem', width: '3rem' }}
                                            src={`${REACT_APP_OTA_URL}${item?.agency_id?.receipt}`}
                                            alt='img not found'
                                        />
                                        :
                                        "-"
                                    }
                                </td>
                                <td>{item?.createdAt ? formatDate(item?.createdAt) : '-'}</td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </div>
            {total_payments > 0 &&
                <CustomPagination
                    total={total_payments}
                    onPageChange={(page, perpage) => {
                        get_downpayments(user?.token, dispatch, agencyId, vendor_id, '', page, perpage, '');
                    }}
                />}
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        user: state.auth.user,
        allusers: state.vendor.payments,
        vendor_id: state.auth.vendor_id,
        total_payments: state.vendor.total_payments,
    }
}
export default connect(mapStateToProps)(AgencyPayments);