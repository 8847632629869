import React,{useState,useEffect} from "react";
import { Quill } from "react-quill";
// Custom Undo button icon component for Quill editor. You can import it directly
// from 'quill/assets/icons/undo.svg' but I found that a number of loaders do not
// handle them correctly
const CustomUndo = () => (
  <svg viewBox="0 0 18 18">
    <polygon className="ql-fill ql-stroke" points="6 10 4 12 2 10 6 10" />
    <path
      className="ql-stroke"
      d="M8.09,13.91A4.6,4.6,0,0,0,9,14,5,5,0,1,0,4,9"
    />
  </svg>
);

// Redo button icon component for Quill editor
const CustomRedo = () => (
  <svg viewBox="0 0 18 18">
    <polygon className="ql-fill ql-stroke" points="12 10 14 12 16 10 12 10" />
    <path
      className="ql-stroke"
      d="M9.91,13.91A4.6,4.6,0,0,1,9,14a5,5,0,1,1,5-5"
    />
  </svg>
);
function leaveChange(e) {
  const cursorPosition = this.quill.getSelection().index;
  if (document.getElementById("leave").value != "100") {
    this.quill.insertText(cursorPosition, e);
  } else {
    this.quill.insertText(cursorPosition, "__second else");
  }
  this.quill.setSelection(cursorPosition);
}

// Undo and redo functions for Custom Toolbar
function undoChange() {
  this.quill.history.undo();
}
function redoChange() {
  this.quill.history.redo();
}

// Add sizes to whitelist and register them
const Size = Quill.import("formats/size");
Size.whitelist = ["extra-small", "small", "medium", "large"];
Quill.register(Size, true);

// Add fonts to whitelist and register them
const Font = Quill.import("formats/font");
Font.whitelist = [
  "arial",
  "comic-sans",
  "courier-new",
  "georgia",
  "helvetica",
  "lucida",
];
Quill.register(Font, true);

// Modules object for setting up the Quill editor
export const cmodules = {
  toolbar: {
    container: "#toolbar",
    handlers: {
      undo: undoChange,
      redo: redoChange,
      size: leaveChange,
    },
  },
  history: {
    delay: 500,
    maxStack: 100,
    userOnly: true,
  },
};

export const  Genralmodules  = {
  toolbar: [
      [{ font: [] }],
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      ["bold", "italic", "underline", "strike"],
      [{ color: ['#000000', '#e60000', '#ff9900', '#ffff00', '#008a00', '#0066cc', '#9933ff', '#ffffff', '#facccc', '#ffebcc', '#ffffcc', '#cce8cc', '#cce0f5', '#ebd6ff', '#bbbbbb', '#f06666', '#ffc266', '#ffff66', '#66b966', '#66a3e0', '#c285ff', '#888888', '#a10000', '#b26b00', '#b2b200', '#006100', '#0047b2', '#6b24b2', '#444444', '#5c0000', '#663d00', '#666600', '#003700', '#002966', '#3d1466'] }, { background: [] }],
      [{ script:  "sub" }, { script:  "super" }],
      ["blockquote", "code-block"],
      [{ list:  "ordered" }, { list:  "bullet" }],
      [{ indent:  "-1" }, { indent:  "+1" }, { align: [] }],
      ["link", "image", "video"],
      ["clean"],
  ],
};


// Formats objects for setting up the Quill editor
export const cformats = [
  "header",
  "font",
  "size",
  "bold",
  "italic",
  "underline",
  "align",
  "strike",
  "script",
  "blockquote",
  "background",
  "list",
  "bullet",
  "indent",
  "link",
  "image",
  "video",
  "color",
  "code-block",
  "lineheight",
];


export const  genralModules  = {
  toolbar: [
      [{ font: [] }],
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      ["bold", "italic", "underline", "strike"],
      [{ color: ['#000000', '#e60000', '#ff9900', '#ffff00', '#008a00', '#0066cc', '#9933ff', '#ffffff', '#facccc', '#ffebcc', '#ffffcc', '#cce8cc', '#cce0f5', '#ebd6ff', '#bbbbbb', '#f06666', '#ffc266', '#ffff66', '#66b966', '#66a3e0', '#c285ff', '#888888', '#a10000', '#b26b00', '#b2b200', '#006100', '#0047b2', '#6b24b2', '#444444', '#5c0000', '#663d00', '#666600', '#003700', '#002966', '#3d1466'] }, { background: [] }],
      [{ script:  "sub" }, { script:  "super" }],
      ["blockquote", "code-block"],
      [{ list:  "ordered" }, { list:  "bullet" }],
      [{ indent:  "-1" }, { indent:  "+1" }, { align: [] }],
      ["link", "image", "video"],
      ["clean"],
      ["copy-link"]
  ],
};


function HandleInsertLink ({ quill,addCopiedText }) {
  const add = () => {
    const linkText = prompt('Enter the text for the link');
    const linkUrl = prompt('Enter the URL');
  
    if (linkText && linkUrl && quill) {
      const range = quill.getSelection();
      if (range) {
        const cursorPosition = quill.getSelection().index;
  
        const html = `<a class="copy_text" data-toggle="tooltip" title="Copy to Clipboard" href="${linkUrl}"><button>${linkText}</button></a>`;
        
        quill.clipboard.dangerouslyPasteHTML(cursorPosition, html);
        
        quill.setSelection(cursorPosition + linkText.length);
        addCopiedText({text:linkText,link:linkUrl});
      }
    }
  };
  return <span onClick={()=>add()} className="w-100">
    Copy and share text
  </span>
};

const popups =[
  {name:"Share Popup",value:"{{share_popup}}"}
]
// Quill Toolbar component
const CustomQuillToolbar = (props) => {
  const {quill,isHtmlMode,addCopiedText,toggleHtmlMode}= props;
  return (<div id="toolbar">
    <>
    <span className="ql-formats">
      <select className="ql-font" defaultValue="arial" disabled={isHtmlMode}>
        <option value="arial">Arial</option>
        <option value="comic-sans">Comic Sans</option>
        <option value="courier-new">Courier New</option>
        <option value="georgia">Georgia</option>
        <option value="helvetica">Helvetica</option>
        <option value="lucida">Lucida</option>
      </select>
      <select className="ql-header" defaultValue="3"  disabled={isHtmlMode}>
        <option value="1">Heading</option>
        <option value="2">Subheading</option>
        <option value="3">Normal</option>
      </select>
    </span>
    <span className="ql-formats">
      <button className="ql-bold"  disabled={isHtmlMode}/>
      <button className="ql-italic"  disabled={isHtmlMode}/>
      <button className="ql-underline"  disabled={isHtmlMode}/>
      <button className="ql-strike"  disabled={isHtmlMode}/>
    </span>
    <span className="ql-formats">
      <button className="ql-list" value="ordered"  disabled={isHtmlMode}/>
      <button className="ql-list" value="bullet"  disabled={isHtmlMode}/>
      <button className="ql-indent" value="-1"  disabled={isHtmlMode}/>
      <button className="ql-indent" value="+1"  disabled={isHtmlMode}/>
    </span>
    <span className="ql-formats">
      <button className="ql-script" value="super"  disabled={isHtmlMode}/>
      <button className="ql-script" value="sub"  disabled={isHtmlMode}/>
      <button className="ql-blockquote"  disabled={isHtmlMode}/>
      <button className="ql-direction"  disabled={isHtmlMode}/>
    </span>
    <span className="ql-formats">
      <select className="ql-align"  disabled={isHtmlMode}/>
      <select className="ql-color"  disabled={isHtmlMode}/>
      <select className="ql-background"  disabled={isHtmlMode}/>
    </span>
    <span className="ql-formats">
      <button className="ql-link"  disabled={isHtmlMode}/>
      <button className="ql-image"  disabled={isHtmlMode}/>
      <button className="ql-video"  disabled={isHtmlMode}/>
    </span>
    <span className="ql-formats">
      <button className="ql-formula"  disabled={isHtmlMode}/>
      <button type="button" onClick={toggleHtmlMode}>{'</>'}</button>
      <button className="ql-clean"  disabled={isHtmlMode}/>
    </span>
    <span className="ql-formats">
      <button className="ql-undo"  disabled={isHtmlMode}>
        <CustomUndo />
      </button>
      <button className="ql-redo"  disabled={isHtmlMode}>
        <CustomRedo />
      </button>
    </span>
    {/* <span className="ql-formats">
      <button className="ql-copy-link"  disabled={isHtmlMode}> <HandleInsertLink quill={quill?.getEditor()} addCopiedText={addCopiedText}/></button>
    </span>
    <span className="ql-formats">
      <select
        id="leave"
        className="ql-size text-capitalize border"
        style={
            {width:"200px"}
        }
        onChange={(e) => leaveChange(e.target.value)}
        >
        <option key="empty" value="">Select Keywords</option>
        {popups.map(( item ) => (
          <option key={item.name} value={item.value}>{item.name}</option>
        ))}
      </select>
      </span> */}
    </>
  </div>
 );
}

export default CustomQuillToolbar;

