import { GET_AGENCY_DETAILS, GET_ALL_CURRENCIES_LIST, GET_ALL_LANGUAGES_LIST, GET_ALL_MEDIUM_AGENCIES, GET_ALL_SMALL_AGENCIES, GET_ALL_STAFF_MEMBERS, GET_ALL_USERS, GET_LANGUAGE_DATA, GET_TOTAL_MEDIUM, GET_TOTAL_SMALL, GET_TOTAL_STAFF_MEMBERS, GET_USERS_TOTAL,GET_DOWNPAYMENTS_DATA,GET_AGENCY_THEME,GET_CMS_PAGES, GET_CASHBACKS_DATA } from "./vendorTypes";

const initialState = {
    agency_data: null,
    small_agencies: [],
    medium_agencies: [],
    total_small: 0,
    total_medium: 0,
    staff_members: [],
    total_staff: 0,
    total_members: 0,
    langdata: null,
    langLists: [],
    currLists: [],
    payments:[],
    total_payments:0,
    theme:{
        headerGradientStart: '#3e1257',
        headerGradientEnd: '#522169',
        headerTextColor: '#ffffff',
        footerGradientStart: '#3e1257',
        footerGradientEnd: '#522169',
        footerTextColor: '#ffffff',
        buttonPrimaryColor: '#ffffff',
        buttonSecondaryColor: '#ffffff',
        buttonPrimaryBackground: '#522169',
        buttonSecondaryBackground: '#febb63',
        customDomain: '',
        metaTitle: '',
        metaDescription: '',
        copyright: ''
    },
    cms_pages:[],
    cms_pages_total: 0,
    cashback_data: null
};

const vendorReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_AGENCY_DETAILS:
            return {
                ...state,
                agency_data: action.payload,
            };
        case GET_ALL_SMALL_AGENCIES:
            return {
                ...state,
                small_agencies: action.payload,
            };
        case GET_ALL_MEDIUM_AGENCIES:
            return {
                ...state,
                medium_agencies: action.payload,
            };
        case GET_ALL_STAFF_MEMBERS:
            return {
                ...state,
                staff_members: action.payload,
            };
        case GET_TOTAL_SMALL:
            return {
                ...state,
                total_small: action.payload,
            };
        case GET_TOTAL_MEDIUM:
            return {
                ...state,
                total_medium: action.payload,
            };
        case GET_TOTAL_STAFF_MEMBERS:
            return {
                ...state,
                total_staff: action.payload,
            };
        case GET_LANGUAGE_DATA:
            return {
                ...state,
                langdata: action.payload,
            };
        case GET_ALL_LANGUAGES_LIST:
            return {
                ...state,
                langLists: action.payload,
            };
        case GET_ALL_CURRENCIES_LIST:
            return {
                ...state,
                currLists: action.payload,
            };
       case GET_DOWNPAYMENTS_DATA:
            return {
                ...state,
                payments: action.payload.data,
                total_payments: action.payload.length,
            };
        case GET_AGENCY_THEME:
            return {
                ...state,
                theme:{...state?.theme,...action?.payload}
            };  
        case GET_CMS_PAGES:
            return {
                ...state,
                cms_pages:action.payload.data,
                cms_pages_total: action.payload.length,
            };
        case GET_CASHBACKS_DATA:
            return {
                ...state,
                cashback_data: action.payload,
            };
        default:
            return state;
    }
};

export default vendorReducer;