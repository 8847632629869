import React, { useEffect, useState } from 'react'
import BookingIMG from './../../assets/booking-confirm.png'
import BGreenCheck from '../../assets/big-green-check.png'
import { Link } from 'react-router-dom'
import { after_booking } from '../../reduxStore/booking/bookingActions'
import { connect } from 'react-redux'
import { currency_converter } from '../../reduxStore/User/userActions'
import CurrencyLogo from '../../shared/CurrencyLogo'
import { useTranslation } from 'react-i18next'

const BookingConfirm = ({currency}) => {
    const { t } = useTranslation();
    const totalOccupancies = JSON.parse(localStorage.getItem("totalOccupancies"));
    const roomdetail = JSON.parse(localStorage.getItem("selectedRoom"));
    //  const [convertedPrice, setConvertedPrice] = useState(Math.round(roomdetail?.TotalPrice));

    //  useEffect(() => {
    //         const fetchConvertedPrice = async () => {
    //             if (currency !== 'USD') {
    //                 const data = {
    //                     amount: Number(roomdetail?.TotalPrice),
    //                     from: roomdetail?.Currency,
    //                     to: currency
    //                 };
    //                 const conversion = await currency_converter(data);
    //                 setConvertedPrice(Math.round(conversion?.data));
    //             } else {
    //                 setConvertedPrice(Math.round(roomdetail?.TotalPrice));
    //             }
    //         };
    
    //         fetchConvertedPrice();
    //     }, [currency]);

    return (
        <div>
            <div className='booking-confirm-wrapper text-center'>
                <div className='container'>
                    <div className='row justify-content-center mt-5'>
                        <div className='col-lg-8 col-md-10 col-sm-12'>
                            <div className=''>
                                <img src={BookingIMG} alt='' className='mx-auto pb-4' />
                                <h2 className='d-flex align-items-center justify-content-center font-size-heading'>{t('booking_message')} <img src={BGreenCheck} className='ms-3' /></h2>
                                <p className='mb-0 font-size-15 font-weight-500'>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                                <div className='booking-btns-wrapper d-flex align-items-center gap-2 justify-content-center'>
                                    <Link to='/' className='btn btn-gradient-purple text-white d-inline-block w-auto booking-btns'>{t('home_page')}</Link>
                                    <Link to='/dashboard/bookings' className='btn btn-gradient-purple text-white d-inline-block w-auto booking-btns'>{t('my_bookings')}</Link>
                                </div>
                                <div className='price-box-room confirm-price-box'>
                                    <h5 className='text-purple p-large mb-0 text-start'>{t('payment_details')}</h5>
                                    <div className='price-line-dotted align-items-end'>
                                        <div className=''>
                                            <p className='p-md mb-0 text-start'>
                                                {roomdetail?.RoomName}
                                                </p>
                                            <p className='p-sm mb-0 text-start'>{totalOccupancies?.totalRooms} {t('room')}</p>
                                        </div>
                                        <div className='text-end'>
                                            <p className='p-lg mb-0 text-color-theme font-weight-500'>
                                                <strike className='p-sm me-2 text-grey-light font-weight-normal'>$41</strike>
                                                <CurrencyLogo currencyCode={currency} />{roomdetail?.TotalPrice}
                                            </p>
                                        </div>
                                    </div>

                                    {/* <div className='price-line-dotted align-items-end'>
                                        <div className=''>
                                            <p className='p-md mb-0 text-start'>Regular Room - Queen Bed</p>
                                            <p className='p-sm mb-0 text-start'>1 Room</p>
                                        </div>
                                        <div className='text-end'>
                                            <p className='p-lg mb-0 text-color-theme font-weight-500'><strike className='p-sm me-2 text-grey-light font-weight-normal'>$41</strike>$35</p>
                                        </div>
                                    </div> */}

                                    {/* <div className='price-line-dotted price-line-plane align-items-end'>
                                        <div className=''>
                                            <p className='p-md mb-0 text-start'>Regular Room - Queen Bed</p>
                                            <p className='p-sm mb-0 text-start'>1 Room</p>
                                        </div>
                                        <div className='text-end'>
                                            <p className='p-lg mb-0 text-color-theme font-weight-500'><strike className='p-sm me-2 text-grey-light font-weight-normal'>$41</strike>$35</p>
                                        </div>
                                    </div> */}

                                    <div className='price-line-dotted align-items-center border-0 pb-0 pt-3 last-payment'>
                                        <div className=''>
                                            <p className='p-large mb-0 font-weight-600 text-color-theme text-start'>{t('total_payment')}</p>
                                        </div>
                                        <div className='text-end'>
                                            <p className='p-large mb-0 font-weight-600 text-color-theme last-txt'>
                                            <CurrencyLogo currencyCode={currency} />{totalOccupancies?.totalRooms * roomdetail?.TotalPrice}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        currency: state.auth.currency
    }
};

export default connect(mapStateToProps)(BookingConfirm);