import React, { useEffect, useState } from 'react'
import RoomOne from '../assets/room-img.png'
import GreenCheck from '../assets/green-check.png'
import { Link } from 'react-router-dom'
import CurrencyLogo from './CurrencyLogo'
import { connect } from 'react-redux'
import { currency_converter } from '../reduxStore/User/userActions'
import { useTranslation } from 'react-i18next'

const RoomsCard = ({ item, hotelDetail, currency, roomslength, selectedRooms, setSelectedRooms, handlePrice }) => {
    const { t } = useTranslation();
    const hotelGDS = JSON.parse(localStorage.getItem("selectedHotelGDS"));
    const searchData = JSON.parse(localStorage.getItem("searchedData"));
    const [days, setDays] = useState(0);
    // const [convertedPrice, setConvertedPrice] = useState(Number(hotelGDS === "Juniper" ? item?.Price?.Gross : item?.TotalPrice));
    // useEffect(() => {
    //     const fetchConvertedPrice = async () => {
    //         // if (currency !== 'USD') {
    //             const data = {
    //                 amount: Number(hotelGDS === "Juniper" ? item?.Price?.Gross : item?.TotalPrice),
    //                 from: hotelGDS === "Juniper" ? item?.Price?.Currency : item?.Currency ? item?.Currency : 'USD',
    //                 to: currency
    //             };
    //             const conversion = await currency_converter(data);
    //             setConvertedPrice(Math.round(conversion?.data));
    //         // } else {
    //         //     setConvertedPrice(Math.round(item?.price));
    //         // }
    //     };

    //     fetchConvertedPrice();
    // }, [currency]);

    useEffect(() => {
        const d1 = new Date(searchData?.CheckInDate);
        const d2 = new Date(searchData?.CheckOutDate);

        // Calculate the difference in time (milliseconds)
        const timeDifference = d2 - d1;

        // Convert the difference from milliseconds to days
        const differenceInDays = timeDifference / (1000 * 60 * 60 * 24);

        setDays(differenceInDays);
    }, []);

    return (
        <div className='realted_inner'>
            <img src={RoomOne} className='img-fluid w-100' />
            <div className='inner-details-cnt'>
                <h3 className='my-3'>
                    {hotelGDS === "Juniper" ? item?.HotelRoom?.Name : item?.RoomName ? item?.RoomName : item?.RatePlanName ? item?.RatePlanName : `Regular Room ${item} - Queen Bed`}
                    {/* Regular Room {item} - Queen Bed */}
                </h3>

                <div className='room-feature'>
                    <ul className='p-0'>
                        {item?.facility && !item?.facility?.includes(null) && item?.facility?.map((item) => (
                            <li className='p-md'><img src={GreenCheck} className='d-inline-block me-2' alt='' />{item?.Name}</li>
                        ))}
                    </ul>
                </div>

                {/* <div className='col-lg-6 col-md-12 col-sm-12'>
                        <div className='room-feature'>
                            <ul className='p-0'>
                                <li className='p-md'><img src={GreenCheck} className='d-inline-block me-2' alt='' />2 Adult</li>
                                <li className='p-md'><img src={GreenCheck} className='d-inline-block me-2' alt='' />Free Wi-fi</li>
                                <li className='p-md'><img src={GreenCheck} className='d-inline-block me-2' alt='' />Coffee/tea maker</li>
                                <li className='p-md'><img src={GreenCheck} className='d-inline-block me-2' alt='' />Queen Bed</li>
                            </ul>
                        </div>
                    </div>
                    <div className='col-lg-6 col-md-12 col-sm-12'>
                        <div className='room-feature'>
                            <ul className='p-0'>
                                <li className='p-md'><img src={GreenCheck} className='d-inline-block me-2' alt='' />TV</li>
                                <li className='p-md'><img src={GreenCheck} className='d-inline-block me-2' alt='' />2 Adult</li>
                                <li className='p-md'><img src={GreenCheck} className='d-inline-block me-2' alt='' />Air Condition</li>
                                <li className='p-md'><img src={GreenCheck} className='d-inline-block me-2' alt='' />24 Hour Room Service</li>
                            </ul>
                        </div>
                    </div> */}

                <div className='price-room mb-3'>
                    <div className='price-box-bottom-txt d-flex align-items-center justify-content-between'>
                        <p className='d-inline-block size-big me-3 tab-0-content-sp mb-0'>
                            <b>
                                <CurrencyLogo currencyCode={currency} /> {item?.TotalPrice}
                            </b>
                            <strike>$41</strike>
                        </p>
                        <p className='p-md mb-0'>{days ? days : 1} {t('night_incl')}</p>
                    </div>
                    <div className=''>
                        <p className='text-danger p-md mb-0'>{t('only')} {item?.InventoryCount} {t('left')}</p>
                    </div>
                </div>
                <button
                    type='button'
                    // to="/hotel-booking/payment"
                    // className='btn btn-gradient-purple h-auto'
                    className={item?.RatePlanID === selectedRooms[0]?.RatePlanID ? 'disabled-btn' : 'btn btn-gradient-purple'}
                    onClick={() => {
                        // handlePrice(item);
                        setSelectedRooms([item]);
                    }}
                // disabled={roomslength !== 0}
                >
                    {t('add_more')}
                </button>
            </div>
        </div >
    )
}

const mapStateToProps = (state) => {
    return {
        currency: state.auth.currency
    }
};

export default connect(mapStateToProps)(RoomsCard);