import React, { useEffect, useState } from 'react'
import GrayFlightIcon from './../../assets/gray-flight-icon.svg';
import GrayLocalTourIcon from './../../assets/gray-local-tour-icon.svg';
import GrayCarIcon from './../../assets/gray-car-icon.svg';
import GreyHotelIcon from './../../assets/grey-hotel-icon.svg';
import { connect, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { get_profile_details, get_vender_id } from '../../reduxStore/User/userActions';
import { useToast } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { get_user_all_booking_lists, get_user_upcomming_booking_lists, hotel_booking_cancel_by_user } from '../../reduxStore/booking/bookingActions';
import CustomPagination from '../../shared/CustomPagination';
import debounce from 'lodash.debounce';
import Cancel from '../../Modals/Cancel';

const UserHome = ({ user, vendor_id, userDetails, totalBookings, upcomingBookings, totalUpcoming }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const toast = useToast();
    const { t } = useTranslation();
    const [isCancel, setIsCancel] = useState(false);
    const [data, setData] = useState(null);

    useEffect(() => {
        const fetchId = debounce(async () => {
            await get_vender_id("superadmin", dispatch);
            localStorage.removeItem("hotelData");
            localStorage.removeItem("goBackPath");
        }, 1000);
        fetchId();
        return () => {
            fetchId.cancel();
        };
    }, []);

    // useEffect(() => {
    //     const fetchId = debounce(async () => {
    //         if (user && vendor_id) {
    //             get_profile_details(user?.token, vendor_id, dispatch, toast);
    //         }
    //     }, 1000);
    //     fetchId();
    //     return () => {
    //         fetchId.cancel();
    //     };
    // }, [vendor_id]);

    useEffect(() => {
        if (!vendor_id) return;

        const handleData = debounce(async () => {
            await get_user_all_booking_lists(dispatch, vendor_id, user?.token, 1, 10);
            await get_user_upcomming_booking_lists(dispatch, vendor_id, user?.token, 1, 10);
        }, 1000);
        handleData();
        return () => {
            handleData.cancel();
        };
    }, [user?.token, vendor_id]);

    const formatDate = (inputdate) => {
        const date = new Date(inputdate);

        // Get day, date, and month
        const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
        const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

        const day = days[date.getUTCDay()];
        const dayOfMonth = date.getUTCDate();
        const month = months[date.getUTCMonth()];
        const year = date.getUTCFullYear();

        return `${dayOfMonth} ${month} ${year}`;
    };

    const handleBooking = async () => {
        await hotel_booking_cancel_by_user(dispatch, vendor_id, data ? data?.BookingID : '', data ? data?.provider : '', toast);
        // if (iscancel) {
        await get_user_upcomming_booking_lists(dispatch, vendor_id, user?.token, 1, 10);
        setIsCancel(false);
        // }
    };


    return (
        <div className='dashboard-page-wrapper'>
            <div className='d-flex align-items-center justify-content-between mb-4'>
                <h4 className='text-capitalize'>Hi {userDetails?.name}...</h4>
                <button className='btn-gradient-purple w-auto' onClick={() => navigate('/')}>{t('home_btn')}</button>
            </div>
            {/* <div className='d-flex align-items-center justify-content-between mb-5 admin-name-with-home-btn-wrap'>
                <div className='admin-profile-name-heading-wrap'>
                    <h2 className='text-purple mb-0'>Hi Manpreet...</h2>
                </div>
                <div className='dashboard-home-btn-wrap'>
                    <a href='#' className='btn-gradient-purple'>Home</a>
                </div>
            </div> */}
            {/* <h1 className='text-center'>{user.role === "superadmin" ? 'Admin' : 'Agency'} Pannel</h1>
            <div className='d-flex justify-content-center gap-4 pt-3'>
                <div>
                    <h3>Total Users</h3>
                    <h4 className='text-center'>{totalUsers}</h4>
                </div>
                <div>
                    <h3>Total Agencies</h3>
                    <h4 className='text-center'>{total_small + total_medium}</h4>
                </div>
            </div> */}

            <div className='admin-booking-detail-containers'>
                <div className='total-booking-container-wrap'>
                    <p className='p-md text-white'>{t('total_booking')}</p>
                    <h1 className='text-white'>{totalBookings}</h1>
                </div>
                <div className='processing-booking-container-wrap'>
                    <p className='p-md text-white'>{t('process_bookings')}</p>
                    <h1 className='text-white'>0</h1>
                </div>
                <div className='completed-booking-container-wrap'>
                    <p className='p-md text-white'>{t('completed_bookings')}</p>
                    <h1 className='text-white'>0</h1>
                </div>
                <div className='cancel-booking-container-wrap'>
                    <p className='p-md text-white'>{t('cancel_bookings')}</p>
                    <h1 className='text-white'>0</h1>
                </div>
            </div>

            {/* =====table-heading-start===== */}
            <div className='table-header-wrapper'>
                <h5 className='text-white mb-0'>{t('upcoming_list')}</h5>
                {/* <a href='#' className='text-white text-decoration-underline'>{t('view_all')}</a> */}
            </div>
            {/* =====End===== */}


            {/* ====table-start=== */}
            <div className='upcoming-list-table-wrapper'>
                <table class="table">
                    <thead>
                        <tr>
                            <th scope="col">#</th>
                            <th scope="col">{t('type')}</th>
                            <th scope="col">{t('name')}</th>
                            <th scope="col">{t('reviews')}</th>
                            <th scope="col">{t('order_date')}</th>
                            <th scope="col">{t('amount')}</th>
                            <th scope="col">{t('status')}</th>
                            <th scope="col">{t('action')}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {totalUpcoming > 0 && upcomingBookings?.map((item, index) => (
                            <tr key={index}>
                                <th scope="row">{index + 1}</th>
                                <td>
                                    <div className='d-flex align-items-center gap-1'>
                                        <img
                                            src={item?.type === "HOTEL" ? GreyHotelIcon :
                                                item?.type === "FLIGHT" ? GrayFlightIcon :
                                                    item?.type === "CAR" ? GrayCarIcon : GrayLocalTourIcon
                                            }
                                            alt='GrayLocalTourIcon'
                                        />
                                        {item?.type}
                                    </div>
                                </td>
                                <td>
                                    <div className='table-col-name-td'>
                                        {item?.customer_id?.name}
                                    </div>
                                </td>
                                <td>
                                    <div className='add-review-disable'>
                                        + Add Reviews
                                    </div>
                                </td>
                                <td>
                                    {formatDate(item?.OrderDate)}
                                </td>
                                <td>
                                    <div className='table-amount-col-data'>
                                        ${item?.totalAmount}
                                    </div>
                                </td>
                                <td>
                                    <div className='status-label'>
                                        <span className={`${item?.Status === 1 ? 'completed-label' : item?.Status === 2 ? 'confirmed-label' : (item?.Status === 3 || item?.Status === 4) ? 'cancel-label' : 'processing-label'}`}>
                                            {item?.Status === 1 ? 'Completed' :
                                                item?.Status === 2 ? 'Confirmed' :
                                                    item?.Status === 3 ? 'Cancelled' :
                                                        item?.Status === 4 ? 'Failed' :
                                                            'Pending'}
                                        </span>
                                    </div>
                                </td>
                                <td>
                                    <div className='action-icon-wrap d-flex align-items-center gap-2'>
                                        <span className='eye-icon-wrap text-decoration-none cursor-pointer' onClick={() => {
                                            localStorage.setItem("hotelData", JSON.stringify(item));
                                            localStorage.setItem("goBackPath", JSON.stringify('/dashboard'));
                                            navigate('/dashboard/booking-detail');
                                        }}>
                                            <i class="bi bi-eye"></i>
                                        </span>
                                        <button className='download-icon-wrap text-decoration-none'>
                                            <i class="bi bi-download"></i>
                                        </button>
                                        {item?.Status === 2 && <span className='cross-icon-wrap text-decoration-none cursor-pointer' onClick={() => {
                                            setData(item);
                                            setIsCancel(true);
                                        }}>
                                            <i class="bi bi-x"></i>
                                        </span>}
                                        {/* <a href='#' className='cross-icon-wrap text-decoration-none'>
                                            <i class="bi bi-x"></i>
                                        </a> */}
                                    </div>
                                </td>
                            </tr>
                        ))}
                        {/* <tr>
                            <th scope="row">1</th>
                            <td>
                                <div className='d-flex align-items-center gap-1'>
                                    <img src={GrayFlightIcon} alt='GrayFlightIcon' />
                                    Flight
                                </div>
                            </td>
                            <td>
                                <div className='table-col-name-td'>
                                    Manpreet Singh
                                </div>
                            </td>
                            <td>
                                <div className='add-review-disable'>
                                    + Add Reviews
                                </div>
                            </td>
                            <td>10 Nov 2024</td>
                            <td>
                                <div className='table-amount-col-data'>
                                    $1899.00
                                </div>
                            </td>
                            <td>
                                <div className='status-label'>
                                    <span className='processing-label'>Processing</span>
                                </div>
                            </td>
                            <td>
                                <div className='action-icon-wrap d-flex align-items-center gap-2'>
                                    <a href='#' className='eye-icon-wrap text-decoration-none'>
                                        <i class="bi bi-eye"></i>
                                    </a>
                                    <a href='#' className='download-icon-wrap text-decoration-none'>
                                        <i class="bi bi-download"></i>
                                    </a>
                                    <a href='#' className='cross-icon-wrap text-decoration-none'>
                                        <i class="bi bi-x"></i>
                                    </a>
                                </div>
                            </td>
                        </tr> */}

                        {/* <tr>
                            <th scope="row">2</th>
                            <td>
                                <div className='d-flex align-items-center gap-1'>
                                    <img src={GrayLocalTourIcon} alt='GrayLocalTourIcon' />
                                    Local Tour
                                </div>
                            </td>
                            <td>
                                <div className='table-col-name-td'>
                                    Vikas Kumar
                                </div>
                            </td>
                            <td>
                                <div className='add-review-disable'>
                                    + Add Reviews
                                </div>
                            </td>
                            <td>09 Nov 2024</td>
                            <td>
                                <div className='table-amount-col-data'>
                                    $3063.53
                                </div>
                            </td>
                            <td>
                                <div className='status-label'>
                                    <span className='processing-label'>Processing</span>
                                </div>
                            </td>
                            <td>
                                <div className='action-icon-wrap d-flex align-items-center gap-2'>
                                    <a href='#' className='eye-icon-wrap text-decoration-none'>
                                        <i class="bi bi-eye"></i>
                                    </a>
                                    <a href='#' className='download-icon-wrap text-decoration-none'>
                                        <i class="bi bi-download"></i>
                                    </a>
                                    <a href='#' className='cross-icon-wrap text-decoration-none'>
                                        <i class="bi bi-x"></i>
                                    </a>
                                </div>
                            </td>
                        </tr> */}

                        {/* <tr>
                            <th scope="row">3</th>
                            <td>
                                <div className='d-flex align-items-center gap-1'>
                                    <img src={GrayCarIcon} alt='GrayLocalTourIcon' />
                                    Car Rentals
                                </div>
                            </td>
                            <td>
                                <div className='table-col-name-td'>
                                    Sahil Kumar
                                </div>
                            </td>
                            <td>
                                <div className='add-review-disable'>
                                    + Add Reviews
                                </div>
                            </td>
                            <td>07 Nov 2024</td>
                            <td>
                                <div className='table-amount-col-data'>
                                    $399.05
                                </div>
                            </td>
                            <td>
                                <div className='status-label'>
                                    <span className='confirmed-label'>Confirmed</span>
                                </div>
                            </td>
                            <td>
                                <div className='action-icon-wrap d-flex align-items-center gap-2'>
                                    <a href='#' className='eye-icon-wrap text-decoration-none'>
                                        <i class="bi bi-eye"></i>
                                    </a>
                                    <a href='#' className='download-icon-wrap text-decoration-none'>
                                        <i class="bi bi-download"></i>
                                    </a>
                                    <a href='#' className='cross-icon-wrap text-decoration-none'>
                                        <i class="bi bi-x"></i>
                                    </a>
                                </div>
                            </td>
                        </tr> */}

                    </tbody>
                </table>
                {totalUpcoming > 0 &&
                    <CustomPagination
                        total={totalUpcoming}
                        onPageChange={(page, perpage) => {
                            get_user_upcomming_booking_lists(dispatch, vendor_id, user?.token, page, perpage);
                        }}
                    />}
            </div>
            {/* ====End=== */}
            <Cancel
                show={isCancel}
                handleClose={() => {
                    setData(null);
                    setIsCancel(!isCancel);
                }}
                content1={`Are you sure you want to cancel the booking?`}
                //content2={"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text."}
                handleCancel={handleBooking}
            />
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        user: state.auth.user,
        vendor_id: state.auth.vendor_id,
        userDetails: state.auth.userDetails,
        totalBookings: state.bookings.totalBookings,
        upcomingBookings: state.bookings.upcomingBookings,
        totalUpcoming: state.bookings.totalUpcoming
    }
};
export default connect(mapStateToProps)(UserHome);