import React, { useEffect, useRef, useState } from 'react'
import { get_vender_id } from '../../reduxStore/User/userActions';
import { getCountryCallingCode, isValidPhoneNumber } from 'react-phone-number-input';
import moment from 'moment-timezone';
import { connect, useDispatch } from 'react-redux';
import { admin_create_agency, admin_edit_agency, edit_user_by_admin, get_all_medium_agencies, get_all_small_agencies, get_user_details } from '../../reduxStore/vendors/vendorActions';
import { useToast } from '@chakra-ui/react';
import PhoneNumber from '../../shared/PhoneNumber';
import { Button, Form } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';
import LogoImg from '../../assets/dummy-image.jpg';
import debounce from 'lodash.debounce';
const { REACT_APP_OTA_URL } = process.env;
const Agency = ({ user, vendor_id }) => {
    const agencyId = JSON.parse(localStorage.getItem("agencyData"));
    const [formdata, setFormdata] = useState({
        // username: '',
        // dob: '',
        name: '',
        email: '',
        // password: '',
        phone_number: '',
        address: '',
        role: '',
        city: '',
        state: '',
        zip_code: '',
        country: '',
        advanceAmount: '',
        receipt: '',
        comissionType: '',
        comission: ''
    });
    const [errors, setErrors] = useState({
        // username: '',
        // dob: '',
        name: '',
        email: '',
        // password: '',
        phone_number: '',
        address: '',
        role: '',
        city: '',
        state: '',
        zip_code: '',
        country: '',
        advanceAmount: '',
        receipt: '',
        // comissionType: '',
        comission: ''
    });
    const fileRef = useRef();
    const handleUpload = () => { fileRef.current.click(); };
    const toast = useToast();
    const [country, setCountry] = useState('US');
    const [isShow, setIsShow] = useState(false);
    let countrycode = getCountryCallingCode(country || 'US');
    let checkval = `+${countrycode}` + formdata.phone_number;
    const exptest = /^(?!.*\.\.)(?!.*-@)(?!.*#)([A-Z0-9._%+-]+)@([A-Z0-9.-]+\.[A-Z]{2,})$/i;
    const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const maxDate = moment.tz(userTimeZone).format('YYYY-MM-DD');
    const roleoptions = [
        { name: 'Agency Type 1', label: 'smallAgency' },
        { name: 'Agency Type 2', label: 'mediumAgency' }
    ];
    const commisionoptions = [
        { name: 'Percentage (%)', label: '%' }
    ];
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const [receiptPreview, setReceiptPreview] = useState(null);
    const handleChange = (label, value) => {

        switch (label) {
            case "advanceAmount":
                setFormdata((prev) => ({ ...prev, [label]: value }));
                setErrors((prev) => ({ ...prev, [label]: value === "" ? "Advance amount is required" : null }));
                break;
            // case "username":
            //     setFormdata((prev) => ({ ...prev, [label]: value }));
            //     setErrors((prev) => ({ ...prev, [label]: value === "" ? "Username is required" : null }));
            //     break;
            case "name":
                setFormdata((prev) => ({ ...prev, [label]: value }));
                setErrors((prev) => ({ ...prev, [label]: value === "" ? "Name is required" : null }));
                break;
            case "email":
                setFormdata((prev) => ({ ...prev, [label]: value }));
                setErrors((prev) => ({ ...prev, [label]: value === "" ? "Email is required" : !exptest.test(value) ? "Invalid email" : null }));
                break;
            // case "dob":
            //     setErrors({ ...errors, dob: value === "" ? "DOB is required" : null });
            //     setFormdata({ ...formdata, [label]: value });
            //     break;
            // case "password":
            //     setFormdata((prev) => ({ ...prev, [label]: value }));
            //     setErrors((prev) => ({ ...prev, [label]: value === "" ? "Password is required" : value.length <= 5 ? "Password should contain more than 5 characters" : null }));
            //     break;
            case "phone_number":
                let checkval = `+${countrycode}` + value;
                setFormdata((prev) => ({ ...prev, [label]: value }));
                setErrors((prev) => ({ ...prev, [label]: !isValidPhoneNumber(checkval) ? "Invalid Phone number" : null }));
                break;
            case "address":
                setFormdata((prev) => ({ ...prev, [label]: value }));
                setErrors((prev) => ({ ...prev, [label]: value === "" ? "Address is required" : null }));
                break;
            case "role":
                setFormdata((prev) => ({ ...prev, [label]: value }));
                setErrors((prev) => ({ ...prev, [label]: value === "" ? "Role is required" : null }));
                break;
            case "city":
                setFormdata((prev) => ({ ...prev, [label]: value }));
                setErrors((prev) => ({ ...prev, [label]: value === "" ? "City is required" : null }));
                break;
            case "state":
                setFormdata((prev) => ({ ...prev, [label]: value }));
                setErrors((prev) => ({ ...prev, [label]: value === "" ? "State is required" : null }));
                break;
            case "zip_code":
                setFormdata((prev) => ({ ...prev, [label]: value }));
                setErrors((prev) => ({ ...prev, [label]: value === "" ? "Zip Code is required" : value.length < 5 ? "Invalid ZipCode" : null }));
                break;
            case "country":
                setFormdata((prev) => ({ ...prev, [label]: value }));
                setErrors((prev) => ({ ...prev, [label]: value === "" ? "Country is required" : null }));
                break;
            case "comissionType":
                setFormdata((prev) => ({ ...prev, [label]: value }));
                setErrors((prev) => ({ ...prev, comission: "Commision Amount is required" }));
                break;
            case "comission":
                setFormdata((prev) => ({ ...prev, [label]: value }));
                setErrors((prev) => ({ ...prev, [label]: value === "" ? "Commision Amount is required" : null }));
                break;
            default:
                setFormdata((prev) => ({ ...prev, [label]: value }));
                break;
        }
    };

    const handleReceipt = (val) => {
        setFormdata((prev) => ({ ...prev, receipt: val }));
        setErrors((prev) => ({ ...prev, receipt: null }));
        const file = val;
        if (file) {
            const reader = new FileReader();
            reader.onload = () => {
                setReceiptPreview(reader.result);
            };
            reader.readAsDataURL(file);
        }
    };


    const handleValid = () => {
        let err = false;

        const Errors = {
            advanceAmount: formdata.advanceAmount === "" ? "Advance Amount is required" : null,
            receipt: formdata.receipt === "" ? "Upload the Receipt" : null,
            // username: formdata.username === "" ? "Username is required" : null,
            address: formdata.address === "" ? "Address is required" : null,
            name: formdata.name === "" ? "Name is required" : null,
            email: formdata.email === "" ? "Email is required" : !exptest.test(formdata.email) ? "Invalid email" : null,
            // dob: formdata.dob === "" ? "DOB is required" : null,
            // password: formdata.password === "" ? "Password is required" : formdata.password.length <= 5 ? "Password should contain more than 5 characters" : null,
            phone_number: formdata.phone_number === "" ? "Phone number is required" : !isValidPhoneNumber(checkval) ? "Invalid Phone number" : null,
            role: formdata.role === "" ? "Role is required" : null,
            city: formdata.city === "" ? "City is required" : null,
            state: formdata.state === "" ? "State is required" : null,
            zip_code: formdata.zip_code === "" ? "Zip Code is required" : formdata?.zip_code?.length < 5 ? "Invalid ZipCode" : null,
            country: formdata.country === "" ? "Country is required" : null,
            // comissionType: formdata.comissionType === "" ? "Commision Type is required" : null,
            comission: (formdata.comission === "" && formdata.comissionType === "") ? "Commision Type and Amount is required" :
                (formdata.comission === "" && formdata.comissionType !== "") ? "Commision Amount is required" :
                    (formdata.comission !== "" && formdata.comissionType === "") ? "Commision Type is required" :
                        null
        };

        let newErrors = Object.entries(Errors);
        // console.log("errrrrrr", newErrors);
        newErrors.forEach(([key, value]) => {
            if (value) {
                err = true;
                setErrors((prev) => ({ ...prev, [key]: value }));
            }
        });

        return err;
    };

    const handleReset = () => {
        setFormdata({ advanceAmount: '', receipt: '', name: '', email: '', phone_number: '', address: '', role: '', city: '', state: '', zip_code: '', country: '', });
        setErrors({ advanceAmount: null, receipt: null, name: null, email: null, phone_number: null, address: null, role: null, city: null, state: null, zip_code: null, country: null, });
        setCountry('US');
        navigate("/agencies");
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (handleValid()) return;
        const dataform = new FormData();
        dataform.append('name', formdata?.name);
        dataform.append('email', formdata?.email);
        dataform.append('phone_number', Number(formdata?.phone_number));
        dataform.append('address', formdata?.address);
        dataform.append('role', formdata?.role);
        dataform.append('city', formdata?.city);
        dataform.append('state', formdata?.state);
        dataform.append('zip_code', formdata?.zip_code);
        dataform.append('country', formdata?.country);
        dataform.append('advanceAmount', formdata?.advanceAmount);
        dataform.append('receipt', formdata?.receipt);
        dataform.append('country_code', `+${countrycode}`);
        dataform.append('country_code_name', country);
        dataform.append('comissionType', formdata?.comissionType);
        dataform.append('comission', formdata?.comission);
        if (agencyId) {
            dataform.append('user_id', agencyId);
            dataform.append('type', 'agency');
        }
        //console.log("Agency Data----->", userdata);

        let create_agency = agencyId ? await admin_edit_agency(dispatch, vendor_id, user?.token, dataform, toast) : await admin_create_agency(vendor_id, user?.token, dataform, toast);

        if (create_agency) {
            await get_all_small_agencies(user?.token, dispatch, vendor_id);
            await get_all_medium_agencies(user?.token, dispatch, vendor_id);
            handleReset();
        }
    };

    useEffect(() => {
        const handleUpdate = debounce(async () => {
            await get_vender_id("superadmin", dispatch);
        }, 1000);
        handleUpdate();
        return () => {
            handleUpdate.cancel();
        };
    }, []);

    useEffect(() => {
        if (location.pathname !== '/edit-agency') {
            localStorage.removeItem("agencyData");
        }
    }, [location.pathname]);

    const formatDate = (inputdate) => {
        const newDate = new Date(inputdate);
        return newDate.toISOString().split('T')[0];
    };

    useEffect(() => {

        if (!vendor_id) return;

        if (!agencyId) return;

        const fetchDetails = debounce(async () => {
            let data = await get_user_details(dispatch, vendor_id, user?.token, agencyId, "agency");
            if (data) {
                setFormdata((prev) => ({
                    ...prev,
                    role: data?.role,
                    advanceAmount: data?.advanceAmount ? data?.advanceAmount : '',
                    receipt: data?.receipt ? data?.receipt : '',
                    // dob: data?.dob ? formatDate(data?.dob) : '',
                    name: data?.name ? data?.name : '',
                    email: data?.email ? data?.email : '',
                    phone_number: data?.phone_number ? data?.phone_number : '',
                    city: data?.city ? data?.city : '',
                    state: data?.state ? data?.state : '',
                    country: data?.country ? data?.country : '',
                    zip_code: data?.zip_code ? data?.zip_code : '',
                    address: data?.address ? data?.address : '',
                    comissionType: data?.comissionType ? data?.comissionType : '',
                    comission: data?.comission ? data?.comission : ''
                }));
                setCountry(data?.country_code_name);
                setReceiptPreview(data?.receipt ? REACT_APP_OTA_URL + data?.receipt : receiptPreview);
            }
        }, 1000);
        fetchDetails();
        return () => {
            fetchDetails.cancel();
        };
    }, [vendor_id]);

    useEffect(() => {
        if (formdata.phone_number === '') return;
        handleChange('phone_number', formdata?.phone_number);
    }, [country]);

    return (
        <div>
            <div class="modal-header border-0 cross-icon p-0 col-md-12 d-flex gap-3 justify-content-space-between mb-3">
                <button type="button" className='w-auto text-purple'
                    onClick={() => {
                        setFormdata({ username: '', email: '', phone_number: '', address: '' });
                        setErrors({ username: null, email: null, phone_number: null, address: null });
                        navigate("/agencies");
                    }}
                > <i class="bi bi-arrow-left h4 mb-0"></i></button>
                <h1 className='text-color-theme mb-0'>{agencyId ? 'Edit' : 'Add'} Agency</h1>
            </div>
            <Form onSubmit={(e) => handleSubmit(e)}>
                <div className='box-with-shadow-bg-wrap'>
                    <div className='container-fluid px-0'>
                        <div className='row'>
                            <div className='col-md-6'>
                                <div className='form-group mb-3'>
                                    <label className='form-label'>Role: </label>
                                    <select
                                        name='role'
                                        type="select"
                                        className="form-select form-control"
                                        placeholder="Select role"
                                        value={formdata.role}
                                        onChange={(e) => handleChange('role', e.target.value)}
                                    >
                                        <option value="" disabled>Select</option>
                                        {roleoptions.map((item, index) => (
                                            <option key={index} value={item.label}>{item.name}</option>
                                        ))}
                                    </select>
                                    {errors.role && <p className='text-danger'>{errors.role}</p>}
                                </div>
                            </div>


                            {/* <div className='col-md-6'>
                                    <label className='form-label'>Username: </label>
                                    <input
                                        type='text'
                                        className='form-control'
                                        placeholder='username'
                                        value={formdata.username}
                                        onChange={(e) => handleChange('username', e.target.value)}
                                    />
                                    {errors.username && <p className='text-danger'>{errors.username}</p>}
                                </div> */}
                            {/* <div className='col-md-6'>
                            <div className='form-group mb-3'>
                                <label className='form-label'>DOB: </label>
                                <input
                                    type='date'
                                    className='form-control'
                                    placeholder=''
                                    defaultValue={formdata?.dob}
                                    max={maxDate}
                                    onChange={(e) => handleChange('dob', e.target.value)}
                                />
                                {errors.dob && (
                                    <p className="d-flex flex-start text-danger error-msg mb-1 mb-md-0">
                                        {errors.dob}
                                    </p>
                                )}
                            </div>
                        </div> */}
                            <div className='col-md-6'>
                                <div className='form-group mb-3'>
                                    <label className='form-label'>Name: </label>
                                    <input
                                        type='text'
                                        className='form-control'
                                        placeholder='name'
                                        value={formdata.name}
                                        onChange={(e) => handleChange('name', e.target.value)}
                                    />
                                    {errors.name && <p className='text-danger'>{errors.name}</p>}
                                </div>

                                {/* <div className='col-md-10'>
                                    <label className='form-label'>Password: </label>
                                    <input
                                        type={isShow ? 'text' : 'password'}
                                        className='form-control'
                                        placeholder='******'
                                        onChange={(e) => handleChange('password', e.target.value)}
                                        onKeyDown={(e) => e.key === " " && e.preventDefault()}
                                    />
                                    {!isShow ? (
                                        <i class="bi bi-eye-slash"
                                            color="black"
                                            size={20}
                                            onClick={() => setIsShow(!isShow)}
                                        />
                                    ) : (
                                        <i class="bi bi-eye"
                                            color="black"
                                            size={20}
                                            onClick={() => setIsShow(!isShow)}
                                        />
                                    )}
                                    {errors.password && <p className='text-danger'>{errors.password}</p>}
                                </div> */}
                            </div>
                            <div className='col-md-6'>
                                <div className='form-group mb-3'>
                                    <label className='form-label'>Email: </label>
                                    <input
                                        type='email'
                                        className='form-control'
                                        placeholder='email'
                                        value={formdata.email}
                                        onChange={(e) => handleChange('email', e.target.value)}
                                    />
                                    {errors.email && <p className='text-danger'>{errors.email}</p>}
                                </div>
                            </div>
                            <div className='col-md-6'>
                                <div className='form-group mb-3'>
                                    <label className='form-label'>Phone Number: </label>
                                    <PhoneNumber
                                        country={country}
                                        setCountry={(val) => setCountry(val)}
                                        phoneNumber={formdata.phone_number}
                                        handleChange={(val) => handleChange('phone_number', val)}
                                    />
                                    {errors.phone_number && <p className='text-danger'>{errors.phone_number}</p>}
                                </div>
                            </div>

                            <div className='col-md-6'>
                                <div className='form-group mb-3'>
                                    <label className='form-label'>City: </label>
                                    <input
                                        type='text'
                                        className='form-control'
                                        placeholder='city'
                                        value={formdata.city}
                                        onChange={(e) => handleChange('city', e.target.value)}
                                    />
                                    {errors.city && <p className='text-danger'>{errors.city}</p>}
                                </div>
                            </div>

                            <div className='col-md-6'>
                                <div className='form-group mb-3'>
                                    <label className='form-label'>State: </label>
                                    <input
                                        type='text'
                                        className='form-control'
                                        placeholder='state'
                                        value={formdata.state}
                                        onChange={(e) => handleChange('state', e.target.value)}
                                    />
                                    {errors.state && <p className='text-danger'>{errors.state}</p>}
                                </div>
                            </div>

                            <div className='col-md-6'>
                                <div className='form-group mb-3'>
                                    <label className='form-label'>Country: </label>
                                    <input
                                        type='text'
                                        className='form-control'
                                        placeholder='country'
                                        value={formdata.country}
                                        onChange={(e) => handleChange('country', e.target.value)}
                                    />
                                    {errors.country && <p className='text-danger'>{errors.country}</p>}
                                </div>
                            </div>

                            <div className='col-md-6'>
                                <div className='form-group mb-3'>
                                    <label className='form-label'>Zip Code: </label>
                                    <input
                                        type='number'
                                        className='form-control'
                                        placeholder='zip code'
                                        value={formdata.zip_code}
                                        onChange={(e) => handleChange('zip_code', e.target.value)}
                                    />
                                    {errors.zip_code && <p className='text-danger'>{errors.zip_code}</p>}
                                </div>
                            </div>

                            <div className='col-md-6'>
                                <div className='form-group mb-3'>
                                    <label className='form-label'>Address: </label>
                                    <textarea
                                        minLength={4}
                                        className='form-control'
                                        placeholder='address details'
                                        value={formdata.address}
                                        onChange={(e) => handleChange('address', e.target.value)}
                                    />
                                    {errors.address && <p className='text-danger'>{errors.address}</p>}
                                </div>
                            </div>
                            <div className='col-md-6'>
                                <div className='form-group mb-3'>
                                    <label className='form-label'>Commision: </label>
                                    <div className='d-flex gap-1'>
                                        <select
                                            name='role'
                                            type="select"
                                            className="form-select form-control"
                                            placeholder="Select type"
                                            value={formdata.comissionType}
                                            onChange={(e) => handleChange('comissionType', e.target.value)}
                                            style={{ width: "25%" }}
                                        >
                                            <option value="" disabled>Select</option>
                                            {commisionoptions.map((item, index) => (
                                                <option key={index} value={item.label}>{item.name}</option>
                                            ))}
                                        </select>
                                        <input
                                            type='number'
                                            className='form-control'
                                            placeholder='commision amount'
                                            value={formdata.comission}
                                            onChange={(e) => handleChange('comission', e.target.value)}
                                        />
                                    </div>
                                    {errors.comission && <p className='text-danger'>{errors.comission}</p>}
                                </div>
                            </div>
                        </div>
                        <div className='col-md-12'>
                            <h5>Downpayment</h5>
                            <div className='row'>
                                <div className='col-md-6'>
                                    <div className='form-group mb-3'>
                                        <label className='form-label'>Advance Amount: </label>
                                        <input
                                            type='number'
                                            className='form-control'
                                            placeholder='advance amount'
                                            value={formdata.advanceAmount}
                                            onChange={(e) => handleChange('advanceAmount', e.target.value)}
                                        />
                                        {errors.advanceAmount && <p className='text-danger'>{errors.advanceAmount}</p>}
                                    </div>
                                </div>

                                <div className='col-md-6'>
                                    <div className='d-flex flex-column'>
                                        <label className='form-label'>
                                            Reciept :
                                        </label>
                                        <input
                                            type='file'
                                            className='form-control d-none'
                                            // value={formdata.advanceAmount}
                                            accept='.jpeg,.jpg,.png,.pdf'
                                            onChange={(e) => {
                                                const files = e.target.files[0];
                                                handleReceipt(files);
                                                e.target.value = "";
                                            }}
                                            ref={fileRef}
                                        />
                                    </div>
                                    <div className='d-flex align-items-center justify-content-center'>
                                        <img src={receiptPreview || LogoImg} height="200" width="150" alt="Receipt" className='border rounded' />
                                        <Button type='button' className='form-control bg-white text-center w-50 ms-4' style={{ color: '#696969' }} onClick={handleUpload} >
                                            <i class="bi bi-upload"></i> {agencyId ? 'Change' : 'Upload'}
                                        </Button>
                                    </div>
                                    {errors.receipt && <p className='text-danger'>{errors.receipt}</p>}
                                </div>
                            </div>
                        </div>
                        <div className='col-md-12 d-flex gap-2 justify-content-end mt-3'>
                            <button type='submit' className='btn-gradientlg-purple w-auto'>{agencyId ? 'Edit' : 'Add'} Agency</button>
                            <button type='button' className='disabled-btn w-auto' onClick={handleReset}>Cancel</button>
                        </div>
                    </div>
                </div>
            </Form>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        user: state.auth.user,
        vendor_id: state.auth.vendor_id
    }
}
export default connect(mapStateToProps)(Agency);