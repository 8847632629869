import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { start_loading, stop_loading } from '../reduxStore/loader/loaderActions';
import axios from 'axios';
import nationalties from '../locales/nationalities.json';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';

const GuestForm = ({ show, handleClose, currIndex, setUsers, guestData, resetGuest }) => {
    const { t } = useTranslation();
    const guestLists = JSON.parse(localStorage.getItem("guestlists"));
    const currentTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const [holderData, setHolderData] = useState({
        Name: '',
        Surname: '',
        Gender: '',
        Age: '',
        Email: '',
        Nationality: nationalties[currentTimeZone]
    });
    const [errors, setErrors] = useState({
        Name: '',
        Surname: '',
        Gender: '',
        Age: '',
        Email: '',
        Nationality: ''
    });
    const exptest = /^(?!.*\.\.)(?!.*-@)(?!.*#)([A-Z0-9._%+-]+)@([A-Z0-9.-]+\.[A-Z]{2,})$/i;
    const totalOccupancies = JSON.parse(localStorage.getItem("totalOccupancies"));
    const dispatch = useDispatch();
    const [lists, setLists] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            // dispatch(start_loading());
            try {
                const data = await axios.get('https://restcountries.com/v3.1/all');
                if (!data) return;

                const formatedData = data?.data?.map((item) => ({
                    nationality: item?.cca2,
                    flag: item?.flag,
                    countryname: item?.name?.common
                }));
                setLists(formatedData);
            } catch (error) {
                console.log("Error in Nation Lists", error);
            }
            //  finally {
            //     dispatch(stop_loading());
            // }
        };
        fetchData();
    }, [show]);

    const handleChange = (value, label) => {
        const validations = {
            Name: value === "" ? `${t('first_valid')}` : null,
            Surname: value === "" ? `${t('last_valid')}` : null,
            Gender: value === "" ? `${t('gender_valid')}` : null,
            Age: value === "" ? `${t('age_valid')}` : totalOccupancies?.totalChild === 0 && Number(value) < 18 ? `${t('age_valid_check')}` : null,
            Email: value !== "" && !exptest.test(value) ? `${t('email_check')}` : null,
            Nationality: value === "" ? "Nationality is required" : null,
        };

        setHolderData((prev) => ({ ...prev, [label]: value }));
        setErrors((prev) => ({ ...prev, [label]: validations[label] || null }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        const validations = {
            Name: holderData?.Name === "" ? `${t('first_valid')}` : null,
            Surname: holderData?.Surname === "" ? `${t('last_valid')}` : null,
            Gender: holderData?.Gender === "" ? `${t('gender_valid')}` : null,
            Age: holderData?.Age === "" ? `${t('age_valid')}` : totalOccupancies?.totalChild === 0 && Number(holderData?.Age) < 18 ? `${t('age_valid_check')}` : null,
            Email:
                // holderData?.Email === "" ? `${t('email_valid')}` : 
                holderData?.Email !== "" && !exptest.test(holderData?.Email) ? `${t('email_check')}` : null,
            Nationality: holderData?.Nationality === "" ? "Nationality is required" : null,
        };
        setErrors(validations);

        const filterErr = Object.values(validations).filter((item) => item !== null);
        if (filterErr?.length !== 0) return;

        if (guestData) {
            const data = guestLists.map((user) => user.id === guestData?.id ? { ...user, ...holderData } : user);
            localStorage.setItem("guestlists", JSON.stringify(data));
            setUsers(data);
            resetGuest();
            handleClose();
        }

        if (!guestData) {
            const data = guestLists !== null ? [...guestLists, { id: uuidv4(), ...holderData, }] : [{ id: uuidv4(), ...holderData, }];
            localStorage.setItem("guestlists", JSON.stringify(data));
            setUsers(data);
            handleClose();
        }
    };

    // console.log(guestLists);

    // useEffect(() => {
    //     if (guestLists === null) return;

    //     const data = guestLists?.find((item) => item?.guestindex == currIndex);
    //     // console.log(data);
    //     setHolderData((prev) => ({
    //         ...prev,
    //         Name: '',
    //         Surname: '',
    //         Gender: '',
    //         Age: '',
    //         Email: '',
    //         Nationality: nationalties[currentTimeZone]
    //     }));
    // }, [show, currIndex]);

    useEffect(() => {
        if (!guestData) return;

        setHolderData((prev) => ({
            ...prev,
            Name: guestData?.Name,
            Surname: guestData?.Surname,
            Gender: guestData?.Gender,
            Age: guestData?.Age,
            Email: guestData?.Email,
            Nationality: nationalties[currentTimeZone]
        }));
    }, []);



    return (
        <div className='pt-5'>
            <div class="modal-header border-0 cross-icon p-0 align-items-center">
                <h2 className='mb-3'>{guestData ? t('edit_person_detail') : t('add_person_detail')}</h2>
                {/* <button type="button" class="btn-close" onClick={handleClose}></button> */}
            </div>
            <div class="modal-body border-0 modal-slider-view p-0">
                <form className='left-fill-details' onSubmit={(e) => handleSubmit(e)}>
                    <div className='container-fluid px-0'>
                        <div className='row'>
                            <div className='col-lg-6 col-md-12 col-sm-6'>
                                <div className='form-group form-spacing'>
                                    <label className='form-label p-lg text-grey-light'>{t('first_name')}</label>
                                    <input type="text" className='form-control' placeholder={`${t('first_label')}`} value={holderData?.Name} onChange={(e) => handleChange(e.target.value, 'Name')} />
                                    {errors.Name && <p className='text-danger'>{errors.Name}</p>}
                                </div>
                            </div>
                            <div className='col-lg-6 col-md-12 col-sm-6'>
                                <div className='form-group form-spacing'>
                                    <label className='form-label p-lg text-grey-light'>{t('last_name')}</label>
                                    <input type="text" className='form-control' placeholder={`${t('last_label')}`} value={holderData?.Surname} onChange={(e) => handleChange(e.target.value, 'Surname')} />
                                    {errors.Surname && <p className='text-danger'>{errors.Surname}</p>}
                                </div>
                            </div>
                            <div className='col-lg-6 col-md-12 col-sm-6'>
                                <div className='form-group form-spacing'>
                                    <label className='form-label p-lg text-grey-light'>{t('gender')}</label>
                                    <select className='text-grey-light w-100 form-select form-control' value={holderData?.Gender} onChange={(e) => handleChange(e.target.value, 'Gender')}>
                                        <option value="" disabled>{t('gender_placeholder')}</option>
                                        <option value="Female">Female</option>
                                        <option value="Male">Male</option>
                                        <option value="Transgender">Transgender</option>
                                    </select>
                                    {errors.Gender && <p className='text-danger'>{errors.Gender}</p>}
                                </div>
                            </div>

                            <div className='col-lg-6 col-md-12 col-sm-6'>
                                <div className='form-group form-spacing'>
                                    <label className='form-label p-lg text-grey-light'>{t('age')}</label>
                                    <input
                                        type="text"
                                        className='form-control'
                                        placeholder={`${t('age')}`}
                                        value={holderData?.Age}
                                        pattern="\d*"
                                        onChange={(e) => handleChange(e.target.value, 'Age')}
                                        onKeyPress={(event) => {
                                            if (!/^\d+$/.test(event.key) && event.key !== 'Backspace') {
                                                event.preventDefault();
                                            }
                                        }}
                                    />
                                    {errors.Age && <p className='text-danger'>{errors.Age}</p>}
                                    {/* <select className='text-grey-light w-100 form-select form-control' value={holderData?.Age} onChange={(e) => handleChange(e.target.value, 'Age')}>
                                        <option selected>Select age</option>
                                        <option>Female</option>
                                        <option>Male</option>
                                    </select> */}
                                </div>
                            </div>


                            {/* <div className='col-md-4'>
                                <div className='form-group form-spacing'>
                                    <label className='form-label p-lg text-grey-light'>Phone Number</label>
                                    <select className='text-grey-light w-100 form-select form-control'>
                                        <option selected>+91</option>
                                        <option>Female</option>
                                        <option>Male</option>
                                    </select>
                                </div>
                            </div>
                            <div className='col-md-8'>
                                <div className='form-group form-spacing'>
                                    <label className='form-label p-lg text-grey-light'></label>
                                    <input type="number" className='form-control' placeholder='Enter your phone number' />
                                </div>
                            </div> */}

                            <div className='col-lg-6 col-md-12 col-sm-6'>
                                <div className='form-group form-spacing'>
                                    <label className='form-label p-lg text-grey-light'>{t('email_label')}</label>
                                    <input type="email" className='form-control' placeholder={`${t('email_placeholder')}`} value={holderData?.Email} onChange={(e) => handleChange(e.target.value, 'Email')} />
                                    {errors?.Email && <p className='text-danger'>{errors?.Email}</p>}
                                </div>
                            </div>

                            {/* <div className='col-md-12'>
                                        <div className='form-group form-spacing'>
                                            <label className='form-label p-lg text-grey-light'>Nationality</label>
                                            <select className='text-grey-light w-100 form-select form-control' value={holderData?.Nationality} onChange={(e) => handleChange(e.target.value, 'Nationality')}>
                                                <option value="" disabled>Select Nationality</option>
                                                {lists.map((item, index) => (
                                                    <option key={index} value={item?.nationality}>
                                                        {item?.countryname} ({item?.nationality})
                                                    </option>
                                                ))}
                                            </select>
                                            {errors?.Nationality && <p className='text-danger'>{errors?.Nationality}</p>}
                                            <input type="text" className='form-control' placeholder='Enter your nationality' />
                                        </div>
                                    </div> */}


                            {/* <div className='col-md-12'>
                                <div className='form-group form-spacing'>
                                    <label className='form-label p-lg text-grey-light'>Date of  Birth</label>
                                    <div className='d-grid d-grid-calender w-100'>
                                        <select className='text-grey-light w-100 form-select form-control'>
                                            <option selected>DD</option>
                                            <option>DD</option>
                                            <option>DD</option>
                                        </select>
                                        <select className='text-grey-light w-100 form-select form-control'>
                                            <option selected>MM</option>
                                            <option>MM</option>
                                            <option>MM</option>
                                        </select>
                                        <select className='text-grey-light w-100 form-select form-control'>
                                            <option selected>YYYY</option>
                                            <option>YYYY</option>
                                            <option>YYYY</option>
                                        </select>
                                    </div>
                                </div>
                            </div> */}
                        </div>
                        <button className='btn-gradient-purple w-auto' type='submit'>{guestData ? t('edit_person') : t('add_person')}</button>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default GuestForm