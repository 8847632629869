import React, { useEffect } from 'react'
import { connect, useDispatch } from 'react-redux'
import GreyHotelIcon from './../../assets/grey-hotel-icon.svg';
import { get_user_cashboack_lists, get_user_referral_lists, get_vender_id } from '../../reduxStore/User/userActions';
import CustomPagination from '../../shared/CustomPagination';
import { useTranslation } from 'react-i18next';

const MyCashbacks = ({ user, vendor_id, cashback_lists, totalcashbacks }) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();

    useEffect(() => {
        get_vender_id("superadmin", dispatch);
    }, []);

    useEffect(() => {
        if (!vendor_id) return;

        const fetchLists = async () => {
            await get_user_cashboack_lists(dispatch, vendor_id, user?.token, 1, 10);
        };
        fetchLists();
    }, [vendor_id]);

    const formatDate = (inputdate) => {
        const date = new Date(inputdate);

        // Get day, date, and month
        const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
        const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

        const day = days[date.getUTCDay()];
        const dayOfMonth = date.getUTCDate();
        const month = months[date.getUTCMonth()];
        const year = date.getUTCFullYear();
        
        return `${dayOfMonth} ${month} ${year}`;
    };

    return (
        <div>
            <div className='table-header-wrapper'>
                <h5 className='text-white mb-0'>{t('my_cashbacks')}</h5>
            </div>

            <div className='upcoming-list-table-wrapper'>
                <table class="table">
                    <thead>
                        <tr>
                            <th scope="col">#</th>
                            <th scope="col">{t('booking_date')}</th>
                            <th scope="col">{t('type')}</th>
                            {/* <th scope="col">{t('name')}</th> */}
                            <th scope="col">{t('amount')}</th>
                            <th scope="col">Cashback Earned</th>
                            {/* <th scope="col">{t('email')}</th>
                            <th scope="col">{t('phone_number')}</th>
                            <th scope="col">{t('referral_date')}</th> */}
                            {/* <th scope="col">{t('created_at')}</th> */}
                            {/* <th scope="col">{t('status')}</th> */}
                        </tr>
                    </thead>
                    <tbody>
                        {totalcashbacks > 0 && cashback_lists?.map((item, index) => (
                            <tr key={index}>
                                <th scope="row">{index + 1}</th>
                                <td>
                                    {formatDate(item?.createdAt)}
                                </td>
                                <td>
                                    <div className='d-flex align-items-center gap-1'>
                                        <img src={GreyHotelIcon} alt='GrayLocalTourIcon' />
                                        Hotel
                                    </div>
                                </td>
                                <td>
                                    {item?.total_amount}
                                </td>
                                <td>
                                    {item?.amount ? item?.amount : '-'}
                                </td>
                                {/* <td>
                                    <div className='status-label'>
                                        <span className='confirmed-label'>Pending</span>
                                    </div>
                                </td> */}
                            </tr>
                        ))}
                    </tbody>
                </table>

                {totalcashbacks > 0 &&
                    <CustomPagination
                        total={totalcashbacks}
                        onPageChange={(page, perpage) => {
                            get_user_referral_lists(dispatch, vendor_id, user?.token, page, perpage);
                        }}
                    />}
            </div>

        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        user: state.auth.user,
        vendor_id: state.auth.vendor_id,
        cashback_lists: state.auth.cashback_lists,
        totalcashbacks: state.auth.totalcashbacks
    }
}
export default connect(mapStateToProps)(MyCashbacks);