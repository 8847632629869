import React, { useEffect } from 'react'
import { connect, useDispatch } from 'react-redux'
import { get_user_referral_lists, get_vender_id } from '../../reduxStore/User/userActions';
import CustomPagination from '../../shared/CustomPagination';
import { useTranslation } from 'react-i18next';
import debounce from 'lodash.debounce';

const Referral = ({ user, vendor_id, referralLists, totalreferral }) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();

    useEffect(() => {
        const fetchId = debounce(async () => {
            await get_vender_id("superadmin", dispatch);
        }, 1000);
        fetchId();
        return () => {
            fetchId.cancel();
        };
    }, []);

    useEffect(() => {
        if (!vendor_id) return;

        const fetchLists = debounce(async () => {
            await get_user_referral_lists(dispatch, vendor_id, user?.token, 1, 10);
        }, 1000);
        fetchLists();
        return () => {
            fetchLists.cancel();
        };
    }, [vendor_id]);

    const formatDate = (inputdate) => {
        const date = new Date(inputdate);

        // Get day, date, and month
        const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
        const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

        const day = days[date.getUTCDay()];
        const dayOfMonth = date.getUTCDate();
        const month = months[date.getUTCMonth()];
        const year = date.getUTCFullYear();

        return `${dayOfMonth} ${month} ${year}`;
    };

    // console.log(referralLists);

    return (
        <div>
            <h1>Referral</h1>
            <div className='table-header-wrapper'>
                <h5 className='text-white mb-0'>Referral</h5>
            </div>

            <div className='upcoming-list-table-wrapper'>
                <table class="table">
                    <thead>
                        <tr>
                            <th scope="col">#</th>
                            <th scope="col">{t('name')}</th>
                            <th scope="col">{t('email')}</th>
                            <th scope="col">{t('phone_number')}</th>
                            <th scope="col">{t('referral_date')}</th>
                            {/* <th scope="col">{t('created_at')}</th> */}
                            <th scope="col">{t('status')}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {totalreferral > 0 && referralLists?.map((item, idx) => (
                            <tr key={idx}>
                                <th scope="row">{idx + 1}.</th>
                                <td>
                                    <div className='table-col-name-td text-capitalize'>
                                        {item?.user_Data?.name}
                                    </div>
                                </td>
                                <td>
                                    <div className='table-amount-col-data'>
                                        {item?.user_Data?.email ? `${item?.user_Data?.email}` : '-'}
                                    </div>
                                </td>
                                <td>
                                    <div className='add-review-disable'>
                                        {item?.user_Data?.phone_number ? `${item?.user_Data?.country_code}${' '}${item?.user_Data?.phone_number}` : '-'}
                                    </div>
                                </td>
                                <td>
                                    {formatDate(item?.referral_date)}
                                </td>
                                <td>
                                    <div className='status-label'>
                                        <span className={`${(item?.status === 0) || !item?.status ? 'processing-label' : 'confirmed-label'}`}>{(item?.status === 0) || !item?.status ? 'Pending' : 'Completed'}</span>
                                    </div>
                                </td>
                                {/* <td>
                                    <div className='action-icon-wrap d-flex align-items-center gap-2'>
                                        <span className='eye-icon-wrap text-decoration-none' onClick={() => {
                                            localStorage.setItem("hotelData", JSON.stringify(item));
                                            navigate('/dashboard/booking-detail');
                                        }}>
                                            <i class="bi bi-eye"></i>
                                        </span>
                                        <a href='#' className='download-icon-wrap text-decoration-none'>
                                            <i class="bi bi-download"></i>
                                        </a>
                                        <span className='cross-icon-wrap text-decoration-none cursor-pointer' onClick={() => {
                                            setData(item);
                                            setIsCancel(true);
                                        }}>
                                            <i class="bi bi-x"></i>
                                        </span>
                                    </div>
                                </td> */}
                            </tr>))}
                    </tbody>
                </table>
                {totalreferral > 0 &&
                    <CustomPagination
                        total={totalreferral}
                        onPageChange={(page, perpage) => {
                            get_user_referral_lists(dispatch, vendor_id, user?.token, page, perpage);
                        }}
                    />}
            </div>

        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        user: state.auth.user,
        vendor_id: state.auth.vendor_id,
        referralLists: state.auth.referralLists,
        totalreferral: state.auth.totalreferral
    }
}
export default connect(mapStateToProps)(Referral);