import { GET_ALL_ADMINS_LISTS, GET_ALL_PLANS, GET_ALL_USERS_LISTS, GET_CURRENCY, GET_DEFAULT_DATA, GET_EDIT_DATA, GET_LANGUAGE, GET_MY_CASHBACKS_LISTS, GET_MY_REFERRAL_LISTS, GET_USER_CARD_DETAILS, GET_USER_DETAILS, GET_USERS_TOTAL, GET_VENDOR_ID, IS_EDIT, LOGOUT, SET_USER_LOCATION, USER_UPDATE } from "./userTypes";

const initialState = {
    user: JSON.parse(localStorage.getItem("userData")) || null,
    userLocation: JSON.parse(localStorage.getItem("userLocation")) || null,
    isEdit: false,
    userDetails: null,
    allUsers: [],
    total_users: 0,
    allAdmins: [],
    editData: null,
    allPlans: [],
    vendor_id: null,
    currency: 'USD',
    language: 'en',
    defaultData: '',
    userCardDetails: null,
    referralLists: [],
    totalreferral: 0,
    cashback_lists: [],
    totalcashbacks: 0
};

const userReducer = (state = initialState, action) => {
    switch (action.type) {
        case USER_UPDATE:
            return {
                ...state,
                user: action.payload,
            };
        case LOGOUT:
            return {
                ...state,
                user: null,
            };
        case IS_EDIT:
            return {
                ...state,
                isEdit: action.payload,
            };
        case GET_USER_DETAILS:
            return {
                ...state,
                userDetails: action.payload,
            };
        case GET_ALL_USERS_LISTS:
            return {
                ...state,
                allUsers: action.payload,
            };
        case GET_USERS_TOTAL:
            return {
                ...state,
                total_users: action.payload,
            };
        case GET_ALL_ADMINS_LISTS:
            return {
                ...state,
                allAdmins: action.payload,
            };
        case GET_EDIT_DATA:
            return {
                ...state,
                editData: action.payload,
            };
        case GET_ALL_PLANS:
            return {
                ...state,
                allPlans: action.payload,
            };
        case GET_VENDOR_ID:
            return {
                ...state,
                vendor_id: action.payload,
            };
        case GET_CURRENCY:
            return {
                ...state,
                currency: action.payload,
            };
        case GET_LANGUAGE:
            return {
                ...state,
                language: action.payload,
            };
        case GET_DEFAULT_DATA:
            return {
                ...state,
                defaultData: action.payload,
            };
        case GET_USER_CARD_DETAILS:
            return {
                ...state,
                userCardDetails: action.payload,
            };
        case GET_MY_REFERRAL_LISTS:
            return {
                ...state,
                referralLists: action.payload.data,
                totalreferral: action.payload.length
            };
        case GET_MY_CASHBACKS_LISTS:
            return {
                ...state,
                cashback_lists: action.payload.data,
                totalcashbacks: action.payload.length
            };
        case SET_USER_LOCATION:
            return {
                ...state,
                userLocation: action.payload,
            };
        default:
            return state;
    }
};

export default userReducer;