import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import store from './reduxStore/store';
import { ChakraProvider } from '@chakra-ui/react';
import { BrowserRouter as Router } from 'react-router-dom';
import './i18next';
import { GoogleOAuthProvider } from '@react-oauth/google';

const root = ReactDOM.createRoot(document.getElementById('root'));

const NEW_CLIENT_ID = "27278241152-6ntci27lgg4amq0qufnehupqua13s0g1.apps.googleusercontent.com";

root.render(
  // <React.StrictMode>
  <Provider store={store}>
    <ChakraProvider>
      <GoogleOAuthProvider clientId={NEW_CLIENT_ID}>
        <Router>
          <App />
        </Router>
      </GoogleOAuthProvider>
    </ChakraProvider>
  </Provider>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
