import React, { useState } from 'react'
import { Modal } from 'react-bootstrap';
import Payment from '../components/DashBoardPages/Payment';

const EditCard = ({isShow, handleClose}) => {
    return (
        <div>
            <Modal show={isShow} size='lg'>
                <Modal.Body className='p-4'>
                    <div class="modal-content position-relative">
                        <div class="modal-header border-0 cross-icon p-0 align-items-center mb-3">
                            <h1 className='mb-0 text-color-theme fw-bold'> Card Details </h1>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => { handleClose(); }}></button>
                        </div>
                        <Payment handleClose={handleClose} />
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default EditCard;