import React, { useEffect, useRef, useState } from 'react'
// import DatePicker from 'react-datepicker';
import { useLocation, useNavigate } from 'react-router-dom';
import SelectPopOver from '../../shared/SelectPopOver';
import { useToast } from '@chakra-ui/react';
import { connect, useDispatch } from 'react-redux';
import { after_booking, get_search_hotel_graphs, get_search_hotel_results } from '../../reduxStore/booking/bookingActions';
import { GET_CITY_DATA, GET_SEARCHED_DATA, GET_SEARCHED_GRAPH } from '../../reduxStore/booking/bookingTypes';
import SelectNation from '../../Modals/SelectNation';
import { start_loading, stop_loading } from '../../reduxStore/loader/loaderActions';
import { useTranslation } from 'react-i18next';
import nationalityData from '../../locales/nationalities.json';
import { DatePicker } from 'antd';
import moment from 'moment';
import dayjs from 'dayjs';
import debounce from 'lodash.debounce';

const { RangePicker } = DatePicker;

const HotelBar = ({ user, graphData, searchedData, modifyData, cityData, currency }) => {
    const currentTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const localSearch = JSON.parse(localStorage.getItem("searchedData"));
    const destinationDetail = JSON.parse(localStorage.getItem("Destinationdata"));
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();
    const toast = useToast();
    const { t } = useTranslation();
    const [isShow, setIsShow] = useState(false);
    const [isCalender, setIsCalender] = useState(false);
    const [locationData, setLocationData] = useState(null);
    const [searchVal, setSearchVal] = useState('');
    const [nation, setNation] = useState('');
    const [selectData, setSelectData] = useState(null);
    const [dateRange, setDateRange] = useState([]);
    const [pickerValue, setPickerValue] = useState([
        dayjs().startOf("month"),
        dayjs().endOf("month"),
    ]);
    // const minDate = dayjs().startOf("day");
    const minDate = dayjs().subtract(1, "day");

    const calenderRef = useRef();

    const handleCalendarChange = (values) => {
        if (values && values.length > 0) {
            setPickerValue(values); // Update the calendar view to the currently selected range
        }
    };
    // minDate.setDate(minDate.getDate() + 5);
    // const [dateRange, setDateRange] = useState([null, null]);
    // const [startDate, endDate] = dateRange;
    const [optionsData, setOptionsData] = useState({
        Room: 1,
        Adults: 1,
        Children: 0,
        // Pets: 1
    });
    const [childsAge, setChildsAge] = useState([]);

    const inputRef = useRef();
    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = () => {
        setAnchorEl(inputRef.current);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    const handleDateFormat = (dateStr) => {
        const date = dayjs(dateStr);
        return date;
    };

    const handleChange = async (value) => {
        setSearchVal(value);

        if (value.trim().length < 3) return;

        // const data = {
        //     query: "query GetCities($name: String!) { getCities(name: $name) { CityName destinationCode { GDS code } } }",
        //     variables: {
        //         name: value
        //     }
        // };

        const data = {
            query: "query GetCities($name: String!) { getCities(name: $name) { cityName destinationName countryCode countryName } }",
            variables: {
                name: value
            }
        };
        await get_search_hotel_graphs(dispatch, data);
    };

    const handleSearch = async () => {
        if (locationData === null) {
            toast({ title: `${t('destination_check')}`, status: "error", duration: 2000, position: "top-right", isClosable: true, });
            return;
        }

        if (dateRange?.length === 0 || dateRange?.length === 1) {
            toast({ title: `${t('date_select')}`, status: "error", duration: 2000, position: "top-right", isClosable: true, });
            return;
        }

        // if (nation === '') {
        //     setIsShow(true);
        //     return;
        // }

        if (childsAge?.length > 0 && childsAge?.find((item) => item?.age === '')) {
            setAnchorEl(inputRef.current);
            return;
        }

        if (modifyData !== null) {
            window.scrollTo({ top: 300, behavior: "smooth" });
            return;
        }

        const destination = selectData !== null ? { ...selectData } : destinationDetail;

        const data = {
            ...destination,
            CheckInDate: dateRange[0].format('YYYY-MM-DD'),
            CheckOutDate: dateRange[1].format('YYYY-MM-DD'),
            RoomCount: optionsData?.Room,
            RealTimeOccupancy: {
                AdultCount: optionsData?.Adults,
                ChildCount: optionsData?.Children,
                ChildAgeDetails: childsAge?.length > 0 ? childsAge?.map((item) => Number(item?.age)) : []
            },
            Nationality: nationalityData[currentTimeZone] ? nationalityData[currentTimeZone] : 'ES',
            Currency: currency
        };
        // console.log(data);
        localStorage.setItem("searchedData", JSON.stringify(data));

        let searched = await get_search_hotel_results(dispatch, data, toast);
        if (searched) {
            navigate('/hotel-results');
        }
    };

    const handleSelect = (val) => {
        setSearchVal(val?.cityName);
        setSelectData(val);
        setLocationData(val);
        localStorage.setItem("Destinationdata", JSON.stringify(val));
        dispatch({ type: GET_SEARCHED_GRAPH, payload: [] });
    };

    // useEffect(() => {

    //     if (searchedData !== null) return;

    //     const handleData = async () => {
    //         const data = {
    //             ...selectData,
    //             CheckInDate: new Date(startDate).toISOString().split('T')[0],
    //             CheckOutDate: new Date(endDate).toISOString().split('T')[0],
    //             RoomCount: optionsData?.Room,
    //             AdultCount: optionsData?.Adults,
    //             ChildCount: optionsData?.Children,
    //             Nationality: nation
    //         };
    //         // console.log(data);
    //         localStorage.setItem("searchedData", JSON.stringify(data));

    //         let searched = await get_search_hotel_results(dispatch, data);
    //         if (searched) {
    //             navigate('/hotel-results');
    //         }
    //     };
    //     handleData();
    // }, []);

    // console.log(searchedData);

    //top hotel auto searched
    // useEffect(() => {
    //     if (searchedData === null) return;

    //     // if (nation === '') return;

    //     const autoSearch = async () => {
    //         dispatch(start_loading());
    //         try {

    //             const currentDate = new Date();
    //             currentDate.setDate(currentDate.getDate() + 5);
    //             const nextDate = new Date(currentDate);
    //             nextDate.setDate(currentDate.getDate() + 1);

    //             const data = {
    //                 destinationCode: [
    //                     { GDS: searchedData?.Gds, code: searchedData?.DestinationID }
    //                 ],
    //                 CheckInDate: currentDate.toLocaleDateString('en-CA'),
    //                 CheckOutDate: nextDate.toLocaleDateString('en-CA'),
    //                 RoomCount: optionsData?.Room,
    //                 RealTimeOccupancy: {
    //                     AdultCount: optionsData?.Adults,
    //                     ChildCount: optionsData?.Children,
    //                     ChildAgeDetails: childsAge?.length > 0 ? childsAge?.map((item) => Number(item?.age)) : []
    //                 },
    //                 Nationality: nationalityData[currentTimeZone] ? nationalityData[currentTimeZone] : 'ES',
    //                 Currency: currency
    //             };
    //             //console.log("Datatatatatataattttttttt", data);
    //             localStorage.setItem("searchedData", JSON.stringify(data));

    //             let searched = await get_search_hotel_results(dispatch, data, toast);
    //             if (searched) {
    //                 navigate('/hotel-results');
    //             }
    //         } catch (error) {
    //             console.log("Errr", error);
    //         } finally {
    //             dispatch(stop_loading());
    //         }
    //     };
    //     autoSearch();

    // }, [searchedData]);

    //top city auto searched
    useEffect(() => {
        if (cityData === null) return;

        const autoSearch = async () => {
            dispatch(start_loading());

            try {
                const data = {
                    ...cityData?.details,
                    CheckInDate: dayjs().add(1, "day").startOf("day").format("YYYY-MM-DD"),
                    CheckOutDate: dayjs().add(2, "day").startOf("day").format("YYYY-MM-DD"),
                    RoomCount: optionsData?.Room,
                    RealTimeOccupancy: {
                        AdultCount: optionsData?.Adults,
                        ChildCount: optionsData?.Children,
                        ChildAgeDetails: childsAge?.length > 0 ? childsAge?.map((item) => Number(item?.age)) : []
                    },
                    Nationality: nationalityData[currentTimeZone] ? nationalityData[currentTimeZone] : 'ES',
                    Currency: currency
                };
                //console.log("Datatatatatataattttttttt", data);
                localStorage.setItem("searchedData", JSON.stringify(data));

                let searched = await get_search_hotel_results(dispatch, data, toast);
                if (searched) {
                    navigate('/hotel-results');
                }
            } catch (error) {
                console.log("Errr", error);
            } finally {
                dispatch(stop_loading());
            }
        };
        autoSearch();

    }, [cityData]);


    //top hotels data
    // useEffect(() => {
    //     if (searchedData !== null) {
    //         const currentDate = new Date();
    //         currentDate.setDate(currentDate.getDate() + 5);
    //         const nextDate = new Date(currentDate);
    //         nextDate.setDate(currentDate.getDate() + 1);

    //         setSearchVal(searchedData?.DestinationName);
    //         const range = [currentDate.toLocaleDateString('en-CA'), nextDate.toLocaleDateString('en-CA')];
    //         setDateRange(range);
    //         setSelectData({
    //             CityName: searchedData?.DestinationName,
    //             destinationCode: [{ GDS: searchedData?.Gds, code: searchedData?.DestinationID }]
    //         });
    //         window.scrollTo({ top: 0, behavior: "smooth" });
    //         // setIsShow(true);
    //     }
    // }, [searchedData]);

    //top city data
    useEffect(() => {
        if (cityData !== null) {

            setSearchVal(cityData?.details?.cityName);
            const range = [dayjs().add(1, "day").startOf("day"), dayjs().add(2, "day").endOf("day"),];
            setDateRange(range);
            setSelectData(cityData?.details);
            window.scrollTo({ top: 0, behavior: "smooth" });
            // setIsShow(true);
        }
    }, [cityData]);


    useEffect(() => {
        const handleConfirm = async () => {
            if (location.pathname === '/') {
                setSearchVal('');
                setNation('');
                setSelectData(null);
                setDateRange([]);
                setOptionsData({ Room: 1, Adults: 1, Children: 0 });
                dispatch({ type: GET_SEARCHED_GRAPH, payload: [] });
                dispatch({ type: GET_SEARCHED_DATA, payload: null });
                dispatch({ type: GET_CITY_DATA, payload: null });
                await after_booking();
            }
        };
        handleConfirm();
    }, [location.pathname]);

    // useEffect(() => {
    //     if (modifyData === null) return;

    //     setSearchVal(modifyData?.CityName);
    //     const range = [new Date(modifyData?.CheckInDate).toLocaleDateString('en-CA'), new Date(modifyData?.CheckOutDate).toLocaleDateString('en-CA')];
    //     setDateRange(range);
    // }, []);


    useEffect(() => {
        const fetchAutoSearch = debounce(async () => {
            if (location.pathname !== "/" && localSearch !== null && searchedData === null && cityData === null) {
                setSearchVal(localSearch?.destinationName);
                setLocationData(localSearch);
                setDateRange([handleDateFormat(localSearch?.CheckInDate), handleDateFormat(localSearch?.CheckOutDate)]);
                setPickerValue([handleDateFormat(localSearch?.CheckInDate), handleDateFormat(localSearch?.CheckOutDate)]);
                await get_search_hotel_results(dispatch, localSearch, toast);
            }
        }, 1000);
        fetchAutoSearch();
        return () => {
            fetchAutoSearch.cancel();
        };
    }, [location.pathname]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            const dropdownElement = document.querySelector(".ant-picker-dropdown");
            if (
                calenderRef.current &&
                !calenderRef.current.contains(event.target) &&
                (!dropdownElement || !dropdownElement.contains(event.target))
            ) {
                setIsCalender(false);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);


    return (
        <div>
            <form>
                <div class="booking-form-grid">
                    <div className="destination-box first-box item-one">
                        <img src="./images/location-icon.svg" className='d-inline-block' alt='' />
                        <div className='position-relative'>
                            <input
                                type="text"
                                className="form-control"
                                value={searchVal}
                                placeholder={t("search_destination")}
                                onChange={(e) => { handleChange(e.target.value); }}
                            />
                            {searchVal !== '' && <span className='display-inline-block pwd-eye-wrap'><i class="bi bi-x-circle-fill cursor-pointer" style={{ color: '#6D338A', fontSize: '14px' }} onClick={() => setSearchVal("")}></i></span>}
                            {searchVal && graphData?.length > 0 && (
                                <ul
                                    className="dropdown-options search-location-options"
                                    style={{
                                        position: "absolute",
                                        top: "100%",
                                        left: 0,
                                        width: "100%",
                                        maxHeight: "250px",
                                        overflowY: "auto",
                                        backgroundColor: "#fff",
                                        border: "1px solid #ccc",
                                        listStyle: "none",
                                        margin: 0,
                                        padding: "5px 0",
                                        zIndex: 1,
                                        color: "white"
                                    }}
                                >
                                    {graphData?.map((option, index) => (
                                        <li
                                            key={index}
                                            onClick={() => handleSelect(option)}
                                            style={{
                                                padding: "10px",
                                                cursor: "pointer",
                                                backgroundColor: "#fff",
                                                color: "#333333",
                                            }}
                                            onMouseEnter={(e) => (e.target.style.backgroundColor = "#fff")}
                                            onMouseLeave={(e) => (e.target.style.backgroundColor = "#fff")}
                                        >
                                            <i class="bi bi-geo-alt-fill text-purple h5 mb-0"></i>
                                            <div className='country-city-name'>
                                                <h6 className=''>{option?.cityName}</h6>
                                                <p className='p-md mb-0'>{option?.countryName && option?.countryName}</p>
                                            </div>
                                        </li>
                                    ))}
                                </ul>
                            )}
                        </div>

                    </div>
                    <div className="destination-box second-box marrgin-left item-two" ref={calenderRef}>
                        {/* <input type="date" className="form-control"></input> */}
                        <img
                            src="./images/calendar-event.svg"
                            className='calendar-icon'
                            alt=''
                            onClick={() => {
                                setIsCalender(true);
                            }}
                        />
                        {/* <DatePicker
                            className='form-control'
                            showIcon
                            selected={startDate}
                            onChange={(update) => setDateRange(update)}
                            startDate={startDate}
                            endDate={endDate}
                            selectsRange
                            minDate={new Date().setDate(new Date().getDate() + 5)}
                            placeholderText={t("date_placeholder")}
                            isClearable
                        /> */}
                        <RangePicker
                            open={isCalender}
                            placeholder={t("date_placeholder")}
                            style={{ padding: "15px 25px 15px 50px" }}
                            value={dateRange}
                            onChange={(values) => {
                                setDateRange(values);
                                if (values && values.length === 2) {
                                    setPickerValue(values);
                                    setIsCalender(false);
                                }
                            }}
                            onCalendarChange={(values) => { handleCalendarChange(values) }}
                            disabledDate={(current) => current && current < minDate}
                            defaultPickerValue={pickerValue}
                            onOpenChange={(open) => {
                                // console.log("open calnderrrr", open, dateRange);
                                if (open && dateRange && dateRange.length === 2) {
                                    setPickerValue(dateRange);
                                }

                                if (!open && !dateRange) {
                                    setPickerValue([dayjs().startOf("month"), dayjs().endOf("month")]);
                                }
                            }}
                            onClick={() => {
                                setIsCalender(true);
                            }}
                        />
                    </div>
                    <div className="destination-box  d-flex justify-content-center align-items-center first-box item-four">
                        <img src="./images/people-outline.svg" alt='' onClick={handleClick} />
                        {/* <select class="form-select" aria-label="Default select example">
                                                    <option selected>Open this select menu</option>
                                                    <option value="1">One</option>
                                                    <option value="2">Two</option>
                                                    <option value="3">Three</option>
                                                </select> */}
                        <input
                            type="text"
                            ref={inputRef}
                            value={`${optionsData.Adults} ${t("adult_name")}, ${optionsData.Children} ${t("children_name")}, ${t("and_name")} ${optionsData.Room} ${t("room_name")}`}
                            className="form-control"
                            placeholder="2 Adult, 1 Children"
                            onClick={handleClick}
                        />
                    </div>
                    {open && <SelectPopOver
                        id={id}
                        open={open}
                        anchorEl={anchorEl}
                        setAnchorEl={() => setAnchorEl(null)}
                        optionsData={optionsData}
                        setOptionsData={setOptionsData}
                        childsAge={childsAge}
                        setChildsAge={setChildsAge}
                    />}
                    <div className="destination-box search-btn item-five">
                        <button type="button" className="btn btn-gradient-purple text-white d-flex align-items-center justify-content-center gap-2" onClick={handleSearch}>
                            <i class="fas fa-search me-2"></i>{t("search_btn")}
                        </button>
                    </div>
                </div>
            </form>
            <SelectNation show={isShow} handleClose={() => setIsShow(false)} setNation={setNation} />
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        user: state.auth.user,
        graphData: state.bookings.graphData,
        searchedData: state.bookings.searchedData,
        modifyData: state.bookings.modifyData,
        cityData: state.bookings.cityData,
        currency: state.auth.currency
    }
};
export default connect(mapStateToProps)(HotelBar);