import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import CustomEditor from "./custom-editor";
import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Select,
  Switch,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  IconButton,
  useToast,
} from "@chakra-ui/react";
import { AddIcon, EditIcon } from "@chakra-ui/icons";
import { manage_store_pages } from "../../../../reduxStore/vendors/vendorActions";
import { useDispatch, useSelector } from "react-redux";

const ManagePages = ({ pageArr, page }) => {
  const toast = useToast();
  const dispatch = useDispatch();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const user = useSelector(state => state.auth.user);
  const schema = Yup.object().shape({
    title: Yup.string().required("Name is required"),
    route: Yup.string().required("Route is required"),
    content: Yup.string().required("Content is required"),
  });

  const {
    register,
    handleSubmit,
    setValue,
    setError,
    clearErrors,
    trigger,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
    defaultValues: {
      title: page?.name || "",
      route: page?.type || "",
      content: page?.content?.value || "",
    },
  });

  const [isHtmlMode, setIsHtmlMode] = useState(page?.content?.isHtmlMode || false);
  const [presentHtmlValue, setPresentHtmlValue] = useState(page?.content?.value || "");
  const [pageStatus, setPageStatus] = useState(page?.status || false);
  const [content, setContent] = useState(page?.content?.value);
  const onChangeContent = async(e) => {
    setContent(e);
    setValue("content",e);
      if (e) {
        clearErrors("content");
      } else {
        setError("content", {
          type: "required",
          message: "Description is required",
        });
      }
  };
  const onSubmit = async(data) => {
    let payload = {
      "name": data?.title,
      "type": data?.route,
      "content": {
        isHtmlMode,
        value: isHtmlMode ? presentHtmlValue : data?.content,
      },
      "status": pageStatus
    };
    if(page){
      payload.page_id=page?._id;
    }
    const res=await manage_store_pages(dispatch, user?.token, payload, toast);
    if(res){
      reset();
      onClose();
    }
  };

  return (
    <>
      {!page ? (
        <Button className='btn-gradientlg-purple w-auto' leftIcon={<AddIcon />} onClick={onOpen}>
          Add New
        </Button>
      ) : (
        <IconButton icon={<EditIcon />} aria-label="Edit" onClick={onOpen} />
      )}
      <Modal isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{page ? "Edit Page" : "Add Page"}</ModalHeader>
          <ModalBody>
            <form onSubmit={handleSubmit(onSubmit)}>
              <FormControl mb={4} isInvalid={errors.title}>
                <FormLabel>Name</FormLabel>
                <Select
                  {...register("title")}
                  isDisabled={page}
                  onChange={(e) => {
                    if (!e.target.value) {
                      setError("title", { message: "Name is required" });
                    } else {
                      clearErrors("title");
                      let p=pageArr.find(r=>r.name===e.target.value);
                      setValue('title',p?.name);
                      setValue('route',p?.route);
                      trigger('title');
                      trigger('route');
                    }
                  }}
                >
                  <option value="">Select</option>
                  {pageArr.map((pg) => (
                    <option key={pg.name} value={pg.name} disabled={pg?.disabled}>
                      {pg?.name}
                    </option>
                  ))}
                </Select>
                <FormErrorMessage>{errors.title?.message}</FormErrorMessage>
              </FormControl>

              <FormControl mb={4} isInvalid={errors.route}>
                <FormLabel>Route</FormLabel>
                <Input
                  {...register("route")}
                  isDisabled
                  onChange={(e) => {
                    if (!e.target.value.trim()) {
                      setError("route", { message: "Route is required" });
                    } else {
                      
                      clearErrors("route");
                    }
                  }}
                />
                <FormErrorMessage>{errors.route?.message}</FormErrorMessage>
              </FormControl>

              <FormControl mb={4}>
                <FormLabel>Status</FormLabel>
                <Switch
                  id="page-status"
                  isChecked={pageStatus}
                  onChange={(e) => setPageStatus(!pageStatus)}
                />
              </FormControl>

              <FormControl mb={4} isInvalid={errors.content}>
                <FormLabel>Description</FormLabel>
                <CustomEditor
                  presentValue={content}
                  isHtmlMode={isHtmlMode}
                  setIsHtmlMode={setIsHtmlMode}
                  presentHtmlValue={presentHtmlValue}
                  setPresentHtmlValue={setPresentHtmlValue}
                  changeValue={onChangeContent}
                />
                <FormErrorMessage>{errors.content?.message}</FormErrorMessage>
              </FormControl>

              <ModalFooter>
                <Button className='disabled-btn w-auto me-2' onClick={()=>{reset(); onClose();}}>
                  Cancel
                </Button>
                <Button type="submit" className='btn-gradientlg-purple w-auto'>
                  {page ? "Edit" : "Add"}
                </Button>
              </ModalFooter>
            </form>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default ManagePages;
