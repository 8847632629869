import React, { useEffect, useState } from 'react'
import SelectRoom from '../../assets/ryt-selct-room.png';
import MapImg from '../../assets/map.svg';
import UserImg from '../../assets/user.png';
import UserSqImg from '../../assets/person-square .png'
import CalenderImg from '../../assets/calendar.png';
import { useNavigate } from 'react-router-dom';
import HolderInfo from '../../Modals/HolderInfo';
import GuestsInfo from '../../Modals/GuestsInfo';
import { useToast } from '@chakra-ui/react';
import CurrencyLogo from '../../shared/CurrencyLogo';
import { currency_converter } from '../../reduxStore/User/userActions';
import { connect } from 'react-redux';
import HolderForm from '../../shared/HolderForm';
import nationalties from '../../locales/nationalities.json';
import { useTranslation } from 'react-i18next';
import GuestForm from '../../shared/GuestForm';

const HotelBooking = ({ currency }) => {
    const navigate = useNavigate();
    const toast = useToast();
    const { t } = useTranslation();
    const currentTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const holderDetail = JSON.parse(localStorage.getItem("holderDetail"));
    const guestLists = JSON.parse(localStorage.getItem("guestlists"));
    const bookingData = JSON.parse(localStorage.getItem("BookingData"));
    const searchedData = JSON.parse(localStorage.getItem("searchedData"));
    const hotelDetail = JSON.parse(localStorage.getItem("hotelDetail"));
    const totalOccupancies = JSON.parse(localStorage.getItem("totalOccupancies"));
    const roomdetail = JSON.parse(localStorage.getItem("selectedRoom"));
    const locationDetail = JSON.parse(localStorage.getItem("Destinationdata"));
    const [users, setUsers] = useState([]);
    const [isHolder, setIsHolder] = useState(false);
    const [isAdd, setIsAdd] = useState(false);
    const [isGuest, setIsGuest] = useState(false);
    const [currIndex, setCurrIndex] = useState(0);
    const [guestError, setGuestError] = useState(null);
    // const [totalPrice, setTotalPrice] = useState(0);

    //holder form
    const [holderData, setHolderData] = useState({
        Name: '',
        Surname: '',
        Gender: '',
        Age: '',
        Email: '',
        phone_number: '',
        DocumentType: '',
        DocumentNo: '',
        Address: '',
        City: '',
        PostalCode: '',
        Country: '',
        Nationality: nationalties[currentTimeZone]
    });
    const [errors, setErrors] = useState({
        Name: '',
        Surname: '',
        Gender: '',
        Age: '',
        Email: '',
        phone_number: '',
        DocumentType: '',
        DocumentNo: '',
        Address: '',
        City: '',
        PostalCode: '',
        Country: '',
        Nationality: ''
    });
    const [isValid, setIsValid] = useState(false);
    const [guestData, setGuestData] = useState(null);
    // console.log(bookingData);

    //const occupancy = bookingData ? bookingData?.HotelList[0]?.RatePlanList[0]?.MaxOccupancy : 3;
    const occupancy = totalOccupancies ? totalOccupancies?.totalPersons : 0;

    //const occupancy = 3;

    useEffect(() => {
        if (holderDetail) {
            setIsAdd(true);
        }

        if (guestLists) {
            setUsers(guestLists);
        }
    }, []);

    const handleRemove = (userid) => {
        const allusers = users.filter((item) => item?.id !== userid);
        console.log(allusers);
        localStorage.setItem("guestlists", JSON.stringify(allusers));
        setUsers(allusers);
    };
    // console.log(guestLists);

    const handleCheck = () => {
        let checkpersons = users.length + 1;
        if (checkpersons == occupancy) {
            toast({ title: 'Maximum persons selected', status: "error", duration: 2000, position: "top-right", isClosable: true, });
            return;
        }

        if (checkpersons !== occupancy) {
            setIsGuest(true);
        }
        // if (isAdd === false) return;
        // setIsGuest(true);
    };

    const handleHolder = () => {
        for (let key in holderData) {
            if (holderData[key] === "") {
                return true;
            }
        }
        localStorage.setItem("holderDetail", JSON.stringify(holderData));
        return false;
    };

    const handleAllPersons = () => {
        let persons = [];
        if (!handleHolder() && users?.length === 0) {
            persons = [
                {
                    Name: {
                        First: holderData?.Name,
                        Last: holderData?.Surname
                    },
                    IsAdult: Number(holderData?.Age) < 18 ? false : true,
                    Age: Number(holderData?.Age)
                }
            ];
        } else if (!handleHolder() && users?.length !== 0) {
            persons = [
                {
                    Name: {
                        First: holderData?.Name,
                        Last: holderData?.Surname
                    },
                    IsAdult: Number(holderData?.Age) < 18 ? false : true,
                    Age: Number(holderData?.Age)
                },
                ...users?.map((item, index) => ({
                    Name: {
                        First: item?.Name,
                        Last: item?.Surname
                    },
                    IsAdult: Number(item?.Age) < 18 ? false : true,
                    Age: Number(item?.Age)

                }))
            ];
        }
        localStorage.setItem("AllPersons", JSON.stringify(persons));
    };

    const handleBooking = async () => {

        if (handleHolder()) {
            setIsValid(true);
            // console.log("holderwrjwr", holderData);
            toast({ title: `${t('fill_details')}`, status: "error", duration: 2000, position: "top-right", isClosable: true, });
            window.scrollTo({ top: 0, behavior: "smooth" });
            return;
        }

        if (!handleHolder() && occupancy > 1 && (users.length === 0 || users?.length + 1 !== occupancy)) {
            setGuestError(`${occupancy - (users?.length + 1)} ${t('more_guest')}`);
            window.scrollTo({ top: 0, behavior: "smooth" });
            return;
        }

        if (!handleHolder() && occupancy === 1 && users.length === 0) {
            handleAllPersons();
            navigate('/hotel-booking/card');
        }

        if (!handleHolder() && occupancy > 1 && users.length + 1 === occupancy) {
            handleAllPersons();
            navigate('/hotel-booking/card');
        }
    };

    const formatDate = (datestring) => {
        const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
        const newDate = new Date(datestring);
        const date = newDate.getDate();
        const month = months[newDate.getMonth()];
        const year = newDate.getUTCFullYear();

        return `${date} ${month} ${year}`;
    };

    // console.log(holderDetail);

    useEffect(() => {
        if (occupancy > 1 && users?.length + 1 === occupancy) {
            setGuestError(null);
        }
    }, [users]);

    // useEffect(() => {
    //     if (!roomdetail) return;

    //     const fetchConvertedPrice = async () => {
    //         const data = {
    //             amount: Number(roomdetail?.TotalPrice),
    //             from: roomdetail?.Currency,
    //             to: currency
    //         };
    //         const conversion = await currency_converter(data);
    //         setTotalPrice(Math.round(conversion?.data));
    //     };
    //     fetchConvertedPrice();
    // }, []);

    useEffect(() => {
        if (!handleHolder()) {
            setIsAdd(true);
        }
    }, [holderData]);

    return (
        <div>
            <div className='payment-details-wrapper'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-8 col-md-7 col-sm-12'>
                            <div className='left-fill-details'>
                                {/* <h2 className='mb-3'>Fill your details</h2>
                                <div className='row'>
                                    <div className='col-lg-6 col-md-6 col-sm-12 form-group form-spacing'>
                                        <label class="form-label p-md text-grey-light">First name</label>
                                        <input type="text" className='form-control' placeholder='Enter first name' />
                                    </div>
                                    <div className='col-lg-6 col-md-6 col-sm-12 form-group form-spacing'>
                                        <label class="form-label p-md text-grey-light">Last name</label>
                                        <input type="text" className='form-control' placeholder='Enter last name' />
                                    </div>
                                </div>
                                <div className='form-group form-spacing'>
                                    <label class="form-label p-md text-grey-light">Email</label>
                                    <input type="email" className='form-control' placeholder='Enter your email' />
                                </div>
                                <div className='form-group form-spacing'>
                                    <label class="form-label p-md text-grey-light">Phone</label>
                                    <input type="tel" className='form-control' placeholder='Enter your phone' />
                                </div>

                                <h4>Address</h4>
                                <div className='form-group form-spacing'>
                                    <label class="form-label p-md text-grey-light">House no./Flat no.</label>
                                    <input type="text" className='form-control' placeholder='Enter detail' />
                                </div>
                                <div className='row'>
                                    <div className='col-lg-6 col-md-6 col-sm-12 form-group form-spacing'>
                                        <label class="form-label p-md text-grey-light">State</label>
                                        <input type="text" className='form-control' placeholder='Enter state' />
                                    </div>
                                    <div className='col-lg-6 col-md-6 col-sm-12 form-group form-spacing'>
                                        <label class="form-label p-md text-grey-light">City</label>
                                        <input type="text" className='form-control' placeholder='Enter city' />
                                    </div>
                                    <div className='col-lg-6 col-md-6 col-sm-12 form-group form-spacing'>
                                        <label class="form-label p-md text-grey-light">Pin code</label>
                                        <input type="text" className='form-control' placeholder='Enter code' />
                                    </div>
                                </div> */}

                                <div className='travel-payment-wrap border-solid-d'>
                                    <h2 className='mb-3'>{t('guest_details')}</h2>
                                    {/* <div className='d-flex align-items-center justify-content-between gap-travel-between'>
                                        <div className='d-flex align-items-center align-start-top gap-3'>
                                            <div className='travel-top-img'>
                                                <img src={UserSqImg} className='' />
                                            </div>
                                            <div className=''>
                                                <h3 className='font-family-poppins mb-0'>{holderDetail !== null ? holderDetail?.Name : 'Person 1'}</h3>
                                                <p className='p-md mb-0'>
                                                    {holderDetail !== null ? `Age - ${holderDetail?.Age} years` : '*Adult - Should be above 18 years'}
                                                    *Adult - Should be above 18 years
                                                </p>
                                            </div>
                                        </div>
                                        <span className='btn-gradient-purple w-auto p-md cursor-pointer'
                                            onClick={() => {
                                                if (isAdd === true) return;
                                                setIsHolder(true);
                                            }}
                                        >
                                            {isAdd ? "Added" : "Add"}
                                        </span>
                                    </div> */}

                                    <HolderForm
                                        holderData={holderData}
                                        setHolderData={setHolderData}
                                        errors={errors}
                                        setErrors={setErrors}
                                        isValid={isValid}
                                    />

                                    {guestError && <p className='text-danger'>{guestError}</p>}

                                    {occupancy > 1 && occupancy !== users?.length + 1 && <span className={isAdd === false ? "disabled-btn d-inline-block w-auto mt-3" : "btn-gradient-purple d-inline-block mt-3 cursor-pointer w-auto"}
                                        onClick={() => {
                                            if (isAdd === false) return;
                                            handleCheck();
                                            setGuestError(null);
                                        }}
                                    >
                                        <i class="bi bi-plus"></i> {t('add_person')}
                                    </span>}

                                    {(guestData ? users.filter((itm) => itm.id !== guestData?.id) : users)?.map((item, index) => (
                                        <div key={index}>
                                            <hr className='border-dotted' />
                                            <div className='d-flex align-items-center justify-content-between'>
                                                <div className='d-flex align-items-center align-start-top gap-3'>
                                                    <div className='travel-top-img '>
                                                        <img src={UserSqImg} className='' />
                                                    </div>
                                                    <div className=''>
                                                        <h3 className='font-family-poppins mb-0'>{item?.Name} {item?.Surname}</h3>
                                                        <p className='p-md mb-0'>
                                                            {item?.Age ? `${t('age')} - ${item?.Age} ${t('years')}` : '*Adult - Should be above 18 years'}
                                                            {/* *Adult - Should be above 18 years */}
                                                        </p>
                                                    </div>
                                                </div>
                                                <div className='d-flex gap-2'>
                                                    <span className='btn-gradient-purple w-auto p-md cursor-pointer'
                                                    onClick={() => {
                                                        setGuestData(item);
                                                        setIsGuest(true);
                                                    }}
                                                    >
                                                        {t('Edit')}
                                                        {/* {(guestLists !== null && guestLists?.find((item) => item?.guestindex === index + 2)) ? 'Added' : 'Add'} */}
                                                    </span>
                                                    <span className='btn-gradient-purple w-auto p-md cursor-pointer' onClick={() => handleRemove(item?.id)}>{t('remove')}</span>
                                                </div>
                                            </div>
                                        </div>
                                    ))}

                                    {isGuest &&
                                        <GuestForm
                                            show={isGuest}
                                            handleClose={() => setIsGuest(false)}
                                            currIndex={users.length + 2}
                                            setUsers={setUsers}
                                            guestData={guestData}
                                            resetGuest={() => setGuestData(null)}
                                        />
                                    }

                                    {/* <hr className='border-dotted' /> */}

                                    {/* <div className='d-flex align-items-center justify-content-between'>
                                        <div className='d-flex align-items-center align-start-top gap-3'>
                                            <div className='travel-top-img '>
                                                <img src={UserSqImg} className='' />
                                            </div>
                                            <div className=''>
                                                <h3 className='font-family-poppins mb-0'>Person 2</h3>
                                                <p className='p-md mb-0'>*Adult - Should be above 18 years</p>
                                            </div>
                                        </div>
                                        <a href="" className='btn-gradient-purple w-auto p-md' data-bs-toggle="modal" data-bs-target="#add-person-second-popup">Add</a>
                                    </div> */}

                                    {/* {[...Array(occupancy)].map((_, index) => (
                                        <div key={index}>
                                            <hr className='border-dotted' />
                                            <div className='d-flex align-items-center justify-content-between'>
                                                <div className='d-flex align-items-center align-start-top gap-3'>
                                                    <div className='travel-top-img '>
                                                        <img src={UserSqImg} className='' />
                                                    </div>
                                                    <div className=''>
                                                        <h3 className='font-family-poppins mb-0'>Person {index + 2}</h3>
                                                        <p className='p-md mb-0'>*Adult - Should be above 18 years</p>
                                                    </div>
                                                </div>
                                                <span className='btn-gradient-purple w-auto p-md cursor-pointer'
                                                    onClick={() => {
                                                        setCurrIndex(index + 2);
                                                        setIsGuest(true);
                                                    }}
                                                >
                                                    {(guestLists !== null && guestLists?.find((item) => item?.guestindex === index + 2)) ? 'Added' : 'Add'}
                                                </span>
                                            </div>
                                        </div>
                                    ))} */}

                                </div>
                            </div>
                        </div>
                        <div className='col-lg-4 col-md-5 col-sm-12'>
                            <div className='ryt-room-select-price'>
                                <img src={(hotelDetail && hotelDetail[0]?.images[0]?.ImageUrl) ? hotelDetail[0]?.images[0]?.ImageUrl : SelectRoom} alt='' className='img-fluid w-100 top-selct-img' />
                                <h5 className='hd-spacing-room-selct'>
                                    {hotelDetail ? hotelDetail?.Name : '-'}
                                    {/* Movenpick Hotel And Residences Riyadh */}
                                </h5>
                                <div class="reviews-box d-flex align-items-baseline mb-2 gap-2">
                                    <p className='d-inline-block p-md mb-0'>
                                        {hotelDetail?.rating ? Math.round(hotelDetail?.rating) : ''}
                                    </p>
                                    <ul class="d-inline-block star-rating p-0 mb-0">

                                        {Array.from({ length: Math.round(hotelDetail?.rating) }).map((_, index) => (
                                            <li class="d-inline-block" key={index}><i class="fas fa-star"></i></li>
                                        ))}
                                        {/* <li class="d-inline-block"><i class="fas fa-star"></i></li>
                                        <li class="d-inline-block"><i class="fas fa-star"></i></li>
                                        <li class="d-inline-block"><i class="fas fa-star"></i></li>
                                        <li class="d-inline-block"><i class="fas fa-star"></i></li>
                                        <li class="d-inline-block"><i class="fas fa-star"></i></li> */}
                                    </ul>
                                    <p className='d-inline-block p-md mb-0'>
                                        {hotelDetail?.reviews && `(${hotelDetail?.reviews?.length} reviews)`}
                                        {/* (144 reviews) */}
                                    </p>
                                </div>
                                <p className='p-lg mb-2 font-size-15 font-weight-500 d-flex align-items-center gap-2'>
                                    {/* <img src={MapImg} className='d-inline-block' alt='' /> */}
                                    <i class="bi bi-geo-alt h5 text-purple mb-0"></i>
                                    {locationDetail ? locationDetail?.cityName : '-'}
                                </p>

                                <p className='p-lg mb-2 font-size-15 font-weight-500 d-flex align-items-center gap-2'>
                                    {/* <img src={UserImg} className='d-inline-block ' alt='' /> */}
                                    <i class="bi bi-person text-purple h5 mb-0"></i>
                                    {totalOccupancies ? totalOccupancies?.totalPersons : 0}
                                    {" "} {t('person')}
                                </p>

                                <p className='p-lgmb-2 font-size-15 font-weight-500 d-flex align-items-center gap-2 ms-1'>
                                    {/* <img src={CalenderImg} className='d-inline-block' alt='' /> */}
                                    <i class="bi bi-calendar mb-0 text-purple h6"></i>
                                    {searchedData?.CheckInDate ? formatDate(searchedData?.CheckInDate) : '-'}
                                    <span className='arrow-right-icon-wrap'>
                                        <i className="fas fa-arrow-right mx-2"></i>
                                    </span>
                                    {searchedData?.CheckOutDate ? formatDate(searchedData?.CheckOutDate) : '-'}
                                </p>

                                <div className='price-box-room '>
                                    <h5 className='text-purple p-large mb-0'>{t('price')}</h5>
                                    {totalOccupancies && Array.from({ length: totalOccupancies?.totalRooms })?.map((_, idx) => (
                                        <div className='price-line-dotted align-items-end'>
                                            <div className=''>
                                                <p className='p-md mb-0'>{roomdetail?.RoomName ? roomdetail?.RoomName : roomdetail?.RatePlanName}</p>
                                                <p className='p-sm mb-0'>{idx + 1} Room</p>
                                            </div>
                                            <div className='text-end'>
                                                <p className='p-lg mb-0 text-color-theme font-weight-500'>
                                                    {/* <strike className='p-sm me-2 text-grey-light font-weight-normal'>$41</strike> */}
                                                    {/* $35 */}
                                                </p>
                                            </div>
                                        </div>
                                    ))}
                                    {/* <div className='price-line-dotted align-items-end'>
                                        <div className=''>
                                            <p className='p-md mb-0'>Regular Room - Queen Bed</p>
                                            <p className='p-sm mb-0'>1 Room</p>
                                        </div>
                                        <div className='text-end'>
                                            <p className='p-lg mb-0 text-color-theme font-weight-500'><strike className='p-sm me-2 text-grey-light font-weight-normal'>$41</strike>$35</p>
                                        </div>
                                    </div> */}

                                    {/* <div className='price-line-dotted align-items-end'>
                                        <div className=''>
                                            <p className='p-md mb-0'>Regular Room - Queen Bed</p>
                                            <p className='p-sm mb-0'>1 Room</p>
                                        </div>
                                        <div className='text-end'>
                                            <p className='p-lg mb-0 text-color-theme font-weight-500'><strike className='p-sm me-2 text-grey-light font-weight-normal'>$41</strike>$35</p>
                                        </div>
                                    </div> */}

                                    {/* <div className='price-line-dotted price-line-plane align-items-end'>
                                        <div className=''>
                                            <p className='p-md mb-0'>Regular Room - Queen Bed</p>
                                            <p className='p-sm mb-0'>1 Room</p>
                                        </div>
                                        <div className='text-end'>
                                            <p className='p-lg mb-0 text-color-theme font-weight-500'><strike className='p-sm me-2 text-grey-light font-weight-normal'>$41</strike>$35</p>
                                        </div>
                                    </div> */}

                                    <div className='price-line-dotted align-items-center my-2 border-0 last-font-line'>
                                        <div className=''>
                                            <p className='size-big mb-0 font-weight-600 text-color-theme'>{t('total_vat')}</p>
                                        </div>
                                        <div className='text-end'>
                                            <p className='p-lg mb-0 text-color-theme size-big font-weight-600'>
                                                {roomdetail && <CurrencyLogo currencyCode={currency} />}
                                                {roomdetail && `${roomdetail?.TotalPrice * totalOccupancies?.totalRooms}`}
                                            </p>
                                        </div>
                                    </div>

                                    <button type="submit" className='btn-gradient-purple text-white'
                                        onClick={() => {
                                            // navigate('/hotel-booking/card');
                                            handleBooking();
                                        }}
                                    >
                                        {t('book_now')}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <HolderInfo show={isHolder} handleClose={() => setIsHolder(false)} ISAdd={() => setIsAdd(true)} />
            {/* <GuestsInfo show={isGuest} handleClose={() => setIsGuest(false)} currIndex={users.length + 2} setUsers={setUsers} /> */}
        </div >
    )
}


const mapStateToProps = (state) => {
    return {
        currency: state.auth.currency
    }
};

export default connect(mapStateToProps)(HotelBooking);