import React from 'react'

const StarRating = ({ rating }) => {
    const maxStars = 5;
    const safeRating = Math.min(Math.max(Number(rating) || 0, 0), maxStars);
    const fullStars = Math.floor(safeRating);
    const halfStar = safeRating % 1 >= 0.5;
    const emptyStars = maxStars - fullStars - (halfStar ? 1 : 0);
    return (
        <ul className="d-inline-block star-rating p-0 mb-0">
            {Array.from({ length: fullStars })
                .map((_, index) => (
                    <li class="d-inline-block p-sm">
                        <i key={`full-${index}`} className="fas fa-star text-warning"></i>
                    </li>
                ))}

            {/* Half Star */}
            {halfStar && <li class="d-inline-block p-sm"><i className="fas fa-star-half-alt text-warning"></i></li>}

            {/* Empty Stars */}
            {/* {Array(emptyStars)
                .fill(0)
                .map((_, index) => (
                    <li class="d-inline-block p-sm">
                        <i key={`empty-${index}`} className="far fa-star"></i>
                    </li>
                ))} */}
        </ul>
    )
}

export default StarRating