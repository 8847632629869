import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import SelectRoom from '../../assets/ryt-selct-room.png';
import MapImg from '../../assets/map.png';
import UserImg from '../../assets/user.png';
import CalenderImg from '../../assets/calendar.png';
import { loadStripe } from '@stripe/stripe-js';
import { CardNumberElement, Elements, ElementsConsumer } from '@stripe/react-stripe-js';
import PaymentCard from '../../shared/PaymentCard';
import { add_user_card, currency_converter, get_user_card_Details, get_vender_id, user_edit_card } from '../../reduxStore/User/userActions';
import { connect, useDispatch } from 'react-redux';
import { useToast } from '@chakra-ui/react';
import { hotel_booking_confirm } from '../../reduxStore/booking/bookingActions';
import UserSqImg from '../../assets/person-square .png'
import CurrencyLogo from '../../shared/CurrencyLogo';
import { useTranslation } from 'react-i18next';

const stripePromise = loadStripe("pk_test_51Q26ObEZOuFNrMpGVNLaMcgGcLmftyqoqUB2fnf9ZgOfKGdOH3aJrVxuvrczoeByTHc4cqgJgIw4tYQixDywZib000xSxPCeWf");

const CardDetails = ({ user, vendor_id, userCardDetails, currency }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const toast = useToast();
    const { t } = useTranslation();
    const holderDetail = JSON.parse(localStorage.getItem("holderDetail"));
    const hotelDetail = JSON.parse(localStorage.getItem("hotelDetail"));
    const guestLists = JSON.parse(localStorage.getItem("guestlists"));
    const bookingData = JSON.parse(localStorage.getItem("BookingData"));
    const searchedData = JSON.parse(localStorage.getItem("searchedData"));
    const locationDetail = JSON.parse(localStorage.getItem("Destinationdata"));
    const gdsname = JSON.parse(localStorage.getItem("selectedHotelGDS"));
    const AllPersons = JSON.parse(localStorage.getItem("AllPersons"));
    const totalOccupancies = JSON.parse(localStorage.getItem("totalOccupancies"));
    const roomdetail = JSON.parse(localStorage.getItem("selectedRoom"));
    // const [cancelPrice, setCancelPrice] = useState(0);
    const [guests, setGuests] = useState([]);
    // const [convertedPrice, setConvertedPrice] = useState(Math.round(roomdetail?.TotalPrice));
    // const [totalPrice, setTotalPrice] = useState(0);
    const [stripeData, setStripeData] = useState(null);
    const [stripeElements, setStripeElements] = useState(null);
    const [cardFeilds, setCardFeilds] = useState({
        cardNumber: true,
        cardExpiry: true,
        cardCvc: true,
        userName: true,
    });
    const [errors, setErrors] = useState({
        cardNumber: "",
        cardExpiry: "",
        cardCvc: "",
        userName: "",
    });
    const [formData, setFormData] = useState({ userName: '' });

    const handleCardElementChange = (event, label) => {
        switch (label) {
            case "cardNumber":
                if (event.empty) {
                    setErrors({ ...errors, cardNumber: `${t("card_number_valid")}` });
                    setCardFeilds({ ...cardFeilds, cardNumber: true });
                } else if (event.error) {
                    setErrors({ ...errors, cardNumber: event.error.message });
                    setCardFeilds({ ...cardFeilds, cardNumber: true });
                } else {
                    setErrors({ ...errors, cardNumber: "" });
                    setCardFeilds({ ...cardFeilds, cardNumber: false });
                }
                break;
            case "cardExpiry":
                if (event.empty) {
                    setErrors({ ...errors, cardExpiry: `${t("card_expiry_check")}` });
                    setCardFeilds({ ...cardFeilds, cardExpiry: true });
                } else if (event.error) {
                    setErrors({ ...errors, cardExpiry: event.error.message });
                    setCardFeilds({ ...cardFeilds, cardExpiry: true });
                } else {
                    setErrors({ ...errors, cardExpiry: "" });
                    setCardFeilds({ ...cardFeilds, cardExpiry: false });
                }
                break;
            case "cardCvc":
                if (event.empty) {
                    setErrors({ ...errors, cardCvc: `${t("cvc_check")}` });
                    setCardFeilds({ ...cardFeilds, cardCvc: true });
                } else if (event.error) {
                    setErrors({ ...errors, cardCvc: event.error.message });
                    setCardFeilds({ ...cardFeilds, cardCvc: true });
                } else {
                    setErrors({ ...errors, cardCvc: "" });
                    setCardFeilds({ ...cardFeilds, cardCvc: false });
                }
                break;
            case "userName":
                if (!event.target.value.trim()) {
                    setErrors({ ...errors, userName: `${t("name_valid")}` });
                    setCardFeilds({ ...cardFeilds, userName: true });
                } else {
                    setErrors({ ...errors, userName: "" });
                    setCardFeilds({ ...cardFeilds, userName: false });
                    setFormData({ ...formData, userName: event.target.value });
                }
                break;
            default:
                break;
        }
    };

    const checkAllErrors = () => {
        let err = false;
        let output = Object.entries(cardFeilds);
        // console.log("ErrorsOutputs....",output);
        output.forEach(([key, value]) => {
            if (value) {
                err = true;
                setErrors((prevErrors) => ({
                    ...prevErrors,
                    [key]:
                        key === "userName"
                            ? prevErrors.userName
                                ? prevErrors.userName
                                : `${t("name_valid")}`
                            : key === "cardNumber"
                                ? prevErrors.cardNumber
                                    ? prevErrors.cardNumber
                                    : `${t("card_number_valid")}`
                                : key === "cardExpiry"
                                    ? prevErrors.cardExpiry
                                        ? prevErrors.cardExpiry
                                        : `${t("card_expiry_check")}`
                                    : prevErrors.cardCvc
                                        ? prevErrors.cardCvc
                                        : `${t("cvc_check")}`,
                }));
                // console.log("errrr",err,key,value);
            } else {
                setCardFeilds((prevErrors) => ({ ...prevErrors, [key]: false }));
            }
        });
        return err;
    };

    // console.log(searchedData);

    const distributeGuestsToRooms = (roomCount, maxOccupancy, guestList) => {
        const rooms = Array.from({ length: roomCount }, (_, i) => ({
            RoomNum: i + 1,
            GuestInfo: []
        }));

        let guestIndex = 0;

        // Distribute guests to ensure each room gets at least one person
        rooms.forEach(room => {
            if (guestIndex < guestList.length) {
                room.GuestInfo.push(guestList[guestIndex]);
                guestIndex++;
            }
        });

        // Distribute remaining guests across rooms sequentially while respecting maxOccupancy
        while (guestIndex < guestList.length) {
            rooms.forEach(room => {
                if (room.GuestInfo.length < maxOccupancy && guestIndex < guestList.length) {
                    room.GuestInfo.push(guestList[guestIndex]);
                    guestIndex++;
                }
            });
        }

        return rooms;
    };

    const bookingdetails = {
        ReferenceNo: bookingData ? bookingData?.ReferenceNo : "",
        Holder_details: holderDetail ? holderDetail : {},
        NumOfRooms: totalOccupancies ? totalOccupancies?.totalRooms : 1,
        CheckInDate: searchedData?.CheckInDate,
        CheckOutDate: searchedData?.CheckOutDate,
        GuestList: totalOccupancies ? distributeGuestsToRooms(totalOccupancies?.totalRooms, totalOccupancies?.totalPersons, AllPersons) : null,
        card_details: {
            payment_method: user && userCardDetails?.id !== null ? userCardDetails?.id : user && userCardDetails?.id === null ? '' : '',
            amount: roomdetail?.TotalPrice > 0 ? roomdetail?.TotalPrice : 0,
        },
        RatePlanID: bookingData ? bookingData?.RatePlanID : "",
        provider: gdsname ? gdsname : "DIDA",
        HotelID: bookingData ? bookingData?.HotelID : "",
        Currency: currency
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        if (user && userCardDetails !== null) {
            bookingdetails.card_details.payment_method = userCardDetails?.id;
            console.log("Normal form submission. Stripe logic skipped.", bookingdetails);
            let isBooked = await hotel_booking_confirm(dispatch, toast, vendor_id, bookingdetails, user?.token);
            if (isBooked) {
                navigate('/hotel-booking/confirm');
            }
            return;
        }

        if ((!stripeData || !stripeElements || checkAllErrors())) {
            window.scrollTo({ top: 0, behavior: "smooth" });
            return;
        }

        const cardElement = stripeElements.getElement(CardNumberElement);
        const { error, token } = await stripeData.createToken(cardElement);
        const { paymentMethod } = await stripeData.createPaymentMethod({
            type: "card",
            card: cardElement,
            billing_details: {
                name: formData?.userName,
            },
        });
        // console.log("payment method ====>", paymentMethod);

        const stripedata = {
            cardToken: token?.id,
        };

        if (user) {
            let isEdit = await user_edit_card(dispatch, vendor_id, stripedata, user?.token);
            if (isEdit) {
                let getdata = await get_user_card_Details(dispatch, vendor_id, user?.token);
                bookingdetails.card_details.payment_method = getdata?.id;
                console.log("Final Booking Data =====>", bookingdetails);
                let isBooked = await hotel_booking_confirm(dispatch, toast, vendor_id, bookingdetails, user?.token);
                if (isBooked) {
                    navigate('/hotel-booking/confirm');
                }
            }
        } else if (!user) {
            bookingdetails.card_details.payment_method = paymentMethod?.id;
            console.log("Final Booking Data =====>", bookingdetails);
            let isBooked = await hotel_booking_confirm(dispatch, toast, vendor_id, bookingdetails, user?.token);
            if (isBooked) {
                navigate('/hotel-booking/confirm');
            }
        }
        // console.log("Error", error);
        // console.log("CArd token", token);
        // if (!token) return;

        // const stripedata = {
        //     cardToken: token?.id,
        // };
        // let isAdd = await add_user_card(vendor_id, user?.token, stripedata);
        // if (isAdd) navigate('/hotel-booking/confirm');
    };

    useEffect(() => {
        get_vender_id("superadmin", dispatch);
    }, []);

    useEffect(() => {
        if (user && vendor_id) {
            get_user_card_Details(dispatch, vendor_id, user?.token);
        }
    }, [vendor_id]);

    // [
    //     {
    //         Name: {
    //             Last: "gehlot",
    //             First: "dheeraj"
    //         },
    //         IsAdult: true,
    //         Age: 24
    //     },
    //     {
    //         Name: {
    //             Last: "bansal",
    //             First: "anmol"
    //         },
    //         IsAdult: true,
    //         Age: 24
    //     }
    // ]

    // useEffect(() => {
    //     if (holderDetail !== null && holderDetail !== undefined && guestLists?.length === 0) {
    //         setGuests([
    //             {
    //                 RoomNum: 1,
    //                 GuestInfo: [
    //                     {
    //                         Name: {
    //                             First: holderDetail?.Name,
    //                             Last: holderDetail?.Surname
    //                         },
    //                         IsAdult: Number(holderDetail?.Age) < 18 ? false : true,
    //                         Age: Number(holderDetail?.Age)
    //                     },
    //                 ]
    //             }
    //         ]);
    //     } else if (holderDetail !== null && holderDetail !== undefined && guestLists?.length !== 0) {
    //         setGuests([
    //             {
    //                 RoomNum: 1,
    //                 GuestInfo: [
    //                     {
    //                         Name: {
    //                             First: holderDetail?.Name,
    //                             Last: holderDetail?.Surname
    //                         },
    //                         IsAdult: Number(holderDetail?.Age) < 18 ? false : true,
    //                         Age: Number(holderDetail?.Age)
    //                     },
    //                 ]
    //             },
    //             ...guestLists?.map((item,index) => ({
    //                 RoomNum: index+2,
    //                 GuestInfo: [
    //                     {
    //                         Name: {
    //                             First: item?.Name,
    //                             Last: item?.Surname
    //                         },
    //                         IsAdult: Number(item?.Age) < 18 ? false : true,
    //                         Age: Number(item?.Age)
    //                     },
    //                 ]
    //             }))
    //         ]);
    //     }
    // }, []);

    // console.log("Guests Dataaaaa=======>", guests);


    //for guets and only holder format
    // [
    //     {
    //         RoomNum: 1,
    //         GuestInfo: (holderDetail && guestLists !== null) ? [
    //             {
    //                 Name: {
    //                     Last: holderDetail?.Surname,
    //                     First: holderDetail?.Name
    //                 },
    //                 IsAdult: Number(holderDetail?.Age) < 18 ? false : true,
    //                 Age: Number(holderDetail?.Age)
    //             }
    //             , ...guestLists?.map((item) => ({
    //                 Name: {
    //                     Last: item?.Surname,
    //                     First: item?.Name
    //                 },
    //                 IsAdult: Number(item?.Age) < 18 ? false : true,
    //                 Age: Number(item?.Age)
    //             }))
    //         ] : (holderDetail && guestLists === null) ? [
    //             {
    //                 Name: {
    //                     Last: holderDetail?.Surname,
    //                     First: holderDetail?.Name
    //                 },
    //                 IsAdult: Number(holderDetail?.Age) < 18 ? false : true,
    //                 Age: Number(holderDetail?.Age)
    //             }
    //         ] : []
    //     }
    // ],

    // console.log(userCardDetails);

    const formatDate = (datestring) => {
        const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
        const newDate = new Date(datestring);
        const date = newDate.getDate();
        const month = months[newDate.getMonth()];
        const year = newDate.getUTCFullYear();

        return `${date} ${month} ${year}`;
    };

    // useEffect(() => {
    //     if (!roomdetail) return;

    //     const fetchConvertedPrice = async () => {
    //         const data = {
    //             amount: Number(roomdetail?.TotalPrice),
    //             from: roomdetail?.Currency,
    //             to: currency
    //         };
    //         const conversion = await currency_converter(data);
    //         setTotalPrice(Math.round(conversion?.data));
    //         const data2 = {
    //             amount: Number(roomdetail?.RatePlanCancellationPolicyList[0]?.Amount),
    //             from: roomdetail?.Currency,
    //             to: currency
    //         };
    //         const conversion2 = await currency_converter(data2);
    //         setCancelPrice(Math.round(conversion2?.data));
    //     };
    //     fetchConvertedPrice();
    // }, []);

    return (
        <div>
            <div className='card-details-wrapper'>
                <div className='container'>
                    <Elements stripe={stripePromise}>
                        <ElementsConsumer>
                            {({ stripe, elements }) => {
                                setStripeElements(elements);
                                setStripeData(stripe);
                                return (
                                    <>
                                        <form onSubmit={handleSubmit}>
                                            <div className='row'>
                                                <div className='col-lg-8 col-md-7 col-sm-12'>
                                                    <div className='left-fill-details'>
                                                        {(user && userCardDetails?.id) &&
                                                            <div className='travel-payment-wrap border-solid-d'>
                                                                <div className='d-flex align-items-center justify-content-between gap-travel-between'>
                                                                    <div className='d-flex align-items-center align-start-top gap-3'>
                                                                        <div className='travel-top-img'>
                                                                            <i class="bi bi-credit-card-2-back fs-1"></i>
                                                                        </div>
                                                                        <div className=''>
                                                                            <h3 className='font-family-poppins mb-0'>
                                                                                {userCardDetails !== null ? `**** **** **** ${userCardDetails?.last4}` : `**** **** **** ****`}
                                                                            </h3>
                                                                            <p className='p-md mb-0'>
                                                                                {/* {holderDetail !== null ? `Age - ${holderDetail?.Age} years` : '*Adult - Should be above 18 years'} */}
                                                                                {/* *Adult - Should be above 18 years */}
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                    <span className='btn-gradient-purple w-auto p-md cursor-pointer'
                                                                    // onClick={() => {
                                                                    //     if (isAdd === true) return;
                                                                    //     setIsHolder(true);
                                                                    // }}
                                                                    >
                                                                        {t('card_added')}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        }

                                                        {(!user || userCardDetails === null) &&
                                                            <div>
                                                                <h2 className='mb-3'>{t('card_details')}</h2>

                                                                <PaymentCard
                                                                    stripe={stripe}
                                                                    elements={elements}
                                                                    errors={errors}
                                                                    handleCardElementChange={(e, label) => handleCardElementChange(e, label)}
                                                                    formData={formData}
                                                                />
                                                            </div>
                                                        }

                                                        <div className='policies-wrapper pt-3'>
                                                            <h2 className='mb-3'>{t("polices")}</h2>
                                                            <div className='row'>
                                                                <div className='col-lg-6 col-md-6 col-sm-6'>
                                                                    <div className='policies_box_inner'>
                                                                        <h3>Room information</h3>
                                                                        {roomdetail && < p className='font-size-15 font-weight-500 mb-0'>
                                                                            {roomdetail?.RoomName ? roomdetail?.RoomName : roomdetail?.RatePlanName}
                                                                        </p>}
                                                                        <ul className='list-style-dots'>
                                                                            {roomdetail && roomdetail?.facility?.map((item, index) => (
                                                                                <li className='font-weight-500 font-size-15' key={index}>{item?.Name}</li>
                                                                            ))}
                                                                            {/* <li className='font-weight-500 font-size-15'>Lorem Ipsum is simply dummy text</li>
                                                                            <li className='font-weight-500 font-size-15'>Lorem Ipsum is simply dummy text</li>
                                                                            <li className='font-weight-500 font-size-15'>Lorem Ipsum is simply dummy text</li>
                                                                            <li className='font-weight-500 font-size-15'>Lorem Ipsum is simply dummy text</li>
                                                                            <li className='font-weight-500 font-size-15'>Lorem Ipsum is simply dummy text</li>
                                                                            <li className='font-weight-500 font-size-15'>Lorem Ipsum is simply dummy text</li> */}
                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                                <div className='col-lg-6 col-md-6 col-sm-6'>
                                                                    <div className='policies_box_inner'>
                                                                        <h3>Cancellation Charges </h3>
                                                                        <p className='font-size-15 font-weight-500 mb-0'>
                                                                            Amount: <CurrencyLogo currencyCode={currency} /> {roomdetail?.RatePlanCancellationPolicyList[0]?.Amount}
                                                                        </p>
                                                                        {/* <ul className='list-style-dots'>
                                                                            <li className='font-weight-500 font-size-15'>Lorem Ipsum is simply dummy text</li>
                                                                            <li className='font-weight-500 font-size-15'>Lorem Ipsum is simply dummy text</li>
                                                                            <li className='font-weight-500 font-size-15'>Lorem Ipsum is simply dummy text</li>
                                                                            <li className='font-weight-500 font-size-15'>Lorem Ipsum is simply dummy text</li>
                                                                            <li className='font-weight-500 font-size-15'>Lorem Ipsum is simply dummy text</li>
                                                                            <li className='font-weight-500 font-size-15'>Lorem Ipsum is simply dummy text</li>
                                                                        </ul> */}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='col-lg-4 col-md-5 col-sm-12'>
                                                    <div className='ryt-room-select-price'>
                                                        <img src={(hotelDetail && hotelDetail[0]?.images[0]?.ImageUrl) ? hotelDetail[0]?.images[0]?.ImageUrl : SelectRoom} alt='' className='img-fluid w-100 top-selct-img' />
                                                        <h5 className='hd-spacing-room-selct'>
                                                            {hotelDetail ? hotelDetail?.Name : '-'}
                                                            {/* Movenpick Hotel And Residences Riyadh */}
                                                        </h5>
                                                        <div class="reviews-box d-flex align-items-baseline mb-2 gap-2">
                                                            <p className='d-inline-block p-md mb-0'>
                                                            {hotelDetail?.rating ? Math.round(hotelDetail?.rating) : ''}
                                                            </p>
                                                            <ul class="d-inline-block star-rating p-0 mb-0">
                                                                {Array.from({ length: Math.round(hotelDetail?.rating) }).map((_, index) => (
                                                                    <li class="d-inline-block" key={index}><i class="fas fa-star"></i></li>
                                                                ))}
                                                                {/* <li class="d-inline-block"><i class="fas fa-star"></i></li>
                                                                <li class="d-inline-block"><i class="fas fa-star"></i></li>
                                                                <li class="d-inline-block"><i class="fas fa-star"></i></li>
                                                                <li class="d-inline-block"><i class="fas fa-star"></i></li>
                                                                <li class="d-inline-block"><i class="fas fa-star"></i></li> */}
                                                            </ul>
                                                            <p className='d-inline-block p-md mb-0'>
                                                                {hotelDetail?.reviews && `(${hotelDetail?.reviews?.length} reviews)`}
                                                                {/* (144 reviews) */}
                                                            </p>
                                                        </div>
                                                        <p className='p-lg mb-2 font-size-15 font-weight-500 d-flex align-items-center gap-2'>
                                                            <img src={MapImg} className='d-inline-block' alt='' />
                                                            {locationDetail ? locationDetail?.cityName : '-'}
                                                        </p>

                                                        <p className='p-lg mb-2 font-size-15 font-weight-500 d-flex align-items-center gap-2'>
                                                            <img src={UserImg} className='d-inline-block ' alt='' />
                                                            {/* {guestLists ? guestLists?.length + 1 : 0} */}
                                                            {totalOccupancies ? totalOccupancies?.totalPersons : 0}
                                                            {" "}{t('person')}
                                                        </p>

                                                        <p className='p-lgmb-2 font-size-15 font-weight-500 d-flex align-items-center gap-2'><img src={CalenderImg} className='d-inline-block' alt='' />
                                                            {searchedData?.CheckInDate ? formatDate(searchedData?.CheckInDate) : '-'}
                                                            <span className='arrow-right-icon-wrap'>
                                                                <i className="fas fa-arrow-right mx-2"></i>
                                                            </span>
                                                            {searchedData?.CheckOutDate ? formatDate(searchedData?.CheckOutDate) : '-'}
                                                        </p>

                                                        <div className='price-box-room '>
                                                            <h5 className='text-purple p-large mb-0'>{t('price')}</h5>
                                                            {totalOccupancies && Array.from({ length: totalOccupancies?.totalRooms })?.map((_, idx) => (
                                                                <div className='price-line-dotted align-items-end'>
                                                                    <div className=''>
                                                                        <p className='p-md mb-0'>{roomdetail?.RoomName ? roomdetail?.RoomName : roomdetail?.RatePlanName}</p>
                                                                        <p className='p-sm mb-0'>{idx + 1} Room</p>
                                                                    </div>
                                                                    <div className='text-end'>
                                                                        <p className='p-lg mb-0 text-color-theme font-weight-500'>
                                                                            {/* <strike className='p-sm me-2 text-grey-light font-weight-normal'>$41</strike> */}
                                                                            {/* $35 */}
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            ))}
                                                            {/* <div className='price-line-dotted align-items-end'>
                                        <div className=''>
                                            <p className='p-md mb-0'>Regular Room - Queen Bed</p>
                                            <p className='p-sm mb-0'>1 Room</p>
                                        </div>
                                        <div className='text-end'>
                                            <p className='p-lg mb-0 text-color-theme font-weight-500'><strike className='p-sm me-2 text-grey-light font-weight-normal'>$41</strike>$35</p>
                                        </div>
                                    </div> */}

                                                            {/* <div className='price-line-dotted align-items-end'>
                                        <div className=''>
                                            <p className='p-md mb-0'>Regular Room - Queen Bed</p>
                                            <p className='p-sm mb-0'>1 Room</p>
                                        </div>
                                        <div className='text-end'>
                                            <p className='p-lg mb-0 text-color-theme font-weight-500'><strike className='p-sm me-2 text-grey-light font-weight-normal'>$41</strike>$35</p>
                                        </div>
                                    </div> */}

                                                            {/* <div className='price-line-dotted price-line-plane align-items-end'>
                                        <div className=''>
                                            <p className='p-md mb-0'>Regular Room - Queen Bed</p>
                                            <p className='p-sm mb-0'>1 Room</p>
                                        </div>
                                        <div className='text-end'>
                                            <p className='p-lg mb-0 text-color-theme font-weight-500'><strike className='p-sm me-2 text-grey-light font-weight-normal'>$41</strike>$35</p>
                                        </div>
                                    </div> */}

                                                            <div className='price-line-dotted align-items-center mb-3 border-0 last-font-line'>
                                                                <div className=''>
                                                                    <p className='size-big mb-0 font-weight-600 text-color-theme'>{t('total_vat')}</p>
                                                                </div>
                                                                <div className='text-end'>
                                                                    <p className='p-lg mb-0 text-color-theme size-big font-weight-600'>
                                                                        {roomdetail && <CurrencyLogo currencyCode={currency} />}
                                                                        {roomdetail && `${roomdetail?.TotalPrice * totalOccupancies?.totalRooms}`}
                                                                    </p>
                                                                </div>
                                                            </div>

                                                            <button type="submit" className='btn-gradient-purple text-white'
                                                                onClick={() => {
                                                                    // navigate('/hotel-booking/card');
                                                                    // handleBooking();
                                                                }}
                                                            >
                                                                {t('book_now')}
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </form >
                                    </>
                                )
                            }}
                        </ElementsConsumer>
                    </Elements>
                </div>
            </div>
        </div >
    )
}

const mapStateToProps = (state) => {
    return {
        user: state.auth.user,
        vendor_id: state.auth.vendor_id,
        userCardDetails: state.auth.userCardDetails,
        currency: state.auth.currency
    }
}
export default connect(mapStateToProps)(CardDetails);