import React, { useEffect, useState } from 'react'
import ReceptionImg from '../assets/reception-icon.svg';
import AirconditionImg from '../assets/ac-icon.svg';
import BanquetImg from '../assets/banquet-icon.svg';
import ParkingbtmImg from '../assets/parking-icon.svg';
import AtmImg from '../assets/ATM-icon.svg';
import RoomOne from '../assets/room1.png';
import WifibtnImg from '../assets/wifi-icon.svg';
import { Link, useNavigate } from 'react-router-dom';
import Slider from 'react-slick';
import { connect, useDispatch } from 'react-redux';
import { currency_converter } from '../reduxStore/User/userActions';
import Amenities from '../shared/Amenities';
import GreenCheck from '../assets/green-check.png';
import NotFound from '../assets/NotFound.png';
import { GET_HOTEL_ID } from '../reduxStore/booking/bookingTypes';
import CurrencyLogo from './CurrencyLogo';
import { useTranslation } from 'react-i18next';



const roomslider = {
    dots: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 3000,
    responsive: [
        {
            breakpoint: 1024,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 3,
                infinite: true,
                dots: true
            }
        },
        {
            breakpoint: 700,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                initialSlide: 1
            }
        },
        {
            breakpoint: 480,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1
            }
        }
    ]
};

const Products = ({ item, currency }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const searchData = JSON.parse(localStorage.getItem("searchedData"));
    // const [convertedPrice, setConvertedPrice] = useState(Number(item?.HotelPrice ? item?.HotelPrice?.Price : 35));
    const [isShow, setIsShow] = useState(false);
    const [days, setDays] = useState(0);
    const { t } = useTranslation();

    // useEffect(() => {
    //     const fetchConvertedPrice = async () => {
    //         if (currency !== 'USD') {
    //             const data = {
    //                 amount: Number(item?.HotelPrice?.Price),
    //                 from: item?.HotelPrice?.Currency ? item?.HotelPrice?.Currency : 'USD',
    //                 to: currency
    //             };
    //             const conversion = await currency_converter(data);
    //             setConvertedPrice(Math.round(conversion?.data));
    //         } else {
    //             setConvertedPrice(Math.round(item?.HotelPrice?.Price));
    //         }
    //     };

    //     fetchConvertedPrice();
    // }, [currency]);

    const handleBooking = async (itemData) => {
        // localStorage.setItem("selectedHotelGDS", JSON.stringify(itemData?.Gds));
        // localStorage.setItem("roomsData", JSON.stringify(itemData?.rooms));
        // navigate(`/detail/${itemData?.HotelID}`);

        localStorage.setItem("selectedHotelGDS", JSON.stringify(itemData?.provider));
        localStorage.setItem("roomsData", JSON.stringify(itemData?.rooms));
        localStorage.setItem("hotelId", JSON.stringify(itemData?.GiataId ? itemData?.GiataId : itemData?.HotelID));
        dispatch({ type: GET_HOTEL_ID, payload: itemData?.GiataId ? itemData?.GiataId : itemData?.HotelID });
        navigate(`/detail/${itemData?.Name}`);
    };

    useEffect(() => {
        const d1 = new Date(searchData?.CheckInDate);
        const d2 = new Date(searchData?.CheckOutDate);

        // Calculate the difference in time (milliseconds)
        const timeDifference = d2 - d1;

        // Convert the difference from milliseconds to days
        const differenceInDays = timeDifference / (1000 * 60 * 60 * 24);

        setDays(differenceInDays);
    }, []);

    return (
        <div className='realted_inner similar-products-wrapper'>
            {/* <Slider {...roomslider}> */}
            <img src={RoomOne} alt='' className='w-100' />
            {/* </Slider> */}
            <div className='inner-details-cnt'>
                <h5 className='my-3'>
                    {item?.Name ? item?.Name : 'Regular Room - Queen Bed'}
                </h5>
                <div class="reviews-box d-flex align-items-center mb-4 gap-2">

                    {/* <ul class="d-inline-block star-rating p-0 mb-0">
                        {Array.from({ length: Math.round(item?.rating) }).map((_, index) => (
                            <li class="d-inline-block" key={index}><i class="fas fa-star"></i></li>
                        ))}
                        <li class="d-inline-block"><i class="fas fa-star"></i></li>
                        <li class="d-inline-block"><i class="fas fa-star"></i></li>
                        <li class="d-inline-block"><i class="fas fa-star"></i></li>
                        <li class="d-inline-block"><i class="fas fa-star"></i></li>
                        <li class="d-inline-block"><i class="fas fa-star"></i></li>
                    </ul> */}
                    <div className='rating-with-reviews-wrap d-flex align-items-center gap-2'>
                       {item?.rating && <p className='p-all-small mb-0 total-rating-container'>
                            {item?.rating} <i class="fas fa-star"></i>
                        </p>}
                        {item?.total_reviews && <p className='d-inline-block p-all-small text-grey-light mb-0'>({item?.total_reviews} reviews)</p>}
                    </div>
                </div>
                <div className='row'>
                    <div className={`${item?.facilities?.length > 0 ? 'col-lg-12' : 'col-lg-6'} col-md-12 col-sm-12`}>
                        <div className='room-feature'>
                            <ul className={`${item?.facilities?.length > 0 ? 'd-flex flex-wrap ps-0' : 'p-0'}`}>
                                {item?.facilities && item?.facilities?.slice(0, 6)?.map((itm, idx) => (
                                    <li className='col-md-6 p-sm text-grey-light d-flex align-items-start gap-2 mb-2' key={idx}>
                                        <img
                                            src={
                                                Amenities?.find((k) => k?.Description?.toLowerCase() === itm?.toLowerCase())?.ImagePath ?
                                                    Amenities?.find((k) => k?.Description?.toLowerCase() === itm?.toLowerCase())?.ImagePath :
                                                    GreenCheck
                                            }
                                            //  src={AirCondition} 
                                            className='d-inline-block mt-1'
                                            alt=''
                                        />
                                        {itm}
                                    </li>
                                ))}
                                {/* {isShow && item?.facilities?.map((itm, idx) => (
                                    <li className='col-md-6 p-sm text-grey-light d-flex align-items-center gap-2 mb-2' key={idx}>
                                        <img
                                            src={
                                                Amenities?.find((k) => k?.Description?.toLowerCase().includes(itm?.toLowerCase()))?.ImagePath ?
                                                    Amenities?.find((k) => k?.Description?.toLowerCase().includes(itm?.toLowerCase()))?.ImagePath :
                                                    NotFound
                                            }
                                            // src={AirCondition}
                                            className='d-inline-block'
                                            alt=''
                                        />
                                        {itm}
                                    </li>
                                ))} */}
                                {/* {!isShow && <li className='col-md-6 p-sm text-grey-light d-flex align-items-center gap-2 mb-2 cursor-pointer' onClick={() => setIsShow(!isShow)}>
                                    {t('more')}
                                </li>} */}

                                {/* {item?.facilities?.length > 0 ? item?.facilities?.map((itm, idx) => (
                                    <li className='col-md-6 p-md text-grey-light d-flex align-items-center gap-2 mb-2' key={idx}>
                                        <img
                                            // src={AirconditionImg}
                                            src={
                                                Amenities?.find((k) => k?.Description?.toLowerCase().includes(itm?.toLowerCase()))?.ImagePath ?
                                                    Amenities?.find((k) => k?.Description?.toLowerCase().includes(itm?.toLowerCase()))?.ImagePath :
                                                    NotFound
                                            }
                                            className='d-inline-block'
                                            alt=''
                                        />
                                        {itm}
                                    </li>
                                )) :
                                    <>
                                        <li className='p-sm text-grey-light d-flex align-items-center gap-2 mb-2'><img src={AirconditionImg} className='d-inline-block' alt='' />Air Condition </li>
                                        <li className='p-sm text-grey-light d-flex align-items-center gap-2 mb-2'><img src={ReceptionImg} className='d-inline-block' alt='' />24H. Reception</li>
                                        <li className='p-sm text-grey-light d-flex align-items-center gap-2 mb-2'><img src={AtmImg} className='d-inline-block' alt='' />ATM</li>
                                        <li className='p-sm text-grey-light d-flex align-items-center gap-2 mb-2'><img src={BanquetImg} className='d-inline-block' alt='' />Gym</li>
                                    </>
                                } */}
                                {/* <li className='p-md d-flex align-items-center gap-2'><img src={AirconditionImg} className='d-inline-block' alt='' />Air Condition </li>
                                <li className='p-md d-flex align-items-center gap-2'><img src={ReceptionImg} className='d-inline-block' alt='' />24H. Reception</li>
                                <li className='p-md d-flex align-items-center gap-2'><img src={AtmImg} className='d-inline-block' alt='' />ATM</li>
                                <li className='p-md d-flex align-items-center gap-2'><img src={BanquetImg} className='d-inline-block' alt='' />Banquet</li> */}
                            </ul>
                        </div>
                    </div>
                    {/* {item?.facilities?.length === 0 && <div className='col-lg-6 col-md-12 col-sm-12'>
                        <div className='room-feature'>
                            <ul className='p-0'>
                                <li className='p-md d-flex align-items-center gap-2'><img src={ParkingbtmImg} className='d-inline-block' alt='' />Parking</li>
                                <li className='p-md d-flex align-items-center gap-2'><img src={WifibtnImg} className='d-inline-block' alt='' />Free Wifi</li>
                            </ul>
                        </div>
                    </div>} */}
                </div>
                <div className='price-room mb-3'>
                    <div className='price-box-bottom-txt d-flex align-items-center justify-content-between'>
                        <p className='d-inline-block size-big me-3 tab-0-content-sp mb-0'>
                            <b>
                                <CurrencyLogo currencyCode={currency} />
                                {item?.HotelPrice?.Price ? item?.HotelPrice?.Price : item?.price}
                            </b>
                            {/* <strike>$41</strike> */}
                        </p>
                        <p className='d-inline-block p-md'>{searchData ? days : 1} {t('night_incl')}</p>
                    </div>
                    {/* <div className=''>
                        <p className='text-danger p-md'>Only 2 Left</p>
                    </div> */}
                </div>
                <span className='btn btn-gradient-purple text-white d-inline-block h-auto w-100' onClick={() => handleBooking(item)}>
                    {t('book_now')}
                </span>
                {/* <Link to="/hotel-booking/payment" className='btn btn-gradient-purple'>Book Now</Link> */}
            </div>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        currency: state.auth.currency
    }
};

export default connect(mapStateToProps)(Products);