import React from 'react'
import HotelsImg from '../assets/hotel1.jpg';
import PeopleImg from '../assets/people-sharp-icon.svg';
import GearImg from '../assets/gear-icon.svg';
import SuitcaseImg from '../assets/suitcase.svg';
import { useEffect } from 'react';
import { useState } from 'react';
import { connect } from 'react-redux';
import { Convert } from "easy-currencies";
import { currency_converter } from '../reduxStore/User/userActions';

const CarsCard = ({ item, currency }) => {
    // const [convertedPrice, setConvertedPrice] = useState(item?.price);

    // useEffect(() => {
    //     const fetchConvertedPrice = async () => {
    //         if (currency !== 'USD') {
    //             const data = {
    //                 amount: item?.price,
    //                 from: 'USD',
    //                 to: currency
    //             };
    //             const conversion = await currency_converter(data);
    //             setConvertedPrice(Math.round(conversion?.data));
    //         } else {
    //             setConvertedPrice(Math.round(item?.price));
    //         }
    //     };

    //     fetchConvertedPrice();
    // }, [currency]);

    const currencyLogo = (currencyCode) => {
        switch (currencyCode) {
            case 'USD':
                return '$';
            case 'EUR':
                return '€';
            case 'GBP':
                return '£';
            case 'AUD':
                return 'A$';
            case 'SAR':
                return '﷼';
            case 'BRL':
                return 'R$';
            case 'ARS':
                return '$';
            case 'CAD':
                return 'C$';
            case 'CHF':
                return 'CHF';
            case 'SEK':
                return 'kr';
            case 'DKK':
                return 'kr';
            case 'NOK':
                return 'kr';
            case 'PLN':
                return 'zł';
            case 'RON':
                return 'lei';
            case 'TRY':
                return '₺';
            case 'ZAR':
                return 'R';
            case 'EGP':
                return '£';
            case 'NGN':
                return '₦';
            case 'KES':
                return 'KSh';
            case 'AED':
                return 'د.إ';
            case 'ILS':
                return '₪';
            case 'INR':
                return '₹';
            case 'CNY':
                return '¥';
            case 'JPY':
                return '¥';
            case 'SGD':
                return 'S$';
            case 'HKD':
                return 'HK$';
            case 'KRW':
                return '₩';
            case 'THB':
                return '฿';
            case 'IDR':
                return 'Rp';
            case 'MYR':
                return 'RM';
            case 'NZD':
                return 'NZ$';
            case 'FJD':
                return '$';
            case 'CLP':
                return '$';
            case 'PKR':
                return '₨';
            case 'BHD':
                return 'د.ب';
            case 'QAR':
                return 'ر.ق';
            case 'KWD':
                return 'د.ك';
            case 'OMR':
                return 'ر.ع.';
            case 'TWD':
                return 'NT$';
            case 'VND':
                return '₫';
            case 'MXN':
                return 'MX$';
            case 'COP':
                return 'COL$';
            case 'GTQ':
                return 'Q';
            case 'BAM':
                return 'KM';
            case 'HRK':
                return 'kn';
            case 'LKR':
                return '₨';
            case 'MOP':
                return 'MOP';
            case 'KZT':
                return '₸';
            case 'UZS':
                return 'сум';
            case 'MDL':
                return 'lei';
            default:
                return '';
        }
    };

    return (
        <div className='trending-box-content'>
            <div className='trending-car-img position-relative'>
                <img src={item?.images[0]} class="img-fluid" alt="..." />

                <div class="reviews-box position-absolute">
                    <ul class="d-inline-block star-rating p-0 mb-0">
                        <li class="d-inline-block"><i class="fas fa-star"></i></li>
                        <li class="d-inline-block"><i class="fas fa-star"></i></li><li class="d-inline-block"><i class="fas fa-star"></i></li>
                        <li class="d-inline-block"><i class="fas fa-star"></i></li>
                        <li class="d-inline-block"><i class="fas fa-star"></i></li>
                    </ul>
                    <p className='d-inline-block text-white p-all-small mb-0 ms-1'>(4.3)</p>
                </div>
            </div>
            <div className='trending-car-content'>
                <h3 className='mb-0'>{item?.make} {item?.model}</h3>
                <p className='p-md trending-car-location'>{item?.location}</p>
                <div className='car-inner-grid'>
                    <div className='d-inline-block'>
                        <img src={PeopleImg} class="" alt="..." />
                        <p className='p-md text-center'>4</p>
                    </div>
                    <div className='text-center'>
                        <img src={GearImg} className='d-inline-block' />
                        <p className='p-md'>Automatic</p>
                    </div>
                    <div className='text-center'>
                        <img src={SuitcaseImg} />
                        <p className='p-md'>3</p>
                    </div>
                </div>
                <div className='bottom-content'>
                    <div className='trending-car-price-wrap'>
                        <p className='p-md font-size-15'><b className='p-lg font-weight-500'>{currencyLogo(currency)}{item?.price}</b>/day</p>
                    </div>
                    <div className='trending-car-price-month-wrap text-end'>
                        <p className='month-text'>{currencyLogo(currency)}{item?.price}/month</p>
                    </div>
                </div>
                <a href="" className='btn btn-gradient-purple'>Book Now</a>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        currency: state.auth.currency
    }
};

export default connect(mapStateToProps)(CarsCard);