export const GET_AGENCY_DETAILS = "GET_AGENCY_DETAILS";
export const GET_ALL_USERS = "GET_ALL_USERS";
export const GET_ALL_SMALL_AGENCIES = "GET_ALL_SMALL_AGENCIES";
export const GET_TOTAL_SMALL = "GET_TOTAL_SMALL";
export const GET_TOTAL_MEDIUM = "GET_TOTAL_MEDIUM";
export const GET_ALL_MEDIUM_AGENCIES = "GET_ALL_MEDIUM_AGENCIES";
export const GET_ALL_STAFF_MEMBERS = "GET_ALL_STAFF_MEMBERS";
export const GET_TOTAL_STAFF_MEMBERS = "GET_TOTAL_STAFF_MEMBERS";
export const GET_LANGUAGE_DATA = "GET_LANGUAGE_DATA";
export const GET_ALL_LANGUAGES_LIST = "GET_ALL_LANGUAGES_LIST";
export const GET_ALL_CURRENCIES_LIST = "GET_ALL_CURRENCIES_LIST";
export const GET_DOWNPAYMENTS_DATA="GET_DOWNPAYMENTS_DATA";
export const GET_AGENCY_THEME="GET_AGENCY_THEME";
export const GET_CMS_PAGES = "GET_CMS_PAGES";
export const GET_CASHBACKS_DATA = "GET_CASHBACKS_DATA";