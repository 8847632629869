const TopCities = [
    {
        details: {
            cityName: "Abu Dhabi",
            countryCode: "AE",
            countryName: "United Arab Emirates",
            destinationName: "Abu Dhabi",
        },
        imageUrl: "https://www.timeoutabudhabi.com/cloud/timeoutabudhabi/2024/07/05/Untitled-6.jpeg",
    },
    {
        details: {
            cityName: "New York",
            countryCode: "US",
            countryName: "USA",
            destinationName: "New York",
        },
        imageUrl: "https://img.freepik.com/free-photo/view-statue-liberty-new-york-city_23-2150860843.jpg?t=st=1737016041~exp=1737019641~hmac=37dd0cf176bb3cacde81bd2c349ac798a19521b4bcce28f3ce7c6989269e32f9&w=740",
    },
    {
        details: {
            cityName: "Tokyo",
            countryCode: "JP",
            countryName: "Japan",
            destinationName: "Tokyo & surrounding area",
        },
        imageUrl: "https://dynamic-media-cdn.tripadvisor.com/media/photo-o/1b/4b/5d/10/caption.jpg?w=1400&h=1400&s=1&cx=1005&cy=690&chk=v1_2ed86f729380ea073850",
    },
    {
        details: {
            cityName: "Paris",
            countryCode: "CA",
            countryName: "Canada",
            destinationName: "Ontario",
        },
        imageUrl: "https://cdn.britannica.com/31/255531-050-B7E07090/eiffel-tower-paris-france-champ-de-mars-view.jpg",
    },
    {
        details: {
            cityName: "London",
            countryCode: "GB",
            countryName: "United Kingdom",
            destinationName: "London & surrounding area",
        },
        imageUrl: "https://dynamic-media-cdn.tripadvisor.com/media/photo-o/26/df/66/c8/caption.jpg?w=1200&h=-1&s=1",
    },
    {
        details: {
            cityName: "Dubai",
            countryCode: "AE",
            countryName: "United Arab Emirates",
            destinationName: "Dubai",
        },
        imageUrl: "https://bsmedia.business-standard.com/_media/bs/img/article/2024-10/21/full/1729486826-6805.jpg?im=FitAndFill=(826,465)",
    },
    {
        details: {
            cityName: "Singapore",
            countryCode: "SG",
            countryName: "Singapore",
            destinationName: "Singapore",
        },
        imageUrl: "https://bsmedia.business-standard.com/_media/bs/img/article/2023-03/30/full/1680163933-2575.jpg?im=FeatureCrop,size=(826,465)",
    },
    {
        details: {
            cityName: "Sydney",
            countryCode: "AU",
            countryName: "Australia",
            destinationName: "New South Wales",
        },
        imageUrl: "https://www.virginaustralia.com/content/dam/vaa/images/destinations/sydney/things-to-do/vaa-720x480-sydney-things-to-do-opera-house.jpg/_jcr_content/renditions/vaacore.web.720.0.jpg",
    },
    {
        details: {
            cityName: "Hong Kong",
            countryCode: "HK",
            countryName: "Hong Kong",
            destinationName: "Hong Kong",
        },
        imageUrl: "https://plus.unsplash.com/premium_photo-1661887292499-cbaefdb169ce?fm=jpg&q=60&w=3000&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MXx8aG9uZyUyMGtvbmclMjBza3lsaW5lfGVufDB8fDB8fHww",
    },
    {
        details: {
            cityName: "Rome",
            countryCode: "IT",
            countryName: "Italy",
            destinationName: "Latium",
        },
        imageUrl: "https://assets.voxcity.com/uploads/blog_images/Iconic%20Landmarks%20in%20Rome_original.jpg",
    },
    {
        details: {
            cityName: "Bangkok",
            countryCode: "TH",
            countryName: "Thailand",
            destinationName: "Bangkok & surrounding area",
        },
        imageUrl: "https://quintessentially.com/assets/noted/Header_2023-04-12-154210_sigz.webp",
    },
    {
        details: {
            cityName: "Istanbul",
            countryCode: "TR",
            countryName: "Turkey",
            destinationName: "Istanbul (province)",
        },
        imageUrl: "https://img.klm.com.cn/images/media/9B7CD988-D791-4B1F-8B5011942FFC8FD1",
    },
    {
        details: {
            cityName: "Barcelona",
            countryCode: "ES",
            countryName: "Spain",
            destinationName: "Barcelona & surrounding area",
        },
        imageUrl: "https://www.arch2o.com/wp-content/uploads/2017/12/Arch2O-6-buildings-you-must-visit-in-barcelona-designed-by-antoni-gaudi.webp",
    },
    {
        details: {
            cityName: "Los Angeles",
            countryCode: "US",
            countryName: "USA",
            destinationName: "California",
        },
        imageUrl: "https://a.travel-assets.com/findyours-php/viewfinder/images/res70/475000/475457-Los-Angeles.jpg",
    },
    {
        details: {
            cityName: "Bombay/ Mumbai",
            countryCode: "IN",
            countryName: "India",
            destinationName: "Maharashtra",
        },
        imageUrl: "https://i.natgeofe.com/n/a3ab98d9-e181-4ab3-a888-742c65acaf26/gateway-of-india-mumbai-india.jpg",
    },
    {
        details: {
            cityName: "Cape Town",
            countryCode: "ZA",
            countryName: "South Africa",
            destinationName: "Cape Town & surrounding area",
        },
        imageUrl: "https://img.klm.com.cn/images/media/B2B667B6-DC8C-46DA-B9F0A9EBF1877EDE",
    },
    {
        details: {
            cityName: "New Delhi",
            countryCode: "IN",
            countryName: "India",
            destinationName: "Delhi & surrounding area",
        },
        imageUrl: "https://cdn.britannica.com/13/146313-050-DD9AAC27/India-War-Memorial-arch-New-Delhi-Sir.jpg",
    },
];

export default TopCities;
