import React, { useEffect, useState } from 'react'
import { get_vender_id } from '../../reduxStore/User/userActions';
import { connect, useDispatch } from 'react-redux';
import { useToast } from '@chakra-ui/react';
import { get_cashback_data, set_cashback_data } from '../../reduxStore/vendors/vendorActions';
import debounce from 'lodash.debounce';

const CashBacks = ({ user, vendor_id, cashback_data }) => {
    const toast = useToast();
    const dispatch = useDispatch();
    const options = [
        { label: 'Percentage', value: '%' },
        { label: 'Flat', value: 'flat' }
    ];
    const [bookingData, setBookingData] = useState({
        cashback_type: '',
        cashBack_point: ''
    });
    const [referralData, setReferralData] = useState({
        referal_type: '',
        referal_point: ''
    });

    const handleBooking = (label, value) => {
        setBookingData(prev => ({ ...prev, [label]: value }));
    };

    const handleReferral = (label, value) => {
        setReferralData(prev => ({ ...prev, [label]: value }));
    };

    useEffect(() => {
        const fetchId = debounce(async () => {
            await get_vender_id("superadmin", dispatch);
        }, 1000);
        fetchId();
        return () => {
            fetchId.cancel();
        };
    }, []);

    useEffect(() => {
        const fetchData = debounce(async () => {
            await get_cashback_data(dispatch, toast, vendor_id);
        }, 1000);
        fetchData();
        return () => {
            fetchData.cancel();
        };
    }, [vendor_id]);

    useEffect(() => {
        if (cashback_data !== null) {
            setBookingData({
                cashback_type: cashback_data?.cashback_type !== null ? cashback_data?.cashback_type : '',
                cashBack_point: cashback_data?.cashBack_point !== null ? cashback_data?.cashBack_point : ''
            });
            setReferralData({
                referal_type: cashback_data?.referal_type !== null ? cashback_data?.referal_type : '',
                referal_point: cashback_data?.referal_point !== null ? cashback_data?.referal_point : ''
            });
        }
    }, [cashback_data]);

    return (
        <div className='mb-3'>
            <h2 className='mb-3'>Cashback</h2>
            <div className='box-with-shadow-bg-wrap'>
                <div className='container-fluid px-0 admin-booking-cashback-wrap'>
                    <div className='d-flex align-items-center gap-4 mb-3'>
                        <h3 className='mb-0'>Booking Cashback</h3>
                        <div class="form-check form-switch">
                            <input class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault"
                                checked={cashback_data?.cashback_status}
                                onChange={async (e) => {
                                    const data = {
                                        ...referralData,
                                        cashback_status: cashback_data?.cashback_status ? false : true
                                    };
                                    let isset = await set_cashback_data(dispatch, localStorage.getItem("token"), data, toast);
                                    if (isset) {
                                        await get_cashback_data(dispatch, toast, vendor_id);
                                    }
                                }}
                            />
                        </div>
                    </div>
                    <div className='row align-items-end mb-3'>
                        <div className='col-md-4'>
                            <div className='form-group mb-3'>
                                <label className='form-label text-grey-light fw-medium'>Select Cashback Type :</label>
                                <select
                                    className="form-select form-control"
                                    value={bookingData?.cashback_type}
                                    onChange={(e) => handleBooking("cashback_type", e.target.value)}
                                >
                                    <option value="" disabled>Select</option>
                                    {options?.length > 0 && options?.map((item, index) => (
                                        <option key={index} value={item?.value}> {item?.label}</option>
                                    ))}
                                </select>
                            </div>
                        </div>
                        <div className='col-md-4'>
                            <div className='form-group mb-3'>
                                <label className='form-label text-grey-light fw-medium'>Enter value :</label>
                                <input type='number' className='form-control' value={bookingData?.cashBack_point} placeholder='Enter value' onChange={(e) => handleBooking("cashBack_point", e.target.value)} />
                            </div>
                        </div>
                        <div className='col-md-4'>
                            <div className='d-flex gap-2 mb-3'>
                                <button type='button' className='btn-gradientlg-purple w-auto'
                                    onClick={async () => {
                                        if (bookingData.cashback_type === '' || bookingData.cashBack_point === '') {
                                            toast({ title: 'Please Select Type and Enter Points', status: "error", duration: 2000, position: "top-right", isClosable: true, });
                                        } else {
                                            const data = {
                                                ...bookingData,
                                            };
                                            let isset = await set_cashback_data(dispatch, localStorage.getItem("token"), data, toast);
                                            if (isset) {
                                                await get_cashback_data(dispatch, toast, vendor_id);
                                            }
                                        }
                                    }}
                                >
                                    Save
                                </button>
                            </div>
                        </div>

                    </div>
                </div>

                <div className='container-fluid px-0 admin-booking-cashback-wrap'>
                    <div className='d-flex align-items-center gap-4 mb-3'>
                        <h3 className='mb-0'>Referral Cashback</h3>
                        <div class="form-check form-switch">
                            <input class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault"
                                checked={cashback_data?.referral_status}
                                onChange={async (e) => {
                                    const data = {
                                        ...referralData,
                                        referral_status: cashback_data?.referral_status ? false : true
                                    };
                                    let isset = await set_cashback_data(dispatch, localStorage.getItem("token"), data, toast);
                                    if (isset) {
                                        await get_cashback_data(dispatch, toast, vendor_id);
                                    }
                                }}
                            />
                        </div>
                    </div>
                    <div className='row align-items-end'>
                        <div className='col-md-4'>
                            <div className='form-group mb-3'>
                                <label className='form-label text-grey-light fw-medium'>Select Cashback Type :</label>
                                <select
                                    className="form-select form-control"
                                    value={referralData?.referal_type}
                                    onChange={(e) => handleReferral("referal_type", e.target.value)}
                                >
                                    <option value="" disabled>Select</option>
                                    {options?.length > 0 && options?.map((item, index) => (
                                        <option key={index} value={item?.value}> {item?.label}</option>
                                    ))}
                                </select>
                            </div>
                        </div>
                        <div className='col-md-4'>
                            <div className='form-group mb-3'>
                                <label className='form-label text-grey-light fw-medium'>Enter value :</label>
                                <input type='number' className='form-control' value={referralData?.referal_point} placeholder='Enter value' onChange={(e) => handleReferral("referal_point", e.target.value)} />
                            </div>
                        </div>
                        <div className='col-md-4'>
                            <div className='d-flex gap-2 mb-3'>
                                <button type='button' className='btn-gradientlg-purple w-auto'
                                    onClick={async () => {
                                        if (referralData.referal_type === '' || referralData.referal_point === '') {
                                            toast({ title: 'Please Select Type and Enter Points', status: "error", duration: 2000, position: "top-right", isClosable: true, });
                                        } else {
                                            const data = {
                                                ...referralData,
                                            };
                                            let isset = await set_cashback_data(dispatch, localStorage.getItem("token"), data, toast);
                                            if (isset) {
                                                await get_cashback_data(dispatch, toast, vendor_id);
                                            }
                                        }
                                    }}
                                >
                                    Save
                                </button>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        user: state.auth.user,
        vendor_id: state.auth.vendor_id,
        cashback_data: state.vendor.cashback_data
    }
}
export default connect(mapStateToProps)(CashBacks);