import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import Login from './Login';
import LoginIcon from '../assets/login-btn-icon.svg';
import signupIcon from '../assets/signup-btn-icon.svg';
import Signup from './Signup';
import { Router, useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const AuthPages = ({ user, vendor_id, isMobile }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const tabs = [
    { name: `${t("login")}`, image: LoginIcon, path: 'login' },
    { name: `${t("sign_up")}`, image: signupIcon, path: 'signup' }
  ];

  return (
    <div className='login-signup-bg-wrapper'>
      <div className='login-signup-btn-grp'>
        {tabs.map(({ name, image, path }) => (
          <button
            type="button"
            key={path}
            className={`btn btn-default ${location.pathname.includes(path.toLowerCase()) ? "active" : ''}`}
            onClick={() => navigate('/' + path.toLowerCase())}
          >
            {/* Image added here */}
            <img src={image} alt={`${path}`} className="button-icon img-fluid" />
            {name}
          </button>
        ))}
      </div>
      {location.pathname === '/login' ? (
        <Login isMobile={isMobile} />
      ) : (
        <Signup />
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    vendor_id: state.auth.vendor_id
  }
}
export default connect(mapStateToProps)(AuthPages);