import React from 'react'
import { Modal } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

const Cancel = ({ show, handleClose, content1, content2, handleCancel }) => {
    const {t} = useTranslation();
    return (
        <Modal show={show}>
            <Modal.Body className='p-4'>
                <div class="modal-content">
                    <div class="modal-header border-0 cross-icon p-0 align-items-center justify-content-end mb-2">
                        <div className='d-flex align-items-center'>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={handleClose}></button>
                        </div>
                    </div>
                    <div class="modal-body border-0 modal-slider-view p-0">
                        <div className='text-center'>
                            <h2>
                                {content1}
                                {/* Are you sure want to cancel the booking? */}
                            </h2>
                            {/* <p className='p-md'>
                                {content2}
                            </p> */}
                        </div>
                    </div>
                    <div className='d-flex justify-content-center gap-3 p-3'>
                        <button className='btn-gradientlg-purple w-auto' onClick={handleCancel}>{t('yes')}</button>
                        <button className='disabled-btn w-auto' onClick={handleClose}>{t('no')}</button>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default Cancel