import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Input,
  FormControl,
  FormLabel,
  FormErrorMessage,
  useToast,
  SimpleGrid,
  Textarea,
} from '@chakra-ui/react';
import { useForm, Controller } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import axios from 'axios';
import LogoImg from '../../../../assets/dummy-image.jpg';
import ColorPicker from './ColorPicker';
import FileUpload from './FileUpload';
import { useDispatch, useSelector } from 'react-redux';
import { get_theme_setting_api, theme_setting_api } from '../../../../reduxStore/vendors/vendorActions';
import debounce from 'lodash.debounce';
const { REACT_APP_OTA_URL } = process.env;
const schema = yup.object().shape({
  headerLogo: yup
    .mixed()
    .required('Header Logo is required'),
  footerLogo: yup
    .mixed()
    .required('Footer Logo is required'),
  metaImage: yup
    .mixed()
    .required('Image is required'),
  favicon: yup
    .mixed()
    .required('Favicon is required'),
  webclip: yup
    .mixed()
    .required('Webclip is required'),
  headerGradientStart: yup.string().required('Header gradient start color is required'),
  headerGradientEnd: yup.string().required('Header gradient end color is required'),
  headerTextColor: yup.string().required('Header text color is required'),
  footerGradientStart: yup.string().required('Footer gradient start color is required'),
  footerGradientEnd: yup.string().required('Footer gradient end color is required'),
  footerTextColor: yup.string().required('Footer text color is required'),
  buttonPrimaryColor: yup.string().required('Primary button color is required'),
  buttonSecondaryColor: yup.string().required('Secondary button color is required'),
  customDomain: yup.string().url('Enter a valid URL').required('Domain is required'),
  metaTitle: yup.string().required('Meta title is required'),
  metaDescription: yup.string().required('Meta description is required'),
  copyright: yup.string().required('Copyright is required'),
  linkedin: yup.string()
    .required('LinkedIn Url is required')
    .matches(
      /^(https?:\/\/)?(www\.)?linkedin\.com\/(in|pub|company|school)\/[a-zA-Z0-9_-]+\/?$/,
      "Invalid LinkedIn Profile Link"
    ).nullable(),
  instagram: yup.string()
    .required('Instagram Url is required')
    .matches(
      /^(https?:\/\/)?(www\.)?instagram\.com\/[a-zA-Z0-9._-]+\/?$/,
      "Invalid Instagram Profile Link"
    ).nullable(),
  youtube: yup.string()
    .required('YouTube Url is required')
    .matches(
      /^(https?:\/\/)?(www\.)?youtube\.com\/@([a-zA-Z0-9._-]+)\/?$/,
      "Invalid YouTube Profile Link"
    ).nullable(),
  facebook: yup.string()
    .required('Facebook Url is required')
    .matches(
      /^(https?:\/\/)?(www\.)?facebook\.com\/[a-zA-Z0-9._-]+\/?$/,
      "Invalid Facebook Profile Link"
    ).nullable(),
});

const Theme = () => {
  const toast = useToast();
  const dispatch = useDispatch();
  const user = useSelector(state => state.auth.user);
  const themeData = useSelector(state => state.vendor.theme);
  const {
    register,
    handleSubmit,
    control,
    setValue,
    clearErrors,
    getValues,
    trigger,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'onChange',
    defaultValues: themeData,
  });
  const [previewHeader, setPreviewHeader] = useState(null);
  const [previewFooter, setPreviewFooter] = useState(null);
  const [previewFavicon, setPreviewFavicon] = useState(null);
  const [previewWebclip, setPreviewWebclip] = useState(null);
  const [previewMetaImage, setPreviewMetaImage] = useState(null);
  useEffect(() => {
    const fetchTheme = debounce(async () => {
      await get_theme_setting_api(dispatch,user?.token,user?._id,toast);
    }, 1000);
    fetchTheme();
    return () => {
      fetchTheme.cancel();
    };
  }, [user?.token, dispatch,toast]);
  useEffect(() => {
    if(themeData){
      setPreviewHeader(`${REACT_APP_OTA_URL}${themeData?.headerLogo}`)
      setPreviewMetaImage(`${REACT_APP_OTA_URL}${themeData?.metaImage}`);
      setPreviewFavicon(`${REACT_APP_OTA_URL}${themeData?.favicon}`);
      setPreviewWebclip(`${REACT_APP_OTA_URL}${themeData?.webclip}`);
      setPreviewFooter(`${REACT_APP_OTA_URL}${themeData?.footerLogo}`);
      reset(themeData);
    }
  }, [themeData]);
  const onSubmit = async (data) => {
    await theme_setting_api(dispatch, user?.token, data, toast);
  };
  const handleLogoChange = async (file, label) => {
    if (file) {
      if (label === 'headerLogo') {
        setPreviewHeader(`${REACT_APP_OTA_URL}${file}`);
      } else if (label === 'metaImage') {
        setPreviewMetaImage(`${REACT_APP_OTA_URL}${file}`);
      } if (label === 'favicon') {
        setPreviewFavicon(`${REACT_APP_OTA_URL}${file}`);
      } if (label === 'webclip') {
        setPreviewWebclip(`${REACT_APP_OTA_URL}${file}`);
      } else if (label === 'footerLogo') {
        setPreviewFooter(`${REACT_APP_OTA_URL}${file}`);
      }
    }
    setValue(label, file);
    await trigger(label);
  };

  const handleChange = async (value, label) => {
    setValue(label, value);
    await trigger(label);
  };

  return (
    <Box>
      <Box className="border-0 cross-icon p-0 d-flex gap-3 justify-content-space-between mb-3">
        <h1 className="text-color-theme mb-0">Theme</h1>
      </Box>
      <form onSubmit={handleSubmit(onSubmit)}>
        <SimpleGrid columns={{ base: 1, md: 2 }} spacing={6}>
          <Box>
            <h3 className="text-color-theme mb-0">Header</h3>
            <FormControl isInvalid={!!errors.headerLogo}>
              <Controller
                name="headerLogo"
                control={control}
                render={() => (
                  <FileUpload
                    type="headerLogo"
                    label="Logo"
                    preview={previewHeader || LogoImg}
                    onChange={(e) => handleLogoChange(e, 'headerLogo')}
                  />
                )}
              />
              <FormErrorMessage>{errors.headerLogo?.message}</FormErrorMessage>
            </FormControl>
            <FormControl isInvalid={!!errors.headerGradientStart || !!errors.headerGradientEnd}>
              <FormLabel>Background Gradient Colors</FormLabel>
              <SimpleGrid columns={{ base: 1, md: 2 }} spacing={4}>
                <ColorPicker
                  value={getValues('headerGradientStart')}
                  onChange={(value) => handleChange(value, 'headerGradientStart')}
                />
                <ColorPicker
                  value={getValues('headerGradientEnd')}
                  onChange={(value) => handleChange(value, 'headerGradientEnd')}
                />
              </SimpleGrid>
              <FormErrorMessage>
                {errors.headerGradientStart?.message || errors.headerGradientEnd?.message}
              </FormErrorMessage>
            </FormControl>
            <FormControl isInvalid={!!errors.headerTextColor}>
              <FormLabel>Text Color</FormLabel>
              <Controller
                name="headerTextColor"
                control={control}
                render={({ field }) => (
                  <ColorPicker
                    value={field.value}
                    onChange={(value) => {
                      field.onChange(value);
                      handleChange(value, 'headerTextColor');
                    }}
                  />
                )}
              />
              <FormErrorMessage>{errors.headerTextColor?.message}</FormErrorMessage>
            </FormControl>
          </Box>
          <Box>
            <h3 className="text-color-theme mb-0">Footer</h3>
            <FormControl isInvalid={!!errors.footerLogo}>
              <Controller
                name="footerLogo"
                control={control}
                render={() => (
                  <FileUpload
                    type="footerLogo"
                    label="Logo"
                    preview={previewFooter || LogoImg}
                    onChange={(e) => handleLogoChange(e, 'footerLogo')}
                  />
                )}
              />
              <FormErrorMessage>{errors.footerLogo?.message}</FormErrorMessage>
            </FormControl>
            <FormControl isInvalid={!!errors.footerGradientStart || !!errors.footerGradientEnd}>
              <FormLabel>Background Gradient Colors</FormLabel>
              <SimpleGrid columns={{ base: 1, md: 2 }} spacing={4}>
                <ColorPicker
                  value={getValues('footerGradientStart')}
                  onChange={(value) => handleChange(value, 'footerGradientStart')}
                />
                <ColorPicker
                  value={getValues('footerGradientEnd')}
                  onChange={(value) => handleChange(value, 'footerGradientEnd')}
                />
              </SimpleGrid>
              <FormErrorMessage>
                {errors.footerGradientStart?.message || errors.footerGradientEnd?.message}
              </FormErrorMessage>
            </FormControl>
            <FormControl isInvalid={!!errors.footerTextColor}>
              <FormLabel>Text Color</FormLabel>
              <Controller
                name="footerTextColor"
                control={control}
                render={({ field }) => (
                  <ColorPicker
                    value={field.value}
                    onChange={(value) => {
                      field.onChange(value);
                      handleChange(value, 'footerTextColor');
                    }}
                  />
                )}
              />
              <FormErrorMessage>{errors.footerTextColor?.message}</FormErrorMessage>
            </FormControl>
          </Box>
        </SimpleGrid>
        <h3 className="text-color-theme mb-0">Buttons</h3>
        <SimpleGrid columns={{ base: 1, md: 2 }} spacing={6}>
          <Box>
            <h4 className="text-color-theme mb-0">Primary</h4>
            <SimpleGrid columns={{ base: 1, md: 2 }} spacing={4}>
              <FormControl isInvalid={!!errors.buttonPrimaryBackground}>
                <FormLabel>Background Color</FormLabel>
                <Controller
                  name="buttonPrimaryColor"
                  control={control}
                  render={({ field }) => (
                    <ColorPicker
                      value={field.value}
                      onChange={(value) => {
                        field.onChange(value);
                        handleChange(value, 'buttonPrimaryBackground');
                      }}
                    />
                  )}
                />
                <FormErrorMessage>{errors.buttonPrimaryBackground?.message}</FormErrorMessage>
              </FormControl>
              <FormControl isInvalid={!!errors.buttonPrimaryColor}>
                <FormLabel>Text Color</FormLabel>
                <Controller
                  name="buttonPrimaryColor"
                  control={control}
                  render={({ field }) => (
                    <ColorPicker
                      value={field.value}
                      onChange={(value) => {
                        field.onChange(value);
                        handleChange(value, 'buttonPrimaryColor');
                      }}
                    />
                  )}
                />
                <FormErrorMessage>{errors.buttonPrimaryColor?.message}</FormErrorMessage>
              </FormControl>
            </SimpleGrid>
          </Box>
          <Box>
            <h4 className="text-color-theme mb-0">Secondary</h4>
            <SimpleGrid columns={{ base: 1, md: 2 }} spacing={4}>
              <FormControl isInvalid={!!errors.buttonSecondaryBackground}>
                <FormLabel>Background Color</FormLabel>
                <Controller
                  name="buttonPrimaryColor"
                  control={control}
                  render={({ field }) => (
                    <ColorPicker
                      value={field.value}
                      onChange={(value) => {
                        field.onChange(value);
                        handleChange(value, 'buttonSecondaryBackground');
                      }}
                    />
                  )}
                />
                <FormErrorMessage>{errors.buttonSecondaryBackground?.message}</FormErrorMessage>
              </FormControl>
              <FormControl isInvalid={!!errors.buttonSecondaryColor}>
                <FormLabel>Text Color</FormLabel>
                <Controller
                  name="buttonSecondaryColor"
                  control={control}
                  render={({ field }) => (
                    <ColorPicker
                      value={field.value}
                      onChange={(value) => {
                        field.onChange(value);
                        handleChange(value, 'buttonSecondaryColor');
                      }}
                    />
                  )}
                />
                <FormErrorMessage>{errors.buttonSecondaryColor?.message}</FormErrorMessage>
              </FormControl>
            </SimpleGrid>
          </Box>
        </SimpleGrid>
        <h3 className="text-color-theme mb-0">Custom Domain</h3>
        <SimpleGrid columns={{ base: 1, md: 2 }} spacing={6}>
          <FormControl isInvalid={!!errors.customDomain}>
            <FormLabel>Url</FormLabel>
            <Input
              type="url"
              placeholder="https://domain.com"
              {...register('customDomain')}
              onChange={(e) => handleChange(e.target.value, 'customDomain')}
            />
            <FormErrorMessage>{errors.customDomain?.message}</FormErrorMessage>
          </FormControl>
        </SimpleGrid>

        <h3 className="text-color-theme mb-0">Open Graph</h3>
        <SimpleGrid columns={{ base: 1, md: 2 }} spacing={6}>
          <FormControl isInvalid={!!errors.metaTitle}>
            <FormLabel>Title</FormLabel>
            <Input
              placeholder="Enter Meta Title"
              {...register('metaTitle')}
              onChange={(e) => handleChange(e.target.value, 'metaTitle')}
            />
            <FormErrorMessage>{errors.metaTitle?.message}</FormErrorMessage>
          </FormControl>

          <FormControl isInvalid={!!errors.metaDescription}>
            <FormLabel>Description</FormLabel>
            <Textarea
              placeholder="Enter Meta Description"
              {...register('metaDescription')}
              onChange={(e) => handleChange(e.target.value, 'metaDescription')}
            />
            <FormErrorMessage>{errors.metaDescription?.message}</FormErrorMessage>
          </FormControl>
          <FormControl isInvalid={!!errors.metaImage}>
            <Controller
              name="metaImage"
              control={control}
              render={() => (
                <FileUpload
                  type="metaImage"
                  label="Image"
                  preview={previewMetaImage || LogoImg}
                  onChange={(e) => handleLogoChange(e, 'metaImage')}
                />
              )}
            />
            <FormErrorMessage>{errors.metaImage?.message}</FormErrorMessage>
          </FormControl>
          <FormControl isInvalid={!!errors.favicon}>
            <Controller
              name="favicon"
              control={control}
              render={() => (
                <FileUpload
                  type="favicon"
                  label="Favicon"
                  preview={previewFavicon || LogoImg}
                  onChange={(e) => handleLogoChange(e, 'favicon')}
                />
              )}
            />
            <FormErrorMessage>{errors.favicon?.message}</FormErrorMessage>
          </FormControl>
          <FormControl isInvalid={!!errors.webclip}>
            <Controller
              name="webclip"
              control={control}
              render={() => (
                <FileUpload
                  type="webclip"
                  label="Webclip"
                  preview={previewWebclip || LogoImg}
                  onChange={(e) => handleLogoChange(e, 'webclip')}
                />
              )}
            />
            <FormErrorMessage>{errors.webclip?.message}</FormErrorMessage>
          </FormControl>
          <FormControl isInvalid={!!errors.copyright}>
            <FormLabel>Copyright</FormLabel>
            <Textarea
              placeholder="Enter Copyright"
              {...register('copyright')}
              onChange={(e) => handleChange(e.target.value, 'copyright')}
            />
            <FormErrorMessage>{errors.copyright?.message}</FormErrorMessage>
          </FormControl>
        </SimpleGrid>
        <h3 className="text-color-theme mb-0">Social Media</h3>
        <SimpleGrid columns={{ base: 1, md: 2 }} spacing={6}>
          <FormControl isInvalid={!!errors.linkedin}>
            <FormLabel>Linkedin</FormLabel>
            <Input
              placeholder="https://www.linkedin.com/in/name/"
              {...register('linkedin')}
              onChange={(e) => handleChange(e.target.value, 'linkedin')}
            />
            <FormErrorMessage>{errors.linkedin?.message}</FormErrorMessage>
          </FormControl>
          <FormControl isInvalid={!!errors.youtube}>
            <FormLabel>YouTube</FormLabel>
            <Input
              placeholder="https://www.youtube.com/@username"
              {...register('youtube')}
              onChange={(e) => handleChange(e.target.value, 'youtube')}
            />
            <FormErrorMessage>{errors.youtube?.message}</FormErrorMessage>
          </FormControl>
          <FormControl isInvalid={!!errors.facebook}>
            <FormLabel>Facebook</FormLabel>
            <Input
              placeholder="https://www.facebook.com/username"
              {...register('facebook')}
              onChange={(e) => handleChange(e.target.value, 'facebook')}
            />
            <FormErrorMessage>{errors.facebook?.message}</FormErrorMessage>
          </FormControl>
          <FormControl isInvalid={!!errors.instagram}>
            <FormLabel>Instagram</FormLabel>
            <Input
              placeholder="https://www.instagram.com/username/"
              {...register('instagram')}
              onChange={(e) => handleChange(e.target.value, 'instagram')}
            />
            <FormErrorMessage>{errors.instagram?.message}</FormErrorMessage>
          </FormControl>
        </SimpleGrid>
        <Button mt={4} type="submit" className='btn-gradientlg-purple w-auto'>
          Save
        </Button>
      </form>
    </Box>
  );
};

export default Theme;
