import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { connect, useDispatch } from 'react-redux';
import { apply_language_active, get_all_languages_lists, get_default_lang_and_currency, set_default_lang_and_currency, enable_lang_and_currency } from '../../reduxStore/vendors/vendorActions';
import { useToast } from '@chakra-ui/react';
import Select, { components } from 'react-select';

const LanguagesList = ({ user, langLists }) => {
    // const [languages, setLanguages] = useState([]);
    const dispatch = useDispatch();
    const toast = useToast();
    const [verifiedLangs, setVerifiedLangs] = useState([]);
    const [verifiedCurrencies, setVerifiedCurrencies] = useState([]);
    const [defaultData, setDefaultData] = useState({
        lang: '',
        curr: ''
    });
    const [defaultId, setDefaultId] = useState(null);
    const [isEnabled, setIsEnabled] = useState(false);

    const [selectedCurrencies, setSelectedCurrencies] = useState([]);
    const currencyOptions = [
        { label: "USD", value: "USD" },
        { label: "BRL", value: "BRL" },
        { label: "ARS", value: "ARS" },
        { label: "CAD", value: "CAD" },
        { label: "GBP", value: "GBP" },
        { label: "EUR", value: "EUR" },
        { label: "CHF", value: "CHF" },
        { label: "SEK", value: "SEK" },
        { label: "DKK", value: "DKK" },
        { label: "NOK", value: "NOK" },
        { label: "PLN", value: "PLN" },
        { label: "RON", value: "RON" },
        { label: "TRY", value: "TRY" },
        { label: "ZAR", value: "ZAR" },
        { label: "EGP", value: "EGP" },
        { label: "NGN", value: "NGN" },
        { label: "KES", value: "KES" },
        { label: "AED", value: "AED" },
        { label: "ILS", value: "ILS" },
        { label: "INR", value: "INR" },
        { label: "CNY", value: "CNY" },
        { label: "JPY", value: "JPY" },
        { label: "SGD", value: "SGD" },
        { label: "HKD", value: "HKD" },
        { label: "KRW", value: "KRW" },
        { label: "THB", value: "THB" },
        { label: "IDR", value: "IDR" },
        { label: "MYR", value: "MYR" },
        { label: "AUD", value: "AUD" },
        { label: "NZD", value: "NZD" },
        { label: "FJD", value: "FJD" },
        { label: "CLP", value: "CLP" },
        { label: "SAR", value: "SAR" }
    ];

    const handleCurrency = (selected) => {
        const updatedSelection = selected
      ? [currencyOptions.find((option) => option.value === defaultData.curr), ...selected.filter((opt) => opt.value !== defaultData.curr)]
      : [currencyOptions.find((option) => option.value === defaultData.curr)];
        setSelectedCurrencies(updatedSelection);
    };

    const handleChange = (label, value) => {
        setDefaultData((prev) => ({ ...prev, [label]: value }));
    };

    const [selectedOptions, setSelectedOptions] = useState([]);
    const [langoptions, setLangoptions] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            let getdata = await get_all_languages_lists(user?.token, dispatch, "all");
            if (!getdata) return;

            // console.log("----", getdata);

            const data = getdata?.data?.map((item) => ({
                label: item?.language,
                value: item
            }));
            // console.log("Lang optionsnss", data);
            setLangoptions(data);
            setSelectedCurrencies(getdata?.currency?.map((item) => ({
                label: item,
                value: item
            })));
            setSelectedOptions(getdata?.data?.filter((item) => item?.value === true && item?._id !== defaultData?.lang)?.map((item) => ({
                label: item?.language,
                value: item
            })));
        };
        fetchData();
    }, []);

    const handleOptions = (selected) => {
        const updatedSelection = selected
      ? [selectedOptions.find((option) => option.value._id === defaultData.lang), ...selected.filter((opt) => opt.value._id !== defaultData.lang)]
      : [selectedOptions.find((option) => option.value._id === defaultData.lang)];
        setSelectedOptions(updatedSelection);
    };

    const customOption = (props) => {
        const { data, innerRef, innerProps } = props;
        return (
            <div ref={innerRef} {...innerProps} style={{ display: "flex", alignItems: "center", padding: "10px" }}>
                <img
                    src={data.value.flag}
                    alt={data.label}
                    style={{ width: 20, height: 15, marginRight: 10 }}
                />
                {data.label}
            </div>
        );
    };

    const CustomMultiValue = (props) => {
        const { data } = props;
        console.log(data);
        
        return (
            <components.MultiValue {...props}>
            {data.value._id !== defaultData?.lang && <components.MultiValueRemove {...props} />}
          </components.MultiValue>
        );
      };

    const handleEnable = async () => {
        const langs = selectedOptions?.map((item) => item?.value?._id);
        const currs = selectedCurrencies?.map((item) => item?.value);
        const data = {
            langIDs: langs,
            currency: currs
        };
        let isEnable = await enable_lang_and_currency(dispatch, user?.token, data, toast);
        if (isEnable) {
            setIsEnabled(true);
        }
    };
    useEffect(() => {
        const fetchEnabledData = async () => {
            let getdata = await get_all_languages_lists(user?.token, dispatch, "all");
            if (getdata) {
                const filteredLangs = getdata?.data?.filter((item) => item?.value);
                setVerifiedLangs(filteredLangs);
                setVerifiedCurrencies(getdata?.currency);
                setIsEnabled(false);
            }
        };
        fetchEnabledData();
    }, [isEnabled]);

    // const handleToogle = async (item) => {
    //     const data = {
    //         id: item?._id,
    //         language: item?.language,
    //         value: item?.value === true ? false : true,
    //     };
    //     let isapplied = await apply_language_active(user?.token, data);
    //     if (isapplied) await get_all_languages_lists(user?.token, dispatch, "all");
    // };

    useEffect(() => {
        const handleDefault = async () => {
            let getdefault = await get_default_lang_and_currency(user?.token);
            if (getdefault === null) return;

            setDefaultData((prev) => ({
                ...prev,
                lang: getdefault?.defaultLanguage?._id,
                curr: getdefault?.defaultCurrency
            }));
            setDefaultId(getdefault?._id);
        };
        handleDefault();
    }, []);
    const stylesLang= {
        multiValue: (base, state) => {
          return state.data.value._id===defaultData?.lang ? { ...base, backgroundColor: 'gray' } : base;
        },
        multiValueLabel: (base, state) => {
          return state.data.value._id===defaultData?.lang
            ? { ...base, color: 'white', paddingRight: 6 }
            : base;
        },
        multiValueRemove: (base, state) => {
          return state.data.value._id===defaultData?.lang ? { ...base, display: 'none' } : base;
        },
    };
    const stylesCurr= {
        multiValue: (base, state) => {
            console.log(state);
            
          return state.data.value===defaultData?.curr ? { ...base, backgroundColor: 'gray' } : base;
        },
        multiValueLabel: (base, state) => {
          return state.data.value===defaultData?.curr
            ? { ...base, color: 'white', paddingRight: 6 }
            : base;
        },
        multiValueRemove: (base, state) => {
          return state.data.value===defaultData?.curr ? { ...base, display: 'none' } : base;
        },
    };
    return (
        <div className='mb-3'>
            <h2 className='mb-3'>Languages and Currency</h2>
            <div className='box-with-shadow-bg-wrap'>
                <div className='container-fluid px-0'>
                    <div className='row align-items-end py-3 mb-2'>
                        <h5 className='mb-3'>Add Default Language/Currency</h5>
                        <div className='col-md-4'>
                            <div className='form-group mb-3'>
                                <label className='form-label text-grey-light fw-medium'>Select Language :</label>
                                <select
                                    className="form-control"
                                    value={defaultData?.lang}
                                    onChange={(e) => handleChange("lang", e.target.value)}
                                >
                                    <option value="" disabled>Choose Default Language</option>
                                    {verifiedLangs?.length > 0 && verifiedLangs?.map((item, index) => (
                                        <option key={index} value={item?._id}> {item?.language}</option>
                                    ))}
                                </select>
                            </div>
                        </div>
                        <div className='col-md-4'>
                            <div className='form-group mb-3'>
                                <label className='form-label text-grey-light fw-medium'>Select Currency :</label>
                                <select
                                    className="form-control"
                                    value={defaultData?.curr}
                                    onChange={(e) => handleChange("curr", e.target.value)}
                                >
                                    <option value="" disabled>Choose Default Currency</option>
                                    {verifiedCurrencies?.map((item, index) => (
                                        <option key={index} value={item}> {item}</option>
                                    ))}
                                </select>
                            </div>
                        </div>
                        <div className='col-md-4'>
                            <div className='mb-3'>
                                <button type='submit' className='btn-gradientlg-purple w-auto'
                                    onClick={async () => {
                                        if (defaultData.lang === '' || defaultData.curr === '') {
                                            toast({ title: 'Please Select Language and Currency', status: "error", duration: 2000, position: "top-right", isClosable: true, });
                                        } else {
                                            const data = {
                                                language: defaultData?.lang,
                                                currency: defaultData?.curr
                                            };
                                            if (defaultId !== null) {
                                                data.id = defaultId;
                                            }
                                            await set_default_lang_and_currency(user?.token, data, toast);
                                        }
                                    }}
                                >
                                    Set Default
                                </button>
                            </div>
                        </div>

                    </div>
                </div>

                <div className='container-fluid px-0'>
                    <div className='row'>
                        <h5 className='mb-3'>Add Multiple Language/Currency</h5>
                        
                        <div className='col-md-4'>
                            <div className='form-group mb-3'>
                                <label className='form-label text-grey-light fw-medium'>Enable Languages :</label>
                                <Select
                                    value={selectedOptions}
                                    styles={stylesLang}
                                    onChange={handleOptions}
                                    options={langoptions}
                                    isMulti={true}
                                    isClearable={selectedOptions.some((v) => v.value._id!==defaultData?.lang)}
                                    placeholder="Enable Languages"
                                   components={{
                                        Option: customOption,
                                        // MultiValue: CustomMultiValue
                                    }}
                                />
                            </div>
                        </div>
                        <div className='col-md-4'>
                            <div className='form-group mb-3'>
                                <label className='form-label text-grey-light fw-medium'>Enable Currencies :</label>
                                <Select
                                    value={selectedCurrencies}
                                    styles={stylesCurr}
                                    onChange={handleCurrency}
                                    options={currencyOptions}
                                    isMulti={true}
                                    isClearable={selectedCurrencies.some((v) => v.value!==defaultData?.curr)}
                                    placeholder="Enable Currencies"
                                // components={{ Option: customOption }}
                                />
                            </div>
                        </div>
                        <div className='col-md-4'>
                            <label className='form-label text-white fw-medium opacity-0'>lorem</label>
                            <div className=''>
                                <button type='submit'
                                    className={(selectedOptions.length === 0 || selectedCurrencies.length === 0) ? 'disabled-btn w-auto' : 'btn-gradientlg-purple w-auto'}
                                    onClick={handleEnable}
                                    // onClick={async () => {
                                    //     if (defaultData.lang === '' || defaultData.curr === '') {
                                    //         toast({ title: 'Please Select Language and Currency', status: "error", duration: 2000, position: "top-right", isClosable: true, });
                                    //     } else {
                                    //         const data = {
                                    //             language: defaultData?.lang,
                                    //             currency: defaultData?.curr
                                    //         };
                                    //         if (defaultId !== null) {
                                    //             data.id = defaultId;
                                    //         }
                                    //         await set_default_lang_and_currency(user?.token, data, toast);
                                    //     }
                                    // }}
                                    disabled={selectedOptions.length === 0 || selectedCurrencies.length === 0}
                                >
                                    Enable
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <div className='box-with-shadow-bg-wrap'>
                <div className='select-lang-setting-admin'
                // style={{
                //     height: '300px',
                //     width: '500px',
                //     border: '1px solid #ddd',
                //     padding: '10px'
                // }}
                >
                    {langLists.map((item, index) => (
                        <div key={index} className='d-flex align-items-center gap-2'>
                            <img src={item?.flag} alt='img not found' style={{ maxHeight: '19px' }} />
                            <span className=''>{item?.language}</span>
                            <input type="checkbox" checked={item?.value} onClick={() => handleToogle(item)} />
                        </div>
                    ))}
                </div>
            </div> */}
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        user: state.auth.user,
        vendor_id: state.auth.vendor_id,
        langdata: state.vendor.langdata,
        langLists: state.vendor.langLists
    }
}
export default connect(mapStateToProps)(LanguagesList);