import { useToast } from '@chakra-ui/react';
import moment from 'moment-timezone';
import React, { useEffect, useRef, useState } from 'react'
import { Form, Modal } from 'react-bootstrap'
import { getCountryCallingCode, isValidPhoneNumber } from 'react-phone-number-input';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import PhoneNumber from '../shared/PhoneNumber';
import { create_customer_by_admin, edit_user_by_admin, upload_customer_profile_pic } from '../reduxStore/vendors/vendorActions';
import { get_all_user_lists } from '../reduxStore/User/userActions';
import userProfile from '../assets/admin-profile-img.svg';

const { REACT_APP_OTA_URL } = process.env;

const CreateCustomer = ({ show, handleClose, vendor_id, user, data }) => {
    const toast = useToast();
    const [formdata, setFormdata] = useState({
        username: '',
        email: '',
        // dob: '',
        // setpassword: '',
        // password: '',
        phone_number: '',
        address: '',
        profile_pic: ''
    });
    const [country, setCountry] = useState('US');

    const [errors, setErrors] = useState({
        username: '',
        email: '',
        // dob: '',
        // setpassword: '',
        // password: '',
        phone_number: '',
        address: '',
        profile_pic: ''
    });
    const exptest = /^(?!.*\.\.)(?!.*-@)(?!.*#)([A-Z0-9._%+-]+)@([A-Z0-9.-]+\.[A-Z]{2,})$/i;
    // const [isShow, setIsShow] = useState(false);
    // const [isConfirmShow, setIsConfirmShow] = useState(false);
    let countrycode = getCountryCallingCode(country);
    let checkval = `+${countrycode}` + formdata.phone_number;
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const maxDate = moment.tz(userTimeZone).format('YYYY-MM-DD');
    const imageRef = useRef();
    const [imagePreview, setImagePreview] = useState('');

    const handleChange = (label, value) => {

        switch (label) {
            case "username":
                setFormdata((prev) => ({ ...prev, [label]: value }));
                setErrors((prev) => ({ ...prev, [label]: value === "" ? "Name is required" : null }));
                break;
            case "email":
                setFormdata((prev) => ({ ...prev, [label]: value }));
                setErrors((prev) => ({ ...prev, [label]: value === "" ? "Email is required" : !exptest.test(value) ? "Invalid email" : null }));
                break;
            // case "dob":
            //     setErrors({ ...errors, dob: value === "" ? "DOB is required" : null });
            //     setFormdata({ ...formdata, [label]: value });
            //     break;
            // case "setpassword":
            //     setFormdata((prev) => ({ ...prev, [label]: value }));
            //     setErrors((prev) => ({ ...prev, [label]: value === "" ? "Password is required" : value.length <= 5 ? "Password should contain more than 5 characters" : null }));
            //     break;
            // case "password":
            //     setFormdata((prev) => ({ ...prev, [label]: value }));
            //     setErrors((prev) => ({ ...prev, [label]: value === "" ? "Password is required" : value.length <= 5 ? "Password should contain more than 5 characters" : value !== formdata?.setpassword ? "Confirm Password doesnot match" : null }));
            //     break;
            case "phone_number":
                let checkval = `+${countrycode}` + value;
                //console.log(checkval,isValidPhoneNumber(checkval));
                setFormdata((prev) => ({ ...prev, [label]: value }));
                setErrors((prev) => ({ ...prev, [label]: !isValidPhoneNumber(checkval) ? "Invalid Phone number" : null }));
                break;
            case "address":
                setFormdata((prev) => ({ ...prev, [label]: value }));
                setErrors((prev) => ({ ...prev, [label]: value === "" ? "Address is required" : null }));
                break;

            default:
                setFormdata((prev) => ({ ...prev, [label]: value }));
                break;
        }
    };

    const handleUpload = async (val) => {
        setImagePreview(URL.createObjectURL(val));
        let getdata = await upload_customer_profile_pic(dispatch, val);
        console.log(getdata);
        if (getdata) {
            setFormdata((prev) => ({ ...prev, profile_pic: getdata?.path }));
        }
    };

    const handleValid = () => {
        let err = false;

        const Errors = {
            username: formdata.username === "" ? "Name is required" : null,
            address: formdata.address === "" ? "Address is required" : null,
            email: formdata.email === "" ? "Email is required" : !exptest.test(formdata.email) ? "Invalid email" : null,
            // dob: formdata?.dob === "" ? "DOB is required" : null,
            // setpassword: formdata.setpassword === "" ? "Set Password is required" : formdata.setpassword.length <= 5 ? "Password should contain more than 5 characters" : null,
            // password: formdata.password === "" ? "Confirm Password is required" : formdata.password.length <= 5 ? "Password should contain more than 5 characters" : formdata?.password !== formdata?.setpassword ? "Confirm Password doesnot match" : null,
            phone_number: formdata.phone_number === "" ? "Phone number is required" : !isValidPhoneNumber(checkval) ? "Invalid Phone number" : null,
        };

        let newErrors = Object.entries(Errors);
        newErrors.forEach(([key, value]) => {
            if (value) {
                err = true;
                setErrors((prev) => ({ ...prev, [key]: value }));
            }
        });

        return err;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (handleValid()) return;

        const userdata = {
            // username: formdata?.username,
            email: formdata?.email,
            phone_number: formdata?.phone_number,
            // password: formdata?.password,
            country_code: `+${countrycode}`,
            country_code_name: country,
            name: formdata.username,
            address: formdata?.address,
            profile_pic: formdata?.profile_pic,
            role: "user"
        };

        if (data) {
            userdata.user_id = data?._id;
            userdata.type = 'user';
        }

        let createuser = data ? await edit_user_by_admin(dispatch, vendor_id, user?.token, userdata, toast) : await create_customer_by_admin(dispatch, vendor_id, user?.token, userdata, toast);

        if (createuser) {
            setFormdata({ username: '', email: '', phone_number: '', address: '' });
            await get_all_user_lists(toast, user?.token, dispatch, vendor_id, '', 1, 10);
            handleClose();
        }
    };

    useEffect(() => {
        if (!data) {
            setFormdata({ username: '', email: '', phone_number: '', address: '' });
            setCountry('US');
        } else if (data) {
            setFormdata({
                username: data?.name ? data?.name : '',
                email: data?.email ? data?.email : '',
                phone_number: data?.phone_number ? data?.phone_number : '',
                address: data?.address ? data?.address : '',
                profile_pic: data?.profile_pic ? data?.profile_pic : ''
            });
            setCountry(data?.country_code_name ? data?.country_code_name : 'US');
            setImagePreview(data?.profile_pic !== null ? `${REACT_APP_OTA_URL}${data?.profile_pic}` : '');
        }
    }, [data]);

    useEffect(() => {
        if (formdata.phone_number === '') return;
        handleChange('phone_number', formdata?.phone_number);
    }, [country]);

    return (
        <div>
            <Modal show={show} size='lg'>
                <Modal.Body className='p-4'>
                    <div class="modal-content">
                        <div className='custom-popup-scrollbar'>
                            <div class="modal-header border-0 cross-icon p-0 align-items-center mb-3">
                                <h2 className='mb-0 text-color-theme fw-bold'>{data ? "Edit" : "Add"} Customer </h2>
                                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"
                                    onClick={() => {
                                        setFormdata({ username: '', email: '', phone_number: '', address: '' });
                                        setErrors({ username: null, email: null, phone_number: null, address: null });
                                        handleClose();
                                    }}
                                ></button>
                            </div>
                            <Form onSubmit={(e) => handleSubmit(e)} noValidate>
                                <div className=''>
                                    <div className='admin-profile-wrap'>
                                        <div className='admin-profile-img-container position-relative cursor-pointer' onClick={() => imageRef.current.click()}>
                                            <div className='admin-profile-edit-icon-wrap d-flex align-items-center justify-content-end position-absolute top-0 end-0 px-3'>
                                                <i className="bi bi-pencil-square cursor-pointer"></i>
                                            </div>
                                            <div className='d-flex flex-column light-border-circle-wrapper'>
                                                <div className='admin-profile-img-wrap'>
                                                    <img src={imagePreview !== '' ? imagePreview : userProfile} alt='' />
                                                </div>
                                            </div>
                                        </div>
                                        <input
                                            type='file'
                                            className='form-control d-none'
                                            accept='.jpeg,.jpg,.png'
                                            onChange={(e) => {
                                                const files = e.target.files[0];
                                                handleUpload(files);
                                                e.target.value = "";
                                            }}
                                            ref={imageRef}
                                        />
                                    </div>
                                    <div className='row'>
                                        <div className='col-md-6'>
                                            <div className='form-group mb-3'>
                                                <label className='form-label text-grey-light fw-medium'>Name</label>
                                                <input
                                                    type='text'
                                                    className='form-group form-control'
                                                    placeholder='Enter your name'
                                                    value={formdata.username}
                                                    onChange={(e) => handleChange('username', e.target.value)}
                                                />
                                                {errors.username && <p className='text-danger'>{errors.username}</p>}
                                            </div>
                                        </div>
                                        <div className='col-md-6'>
                                            <div className='form-group mb-3'>
                                                <label className='form-label text-grey-light fw-medium'>Email</label>
                                                <input
                                                    type='email'
                                                    className='form-group form-control'
                                                    placeholder='Enter your email'
                                                    value={formdata.email}
                                                    onChange={(e) => handleChange('email', e.target.value)}
                                                    onKeyDown={(e) => e.key === " " && e.preventDefault()}
                                                />
                                                {errors.email && <p className='text-danger'>{errors.email}</p>}
                                            </div>
                                        </div>
                                        <div className='col-md-12'>
                                            <div className='form-group mb-3'>
                                                <label className='form-label text-grey-light fw-medium'>Phone Number</label>
                                                <PhoneNumber
                                                    country={country}
                                                    setCountry={(val) => setCountry(val)}
                                                    phoneNumber={formdata.phone_number}
                                                    handleChange={(val) => handleChange('phone_number', val)}
                                                />
                                                {errors.phone_number && <p className='text-danger'>{errors.phone_number}</p>}
                                            </div>
                                        </div>
                                        <div className='col-md-12'>
                                            <div className='form-group mb-3'>
                                                <label className='form-label'>Address </label>
                                                <textarea
                                                    minLength={4}
                                                    className='form-group form-control'
                                                    placeholder='address details'
                                                    value={formdata.address}
                                                    onChange={(e) => handleChange('address', e.target.value)}
                                                />
                                                {errors.address && <p className='text-danger'>{errors.address}</p>}
                                            </div>
                                        </div>
                                    </div>


                                    {/* <div className="form-group mb-3">
                                        <label className="form-label text-white">DOB</label>
                                        <input
                                            type="date"
                                            name="dob"
                                            placeholder=""
                                            className="form-control"
                                            defaultValue={formdata?.dob}
                                            max={maxDate}
                                            onChange={(e) => handleChange('dob', e.target.value)}
                                        />
                                        {errors.dob && (
                                            <p className="d-flex flex-start text-danger error-msg mb-1 mb-md-0">
                                                {errors.dob}
                                            </p>
                                        )}
                                    </div> */}

                                    {/* <div className='form-group mb-3'>
                                        <label className='form-label text-grey-light fw-medium'>Set Password</label>
                                        <div className='position-relative pwd-field'>
                                            <input
                                                type={isShow ? 'text' : 'password'}
                                                maxlength="45"
                                                className='form-group form-control'
                                                value={formdata?.setpassword}
                                                placeholder='Enter your password'
                                                onChange={(e) => handleChange('setpassword', e.target.value)}
                                                onKeyDown={(e) => e.key === " " && e.preventDefault()}
                                            />
                                            {!isShow ? (
                                                <span className='pwd-eye-wrap'><i class="bi bi-eye-slash"
                                                    color="black"
                                                    size={20}
                                                    onClick={() => setIsShow(!isShow)}
                                                /></span>
                                            ) : (
                                                <span className='pwd-eye-wrap'><i class="bi bi-eye"
                                                    color="black"
                                                    size={20}
                                                    onClick={() => setIsShow(!isShow)}
                                                /></span>
                                            )}
                                        </div>
                                        {errors?.setpassword && <p className='text-danger'>{errors?.setpassword}</p>}
                                    </div>
                                    <div className='form-group mb-3'>
                                        <label className='form-label text-grey-light fw-medium'>Confirm Password </label>
                                        <div className='position-relative pwd-field'>
                                            <input
                                                maxlength="45"
                                                type={isConfirmShow ? 'text' : 'password'}
                                                className='form-group form-control'
                                                placeholder='Enter your confirm password'
                                                onChange={(e) => handleChange('password', e.target.value)}
                                                onKeyDown={(e) => e.key === " " && e.preventDefault()}
                                            />
                                            {!isConfirmShow ? (
                                                <span className='pwd-eye-wrap'><i class="bi bi-eye-slash"
                                                    color="black"
                                                    size={20}
                                                    onClick={() => setIsConfirmShow(!isConfirmShow)}
                                                /></span>
                                            ) : (
                                                <span className='pwd-eye-wrap'><i class="bi bi-eye"
                                                    color="black"
                                                    size={20}
                                                    onClick={() => setIsConfirmShow(!isConfirmShow)}
                                                /></span>
                                            )}
                                        </div>
                                        {errors?.password && <p className='text-danger'>{errors?.password}</p>}
                                    </div> */}
                                    <div className='d-flex align-items-center justify-content-end'>
                                        <button type='submit' className='btn-gradient-purple w-auto'>{data ? 'Edit' : 'Add'} Customer</button>
                                    </div>
                                </div>
                            </Form>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default CreateCustomer