import React from 'react';
import GoogleIcon from '../assets/google-icon.svg';
import FbIcon from '../assets/fb-icon.svg';
import { signInWithPopup } from '@firebase/auth';
import { handle_user_social_login } from '../reduxStore/User/userActions';
import { useToast } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { appleProvider, auth, facebookProvider, googleProvider } from '../firebase/firebase';
import { useGoogleLogin } from '@react-oauth/google';
import axios from 'axios';

const SocialLogin = ({ vendor_id }) => {
    const toast = useToast();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const login = useGoogleLogin({
        onSuccess: async (codeResponse) => {
            let res = await axios.get(`https://www.googleapis.com/oauth2/v1/userinfo?access_token=${codeResponse?.access_token}`,
                {
                    headers: {
                        Authorization: `Bearer ${codeResponse?.access_token}`,
                        Accept: "application/json",
                    },
                });
            if (res.data) {
                const userdata = {
                    google_id: res?.data?.id,
                    name: res?.data?.name,
                    email: res?.data?.email,
                };
                await handle_user_social_login(vendor_id, userdata, toast, navigate, dispatch);
            }
        },
        onError: (error) => {
            console.log("error in google", error)
        }
    });

    const handleLogin = async (label) => {
        try {
            switch (label) {
                case 'google':
                    let result = await signInWithPopup(auth, googleProvider);
                    //console.log("Google result ====>", result);
                    const userdata = {
                        google_id: result?.user?.providerData[0]?.uid,
                        name: result?.user?.providerData[0]?.displayName,
                        email: result?.user?.providerData[0]?.email,
                    };
                    await handle_user_social_login(vendor_id, userdata, toast, navigate, dispatch);
                    break;

                case 'facebook':
                    let result2 = await signInWithPopup(auth, facebookProvider);
                    //console.log("Facebook result ====>", result2);
                    const userdata2 = {
                        facebook_id: result2?.user?.providerData[0]?.uid,
                        name: result2?.user?.providerData[0]?.displayName,
                        email: result2?.user?.providerData[0]?.email,
                    };
                    await handle_user_social_login(vendor_id, userdata2, toast, navigate, dispatch);
                    break;

                case 'apple':
                    let result3 = await signInWithPopup(auth, appleProvider);
                    console.log("Apple result ====>", result3);
                    const userdata3 = {
                        apple_id: result3?.user?.providerData[0]?.uid,
                        name: result3?.user?.providerData[0]?.displayName,
                        email: result3?.user?.providerData[0]?.email,
                    };
                    console.log("apple", userdata3);
                    await handle_user_social_login(vendor_id, userdata3, toast, navigate, dispatch);
                    break;
                default:
                    break;
            }

            // let result = await signInWithPopup(auth, googleProvider);
            // //console.log("Google result ====>", result);
            // const userdata = {
            //   google_id: result?.user?.providerData[0]?.uid,
            //   name: result?.user?.providerData[0]?.displayName,
            //   email: result?.user?.providerData[0]?.email,
            // };
            // await handle_user_social_login(vendor_id, userdata, toast, navigate, dispatch);
        } catch (error) {
            console.log("Social Login Error ====>", error);
        }
    };

    return (
        <div class="d-flex justify-content-center align-items-center gap-5 my-4 social-login-icons-wrap">
            <div className="d-inline-block text-white h4 mb-0 cursor-pointer"
                //onClick={() => handleLogin('google')}
                onClick={() => login()}
            >
                <img src={GoogleIcon} alt='GoogleIcon' className='' />
            </div>
            <div className="d-inline-block text-white h4 mb-0 cursor-pointer" onClick={() => handleLogin('facebook')}>
                <img src={FbIcon} alt='GoogleIcon' className='' />
            </div>
            <div className="d-inline-block text-white h4 mb-0 cursor-pointer" onClick={() => handleLogin('apple')}>
                <i class="bi bi-apple"></i>
            </div>
            {/* <Link className="d-inline-block" to="/user-verify">
                  <img src={OTPImg} alt='img not found' style={{ height: '30px', width: '40px' }} />
                </Link> */}
        </div>
    )
}

export default SocialLogin