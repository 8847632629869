import React from 'react'
import { useTranslation } from 'react-i18next';

const EmailSubscribe = () => {
     const { t } = useTranslation();
    return (
        <div className='newsletter-wrapper section-padding'>
            <div className='container'>
                <div className='inner-newslwtter-wrapper'>
                    <div class="outer-shape">
                        <div class="custom-shape"></div>
                    </div>
                    <div className='inner-newsletter-form text-center'>
                        <h2 className='text-white'>
                        {t("contact_point")}
                        </h2>
                        <p className='text-white mb-4 p-md'>
                            {t("contact_para")}
                        </p>
                        <form className='newsletter-form'>
                            <input type="email" id="site-search" placeholder={t("email_placeholder")} />
                            <button type="submit" class="newsletter-btn">
                                {t("email_submit")}
                            </button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default EmailSubscribe