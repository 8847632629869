import React from 'react'
import { Link } from 'react-router-dom'

const SendEmail = () => {
    return (
        <div className='login-signup-bg-wrapper'>
            <div className='container'>
                <div className='row justify-content-end'>
                    <div className='col-xl-5 col-lg-6 col-md-8'>
                        <div className='login-form-wrap check-your-mail-wrap'>
                            <h1 className='text-center text-white mb-3'>Check Your Email</h1>
                            <div className=''>
                                <p className='text-white text-center mb-0'>We have sent you a password reset link to your email with further instructions. Please check your mail to rest your password.</p>
                            </div>
                            <Link to="/login" className='ylw-theme-button w-100 mt-4 text-center d-inline-block '>Back to Login</Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SendEmail