import React, { useEffect, useRef, useState } from 'react'
import { Table } from 'react-bootstrap'
import { connect, useDispatch } from 'react-redux';
import CustomPagination from '../../shared/CustomPagination';
import { get_downpayments } from '../../reduxStore/vendors/vendorActions';
import debounce from 'lodash.debounce';
import { useNavigate } from 'react-router-dom';

const Downpayments = ({ user, allusers, vendor_id, total_users }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [isName, setIsName] = useState(null);
    const [order, setOrder] = useState(null);
    useEffect(() => {
        const fetchDownpayments = debounce(async () => {
            if (vendor_id) {
                await get_downpayments(user?.token, dispatch, "" ,vendor_id, '', 1, 10, "");
            }
        }, 1000);
        fetchDownpayments();
        return () => {
            fetchDownpayments.cancel();
        };
    }, [vendor_id, user?.token, dispatch]);

    const handleSearch = async (searchval) => {
        await get_downpayments(user?.token, dispatch, "", vendor_id, searchval, 1, 10, "");
    };

    const handleSort = async (label) => {
        const ordr = (isName === label) ? ((order === 'asce') ? 'desc' : 'asce') : 'asce';
        setIsName(label);
        setOrder(ordr);
        await get_downpayments(user?.token, dispatch, "", vendor_id, '', 1, 10, label, ordr, "");
    };

    const formatDate = (date) => {
        const dated = new Date(date);

        const formattedDate = dated.toLocaleDateString("en-GB", {
            day: "2-digit",
            month: "long",
            year: "numeric"
        });
        return formattedDate;
    };

    useEffect(() => {
        localStorage.removeItem("agencyId");
     }, []);

    return (
        <div>
            <div className='container-fluid mb-4 px-0'>
                <h1 className='text-color-theme mb-3 fw-bold'>Downpayment History</h1>
                <div className='row align-items-start'>
                    <div className='col-md-8'>
                        <label className='form-label text-grey-light fw-medium'>Search</label>
                        <input
                            type='text'
                            className='form-control'
                            placeholder='Search by name'
                            onChange={(e) => handleSearch(e.target.value)}
                        />
                    </div>
                </div>
            </div>

            {/* =====table-heading-start===== */}
            <div className='table-header-wrapper'>
                <h5 className='text-white mb-0'>Downpayment History</h5>
            </div>
            {/* =====End===== */}
            <div className='upcoming-list-table-wrapper'>
                <Table>
                    <thead>
                        <tr>
                            <th scope="col">Sr. No.</th>
                            <th scope="col">Name of Agency <i class={`bi ${isName === 'name' && order === 'asce' ? 'bi-caret-up-fill' : 'bi-caret-down-fill'} cursor-pointer`} onClick={() => { handleSort('name') }}></i> </th>
                            <th scope="col">Address</th>
                            <th scope="col">Amount <i class={`bi ${isName === 'advanceAmount' && order === 'asce' ? 'bi-caret-up-fill' : 'bi-caret-down-fill'} cursor-pointer`} onClick={() => { handleSort('advanceAmount') }}></i></th>
                            <th scope="col">Current Balance<i class={`bi ${isName === 'usedAmount' && order === 'asce' ? 'bi-caret-up-fill' : 'bi-caret-down-fill'} cursor-pointer`} onClick={() => { handleSort('usedAmount') }}></i></th>
                            <th scope="col">Created At</th>
                            <th scope="col">Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {allusers?.map((item, index) => (
                            <tr key={index}>
                                <th>{index + 1}</th>
                                <td>{item?.agency_id?.name ? item?.agency_id?.name : 'N/A'}</td>
                                <td>{item?.agency_id?.address ? item?.agency_id?.address : 'N/A'}</td>
                                <td>${item?.advanceAmount ? item?.advanceAmount : '0'}</td>
                                <td>${item?.usedAmount ? item?.usedAmount : '0'}</td>
                                <td>{item?.createdAt ? formatDate(item?.createdAt) : '-'}</td>
                                <td className="text-danger sticky-action-td">
                                    <div className='action-icon-wrap d-flex align-items-center gap-2'>
                                        <span className='eye-icon-wrap text-decoration-none' onClick={() => {
                                            if (item?.agency_id === null) return;
                                            localStorage.setItem("agencyId", JSON.stringify(item?.agency_id?._id));
                                            navigate('/agency-payment');
                                        }}>
                                            <i class="bi bi-eye h6 mb-0 cursor-pointer"></i>
                                        </span>
                                    </div>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </div>

            {total_users > 0 &&
                <CustomPagination
                    total={total_users}
                    onPageChange={(page, perpage) => {
                        get_downpayments(user?.token, dispatch, "",  vendor_id, '', page, perpage, "");
                    }}
                />}
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        user: state.auth.user,
        allusers: state.vendor.payments,
        vendor_id: state.auth.vendor_id,
        total_users: state.vendor.total_payments,
    }
}
export default connect(mapStateToProps)(Downpayments);