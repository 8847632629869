import React, { useState } from 'react'
import { useEffect } from 'react';
import { getCountryCallingCode, isValidPhoneNumber } from 'react-phone-number-input';
import { connect, useDispatch } from 'react-redux';
import { start_loading, stop_loading } from '../reduxStore/loader/loaderActions';
import axios from 'axios';
import PhoneNumber from './PhoneNumber';
import { useTranslation } from 'react-i18next';
import { get_search_hotel_graphs } from '../reduxStore/booking/bookingActions';
import { GET_SEARCHED_GRAPH } from '../reduxStore/booking/bookingTypes';

const HolderForm = ({ holderData, setHolderData, errors, setErrors, isValid, graphData }) => {
    const { t } = useTranslation();
    const holderDetail = JSON.parse(localStorage.getItem("holderDetail"));
    const exptest = /^(?!.*\.\.)(?!.*-@)(?!.*#)([A-Z0-9._%+-]+)@([A-Z0-9.-]+\.[A-Z]{2,})$/i;
    const [country, setCountry] = useState('US');
    let countrycode = getCountryCallingCode(country);
    let checkval = `+${countrycode}` + holderData?.phone_number;
    const dispatch = useDispatch();
    const [lists, setLists] = useState([]);
    useEffect(() => {
        const fetchData = async () => {
            dispatch(start_loading());
            try {
                const data = await axios.get('https://restcountries.com/v3.1/all');
                if (!data) return;

                const formatedData = data?.data?.map((item) => ({
                    nationality: item?.cca2,
                    flag: item?.flag,
                    countryname: item?.name?.common
                }));
                setLists(formatedData);
            } catch (error) {
                console.log("Error in Nation Lists", error);
            } finally {
                dispatch(stop_loading());
            }
        };
        fetchData();
    }, []);

    const handleChange = (value, label) => {
        const validations = {
            Name: value === "" ? `${t('first_valid')}` : null,
            Surname: value === "" ? `${t('last_valid')}` : null,
            Gender: value === "" ? `${t('gender_valid')}` : null,
            Age: value === "" ? `${t('age_valid')}` : Number(value) < 18 ? `${t('age_valid_check')}` : null,
            Email: value === "" ? `${t('email_valid')}` : !exptest.test(value) ? `${t('email_check')}` : null,
            phone_number: value === "" ? `${t('phone_valid')}` : !isValidPhoneNumber(`+${countrycode}` + value) ? `${t('phone_valid_check')}` : null,
            DocumentType: value === "" ? `${t('document_type_valid')}` : null,
            DocumentNo: value === "" ? `${t('document_number_valid')}` : null,
            Address: value === "" ? `${t('address_valid')}` : null,
            City: value === "" ? `${t('city_valid')}` : null,
            PostalCode: value === "" ? `${t('postal_code_valid')}` : null,
            Country: value === "" ? `${t('country_code_valid')}` : null,
            Nationality: value === "" ? "Nationality is required" : null,
        };

        setErrors((prev) => ({ ...prev, [label]: validations[label] || null }));
        setHolderData((prev) => ({ ...prev, [label]: value }));
    };

    const handleRename = (obj, oldkey, newkey) => {
        if (obj.hasOwnProperty(oldkey)) {
            obj[newkey] = obj[oldkey];
            delete obj[oldkey];
        }
        return obj;
    };

    const handleCityChange = async (value) => {
        setHolderData((prev) => ({ ...prev, City: value }));
        setErrors((prev) => ({ ...prev, City: value === "" ? `${t('city_valid')}` : null }));

        if (value.trim().length < 3) return;

        const data = {
            query: "query GetCities($name: String!) { getCities(name: $name) { cityName destinationName countryCode countryName } }",
            variables: {
                name: value
            }
        };
        await get_search_hotel_graphs(dispatch, data);
    };

    const handleSelect = (option) => {
        setHolderData((prev) => ({ ...prev, City: option?.cityName, Country: option?.countryName }));
        setErrors((prev) => ({ ...prev, City: null, Country: null }));
        dispatch({ type: GET_SEARCHED_GRAPH, payload: [] });
    };

    // const handleSubmit = (e) => {
    //     e.preventDefault();

    //     const validations = {
    //         Name: holderData?.Name === "" ? "First name is required" : null,
    //         Surname: holderData?.Surname === "" ? "Last name is required" : null,
    //         Gender: holderData?.Gender === "" ? "Gender is required" : null,
    //         Age: holderData?.Age === "" ? "Age is required" : Number(holderData?.Age) < 18 ? "Age must be greater than 18" : null,
    //         Email: holderData?.Email === "" ? "Email is required" : !exptest.test(holderData?.Email) ? "Invalid email" : null,
    //         phone_number: holderData?.phone_number === "" ? "Phone number is required" : isValidPhoneNumber(checkval) ? "Invalid Phone number" : null,
    //         DocumentType: holderData?.DocumentType === "" ? "Document type is required" : null,
    //         DocumentNo: holderData?.DocumentNo === "" ? "Document number is required" : null,
    //         Address: holderData?.Address === "" ? "Address is required" : null,
    //         City: holderData?.City === "" ? "City is required" : null,
    //         PostalCode: holderData?.PostalCode === "" ? "Postal code is required" : null,
    //         Country: holderData?.Country === "" ? "Country is required" : null,
    //         Nationality: holderData?.Nationality === "" ? "Nationality is required" : null,
    //     };
    //     setErrors(validations);

    //     const filterErr = Object.values(validations).filter((item) => item !== null);
    //     if (filterErr?.length !== 0) return;

    //     const data = handleRename(holderData, "phone_number", "PhoneNumber");

    //     localStorage.setItem("holderDetail", JSON.stringify(data));
    // };

    // console.log(holderDetail);


    useEffect(() => {
        if (holderDetail === null) return;

        setHolderData(prev => ({
            ...prev,
            Name: holderDetail?.Name,
            Surname: holderDetail?.Surname,
            Gender: holderDetail?.Gender,
            Age: holderDetail?.Age,
            Email: holderDetail?.Email,
            phone_number: holderDetail?.phone_number,
            DocumentType: holderDetail?.DocumentType,
            DocumentNo: holderDetail?.DocumentNo,
            Address: holderDetail?.Address,
            City: holderDetail?.City,
            PostalCode: holderDetail?.PostalCode,
            Country: holderDetail?.Country,
            Nationality: holderDetail?.Nationality
        }));
    }, []);

    useEffect(() => {
        if (isValid !== true) return;
        const handleValid = () => {
            const validations = {
                Name: holderData?.Name === "" ? `${t('first_valid')}` : null,
                Surname: holderData?.Surname === "" ? `${t('last_valid')}` : null,
                Gender: holderData?.Gender === "" ? `${t('gender_valid')}` : null,
                Age: holderData?.Age === "" ? `${t('age_valid')}` : Number(holderData?.Age) < 18 ? `${t('age_valid_check')}` : null,
                Email: holderData?.Email === "" ? `${t('email_valid')}` : !exptest.test(holderData?.Email) ? `${t('email_check')}` : null,
                phone_number: holderData?.phone_number === "" ? `${t('phone_valid')}` : !isValidPhoneNumber(checkval) ? `${t('phone_valid_check')}` : null,
                DocumentType: holderData?.DocumentType === "" ? `${t('document_type_valid')}` : null,
                DocumentNo: holderData?.DocumentNo === "" ? `${t('document_number_valid')}` : null,
                Address: holderData?.Address === "" ? `${t('address_valid')}` : null,
                City: holderData?.City === "" ? `${t('city_valid')}` : null,
                PostalCode: holderData?.PostalCode === "" ? `${t('postal_code_valid')}` : null,
                Country: holderData?.Country === "" ? `${t('country_code_valid')}` : null,
                Nationality: holderData?.Nationality === "" ? "Nationality is required" : null,
            };
            setErrors(validations);
        };
        handleValid();
    }, [isValid]);

    return (
        <div>
            <div class="modal-body border-0 modal-slider-view p-0">
                <form className='left-fill-details'>
                    <div className='container-fluid px-0'>
                        <div className='row'>
                            <div className='col-lg-6 col-md-12 col-sm-6'>
                                <div className='form-group form-spacing'>
                                    <label className='form-label p-lg text-grey-light'>{t('first_name')}</label>
                                    <input type="text" className='form-control' placeholder={`${t('first_label')}`} value={holderData?.Name} onChange={(e) => handleChange(e.target.value, 'Name')} />
                                    {errors.Name && <p className='text-danger'>{errors.Name}</p>}
                                </div>
                            </div>
                            <div className='col-lg-6 col-md-12 col-sm-6'>
                                <div className='form-group form-spacing'>
                                    <label className='form-label p-lg text-grey-light'>{t('last_name')}</label>
                                    <input type="text" className='form-control' placeholder={`${t('last_label')}`} value={holderData?.Surname} onChange={(e) => handleChange(e.target.value, 'Surname')} />
                                    {errors.Surname && <p className='text-danger'>{errors.Surname}</p>}
                                </div>
                            </div>
                            <div className='col-lg-6 col-md-12 col-sm-6'>
                                <div className='form-group form-spacing'>
                                    <label className='form-label p-lg text-grey-light'>{t('gender')}</label>
                                    <select className='text-grey-light w-100 form-select form-control' value={holderData?.Gender} onChange={(e) => handleChange(e.target.value, 'Gender')}>
                                        <option value="" disabled>{t('gender_placeholder')}</option>
                                        <option value="Female">Female</option>
                                        <option value="Male">Male</option>
                                        <option value="Transgender">Transgender</option>
                                    </select>
                                    {errors.Gender && <p className='text-danger'>{errors.Gender}</p>}
                                </div>
                            </div>

                            <div className='col-lg-6 col-md-12 col-sm-6'>
                                <div className='form-group form-spacing'>
                                    <label className='form-label p-lg text-grey-light'>{t('age')}</label>
                                    <input
                                        type="text"
                                        className='form-control'
                                        placeholder={`${t('age')}`}
                                        value={holderData?.Age}
                                        onChange={(e) => handleChange(e.target.value, 'Age')}
                                        onKeyPress={(event) => {
                                            if (!/^\d+$/.test(event.key) && event.key !== 'Backspace') {
                                                event.preventDefault();
                                            }
                                        }}
                                    />
                                    {errors.Age && <p className='text-danger'>{errors.Age}</p>}
                                    {/* <select className='text-grey-light w-100 form-select form-control' value={holderData?.Age} onChange={(e) => handleChange(e.target.value, 'Age')}>
                                        <option selected>Select age</option>
                                        <option>Female</option>
                                        <option>Male</option>
                                    </select> */}
                                </div>
                            </div>


                            {/* <div className='col-md-4'>
                                <div className='form-group form-spacing'>
                                    <label className='form-label p-lg text-grey-light'>Phone Number</label>
                                    <select className='text-grey-light w-100 form-select form-control'>
                                        <option selected>+91</option>
                                        <option>Female</option>
                                        <option>Male</option>
                                    </select>
                                </div>
                            </div>
                            <div className='col-md-8'>
                                <div className='form-group form-spacing'>
                                    <label className='form-label p-lg text-grey-light'></label>
                                    <input type="number" className='form-control' placeholder='Enter your phone number' />
                                </div>
                            </div> */}

                            <div className='col-lg-6 col-md-12 col-sm-6'>
                                <div className='form-group form-spacing'>
                                    <label className='form-label p-lg text-grey-light'>{t('phone_label')}</label>
                                    <PhoneNumber
                                        country={country}
                                        setCountry={(val) => setCountry(val)}
                                        phoneNumber={holderData.phone_number}
                                        handleChange={(val) => handleChange(val, 'phone_number')}
                                    />
                                    {errors.phone_number && <p className='text-danger'>{errors.phone_number}</p>}
                                </div>
                            </div>

                            <div className='col-lg-6 col-md-12 col-sm-6'>
                                <div className='form-group form-spacing'>
                                    <label className='form-label p-lg text-grey-light'>{t('email_label')}</label>
                                    <input type="email" className='form-control' placeholder={`${t('email_placeholder')}`} value={holderData?.Email} onChange={(e) => handleChange(e.target.value, 'Email')} />
                                    {errors?.Email && <p className='text-danger'>{errors?.Email}</p>}
                                </div>
                            </div>

                            <div className='col-lg-4 col-md-12 col-sm-5'>
                                <div className='form-group form-spacing'>
                                    <label className='form-label p-lg text-grey-light'>{t('document')}</label>
                                    <select className='text-grey-light w-100 form-select form-control' value={holderData?.DocumentType} onChange={(e) => handleChange(e.target.value, 'DocumentType')}>
                                        <option value="" disabled>Select</option>
                                        <option value="Passport">Passport</option>
                                        <option value="Driving license">Driving license</option>
                                    </select>
                                    {errors?.DocumentType && <p className='text-danger'>{errors?.DocumentType}</p>}
                                </div>
                            </div>
                            <div className='col-lg-8 col-md-12 col-sm-7'>
                                <div className='form-group form-spacing'>
                                    <label className='form-label p-lg text-grey-light opacity-0 d-none d-sm-block d-lg-block'>1</label>
                                    <input type="number" className='form-control' placeholder={`${t('document_number')}`} value={holderData?.DocumentNo} onChange={(e) => handleChange(e.target.value, 'DocumentNo')} />
                                    {errors?.DocumentNo && <p className='text-danger'>{errors?.DocumentNo}</p>}
                                </div>
                            </div>

                            <div className='col-lg-6 col-md-12 col-sm-6'>
                                <div className='form-group form-spacing'>
                                    <label className='form-label p-lg text-grey-light'>{t('address')}</label>
                                    <input type="text" className='form-control' placeholder={`${t('street_no')}`} value={holderData?.Address} onChange={(e) => handleChange(e.target.value, 'Address')} />
                                    {errors?.Address && <p className='text-danger'>{errors?.Address}</p>}
                                </div>
                            </div>

                            <div className='col-lg-6 col-md-12 col-sm-6'>
                                <div className='form-group form-spacing'>
                                    <label className='form-label p-lg text-grey-light'>{t('city')}</label>
                                    <div className='position-relative'>
                                        <input type="text" className='form-control' placeholder={`${t('city_placeholder')}`} value={holderData?.City} onChange={(e) => handleCityChange(e.target.value)} />
                                        {errors?.City && <p className='text-danger'>{errors?.City}</p>}
                                        {holderData?.City !== '' && graphData?.length > 0 && (
                                            <ul
                                                className="dropdown-options search-location-options"
                                                style={{
                                                    position: "absolute",
                                                    top: "100%",
                                                    left: 0,
                                                    width: "100%",
                                                    maxHeight: "250px",
                                                    overflowY: "auto",
                                                    backgroundColor: "#fff",
                                                    border: "1px solid #ccc",
                                                    listStyle: "none",
                                                    margin: 0,
                                                    padding: "5px 0",
                                                    zIndex: 1,
                                                    color: "white"
                                                }}
                                            >
                                                {graphData?.map((option, index) => (
                                                    <li
                                                        key={index}
                                                        onClick={() => handleSelect(option)}
                                                        style={{
                                                            padding: "10px",
                                                            cursor: "pointer",
                                                            backgroundColor: "#fff",
                                                            color: "#333333",
                                                        }}
                                                        onMouseEnter={(e) => (e.target.style.backgroundColor = "#fff")}
                                                        onMouseLeave={(e) => (e.target.style.backgroundColor = "#fff")}
                                                    >
                                                        <i class="bi bi-geo-alt-fill text-purple h5 mb-0"></i>
                                                        <div className='country-city-name'>
                                                            <h6 className=''>{option?.cityName}</h6>
                                                            <p className='p-md mb-0'>{option?.countryName && option?.countryName}</p>
                                                        </div>
                                                    </li>
                                                ))}
                                            </ul>
                                        )}
                                    </div>
                                </div>
                            </div>

                            <div className='col-lg-6 col-md-12 col-sm-6'>
                                <div className='form-group form-spacing'>
                                    <label className='form-label p-lg text-grey-light'>{t('country_label')}</label>
                                    <input type="text" className='form-control' placeholder={`${t('country_placeholder')}`} value={holderData?.Country} onChange={(e) => handleChange(e.target.value, 'Country')} />
                                    {errors?.Country && <p className='text-danger'>{errors?.Country}</p>}
                                </div>
                            </div>

                            <div className='col-lg-6 col-md-12 col-sm-6'>
                                <div className='form-group form-spacing'>
                                    <label className='form-label p-lg text-grey-light'>{t('postal_code')}</label>
                                    <input type="number" className='form-control' placeholder={`${t('postal_label')}`} value={holderData?.PostalCode} onChange={(e) => handleChange(e.target.value, 'PostalCode')} />
                                    {errors?.PostalCode && <p className='text-danger'>{errors?.PostalCode}</p>}
                                </div>
                            </div>

                            {/* <div className='col-md-12'>
                                <div className='form-group form-spacing'>
                                    <label className='form-label p-lg text-grey-light'>Nationality</label>
                                    <select className='text-grey-light w-100 form-select form-control' value={holderData?.Nationality} onChange={(e) => handleChange(e.target.value, 'Nationality')}>
                                        <option value="" disabled>Select Nationality</option>
                                        {lists.map((item, index) => (
                                            <option key={index} value={item?.nationality}>
                                                {item?.countryname} ({item?.nationality})
                                            </option>
                                        ))}
                                    </select>
                                    {errors?.Nationality && <p className='text-danger'>{errors?.Nationality}</p>}
                                </div>
                            </div> */}


                            {/* <div className='col-md-12'>
                                <div className='form-group form-spacing'>
                                    <label className='form-label p-lg text-grey-light'>Date of  Birth</label>
                                    <div className='d-grid d-grid-calender w-100'>
                                        <select className='text-grey-light w-100 form-select form-control'>
                                            <option selected>DD</option>
                                            <option>DD</option>
                                            <option>DD</option>
                                        </select>
                                        <select className='text-grey-light w-100 form-select form-control'>
                                            <option selected>MM</option>
                                            <option>MM</option>
                                            <option>MM</option>
                                        </select>
                                        <select className='text-grey-light w-100 form-select form-control'>
                                            <option selected>YYYY</option>
                                            <option>YYYY</option>
                                            <option>YYYY</option>
                                        </select>
                                    </div>
                                </div>
                            </div> */}
                        </div>
                        {/* <button className='btn-gradient-purple' type='submit'>Add Person</button> */}
                    </div>
                </form>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        user: state.auth.user,
        graphData: state.bookings.graphData,
        currency: state.auth.currency
    }
};
export default connect(mapStateToProps)(HolderForm);