export const USER_UPDATE = 'USER_UPDATE';
export const LOGOUT = 'LOGOUT';
export const IS_EDIT = 'IS_EDIT';
export const SET_USER_LOCATION = 'SET_USER_LOCATION';
export const GET_USER_DETAILS = 'GET_USER_DETAILS';
export const GET_ALL_USERS_LISTS = 'GET_ALL_USERS_LISTS';
export const GET_USERS_TOTAL = "GET_USERS_TOTAL";
export const GET_ALL_ADMINS_LISTS = 'GET_ALL_ADMINS_LISTS';
export const GET_EDIT_DATA = 'GET_EDIT_DATA';
export const GET_ALL_PLANS = 'GET_ALL_PLANS';
export const GET_VENDOR_ID = 'GET_VENDOR_ID';
export const GET_CURRENCY = 'GET_CURRENCY';
export const GET_LANGUAGE = 'GET_LANGUAGE';
export const GET_DEFAULT_DATA = 'GET_DEFAULT_DATA';
export const GET_USER_CARD_DETAILS = 'GET_USER_CARD_DETAILS';
export const GET_MY_REFERRAL_LISTS = 'GET_MY_REFERRAL_LISTS';
export const GET_MY_CASHBACKS_LISTS = 'GET_MY_CASHBACKS_LISTS';