import React, { useEffect, useState } from 'react'
import { Table } from 'react-bootstrap'
import AddAgency from '../../Modals/AddAgency';
import { connect, useDispatch } from 'react-redux';
import { get_all_medium_agencies, get_all_small_agencies, user_deleted_by_admin } from '../../reduxStore/vendors/vendorActions';
import { get_profile_details, get_vender_id } from '../../reduxStore/User/userActions';
import Cancel from '../../Modals/Cancel';
import CustomPagination from '../../shared/CustomPagination';
import ViewDetails from '../../Modals/ViewDetails';
import { useToast } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import debounce from 'lodash.debounce';

const AgenciesList = ({ user, smallagencies, mediumagencies, vendor_id, total_small, total_medium }) => {
    const [isShow, setIsShow] = useState(false);
    const currentPage = JSON.parse(localStorage.getItem("currentPage"));
    const [searching, setSearching] = useState('');
    const [currentTab, setCurrentTab] = useState(currentPage ? currentPage : 'agency1');
    const dispatch = useDispatch();
    const [isCancel, setIsCancel] = useState(false);
    const [data, setData] = useState(null);
    const [isView, setIsView] = useState(false);
    const [isName, setIsName] = useState(false);
    const [isEmail, setIsEmail] = useState(false);
    const toast = useToast();
    const navigate = useNavigate();

    useEffect(() => {
        const fetchId = debounce(async () => {
            await get_vender_id("superadmin", dispatch);
        }, 1000);
        fetchId();
        return () => {
            fetchId.cancel();
        };
    }, []);

    useEffect(() => {

        if (!vendor_id) return;

        const fetchLists = debounce(async () => {
            if (currentTab === 'agency1') {
                await get_all_small_agencies(user?.token, dispatch, vendor_id, '', 1, 10);

            } else if (currentTab === 'agency2') {
                await get_all_medium_agencies(user?.token, dispatch, vendor_id, '', 1, 10);
            }
        }, 1000);
        fetchLists();
        return () => {
            fetchLists.cancel();
        };
    }, [currentTab]);

    useEffect(() => {
        if (!vendor_id) return;
        const fetchDetails = debounce(async () => {
            await get_profile_details(user?.token, vendor_id, dispatch, toast);
        }, 1000);
        fetchDetails();
        return () => {
            fetchDetails.cancel();
        }
    }, [vendor_id]);

    const handleSearch = async (searchval) => {
        if (currentTab === 'agency1') {
            await get_all_small_agencies(user?.token, dispatch, vendor_id, searchval, 1, 10);

        } else if (currentTab === 'agency2') {
            await get_all_medium_agencies(user?.token, dispatch, vendor_id, searchval, 1, 10);
        }
    };

    const handledelete = async () => {
        if (data === null) return;

        let isDelete = await user_deleted_by_admin(dispatch, vendor_id, user?.token, data?._id, "admin");
        if (isDelete) {
            if (currentTab === 'agency1') {
                await get_all_small_agencies(user?.token, dispatch, vendor_id, '', 1, 10);
                setIsCancel(false);

            } else if (currentTab === 'agency2') {
                await get_all_medium_agencies(user?.token, dispatch, vendor_id, '', 1, 10);
                setIsCancel(false);
            }
        }
    };

    const getCountryName = (code) => {
        const regionNames = new Intl.DisplayNames(['en'], { type: 'region' });
        const countryName = regionNames.of(code);
        return countryName;
    };

    const handleSort = async (label) => {
        switch (label) {
            case 'name':
                const newIsName = !isName;
                setIsName(newIsName);
                if (currentTab === 'agency1') {
                    await get_all_small_agencies(user?.token, dispatch, vendor_id, '', 1, 10, 'name', newIsName ? 'desc' : 'asce');
                } else if (currentTab === 'agency2') {
                    await get_all_medium_agencies(user?.token, dispatch, vendor_id, '', 1, 10, 'name', newIsName ? 'desc' : 'asce');
                }
                break;
            case 'email':
                const newIsEmail = !isEmail;
                setIsEmail(newIsEmail);
                if (currentTab === 'agency1') {
                    await get_all_small_agencies(user?.token, dispatch, vendor_id, '', 1, 10, 'email', newIsEmail ? 'desc' : 'asce');
                } else if (currentTab === 'agency2') {
                    await get_all_medium_agencies(user?.token, dispatch, vendor_id, '', 1, 10, 'email', newIsEmail ? 'desc' : 'asce');
                }
                break;

            default:
                break;
        }
    };

    useEffect(() => {
        setIsName(false);
        setIsEmail(false);
        setSearching('');
    }, [currentTab]);

    const formatDate = (date) => {
        const dated = new Date(date);

        const formattedDate = dated.toLocaleDateString("en-GB", {
            day: "2-digit",
            month: "long",
            year: "numeric"
        });
        return formattedDate;
    };



    return (
        <div>
            <div className='container-fluid px-0'>
                <h1 className='text-color-theme fw-bold mb-3'>Agencies</h1>
                <div className='row align-items-end mb-4 justify-content-between'>
                    <div className='col-md-8'>
                        <label className='form-label text-grey-light fw-medium'>Search by</label>
                        <input
                            type='text'
                            className='form-control'
                            placeholder='search agency by name/email/phone number'
                            value={searching}
                            onChange={(e) => {
                                setSearching(e.target.value);
                                handleSearch(e.target.value);
                            }}
                        />
                    </div>
                    {/* <div className='col-md-4'>
                        <button className='btn-gradientlg-purple w-auto' onClick={() => {
                            navigate('/create-agency');
                        }}>
                            + Add New
                        </button>
                    </div> */}
                </div>
            </div>


            <div>
                {/* =====table-heading-start===== */}
                <div className='table-header-wrapper d-block'>
                    {/* <button className='ylw-theme-button w-auto mb-3 d-flex ms-auto' onClick={() => {
                        navigate('/create-agency');
                    }}>
                        + Add
                    </button> */}
                    <div className="d-flex justify-content-space-between gap-3">
                        <button className={`h6 border-0 d-flex align-items-center gap-2 tabs-btn py-2 px-3 rounded mb-0 ${currentTab === 'agency1' ? 'text-white bg-purple' : 'text-purple bg-white'}`}
                            onClick={() => {
                                setCurrentTab('agency1');
                                localStorage.setItem("currentPage", JSON.stringify('agency1'));
                            }}
                        >
                            Agency Type 1
                        </button>

                        <button className={`h6 border-0 d-flex align-items-center gap-2 tabs-btn py-2 px-3 rounded mb-0 ${currentTab === 'agency2' ? 'text-white bg-purple' : 'text-purple bg-white'}`}
                            onClick={() => {
                                setCurrentTab('agency2');
                                localStorage.setItem("currentPage", JSON.stringify('agency2'));
                            }}
                        >
                            Agency Type 2
                        </button>

                        <button className='ylw-theme-button w-auto mb-3 d-flex ms-auto' onClick={() => {
                            navigate('/create-agency');
                        }}>
                            + Add
                        </button>
                    </div>
                </div>
                {/* =====End===== */}

                <div className='upcoming-list-table-wrapper'>
                    <Table>
                        <thead>
                            <tr>
                                <th scope="col">Sr. No.</th>
                                <th>
                                    Name
                                    {isName ? <i class="bi bi-caret-up-fill cursor-pointer" onClick={() => { handleSort('name') }}></i> : <i class="bi bi-caret-down-fill cursor-pointer" onClick={() => { handleSort('name') }}></i>}
                                </th>
                                <th>
                                    Email
                                    {isEmail ? <i class="bi bi-caret-up-fill cursor-pointer" onClick={() => { handleSort('email') }}></i> : <i class="bi bi-caret-down-fill cursor-pointer" onClick={() => { handleSort('email') }}></i>}
                                </th>
                                <th>Address</th>
                                <th>Phone No.</th>
                                <th scope="col">Country</th>
                                <th scope="col">Status</th>
                                <th scope="col">Created At</th>
                                <th className='sticky-action-td'>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {(currentTab === 'agency1' ? smallagencies : mediumagencies)?.map((item, index) => (
                                <tr key={index}>
                                    <th>{index + 1}</th>
                                    <td>{item?.name ? item?.name : 'N/A'}</td>
                                    <td>{item?.email ? item?.email : 'N/A'}</td>
                                    <td>{item?.address ? item?.address : 'N/A'}</td>
                                    <td>{item?.country_code && `(${item?.country_code})`} {item?.phone_number ? item?.phone_number : 'N/A'}</td>
                                    <td>{item?.country_code_name ? getCountryName(item?.country_code_name) : 'N/A'}</td>
                                    <td>{item?.isDeleted ? 'In-Active' : 'Active'}</td>
                                    <td>
                                        {item?.createdAt ? formatDate(item?.createdAt) : '-'}
                                    </td>
                                    <td className="sticky-action-td">
                                        <div className='action-icon-wrap d-flex align-items-center gap-2'>
                                            <a href='#' className='eye-icon-wrap text-decoration-none' onClick={() => { setData(item); setIsView(true); }}>
                                                <i class="bi bi-eye h6 mb-0 cursor-pointer"></i>
                                            </a>
                                            <span href='' className='download-icon-wrap text-decoration-none' onClick={() => {
                                                localStorage.setItem("agencyData", JSON.stringify(item?._id));
                                                navigate("/edit-agency");
                                            }}>
                                                <i class="bi bi-pencil-square cursor-pointer"></i>
                                            </span>
                                            <a href='#' className='cross-icon-wrap text-decoration-none' onClick={() => { setData(item); setIsCancel(true); }}>
                                                <i class="bi bi-x h4 mb-0 cursor-pointer"></i>
                                            </a>
                                        </div>
                                    </td>
                                    {/* <td className="cursor-pointer text-danger" onClick={() => handledelete(item?._id)}>
                                        <i class="bi bi-x h4 mb-0"></i>
                                    </td> */}
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </div>
                {(currentTab === 'agency1' && total_small > 0) &&
                    <CustomPagination
                        total={total_small}
                        onPageChange={(page, perpage) => {
                            get_all_small_agencies(user?.token, dispatch, vendor_id, '', page, perpage);
                        }}
                    />}
                {(currentTab === 'agency2' && total_medium > 0) &&
                    <CustomPagination
                        total={total_medium}
                        onPageChange={(page, perpage) => {
                            get_all_medium_agencies(user?.token, dispatch, vendor_id, '', page, perpage);
                        }}
                    />}
            </div>
            {/* <div>
                    <h4>Agency Type 2</h4>
                    <Table>
                        <thead>
                            <th>Name</th>
                            <th>Email</th>
                            <th>Address</th>
                            <th>Phone No.</th>
                        </thead>
                        <tbody>
                            {mediumagencies.map((item, index) => (
                                <tr key={index}>
                                    <td>{item?.name}</td>
                                    <td>{item?.email}</td>
                                    <td>{item?.address}</td>
                                    <td>({item?.country_code}) {item?.phone_number}</td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </div> */}

            {/* <AddAgency show={isShow} handleClose={() => setIsShow(false)} /> */}
            <Cancel
                show={isCancel}
                handleClose={() => setIsCancel(!isCancel)}
                content1={`Are you sure want to ${data?.isDeleted ? 'Active' : 'In-Active'} this Agency?`}
                content2={"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text."}
                handleCancel={handledelete}
            />
            <ViewDetails show={isView} handleClose={() => setIsView(false)} vendor_id={vendor_id} user={user} id={data?._id} type={"agency"} />
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        user: state.auth.user,
        vendor_id: state.auth.vendor_id,
        smallagencies: state.vendor.small_agencies,
        mediumagencies: state.vendor.medium_agencies,
        total_small: state.vendor.total_small,
        total_medium: state.vendor.total_medium
    }
}
export default connect(mapStateToProps)(AgenciesList);