import React, { useEffect, useState } from 'react'
import GrayFlightIcon from './../../assets/gray-flight-icon.svg';
import GrayLocalTourIcon from './../../assets/gray-local-tour-icon.svg';
import GrayCarIcon from './../../assets/gray-car-icon.svg';
import GreyHotelIcon from './../../assets/grey-hotel-icon.svg';
import { connect, useDispatch } from 'react-redux';
import { get_vender_id } from '../../reduxStore/User/userActions';
import { after_booking, get_user_all_booking_lists, hotel_booking_cancel_by_user } from '../../reduxStore/booking/bookingActions';
import CustomPagination from '../../shared/CustomPagination';
import Cancel from '../../Modals/Cancel';
import { useNavigate } from 'react-router-dom';
import { useToast } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import DownloadFile from '../../shared/DownloadFile';
import debounce from 'lodash.debounce';


const MyBooking = ({ user, vendor_id, bookingList, totalBookings }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const toast = useToast();
    const { t } = useTranslation();
    const [isCancel, setIsCancel] = useState(false);
    const [data, setData] = useState(null);
    const [selectedVal, setSelectedVal] = useState('');

    const options = [
        { name: 'All', label: 'All' },
        { name: 'Booked', label: 1 },
        { name: 'Confirmed', label: 2 },
        { name: 'Cancelled', label: 3 },
        { name: 'Failed', label: 4 },
        { name: 'Pending', label: 5 }
    ];

    useEffect(() => {
        const fetchId = debounce(async () => {
            await get_vender_id("superadmin", dispatch);
            localStorage.removeItem("hotelData");
            localStorage.removeItem("goBackPath");
        }, 1000);
        fetchId();
        return () => {
            fetchId.cancel();
        };
    }, []);

    useEffect(() => {
        const handleConfirm = async () => {
            await after_booking();
        };
        handleConfirm();
    }, []);

    useEffect(() => {
        if (!vendor_id) return;

        const handleData = debounce(async () => {
            await get_user_all_booking_lists(dispatch, vendor_id, user?.token, 1, 10);
        }, 1000);
        handleData();
        return () => {
            handleData.cancel();
        };
    }, [user?.token, vendor_id]);

    // console.log(bookingList, totalBookings);

    const formatDate = (inputdate) => {
        const date = new Date(inputdate);

        // Get day, date, and month
        const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
        const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

        const day = days[date.getUTCDay()];
        const dayOfMonth = date.getUTCDate();
        const month = months[date.getUTCMonth()];
        const year = date.getUTCFullYear();

        return `${dayOfMonth} ${month} ${year}`;
    };

    const handleFilter = async (val) => {
        setSelectedVal(val);
        if(val === 'All'){
            await get_user_all_booking_lists(dispatch, vendor_id, user?.token, 0, 0);
        } else {
            await get_user_all_booking_lists(dispatch, vendor_id, user?.token, 1, 10, val);
        }
    };

    const handleBooking = async () => {
        await hotel_booking_cancel_by_user(dispatch, vendor_id, data ? data?.BookingID : '', data ? data?.provider : '', toast);
        // if (iscancel) {
        await get_user_all_booking_lists(dispatch, vendor_id, user?.token, 1, 10);
        setIsCancel(false);
        // }
    };

    const DaysDiff = (stdate, endate) => {
        const startdate = new Date(stdate);
        const enddate = new Date(endate);

        const diffInMilliseconds = enddate - startdate;

        const diffInDays = diffInMilliseconds / (1000 * 60 * 60 * 24);
        return diffInDays;
    };

    return (
        <div>
            <h1>{t('my_booking')}</h1>
            <div className='table-header-wrapper'>
                <h5 className='text-white mb-0'>{t('booking_listing')}</h5>
                <a href='#' className='text-white text-decoration-none'>{t('view_by')} </a>
                <select
                    name='role'
                    type="select"
                    className="form-select form-control"
                    placeholder="Select type"
                    value={selectedVal}
                    onChange={(e) => handleFilter(e.target.value)}
                    style={{ width: "17%", background: 'transparent', color: 'white', appearance: 'auto' }}
                >
                    <option value="" disabled>Select</option>
                    {options.map((item, index) => (
                        <option key={index} value={item.label} style={{ background: '#6D338A' }}>{item.name}</option>
                    ))}
                </select>
            </div>
            {/* =====End===== */}


            {/* ====table-start=== */}
            <div className='upcoming-list-table-wrapper'>
                <table class="table">
                    <thead>
                        <tr>
                            <th scope="col">#</th>
                            <th scope="col">{t('type')}</th>
                            <th scope="col">{t('hotel_name')}</th>
                            <th scope="col">{t('guest_name')}</th>
                            <th scope="col">{t('reviews')}</th>
                            <th scope="col">{t('check_in')}</th>
                            <th scope="col">{t('check_out')}</th>
                            <th scope="col">{t('days')}</th>
                            <th scope="col">{t('booking_date')}</th>
                            <th scope="col">{t('amount')}</th>
                            <th scope="col">{t('status')}</th>
                            <th scope="col">{t('action')}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {totalBookings > 0 && bookingList?.map((item, idx) => (
                            <tr key={idx}>
                                <th scope="row">{idx + 1}</th>
                                <td>
                                    <div className='d-flex align-items-center gap-1'>
                                        <img src={GreyHotelIcon} alt='GrayLocalTourIcon' />
                                        Hotel
                                    </div>
                                </td>
                                <td>
                                    <div className='table-col-name-td'>
                                        {item?.Hotel_details?.Name}
                                    </div>
                                </td>
                                <td>
                                    <div className='table-col-name-td'>
                                        {/* Vikram */}
                                        {item?.Holder_details?.Name} {item?.Holder_details?.Surname}
                                    </div>
                                </td>
                                <td>
                                    <div className='add-review-disable'>
                                        + Add Reviews
                                    </div>
                                </td>
                                <td>
                                    {formatDate(item?.CheckInDate)}
                                </td>
                                <td>
                                    {formatDate(item?.CheckOutDate)}
                                </td>
                                <td>
                                    {DaysDiff(item?.CheckInDate, item?.CheckOutDate)}
                                </td>
                                <td>
                                    {formatDate(item?.OrderDate)}
                                </td>
                                <td>
                                    <div className='table-amount-col-data'>
                                        {item?.totalAmount ? `$${item?.totalAmount}` : '-'}
                                    </div>
                                </td>
                                <td>
                                    <div className='status-label'>
                                        <span className={`${item?.Status === 1 ? 'completed-label' : item?.Status === 2 ? 'confirmed-label' : (item?.Status === 3 || item?.Status === 4) ? 'cancel-label' : 'processing-label'}`}>
                                            {item?.Status === 1 ? 'Booked' :
                                                item?.Status === 2 ? 'Confirmed' :
                                                    item?.Status === 3 ? 'Cancelled' :
                                                        item?.Status === 4 ? 'Failed' :
                                                            'Pending'}
                                        </span>
                                    </div>
                                </td>
                                <td>
                                    <div className='action-icon-wrap d-flex align-items-center gap-2'>
                                        <span className='eye-icon-wrap text-decoration-none cursor-pointer' onClick={() => {
                                            localStorage.setItem("hotelData", JSON.stringify(item));
                                            localStorage.setItem("goBackPath", JSON.stringify('/dashboard/bookings'));
                                            navigate('/dashboard/booking-detail');
                                        }}>
                                            <i class="bi bi-eye"></i>
                                        </span>
                                        <button className='download-icon-wrap text-decoration-none cursor-pointer'>
                                            {/* <i class="bi bi-download"></i> */}
                                            <DownloadFile invoiceUrl={item?.invoicePath} user={user} />
                                        </button>
                                        {item?.Status === 2 && <span className='cross-icon-wrap text-decoration-none cursor-pointer' onClick={() => {
                                            setData(item);
                                            setIsCancel(true);
                                        }}>
                                            <i class="bi bi-x"></i>
                                        </span>}
                                    </div>
                                </td>
                            </tr>
                        ))}
                        {/* <tr>
                            <th scope="row">1</th>
                            <td>
                                <div className='d-flex align-items-center gap-1'>
                                    <img src={GrayFlightIcon} alt='GrayFlightIcon' />
                                    Flight
                                </div>
                            </td>
                            <td>
                                <div className='table-col-name-td'>
                                    Manpreet Singh
                                </div>
                            </td>
                            <td>
                                <div className='add-review-disable'>
                                    + Add Reviews
                                </div>
                            </td>
                            <td>10 Nov 2024</td>
                            <td>
                                <div className='table-amount-col-data'>
                                    $1899.00
                                </div>
                            </td>
                            <td>
                                <div className='status-label'>
                                    <span className='processing-label'>Processing</span>
                                </div>
                            </td>
                            <td>
                                <div className='action-icon-wrap d-flex align-items-center gap-2'>
                                    <a href='#' className='eye-icon-wrap text-decoration-none'>
                                        <i class="bi bi-eye"></i>
                                    </a>
                                    <a href='#' className='download-icon-wrap text-decoration-none'>
                                        <i class="bi bi-download"></i>
                                    </a>
                                    <a href='#' className='cross-icon-wrap text-decoration-none'>
                                        <i class="bi bi-x"></i>
                                    </a>
                                </div>
                            </td>
                        </tr> */}

                        {/* <tr>
                            <th scope="row">2</th>
                            <td>
                                <div className='d-flex align-items-center gap-1'>
                                    <img src={GrayLocalTourIcon} alt='GrayLocalTourIcon' />
                                    Local Tour
                                </div>
                            </td>
                            <td>
                                <div className='table-col-name-td'>
                                    Vikas Kumar
                                </div>
                            </td>
                            <td>
                                <div className='add-review-disable'>
                                    + Add Reviews
                                </div>
                            </td>
                            <td>09 Nov 2024</td>
                            <td>
                                <div className='table-amount-col-data'>
                                    $3063.53
                                </div>
                            </td>
                            <td>
                                <div className='status-label'>
                                    <span className='processing-label'>Processing</span>
                                </div>
                            </td>
                            <td>
                                <div className='action-icon-wrap d-flex align-items-center gap-2'>
                                    <a href='#' className='eye-icon-wrap text-decoration-none'>
                                        <i class="bi bi-eye"></i>
                                    </a>
                                    <a href='#' className='download-icon-wrap text-decoration-none'>
                                        <i class="bi bi-download"></i>
                                    </a>
                                    <a href='#' className='cross-icon-wrap text-decoration-none'>
                                        <i class="bi bi-x"></i>
                                    </a>
                                </div>
                            </td>
                        </tr> */}

                        {/* <tr>
                            <th scope="row">3</th>
                            <td>
                                <div className='d-flex align-items-center gap-1'>
                                    <img src={GrayCarIcon} alt='GrayLocalTourIcon' />
                                    Car Rentals
                                </div>
                            </td>
                            <td>
                                <div className='table-col-name-td'>
                                    Sahil Kumar
                                </div>
                            </td>
                            <td>
                                <div className='add-review-disable'>
                                    + Add Reviews
                                </div>
                            </td>
                            <td>07 Nov 2024</td>
                            <td>
                                <div className='table-amount-col-data'>
                                    $399.05
                                </div>
                            </td>
                            <td>
                                <div className='status-label'>
                                    <span className='confirmed-label'>Confirmed</span>
                                </div>
                            </td>
                            <td>
                                <div className='action-icon-wrap d-flex align-items-center gap-2'>
                                    <a href='#' className='eye-icon-wrap text-decoration-none'>
                                        <i class="bi bi-eye"></i>
                                    </a>
                                    <a href='#' className='download-icon-wrap text-decoration-none'>
                                        <i class="bi bi-download"></i>
                                    </a>
                                    <a href='#' className='cross-icon-wrap text-decoration-none'>
                                        <i class="bi bi-x"></i>
                                    </a>
                                </div>
                            </td>
                        </tr> */}

                        {/* <tr>
                            <th scope="row">4</th>
                            <td>
                                <div className='d-flex align-items-center gap-1'>
                                    <img src={GreyHotelIcon} alt='GrayLocalTourIcon' />
                                    Hotel
                                </div>
                            </td>
                            <td>
                                <div className='table-col-name-td'>
                                    Vikram
                                </div>
                            </td>
                            <td>
                                <div className='add-review-disable'>
                                    + Add Reviews
                                </div>
                            </td>
                            <td>02 Nov 2024</td>
                            <td>
                                <div className='table-amount-col-data'>
                                    $269.80
                                </div>
                            </td>
                            <td>
                                <div className='status-label'>
                                    <span className='confirmed-label'>Confirmed</span>
                                </div>
                            </td>
                            <td>
                                <div className='action-icon-wrap d-flex align-items-center gap-2'>
                                    <a href='#' className='eye-icon-wrap text-decoration-none'>
                                        <i class="bi bi-eye"></i>
                                    </a>
                                    <a href='#' className='download-icon-wrap text-decoration-none'>
                                        <i class="bi bi-download"></i>
                                    </a>
                                    <a href='#' className='cross-icon-wrap text-decoration-none'>
                                        <i class="bi bi-x"></i>
                                    </a>
                                </div>
                            </td>
                        </tr> */}
                    </tbody>
                </table>
                {totalBookings > 0 &&
                    <CustomPagination
                        total={totalBookings}
                        onPageChange={(page, perpage) => {
                            get_user_all_booking_lists(dispatch, vendor_id, user?.token, page, perpage);
                        }}
                    />}
            </div>
            {/* ====End=== */}

            <Cancel
                show={isCancel}
                handleClose={() => {
                    setData(null);
                    setIsCancel(!isCancel);
                }}
                content1={`Are you sure you want to cancel the booking?`}
                //content2={"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text."}
                handleCancel={handleBooking}
            />
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        user: state.auth.user,
        vendor_id: state.auth.vendor_id,
        bookingList: state.bookings.bookingList,
        totalBookings: state.bookings.totalBookings
    }
}
export default connect(mapStateToProps)(MyBooking);