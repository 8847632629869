import React, { useEffect, useRef, useState } from 'react'
import { Form, Modal } from 'react-bootstrap'
import { getCountries, getCountryCallingCode, isValidPhoneNumber } from 'react-phone-number-input';
import PhoneNumber from '../shared/PhoneNumber';
import { edit_profile } from '../reduxStore/User/userActions';
import { useDispatch } from 'react-redux';
import { useToast } from '@chakra-ui/react';
import adminProfile from '../assets/admin-profile-img.svg';
import { EmailIcon, EmailShareButton, FacebookIcon, FacebookShareButton, LinkedinIcon, LinkedinShareButton, RedditIcon, RedditShareButton, TwitterIcon, TwitterShareButton, WhatsappIcon, WhatsappShareButton } from 'react-share';
import CopyToClipboard from 'react-copy-to-clipboard';
import { useTranslation } from 'react-i18next';
import { Metadata } from 'libphonenumber-js/min';

const { REACT_APP_OTA_URL } = process.env;

const EditProfile = ({ show, handleClose, user, vendor_id, userDetails }) => {
    const dispatch = useDispatch();
    const toast = useToast();
    const { t } = useTranslation();
    const [formdata, setFormdata] = useState({
        // username: '',
        email: '',
        name: '',
        // dob: '',
        // password: '',
        phone_number: '',
        address: '',
        city: '',
        state: '',
        zip_code: '',
        picture: ''
    });
    const [country, setCountry] = useState('US');

    const [errors, setErrors] = useState({
        // username: '',
        email: '',
        name: '',
        // dob: '',
        // password: '',
        phone_number: '',
        address: '',
        city: '',
        state: '',
        zip_code: '',
        picture: ''
    });
    const exptest = /^(?!.*\.\.)(?!.*-@)(?!.*#)([A-Z0-9._%+-]+)@([A-Z0-9.-]+\.[A-Z]{2,})$/i;
    let countrycode = getCountryCallingCode(country);
    let checkval = `+${countrycode}` + formdata.phone_number;
    const imageRef = useRef();
    const [imagePreview, setImagePreview] = useState('');
    const [isPop, setIsPop] = useState(false);

    const [copied, setCopied] = useState(false);

    const handleCopy = () => {
        setCopied(true);
        setTimeout(() => setCopied(false), 2000);
    };

    const handleChange = (label, value) => {

        switch (label) {
            case "username":
                setFormdata((prev) => ({ ...prev, [label]: value }));
                setErrors((prev) => ({ ...prev, [label]: value === "" ? "Username is required" : null }));
                break;
            case "email":
                setFormdata((prev) => ({ ...prev, [label]: value }));
                setErrors((prev) => ({ ...prev, [label]: value === "" ? `${t('email_valid')}` : !exptest.test(value) ? `${t('email_check')}` : null }));
                break;
            case "name":
                setFormdata((prev) => ({ ...prev, [label]: value }));
                setErrors((prev) => ({ ...prev, [label]: value === "" ? `${t('name_valid')}` : null }));
                break;
            case "dob":
                setErrors({ ...errors, dob: value === "" ? "DOB is required" : null });
                setFormdata({ ...formdata, [label]: value });
                break;
            // case "password":
            //     setFormdata((prev) => ({ ...prev, [label]: value }));
            //     setErrors((prev) => ({ ...prev, [label]: value === "" ? "Password is required" : value.length <= 5 ? "Password should contain more than 5 characters" : null }));
            //     break;
            case "phone_number":
                let checkingval = `+${countrycode}` + value;
                setFormdata((prev) => ({ ...prev, [label]: value }));
                setErrors((prev) => ({ ...prev, [label]: value === "" ? `${t('phone_valid')}` : !isValidPhoneNumber(checkingval) ? `${t('phone_valid_check')}` : null }));
                break;
            case "address":
                setFormdata((prev) => ({ ...prev, [label]: value }));
                setErrors((prev) => ({ ...prev, [label]: value === "" ? `${t('address_valid')}` : null }));
                break;
            case "city":
                setFormdata((prev) => ({ ...prev, [label]: value }));
                setErrors((prev) => ({ ...prev, [label]: value === "" ? `${t('city_valid')}` : null }));
                break;
            case "state":
                setFormdata((prev) => ({ ...prev, [label]: value }));
                setErrors((prev) => ({ ...prev, [label]: value === "" ? `${t('state_valid')}` : null }));
                break;
            case "zip_code":
                setFormdata((prev) => ({ ...prev, [label]: value }));
                setErrors((prev) => ({ ...prev, [label]: value === "" ? `${t('zip_valid')}` : value?.length < 5 ? `${t('zip_valid_check')}` : null }));
                break;

            default:
                setFormdata((prev) => ({ ...prev, [label]: value }));
                break;
        }
    };

    const handleValid = () => {
        let err = false;

        const Errors = {
            // username: formdata.username === "" ? "Username is required" : null,
            address: formdata.address === "" ? `${t('address_valid')}` : null,
            email: formdata.email === "" ? `${t('email_valid')}` : !exptest.test(formdata.email) ? `${t('email_check')}` : null,
            name: formdata.name === "" ? `${t('name_valid')}` : null,
            // dob: formdata?.dob === "" ? "DOB is required" : null,
            // password: formdata.password === "" ? "Password is required" : formdata.password.length <= 5 ? "Password should contain more than 5 characters" : null,
            phone_number: formdata.phone_number === "" ? `${t('phone_valid')}` : !isValidPhoneNumber(checkval) ? `${t('phone_valid_check')}` : null,
            city: formdata?.city === "" ? `${t('city_valid')}` : null,
            state: formdata?.state === "" ? `${t('state_valid')}` : null,
            zip_code: formdata?.zip_code === "" ? `${t('zip_valid')}` : formdata?.zip_code?.length < 5 ? `${t('zip_valid_check')}` : null,
            picture: formdata?.picture === "" ? `${t('profile_check')}` : null
        };

        let newErrors = Object.entries(Errors);
        //console.log(newErrors);
        newErrors.forEach(([key, value]) => {
            if (value) {
                err = true;
                setErrors((prev) => ({ ...prev, [key]: value }));
            }
        });

        return err;
    };

    const handleUpload = (val) => {
        setFormdata((prev) => ({ ...prev, picture: val }));
        setImagePreview(URL.createObjectURL(val));
        setErrors((prev) => ({ ...prev, picture: null }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (handleValid()) return;

        const userdata = {
            ...formdata,
            country_code: `+${countrycode}`,
            country_code_name: country,
            // role: "user"
        }
        let isedit = await edit_profile(userdata, user?.token, dispatch, user, vendor_id, toast);
        if (isedit) {
            setFormdata({ email: '', name: '', phone_number: '', address: '', city: '', state: '', zip_code: '', });
            setErrors({ email: null, name: null, phone_number: null, address: null, city: null, state: null, zip_code: null });
            handleClose();
        }
    };

    useEffect(() => {
        if (userDetails) {
            setFormdata((prev) => ({
                ...prev,
                // username: userDetails?.username ? userDetails?.username : '',
                email: userDetails?.email ? userDetails?.email : '',
                name: userDetails?.name ? userDetails?.name : '',
                // dob: userDetails?.dob ? formatDate(userDetails?.dob) : '',
                phone_number: userDetails?.country_code === null ? '' : userDetails?.phone_number,
                address: userDetails?.address ? userDetails?.address : '',
                city: userDetails?.city ? userDetails?.city : '',
                state: userDetails?.state ? userDetails?.city : '',
                zip_code: userDetails?.zip_code ? userDetails?.zip_code : '',
                picture: userDetails?.profile_pic !== null ? userDetails?.profile_pic : ''
            }));
            setCountry(userDetails?.country_code_name === null ? 'US' : userDetails?.country_code_name);
            setImagePreview(userDetails?.profile_pic !== null ? `${REACT_APP_OTA_URL}${userDetails?.profile_pic}` : '');
        }
    }, [userDetails]);

    useEffect(() => {
        if (formdata?.phone_number === '') return;
        handleChange('phone_number', formdata?.phone_number);
    }, [country]);

    useEffect(() => {
        if (userDetails) {
            const countries = getCountries();
            const country = countries.find((country) => `+${getCountryCallingCode(country)}` === userDetails?.country_code);
            setCountry(userDetails?.country_code_name === null ? (country || 'US') : userDetails?.country_code_name);
        }
    }, [userDetails]);

    return (
        <Modal show={show} size='lg'>
            <Modal.Body className='p-4 edit-profile-popup-wrap'>
                <div className="modal-content position-relative">
                    <div className='custom-popup-scrollbar'>
                        <div class="modal-header border-0 cross-icon p-0 align-items-center">
                            <div className='d-flex align-items-center'>
                                <h2 className='fw-bold text-color-theme'>{t("edit_profile")}</h2>
                                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={handleClose}></button>
                            </div>
                        </div>
                        <Form onSubmit={(e) => handleSubmit(e)} noValidate>
                            <div className='container-fluid px-0'>
                                <div className='admin-profile-wrap'>
                                    <div className='admin-profile-img-container ' >

                                        <div className='d-flex flex-column light-border-circle-wrapper position-relative'>
                                            <div
                                                className='admin-profile-edit-icon-wrap d-flex align-items-center justify-content-end position-absolute top-0 end-0 px-3'
                                                onClick={() => imageRef.current.click()}
                                            >
                                                <i className="bi bi-pencil-square cursor-pointer"></i>
                                            </div>
                                            <div className='admin-profile-img-wrap cursor-pointer'>
                                                <img src={imagePreview !== '' ? imagePreview : adminProfile} alt='' />
                                            </div>
                                        </div>
                                        {errors.picture && <p className='text-danger text-center'>{errors.picture}</p>}
                                    </div>
                                    <input
                                        type='file'
                                        className='form-control d-none'
                                        accept='.jpeg,.jpg,.png'
                                        onChange={(e) => {
                                            const files = e.target.files[0];
                                            handleUpload(files);
                                            e.target.value = "";
                                        }}
                                        ref={imageRef}
                                    />
                                </div>
                                <div className='row'>
                                    <div className='col-md-6'>
                                        <div className='form-group mb-3'>
                                            <label className='form-label'>{t("name_label")} </label>
                                            <input
                                                type='text'
                                                className='form-control'
                                                placeholder={t('name_placeholder')}
                                                value={formdata.name}
                                                onChange={(e) => handleChange('name', e.target.value)}
                                            />
                                            {errors.name && <p className='text-danger'>{errors.name}</p>}
                                        </div>
                                    </div>
                                    <div className='col-md-6'>
                                        <div className='form-group mb-3'>
                                            <label className='form-label'>{t('email_label')} </label>
                                            <input
                                                type='email'
                                                className='form-control'
                                                placeholder={t('email_placeholder')}
                                                value={formdata.email}
                                                onChange={(e) => handleChange('email', e.target.value)}
                                                disabled={(userDetails?.email && userDetails?.email !== '') ? true : false}
                                            />
                                            {errors.email && <p className='text-danger'>{errors.email}</p>}
                                        </div>
                                    </div>
                                    <div className='col-md-12'>
                                        <div className='form-group mb-3'>
                                            <label className='form-label'>{t('phone_label')}</label>
                                            <PhoneNumber
                                                country={country}
                                                setCountry={(val) => setCountry(val)}
                                                phoneNumber={formdata.phone_number}
                                                handleChange={(val) => handleChange('phone_number', val)}
                                            />
                                            {errors.phone_number && <p className='text-danger'>{errors.phone_number}</p>}
                                        </div>
                                    </div>
                                    <div className='col-md-12'>
                                        <div className='form-group mb-3'>
                                            <label className='form-label'>{t('address')} </label>
                                            <textarea
                                                minLength={2}
                                                className='form-control'
                                                placeholder={`${t('address_placeholder')}`}
                                                value={formdata.address}
                                                onChange={(e) => handleChange('address', e.target.value)}
                                            />
                                            {errors.address && <p className='text-danger'>{errors.address}</p>}
                                        </div>
                                    </div>
                                    <div className='col-md-4'>
                                        <div className='form-group mb-3'>
                                            <label className='form-label'>{t('state')} </label>
                                            <input
                                                type='text'
                                                className='form-control'
                                                placeholder={`${t('state')}`}
                                                value={formdata.state}
                                                onChange={(e) => handleChange('state', e.target.value)}
                                            />
                                            {errors.state && <p className='text-danger'>{errors.state}</p>}
                                        </div>
                                    </div>
                                    <div className='col-md-4'>
                                        <div className='form-group mb-3'>
                                            <label className='form-label'>{t('city')} </label>
                                            <input
                                                type='text'
                                                className='form-control'
                                                placeholder={`${t('city')}`}
                                                value={formdata.city}
                                                onChange={(e) => handleChange('city', e.target.value)}
                                            />
                                            {errors.city && <p className='text-danger'>{errors.city}</p>}
                                        </div>
                                    </div>
                                    <div className='col-md-4'>
                                        <div className='form-group mb-3'>
                                            <label className='form-label'>{t('zip_code')} </label>
                                            <input
                                                type='number'
                                                className='form-control'
                                                placeholder={`${t('zip_code')}`}
                                                value={formdata?.zip_code}
                                                onChange={(e) => handleChange('zip_code', e.target.value)}
                                            />
                                            {errors.zip_code && <p className='text-danger'>{errors.zip_code}</p>}
                                        </div>
                                    </div>
                                    <div className='col-md-6'>
                                        <div className='form-group mb-3'>
                                            <label className='form-label'>{t('referral_code')} </label>
                                            <div className="d-flex align-items-center gap-2">
                                                <input
                                                    type="text"
                                                    className="form-control me-2"
                                                    placeholder="Referral Code"
                                                    value={userDetails?.my_referral_code}
                                                    disabled
                                                />
                                                <CopyToClipboard text={`https://ota.visionvivante.com/signup?referralcode=${userDetails?.my_referral_code}`}
                                                    onCopy={handleCopy}
                                                >
                                                    <i class="bi bi-copy cursor-pointer"></i>
                                                </CopyToClipboard>
                                                {copied && <span className="ms-2 text-success">Copied!</span>}

                                                <div className='d-flex gap-2'>

                                                    {/* <i className="bi bi-share" onClick={() => setIsPop(!isPop)}></i> */}
                                                    {/* {isPop && <div
                                                        className="share-popup"
                                                        style={{
                                                            position: "fixed",
                                                            top: "82%",
                                                            left: "50%",
                                                            transform: "translate(-50%, -50%)",
                                                            background: "white",
                                                            padding: "20px",
                                                            borderRadius: "10px",
                                                            boxShadow: "0 0 10px rgba(0, 0, 0, 0.2)",
                                                            zIndex: 1000,
                                                            width: '25%',
                                                            transition: "all 0.5s ease-in-out"
                                                        }}
                                                    >
                                                        <div>
                                                            <h3 className=''>Referral Code</h3>
                                                        </div>

                                                        <div
                                                            style={{
                                                                display: "grid",
                                                                gridTemplateColumns: "repeat(3, 1fr)",
                                                                gap: "10px",
                                                                justifyItems: "center",
                                                                alignItems: "center",
                                                            }}
                                                        >
                                                            <FacebookShareButton url={`https://ota.visionvivante.com/signup?referralcode=${userDetails?.my_referral_code}`} quote="Check out my referral code!">
                                                                <FacebookIcon size={60} round />
                                                            </FacebookShareButton>

                                                            <TwitterShareButton url={`https://ota.visionvivante.com/signup?referralcode=${userDetails?.my_referral_code}`} title="Check out my referral code!">
                                                                <TwitterIcon size={60} round />
                                                            </TwitterShareButton>

                                                            <WhatsappShareButton url={`https://ota.visionvivante.com/signup?referralcode=${userDetails?.my_referral_code}`} title="Check out my referral code!">
                                                                <WhatsappIcon size={60} round />
                                                            </WhatsappShareButton>

                                                            <RedditShareButton url={`https://ota.visionvivante.com/signup?referralcode=${userDetails?.my_referral_code}`} title="Check out my referral code!">
                                                                <RedditIcon size={60} round />
                                                            </RedditShareButton>

                                                            <EmailShareButton url={`https://ota.visionvivante.com/signup?referralcode=${userDetails?.my_referral_code}`} title="Check out my referral code!">
                                                                <EmailIcon size={60} round />
                                                            </EmailShareButton>

                                                            <LinkedinShareButton url={`https://ota.visionvivante.com/signup?referralcode=${userDetails?.my_referral_code}`} title="Check out my referral code!">
                                                                <LinkedinIcon size={60} round />
                                                            </LinkedinShareButton>
                                                        </div>

                                                        <button type='button' className='btn-gradientlg-purple w-100 mt-2' onClick={() => setIsPop(!isPop)}>Close</button>
                                                    </div>} */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='d-flex justify-content-end gap-3 py-3'>
                                <button type='button' className='disabled-btn w-auto' onClick={handleClose}>{t('cancel')}</button>
                                <button type='submit' className='btn-gradientlg-purple w-auto'>{t('save_changes')}</button>
                            </div>
                        </Form>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default EditProfile