import React, { useEffect, useState } from "react";
import { Button, Col, Container, Form, FormLabel, Row } from "react-bootstrap";
import { connect, useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { forgot_password, get_vender_id } from "../reduxStore/User/userActions";
import { useToast } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

const ForgotPassword = ({ vendor_id }) => {
  const { t } = useTranslation();
  const [formData, setFormData] = useState({
    email: ""
  });
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const [errors, setErrors] = useState({
    email: null
  });
  const emailRegrex = /^(?!.*\.\.)(?!.*-@)(?!.*#)([A-Z0-9._%+-]+)@([A-Z0-9.-]+\.[A-Z]{2,})$/i;
  const toast = useToast();

  const handleChange = (value, label) => {
    errors[label] = !value ? `required` : !emailRegrex.test(value) ? 'invalid' : null;
    setErrors({ ...errors });
    setFormData({ ...formData, [label]: value });
  };

  const checkErrors = () => {
    let err = false;
    const entriesArray = Object.entries(formData);
    entriesArray.forEach(([key, value]) => {
      err = (!value || ((key === 'email') && !emailRegrex.test(value))) ? true : false;
      handleChange(value, key);
    });
    return err;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (checkErrors()) {
      return;
    }
    const userdata = {
      email: formData.email,
    };
    let issend = await forgot_password(userdata, dispatch, vendor_id, toast);
    if (issend) {
      navigate("/password-reset-email");
    }
  };

  useEffect(() => {
    get_vender_id("superadmin", dispatch);
  }, []);

  return (
    <div className="login-signup-bg-wrapper">
      <div className='container'>
        <div className="row justify-content-end">
          <div className="col-xl-5 col-lg-6 col-md-8">
            <div className="login-form-wrap forgot-pwd-form">
              <Form className="auth-form" onSubmit={handleSubmit}>
                <h1 className="text-center text-white mb-4">{t('forgot_password_title')}</h1>
                <div>
                  <label className="form-label text-white fw-medium">{t('email_address')}</label>
                  <input
                    type="email"
                    placeholder={t('email_placeholder')}
                    className="form-control"
                    value={formData.email}
                    onChange={(e) => handleChange(e.target.value, "email")}
                  />
                  {errors?.email && (
                    <div style={{ color: "red" }}>
                      {errors?.email === 'invalid' ? `${t('email_check')}` : `${t('email_valid')}`}
                    </div>
                  )}
                </div>
                <button type="submit" className="ylw-theme-button w-100 my-4">
                  {t('send')}
                </button>
                <Link to="/login" className='text-decoration-underline mb-0 h6 link-color text-center d-flex justify-content-center fw-medium text-white'>
                {t('back_to_login')}
                </Link>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
};

const mapStateToProps = (state) => {
  return {
    vendor_id: state.auth.vendor_id
  }
}
export default connect(mapStateToProps)(ForgotPassword);
