import { useToast } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react'
import { connect, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom';
import { get_user_card_Details, get_vender_id, user_edit_card } from '../../reduxStore/User/userActions';
import { loadStripe } from '@stripe/stripe-js';
import { CardNumberElement, Elements, ElementsConsumer } from '@stripe/react-stripe-js';
import PaymentCard from '../../shared/PaymentCard';
import { useTranslation } from 'react-i18next';

const stripePromise = loadStripe("pk_test_51Q26ObEZOuFNrMpGVNLaMcgGcLmftyqoqUB2fnf9ZgOfKGdOH3aJrVxuvrczoeByTHc4cqgJgIw4tYQixDywZib000xSxPCeWf");

const Payment = ({ user, vendor_id, handleClose }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const toast = useToast();
    const { t } = useTranslation();
    const [stripeData, setStripeData] = useState(null);
    const [stripeElements, setStripeElements] = useState(null);
    const [cardFeilds, setCardFeilds] = useState({
        cardNumber: true,
        cardExpiry: true,
        cardCvc: true,
        userName: true,
    });
    const [errors, setErrors] = useState({
        cardNumber: "",
        cardExpiry: "",
        cardCvc: "",
        userName: "",
    });
    const [formData, setFormData] = useState({ userName: '' });

    const handleCardElementChange = (event, label) => {
        switch (label) {
            case "cardNumber":
                if (event.empty) {
                    setErrors({ ...errors, cardNumber: `${t("card_number_valid")}` });
                    setCardFeilds({ ...cardFeilds, cardNumber: true });
                } else if (event.error) {
                    setErrors({ ...errors, cardNumber: event.error.message });
                    setCardFeilds({ ...cardFeilds, cardNumber: true });
                } else {
                    setErrors({ ...errors, cardNumber: "" });
                    setCardFeilds({ ...cardFeilds, cardNumber: false });
                }
                break;
            case "cardExpiry":
                if (event.empty) {
                    setErrors({ ...errors, cardExpiry: `${t("card_expiry_check")}` });
                    setCardFeilds({ ...cardFeilds, cardExpiry: true });
                } else if (event.error) {
                    setErrors({ ...errors, cardExpiry: event.error.message });
                    setCardFeilds({ ...cardFeilds, cardExpiry: true });
                } else {
                    setErrors({ ...errors, cardExpiry: "" });
                    setCardFeilds({ ...cardFeilds, cardExpiry: false });
                }
                break;
            case "cardCvc":
                if (event.empty) {
                    setErrors({ ...errors, cardCvc: `${t("cvc_check")}` });
                    setCardFeilds({ ...cardFeilds, cardCvc: true });
                } else if (event.error) {
                    setErrors({ ...errors, cardCvc: event.error.message });
                    setCardFeilds({ ...cardFeilds, cardCvc: true });
                } else {
                    setErrors({ ...errors, cardCvc: "" });
                    setCardFeilds({ ...cardFeilds, cardCvc: false });
                }
                break;
            case "userName":
                if (!event.target.value.trim()) {
                    setErrors({ ...errors, userName: `${t("name_valid")}`});
                    setCardFeilds({ ...cardFeilds, userName: true });
                } else {
                    setErrors({ ...errors, userName: "" });
                    setCardFeilds({ ...cardFeilds, userName: false });
                    setFormData({ ...formData, userName: event.target.value });
                }
                break;
            default:
                break;
        }
    };

    const checkAllErrors = () => {
        let err = false;
        let output = Object.entries(cardFeilds);
        // console.log("ErrorsOutputs....",output);
        output.forEach(([key, value]) => {
            if (value) {
                err = true;
                setErrors((prevErrors) => ({
                    ...prevErrors,
                    [key]:
                        key === "userName"
                            ? prevErrors.userName
                                ? prevErrors.userName
                                : `${t("name_valid")}`
                            : key === "cardNumber"
                                ? prevErrors.cardNumber
                                    ? prevErrors.cardNumber
                                    : `${t("card_number_valid")}`
                                : key === "cardExpiry"
                                    ? prevErrors.cardExpiry
                                        ? prevErrors.cardExpiry
                                        : `${t("card_expiry_check")}`
                                    : prevErrors.cardCvc
                                        ? prevErrors.cardCvc
                                        : `${t("cvc_check")}`
                }));
                // console.log("errrr",err,key,value);
            } else {
                setCardFeilds((prevErrors) => ({ ...prevErrors, [key]: false }));
            }
        });
        return err;
    };

    // console.log(searchedData);

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (!stripeData || !stripeElements || checkAllErrors()) {
            window.scrollTo({ top: 0, behavior: "smooth" });
            return;
        }

        const cardElement = stripeElements.getElement(CardNumberElement);
        const { error, token } = await stripeData.createToken(cardElement);
        // const { paymentMethod } = await stripeData.createPaymentMethod({
        //     type: "card",
        //     card: cardElement,
        //     billing_details: {
        //         name: formData?.userName,
        //     },
        // });
        // console.log("payment method ====>", paymentMethod);


        // console.log("Error", error);
        // console.log("CArd token", token);
        // if (!token) return;

        const stripedata = {
            cardToken: token?.id,
        };
        let isEdit = await user_edit_card(dispatch, vendor_id, stripedata, user?.token);
        if (isEdit) {
            await get_user_card_Details(dispatch, vendor_id, user?.token);
            handleClose();
        }
    };

    useEffect(() => {
        get_vender_id("superadmin", dispatch);
    }, []);

    return (
        <div className='container'>
            <Elements stripe={stripePromise}>
                <ElementsConsumer>
                    {({ stripe, elements }) => {
                        setStripeElements(elements);
                        setStripeData(stripe);
                        return (
                            <>
                                <form onSubmit={handleSubmit}>
                                    {/* <div className='row'>
                                        <div className='col-lg-8 col-md-7 col-sm-12'> */}
                                    <div className='left-fill-details'>
                                        <PaymentCard
                                            stripe={stripe}
                                            elements={elements}
                                            errors={errors}
                                            handleCardElementChange={(e, label) => handleCardElementChange(e, label)}
                                            formData={formData}
                                        />
                                        <div className='d-flex justify-content-end'>
                                            <button type="submit" className='btn-gradient-purple text-white w-auto'>{t("save_details")}</button>
                                        </div>
                                    </div>
                                    {/* </div>
                                    </div> */}
                                </form>
                            </>
                        )
                    }}
                </ElementsConsumer>
            </Elements>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        user: state.auth.user,
        vendor_id: state.auth.vendor_id
    }
}
export default connect(mapStateToProps)(Payment);