import React, { useEffect, useRef, useState } from "react";
import ReactQuill from "react-quill";
import CustomQuillToolbar, { cmodules, cformats } from "./CustomEditorToolbar";
import "react-quill/dist/quill.snow.css";
function CustomEditor(props) {
  const editorRef=useRef();
  const { changeValue,isHtmlMode,setIsHtmlMode,presentValue,presentHtmlValue,setPresentHtmlValue,addCopiedText } = props;
  const htmlTextAreaRef = useRef(null);
  const toggleHtmlMode = () => {
    setIsHtmlMode(!isHtmlMode);
  };
  useEffect(() => {
    if (isHtmlMode) {
      htmlTextAreaRef.current.value = presentValue;
      setPresentHtmlValue(presentValue);
    }
  }, [isHtmlMode,editorRef]);

  const handleHtmlChange = (e) => {
    if(e.target.value){
      setPresentHtmlValue(e.target.value);
      changeValue(e.target.value);
    }
  };
  return (<>
      <CustomQuillToolbar quill={editorRef.current} isHtmlMode={isHtmlMode} addCopiedText={addCopiedText} toggleHtmlMode={toggleHtmlMode}/>
      <div className={!isHtmlMode?'d-none':''}>
        <textarea
          ref={htmlTextAreaRef}
          style={{ width: '100%', height: '400px' }}
          value={presentHtmlValue}
          onChange={handleHtmlChange}
        />
      </div>
      <div className={isHtmlMode?'d-none':''}>
        <ReactQuill
          ref={editorRef}
          theme="snow"
          placeholder={"Write something awesome..."}
          modules={cmodules}
          formats={cformats}
          value={presentValue ?? ""}
          onChange={(e) => changeValue(e)}
          
        />
      </div>
    </>
  );
}
export default CustomEditor;
