export const GET_HOTEL_ID = "GET_HOTEL_ID";
export const GET_TOP_HOTELS = "GET_TOP_HOTELS";
export const GET_HOTELS_LISTS = "GET_HOTELS_LISTS";
export const GET_CITIES_LISTS = "GET_CITIES_LISTS";
export const GET_HOTEL_DETAILS = "GET_HOTEL_DETAILS";
export const GET_SEARCHED_GRAPH = "GET_SEARCHED_GRAPH";
export const GET_SEARCHED_DATA = "GET_SEARCHED_DATA";
export const GET_MODIFIED_DATA = "GET_MODIFIED_DATA";
export const GET_CITY_DATA = "GET_CITY_DATA";
export const GET_USER_BOOKING = "GET_USER_BOOKING";
export const GET_ROOMS_LISTS = "GET_ROOMS_LISTS";
export const GET_USER_UPCOMMING_BOOKING = "GET_USER_UPCOMMING_BOOKING";
export const GET_HOTEL_FACILITIES = "GET_HOTEL_FACILITIES";
export const GET_SIMILAR_TOP_HOTELS = "GET_SIMILAR_TOP_HOTELS";