import { Height } from '@mui/icons-material';
import React from 'react'
import { Overlay, Popover } from 'react-bootstrap';


const SelectPopOver = ({ id, open, anchorEl, setAnchorEl, optionsData, setOptionsData, childsAge, setChildsAge }) => {

    const handleData = (label, key) => {
        setOptionsData((prev) => ({
            ...prev,
            [label]: key === '+' ? prev[label] + 1 : Math.max(prev[label] - 1, 0),
        }));
        if (label === "Children" && key === "+") {
            setChildsAge(prev => [...prev, { age: '' }]);
        } else if (label === "Children" && key === "-") {
            setChildsAge(prev => prev.length > 0 ? prev.slice(0, -1) : []);
        }
    };

    const handleAge = (value, index) => {
        setChildsAge(prev => prev.map((prevchild, i) => i === index ? { ...prevchild, age: value } : prevchild));
    };

    return (
        <Overlay target={anchorEl} show={open} placement="bottom" onHide={() => setAnchorEl(null)} rootClose>
            {(props) => (
                <Popover {...props} id={id}>
                    <Popover.Body>
                        <div className='container-fluid px-0'>
                            {[
                                "Room",
                                "Adults",
                                "Children",
                                // "Pets"
                            ].map((label, index) => (
                                <div key={index} className="row justify-content-between align-items-center mb-3 border-bottom pb-3 w-100 mx-auto">
                                    <span className="col-6">{label}</span>
                                    <div className="col-6 d-flex  gap-2">
                                        <button className='rooms-qty-btn' onClick={() => handleData(label, '-')}>-</button>
                                        <span className='rooms-qty-text'>{optionsData[label]}</span>
                                        <button className='rooms-qty-btn' onClick={() => handleData(label, '+')}>+</button>
                                    </div>
                                </div>
                            ))}

                            {childsAge?.length > 0 && <div className='d-flex flex-wrap gap-1'>
                                {childsAge?.map((item, index) => (
                                    <select
                                        className="form-select py-2"
                                        value={item?.age}
                                        // style={{ appearance: 'menulist', width: '7rem', margin: '0.3rem' }}
                                        onChange={(e) => handleAge(e.target.value, index)}
                                    >
                                        <option value="" disabled>Age needed</option>
                                        {Array.from({ length: 18 })?.map((_, index) => (
                                            <option key={index} value={index}> {index} years old</option>
                                        ))}
                                    </select>
                                ))}
                            </div>}

                        </div>
                    </Popover.Body>
                </Popover>
            )}
        </Overlay>

    )
}

export default SelectPopOver