import React from 'react'
import { saveAs } from 'file-saver';

const DownloadFile = ({ invoiceUrl, user, isDetail }) => {
    const handleDownload = async (url) => {
        if (!url) return;

        const filepath = url.includes('+') ? url.replace(/\+/g, '%2B') : url;
        const fileContent = `https://otaapi.visionvivante.com:9000/profile/download/669a0e9ba89ea4c142cb7db4?file=${filepath}`;
        const fileName = url?.substring(url?.lastIndexOf("/") + 1);
        const getMimeType = (ext) => {
            const mimeTypes = {
                txt: "text/plain",
                pdf: "application/pdf",
                zip: "application/zip",
                jpg: "image/jpeg",
                jpeg: "image/jpeg",
                png: "image/png",
                gif: "image/gif",
                mp4: "video/mp4",
                mov: "video/quicktime"
            };
            return mimeTypes[ext] || "application/octet-stream";
        };

        const response = await fetch(fileContent, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'x-access-token': user?.token,
            },
        });
        const blobFile = await response.blob();
        const fileExtension = fileName?.split(".").pop().toLowerCase();
        const mimeType = getMimeType(fileExtension);
        const blobwithtype = new Blob([blobFile], { type: mimeType });
        saveAs(blobwithtype, fileName);
    };

    return (
        <div>
            {isDetail ?
                <button type="button" className='w-auto download-btn btn-gradient-purple d-flex align-items-center gap-2' onClick={() => handleDownload(invoiceUrl)}>
                    <i class="bi bi-download h5 mb-0"></i>
                    Download
                </button>
                :
                <i className="bi bi-download" onClick={() => handleDownload(invoiceUrl)}></i>
            }
        </div>
    )
}

export default DownloadFile