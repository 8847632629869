import i18next from "i18next";
import I18nextBrowserLanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import enData from './locales/en.json';
import arData from './locales/ar.json';
import timeZonesData from './locales/timeZones.json';

const isSwitch = JSON.parse(localStorage.getItem("isSwitch"));
const defaultLang = JSON.parse(localStorage.getItem("DefaultLang"));
const currentTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

export const resources = {
    en: { translation: enData },
    ar: { translation: arData }
};

i18next
    .use(I18nextBrowserLanguageDetector)
    .use(initReactI18next)
    .init({
        resources,
        fallbackLng: (isSwitch === undefined && timeZonesData[currentTimeZone] !== undefined) ? timeZonesData[currentTimeZone]?.languageCode : defaultLang,
        debug: true,
        interpolation: { escapeValue: false }
    });

export default i18next;