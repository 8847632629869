import AirConditionerImg from '../assets/ac-icon.svg';
import ReceptionImg from '../assets/reception-icon.svg';
import InternetImg from '../assets/wifi-icon.svg';
import CafeImg from '../assets/cafe-icon.svg';
import WlanImg from '../assets/wlan-access-img.svg';
import HotelSafeImg from '../assets/hotel-safe-img.svg';
import BarsImg from '../assets/bar-icon.svg';
import RadioImg from '../assets/radio-img.svg';
import SafeImg from '../assets/safe.svg';
import DoubleBedImg from '../assets/double-bed-img.svg';
import MiniBarImg from '../assets/minbar-image.svg';
import FridgeImg from '../assets/fridge.svg';
import SuperMarketImg from '../assets/supermarket-img.svg';
import HeatingImg from '../assets/heating-img.svg';
import BathRoomImg from '../assets/bathroom.svg';
import BidetImg from '../assets/bidet.svg';
import CheckInImg from '../assets/24-hour-check-in.svg';
import BalconyImg from '../assets/balcony-img.svg';
import ParkingImg from '../assets/parking-icon.svg';
import ShowerImg from '../assets/shower-img.svg';
import HousekeepingImg from '../assets/house-keeping.svg';
import TowelsImg from '../assets/towels-provided-img.svg';
import PremiumBedImg from '../assets/premium-bed.svg';
import TvImg from '../assets/tv-img.svg';
import CloakImg from '../assets/cloakroom.svg';
import LinensImg from '../assets/linens-img.svg';
import SmartTv from '../assets/tv-room.svg';
import NonSmokeHoteImg from '../assets/non-smoke.svg';
import NonSmokingImg from '../assets/non-smoking.png';
import HairDryerImg from '../assets/hair-dryer.svg';
import WheelChairImg from '../assets/wheel-chair-img.svg';
import DeskImg from '../assets/desk.svg';
import PrivateImg from '../assets/private-bathroom.svg';
import OverheadImg from '../assets/rainfall-overhead.svg';
import GameRoomImg from '../assets/game-room-img.svg';
import KidsClubImg from '../assets/kids-club-img.svg';
import WaterSlideImg from '../assets/waterslide.svg';
import PlacegroundImg from '../assets/playground-img.svg';
import ResturantSmokingImg from '../assets/resturant-with-smoking-img.svg';
import KingSizedImg from '../assets/king-sized-bed-img.svg';
import NightClubImg from '../assets/nightclub-img.svg';
import LaundryServiceImg from '../assets/laundryservice-img.svg';
import CurrencyImg from '../assets/currency-img.svg';
import TVRoomImg from '../assets/tvroom-img.svg';
import GarageImg from '../assets/garageimg.svg';
import BycycleImg from '../assets/bycycle-seller-img.svg';
import MedicalImg from '../assets/medical-img.svg';
import RoomServiceImg from '../assets/room-service.svg';
import ConferenceImg from '../assets/conference-room.svg';
import LiftImg from '../assets/Lift.png';
import RestaurantImg from '../assets/Restaurant.png';
import LoungeImg from '../assets/lounge.png';
import CarpetingImg from '../assets/Carpeting.png';
import DirectCallImg from '../assets/Direct dial telephone.png';
import HydroMassageImg from '../assets/Hydromassage showerhead.png';
import PhoneImg from '../assets/Phone.png';
import PubsImg from '../assets/pubs-image.svg';
import ShopsImg from '../assets/shops-images.svg';
import NewsPaperImg from '../assets/newspaper-image.svg';
import BathTubImg from '../assets/bathtub-image.svg';
import KitchenetteImg from '../assets/Kitchenette.png';
import FoyerImg from '../assets/Foyer.png';
import SmokingImg from '../assets/smoking-image.svg';
import SoundProofImg from '../assets/soundproof.svg';
import GardenImg from '../assets/garden-icon.svg';

const Amenities = [
  { Description: "Air conditioning", ImagePath: AirConditionerImg },
  { Description: "Air conditioning (centrally regulated)", ImagePath: AirConditionerImg },
  { Description: "Air conditioning (individually regulated)", ImagePath: AirConditionerImg },
  { Description: "Television", ImagePath: TvImg },
  { Description: "Daily housekeeping", ImagePath: HousekeepingImg },
  { Description: "Phone", ImagePath: PhoneImg },
  { Description: "Free local calls", ImagePath: null },
  { Description: "Private bathroom", ImagePath: PrivateImg },
  { Description: "Hair dryer", ImagePath: HairDryerImg },
  { Description: "Desk", ImagePath: DeskImg },
  { Description: "Premium bedding", ImagePath: PremiumBedImg },
  { Description: "Iron/ironing board (on request)", ImagePath: LaundryServiceImg },
  { Description: "Shower only", ImagePath: ShowerImg },
  { Description: "Hair dryer (on request)", ImagePath: HairDryerImg },
  { Description: "Digital TV service", ImagePath: TvImg },
  { Description: "Free WiFi", ImagePath: InternetImg },
  { Description: "Down comforter", ImagePath: null },
  { Description: "Egyptian-cotton sheets", ImagePath: null },
  { Description: "Pillowtop mattress", ImagePath: null },
  { Description: "Rainfall showerhead", ImagePath: OverheadImg },
  { Description: "Hydromassage showerhead", ImagePath: HydroMassageImg },
  { Description: "LED TV", ImagePath: TvImg },
  { Description: "Non-Smoking", ImagePath: NonSmokingImg },
  { Description: "Heating", ImagePath: HeatingImg },
  { Description: "Towels provided", ImagePath: TowelsImg },
  { Description: "Linens provided", ImagePath: LinensImg },
  { Description: "Free WiFi (limited) device count2", ImagePath: InternetImg },
  { Description: "Netflix", ImagePath: require('../assets/netflix.png') },
  { Description: "Wheelchair accessible", ImagePath: WheelChairImg },
  { Description: "Refrigerator", ImagePath: FridgeImg },
  { Description: "Coffee/tea maker", ImagePath: CafeImg },
  { Description: "Smart TV", ImagePath: SmartTv },
  { Description: "Minibar", ImagePath: MiniBarImg },
  { Description: "Restaurant(s) with air conditioning", ImagePath: AirConditionerImg },
  { Description: "Restaurant(s) with non-smoking area", ImagePath: NonSmokeHoteImg },
  { Description: "Café", ImagePath: CafeImg },
  { Description: "24-hour reception", ImagePath: ReceptionImg },
  { Description: "Lifts", ImagePath: LiftImg },
  { Description: "Bar(s)", ImagePath: BarsImg },
  { Description: "Nightclub", ImagePath: NightClubImg },
  { Description: "Car Park", ImagePath: ParkingImg },
  { Description: "Internet access", ImagePath: InternetImg },
  { Description: "Laundry Service", ImagePath: LaundryServiceImg },
  { Description: "Hotel Safe", ImagePath: HotelSafeImg },
  { Description: "Hairdresser", ImagePath: HairDryerImg},
  { Description: "Currency Exchange", ImagePath: CurrencyImg },
  { Description: "Wheelchair access", ImagePath: WheelChairImg },
  { Description: "Disability-friendly", ImagePath: WheelChairImg },
  { Description: "Laundry Facilities", ImagePath: LaundryServiceImg },
  { Description: "TV Room", ImagePath: TVRoomImg },
  { Description: "Garage", ImagePath: GarageImg },
  { Description: "Bicycle Cellar", ImagePath: BycycleImg },
  { Description: "Medical Assistance", ImagePath: MedicalImg },
  { Description: "Room Service", ImagePath: RoomServiceImg },
  { Description: "WLAN access", ImagePath: WlanImg},
  { Description: "Conference Room", ImagePath: ConferenceImg},
  { Description: "Pub(s)", ImagePath: PubsImg},
  { Description: "Shops", ImagePath: ShopsImg },
  { Description: "Newspaper kiosk", ImagePath: NewsPaperImg},
  { Description: "Hairdryer", ImagePath: HairDryerImg},
  { Description: "TV", ImagePath: TvImg},
  { Description: "Satellite/cable TV", ImagePath: TvImg},
  { Description: "Bathtub", ImagePath: BathTubImg},
  { Description: "Shower", ImagePath: ShowerImg},
  { Description: "Radio", ImagePath: RadioImg },
  { Description: "Safe", ImagePath: SafeImg },
  { Description: "Double Bed", ImagePath: DoubleBedImg },
  { Description: "Fridge", ImagePath: FridgeImg },
  { Description: "Tea/coffee maker", ImagePath: CafeImg },
  { Description: "Small supermarket", ImagePath: SuperMarketImg },
  { Description: "Central Heating", ImagePath: HeatingImg },
  { Description: "Bathroom", ImagePath: BathRoomImg },
  { Description: "Bidet", ImagePath: BidetImg },
  { Description: "24-hour check-in", ImagePath: CheckInImg },
  { Description: "Balcony/Terrace", ImagePath: BalconyImg },
  { Description: "Cloakroom", ImagePath: CloakImg },
  { Description: "Games room", ImagePath: GameRoomImg },
  { Description: "Kids Club", ImagePath: KidsClubImg },
  { Description: "Playground", ImagePath: PlacegroundImg },
  { Description: "Waterslide", ImagePath: WaterSlideImg },
  { Description: "Restaurant(s) with smoking area", ImagePath: ResturantSmokingImg },
  { Description: "King-size Bed", ImagePath: KingSizedImg},
  { Description: "Restaurant(s)", ImagePath: RestaurantImg},
  { Description: "Lounge", ImagePath: LoungeImg},
  { Description: "Carpeting", ImagePath: CarpetingImg},
  { Description: "Direct dial telephone", ImagePath: DirectCallImg},
  { Description: "Kitchenette", ImagePath: KitchenetteImg},
  { Description: "Foyer", ImagePath: FoyerImg},
  { Description: "Theatre", ImagePath: TVRoomImg},
  { Description: "Bicycle Hire", ImagePath: BycycleImg},
  { Description: "Heating (individually regulated)", ImagePath: HeatingImg},
  { Description: "Dry cleaning / laundry service", ImagePath: LaundryServiceImg},
  { Description: "Free Newspaper", ImagePath: NewsPaperImg},
  { Description: "Free self parking", ImagePath: ParkingImg},
  { Description: "Free Wireless Internet access", ImagePath: InternetImg},
  { Description: "Restaurant", ImagePath: RestaurantImg},
  { Description: "Coffee/tea in lobby", ImagePath: CafeImg},
  { Description: "Concierge Desk", ImagePath: DeskImg},
  { Description: "Car Parking", ImagePath: ParkingImg},
  { Description: "Meeting Rooms", ImagePath: ConferenceImg},
  { Description: "Newspapers", ImagePath: NewsPaperImg},
  { Description: "Non-Smoking Rooms", ImagePath: NonSmokingImg},
  { Description: "Bar", ImagePath: BarsImg},
  { Description: "Coffee Shop", ImagePath: CafeImg},
  { Description: "Air Conditioned", ImagePath: AirConditionerImg},
  { Description: "Free kids club", ImagePath: KidsClubImg},
  { Description: "Wireless Internet Access", ImagePath: InternetImg},
  { Description: "Designated smoking area", ImagePath: SmokingImg},
  { Description: "Soundproof Rooms", ImagePath: SoundProofImg},
  { Description: "24 Hour Room Service", ImagePath: RoomServiceImg},
  { Description: "Garden", ImagePath: GardenImg}
];

export default Amenities;