import React, { useEffect, useState } from 'react'
import { Form } from 'react-bootstrap'
import PhoneNumber from '../shared/PhoneNumber';
import { getCountryCallingCode, isValidPhoneNumber } from 'react-phone-number-input';
import { get_vender_id, signup_user } from '../reduxStore/User/userActions';
import { connect, useDispatch } from 'react-redux';
import { useToast } from '@chakra-ui/react';
import moment from 'moment-timezone';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import SocialLogin from './SocialLogin';

const Signup = ({ vendor_id }) => {
    const toast = useToast();
    const { t } = useTranslation();
    const [searchParams] = useSearchParams();
    const [formdata, setFormdata] = useState({
        username: '',
        email: '',
        // dob: '',
        setpassword: '',
        password: '',
        phone_number: '',
        referral_code: '',
        // address: ''
    });

    const [country, setCountry] = useState('US');

    const [errors, setErrors] = useState({
        username: '',
        email: '',
        // dob: '',
        setpassword: '',
        password: '',
        phone_number: '',
        // address: ''
    });
    const exptest = /^(?!.*\.\.)(?!.*-@)(?!.*#)([A-Z0-9._%+-]+)@([A-Z0-9.-]+\.[A-Z]{2,})$/i;
    const [isShow, setIsShow] = useState(false);
    const [isConfirmShow, setIsConfirmShow] = useState(false);
    let countrycode = getCountryCallingCode(country);
    let checkval = `+${countrycode}` + formdata.phone_number;
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const maxDate = moment.tz(userTimeZone).format('YYYY-MM-DD');
    const [isAccept, setIsAccept] = useState(false);
    const [iserror, setIsError] = useState(false);

    const handleChange = (label, value) => {

        switch (label) {
            case "username":
                setFormdata((prev) => ({ ...prev, [label]: value }));
                setErrors((prev) => ({ ...prev, [label]: value === "" ? `${t('name_valid')}` : null }));
                break;
            case "email":
                setFormdata((prev) => ({ ...prev, [label]: value }));
                setErrors((prev) => ({ ...prev, [label]: value === "" ? `${t('email_valid')}` : !exptest.test(value) ? `${t('email_check')}` : null }));
                break;
            // case "dob":
            //     setErrors({ ...errors, dob: value === "" ? "DOB is required" : null });
            //     setFormdata({ ...formdata, [label]: value });
            //     break;
            case "setpassword":
                setFormdata((prev) => ({ ...prev, [label]: value }));
                setErrors((prev) => ({ ...prev, [label]: value === "" ? `${t('password_valid')}` : value.length <= 5 ? `${t('password_valid_check')}` : null }));
                break;
            case "password":
                setFormdata((prev) => ({ ...prev, [label]: value }));
                setErrors((prev) => ({ ...prev, [label]: value === "" ? `${t('confirm_valid')}` : value.length <= 5 ? `${t('password_valid_check')}` : value !== formdata?.setpassword ? `${t('confirm_valid_check')}` : null }));
                break;
            case "phone_number":
                let checkval = `+${countrycode}` + value;
                setFormdata((prev) => ({ ...prev, [label]: value }));
                setErrors((prev) => ({ ...prev, [label]: value === "" ? `${t('phone_valid')}` : !isValidPhoneNumber(checkval) ? `${t('phone_valid_check')}` : null }));
                break;
            case "referral_code":
                setFormdata((prev) => ({ ...prev, [label]: value }));
                break;

            default:
                setFormdata((prev) => ({ ...prev, [label]: value }));
                break;
        }
    };

    const handleAccept = (event) => {
        setIsAccept(event.target.checked);
        if (event.target.checked) {
            setIsError(false);
        }
    };

    const handleValid = () => {
        let err = false;

        const Errors = {
            username: formdata.username === "" ? `${t('name_valid')}` : null,
            // address: formdata.address === "" ? "Address is required" : null,
            email: formdata.email === "" ? `${t('email_valid')}` : !exptest.test(formdata.email) ? `${t('email_check')}` : null,
            // dob: formdata?.dob === "" ? "DOB is required" : null,
            setpassword: formdata.setpassword === "" ? `${t('password_valid')}` : formdata.setpassword.length <= 5 ? `${t('password_valid_check')}` : null,
            password: formdata.password === "" ? `${t('confirm_valid')}` : formdata.password.length <= 5 ? `${t('password_valid_check')}` : formdata?.password !== formdata?.setpassword ? `${t('confirm_valid_check')}` : null,
            phone_number: formdata.phone_number === "" ? `${t('phone_valid')}` : !isValidPhoneNumber(checkval) ? `${t('phone_valid_check')}` : null,
        };

        let newErrors = Object.entries(Errors);
        // console.log("errrrr", newErrors);
        newErrors.forEach(([key, value]) => {
            if (value) {
                err = true;
                setErrors((prev) => ({ ...prev, [key]: value }));
            }
        });

        return err;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (handleValid()) return;

        if (!isAccept) {
            setIsError(true);
        }

        if (isAccept) {
            setIsError(false);
            const userdata = {
                username: formdata?.username,
                email: formdata?.email.toLowerCase(),
                phone_number: formdata?.phone_number,
                password: formdata?.password,
                country_code: `+${countrycode}`,
                country_code_name: country,
                name: formdata.username,
                role: "user"
            };
            if (formdata.referral_code !== "") {
                userdata.referral_code = formdata?.referral_code;
            }
            let createuser = await signup_user(userdata, dispatch, toast, vendor_id);

            if (createuser) navigate("/login");
        }
    };

    useEffect(() => {
        get_vender_id("superadmin", dispatch);
    }, []);

    useEffect(() => {
        if (formdata.phone_number === '') return;
        handleChange('phone_number', formdata?.phone_number);
    }, [country]);

    useEffect(() => {
        if (searchParams.get("referralcode")) {
            setFormdata((prev) => ({
                ...prev,
                referral_code: searchParams.get("referralcode"),
            }));
        }
    }, []);

    return (
        <div className=''>
            <div className='container'>
                <div className='row justify-content-end'>
                    <div className='col-xl-5 col-lg-6 col-md-8'>
                        <div className='login-form-wrap'>
                            <h1 className='text-center text-white mb-0'> {t('create_account')}</h1>
                            <p className='text-center text-white h5 mt-3 or-text fw-normal'> {t('sign_up_with')}</p>

                            <SocialLogin vendor_id={vendor_id} />

                            <p className='text-center text-white h5 mb-3 or-text'>{t('or')}</p>

                            <Form onSubmit={(e) => handleSubmit(e)} noValidate>
                                <div className=''>
                                    <div className='form-group mb-3'>
                                        <label className='form-label text-white fw-medium'> {t('name_label')}</label>
                                        <input
                                            type='text'
                                            className='form-group form-control'
                                            placeholder={t('name_placeholder')}
                                            value={formdata.username}
                                            onChange={(e) => handleChange('username', e.target.value)}
                                        />
                                        {errors.username && <p className='text-danger'>{errors.username}</p>}
                                    </div>
                                    <div className='form-group mb-3'>
                                        <label className='form-label text-white fw-medium'> {t('email_label')}</label>
                                        <input
                                            type='email'
                                            className='form-group form-control'
                                            placeholder={t('email_placeholder')}
                                            value={formdata.email}
                                            onChange={(e) => handleChange('email', e.target.value)}
                                            onKeyDown={(e) => e.key === " " && e.preventDefault()}
                                        />
                                        {errors.email && <p className='text-danger'>{errors.email}</p>}
                                    </div>
                                    {/* <div className="form-group mb-3">
                                        <label className="form-label text-white">DOB</label>
                                        <input
                                            type="date"
                                            name="dob"
                                            placeholder=""
                                            className="form-control"
                                            defaultValue={formdata?.dob}
                                            max={maxDate}
                                            onChange={(e) => handleChange('dob', e.target.value)}
                                        />
                                        {errors.dob && (
                                            <p className="d-flex flex-start text-danger error-msg mb-1 mb-md-0">
                                                {errors.dob}
                                            </p>
                                        )}
                                    </div> */}
                                    <div className='form-group mb-3'>
                                        <label className='form-label text-white fw-medium'> {t('phone_label')}</label>
                                        <PhoneNumber
                                            country={country}
                                            setCountry={(val) => setCountry(val)}
                                            phoneNumber={formdata.phone_number}
                                            handleChange={(val) => handleChange('phone_number', val)}
                                        />
                                        {errors.phone_number && <p className='text-danger'>{errors.phone_number}</p>}
                                    </div>
                                    <div className='form-group mb-3'>
                                        <label className='form-label text-white fw-medium'> {t('set_password')}</label>
                                        <div className='position-relative pwd-field'>
                                            <input
                                                type={isShow ? 'text' : 'password'}
                                                maxlength="45"
                                                className='form-group form-control'
                                                value={formdata?.setpassword}
                                                placeholder={t('set_placeholder')}
                                                onChange={(e) => handleChange('setpassword', e.target.value)}
                                                onKeyDown={(e) => e.key === " " && e.preventDefault()}
                                            />
                                            {!isShow ? (
                                                <span className='pwd-eye-wrap'><i class="bi bi-eye-slash"
                                                    color="black"
                                                    size={20}
                                                    onClick={() => setIsShow(!isShow)}
                                                /></span>
                                            ) : (
                                                <span className='pwd-eye-wrap'><i class="bi bi-eye"
                                                    color="black"
                                                    size={20}
                                                    onClick={() => setIsShow(!isShow)}
                                                /></span>
                                            )}
                                        </div>
                                        {errors?.setpassword && <p className='text-danger'>{errors?.setpassword}</p>}
                                    </div>
                                    <div className='form-group mb-3'>
                                        <label className='form-label text-white fw-medium'> {t('confirm_password')} </label>
                                        <div className='position-relative pwd-field'>
                                            <input
                                                maxlength="45"
                                                type={isConfirmShow ? 'text' : 'password'}
                                                className='form-group form-control'
                                                placeholder={t('confirm_placeholder')}
                                                onChange={(e) => handleChange('password', e.target.value)}
                                                onKeyDown={(e) => e.key === " " && e.preventDefault()}
                                            />
                                            {!isConfirmShow ? (
                                                <span className='pwd-eye-wrap'><i class="bi bi-eye-slash"
                                                    color="black"
                                                    size={20}
                                                    onClick={() => setIsConfirmShow(!isConfirmShow)}
                                                /></span>
                                            ) : (
                                                <span className='pwd-eye-wrap'><i class="bi bi-eye"
                                                    color="black"
                                                    size={20}
                                                    onClick={() => setIsConfirmShow(!isConfirmShow)}
                                                /></span>
                                            )}
                                        </div>
                                        {errors?.password && <p className='text-danger'>{errors?.password}</p>}
                                    </div>
                                    <div className='form-group mb-3'>
                                        <label className='form-label text-white'> {t('referral_code')} </label>
                                        <input
                                            type='text'
                                            className='form-group form-control'
                                            placeholder={t('referral_placeholder')}
                                            value={formdata.referral_code}
                                            onChange={(e) => handleChange('referral_code', e.target.value)}
                                        />
                                    </div>
                                    <div class="d-flex justify-content-between align-items-center gap-2 my-3 remember-forgot-text">
                                        <div class="form-check">
                                            <input
                                                className="form-check-input" type="checkbox" value="" id="flexCheckDefault" checked={isAccept} onChange={handleAccept} />
                                            <label className="form-check-label text-white" for="flexCheckDefault">
                                                {t("i_agree")} <Link to="/terms-conditions" className='text-decoration-underline' target='_blank'>{t("terms_conditions")}</Link>
                                            </label>
                                            {iserror && (
                                                <p className="text-danger">
                                                    Please agree the terms and conditions before submit the form
                                                </p>
                                            )}
                                        </div>
                                    </div>
                                    <div className=''>
                                        <button type='submit' className='ylw-theme-button w-100'>{t("create_account_btn")}</button>
                                    </div>
                                </div>
                            </Form>


                            {/* <div class="d-flex gap-2">
                    <span>Already, have an account?</span>
                    <Link to="/login">Login</Link>
                </div> */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        vendor_id: state.auth.vendor_id
    }
}
export default connect(mapStateToProps)(Signup);