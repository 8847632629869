import React, { useEffect, useRef, useState } from 'react'
import HotelOne from '../../assets/hotel-left.png';
import HotelTwo from '../../assets/hotel-two.png';
import HotelThree from '../../assets/hotel-three.png';
import HotelFour from '../../assets/hotel-four.png';
import HotelFive from '../../assets/hotel-five.png';
import HotelSix from '../../assets/hotel-six.png';
import HotelsSeven from '../../assets/hotel-seven.png';
import HotelEight from '../../assets/hotel-eight.png';
import MapImg from '../../assets/map.svg';
import GreenCheck from '../../assets/green-check.png';
import RoomOne from '../../assets/room1.png';
import WiFi from '../../assets/wifi.png';
import RecepTion from '../../assets/reception.png';
import SpaImg from '../../assets/spa.png';
import ClubImg from '../../assets/club.png';
import AirImg from '../../assets/air-condition.png';
import UserSqImg from '../../assets/person-square .png'
import SmokeImg from '../../assets/smoke.png';
import GymImg from '../../assets/gym.png';
import FoodImg from '../../assets/food.png';
import AtmImg from '../../assets/atmb.png';
import ParkImg from '../../assets/parking.png';
import OutdoorImg from '../../assets/pool.png';
import GardImg from '../../assets/garden.png';
import BreakfastImgg from '../../assets/breakfast.png';
import CafeImg from '../../assets/cafe.png';
import LaundryImg from '../../assets/laundry.png';
import LiftsImg from '../../assets/lifts.png';
import WifibtnImg from '../../assets/wifibtm.png';
import AtmbtmImg from '../../assets/atm_btm.png';
import ReceptionImg from '../../assets/24recep.png';
import AirconditionImg from '../../assets/air-condition-btm.png';
import BanquetImg from '../../assets/banquet.png';
import ParkingbtmImg from '../../assets/parkingbt.png';
import SelectOne from '../../assets/Rectangle-54.png'
import RoomImg from '../../assets/room-img.png'
import RoomsCard from '../../shared/RoomsCard';
import carImg from '../../assets/car-sport-outline .png';
import CheckinImg from '../../assets/restaurant-outline.png';
import DinnerImg from '../../assets/bed-outline.png';
import ClockImg from '../../assets/clock.png';
import NotFound from '../../assets/NotFound.png';
import UserIcon from '../../assets/people-outline.svg';
import Slider from 'react-slick';
import Products from '../../shared/Products';
import { Box, useToast } from '@chakra-ui/react';
import { useLocation, useNavigate, Link, useParams } from 'react-router-dom';
import { useDispatch, connect } from 'react-redux';
import { get_hotel_detail, get_hotel_room_lists, get_search_hotel_results, get_similar_top_hotels_lists, hotel_room_price_confirm } from '../../reduxStore/booking/bookingActions';
import SelectPopOver from '../../shared/SelectPopOver';
import { GET_MODIFIED_DATA, GET_SEARCHED_DATA, GET_SEARCHED_GRAPH } from '../../reduxStore/booking/bookingTypes';
// import DatePicker from 'react-datepicker';
import Amenities from '../../shared/Amenities';
import CurrencyLogo from '../../shared/CurrencyLogo';
import { currency_converter } from '../../reduxStore/User/userActions';
import { DatePicker } from 'antd';
import nationalityData from '../../locales/nationalities.json';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import axios from 'axios';
import ReactStars from 'react-stars';
import StarRating from '../../shared/StarRating';
import debounce from 'lodash.debounce';
const { RangePicker } = DatePicker;


const HotelDetail = ({ hotel_id, hotelDetail, hotelReviews, hotelrating, currency, hotels, roomsLists, tophotels }) => {
    const currentTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const roomsData = [1, 2, 3, 4, 5, 6];
    const hotelsImages = [
        { imagePath: HotelTwo },
        { imagePath: HotelThree },
        { imagePath: HotelFour },
        { imagePath: HotelFive },
        { imagePath: HotelSix },
    ];
    const { id } = useParams();
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const toast = useToast();
    const { t } = useTranslation();
    const roomsList = JSON.parse(localStorage.getItem("roomsData"));
    const searchData = JSON.parse(localStorage.getItem("searchedData"));
    const hotelGDS = JSON.parse(localStorage.getItem("selectedHotelGDS"));
    const hotelData = JSON.parse(localStorage.getItem("hotelData"));
    const destinationDetail = JSON.parse(localStorage.getItem("Destinationdata"));
    const [selectedRooms, setSelectedRooms] = useState([]);
    const [convertedPrices, setConvertedPrices] = useState({});
    const [dateRange, setDateRange] = useState([dayjs().startOf("month"), dayjs().endOf("month")]);
    const minDate = dayjs().startOf("day");
    const [cancelPrice, setCancelPrice] = useState(0);
    const [isShow, setIsShow] = useState(false);
    const [isRoom, setIsRoom] = useState(false);

    const similarhotels = hotelData?.CityCode ? tophotels?.length : hotels?.filter((item) => item.HotelID !== hotelData?.HotelID)?.length;

    const settings = {
        dots: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        infinite: true,
        autoplay: false,
        autoplaySpeed: 3000,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true
                }
            },

            {
                breakpoint: 700,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    initialSlide: 0,
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };

    const productssettings = {
        dots: false,
        speed: 500,
        slidesToShow: similarhotels > 3 ? 3 : similarhotels,
        slidesToScroll: similarhotels > 3 ? 3 : similarhotels,
        infinite: similarhotels > 1,
        autoplay: false,
        autoplaySpeed: 3000,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: similarhotels === 1 ? 1 : (similarhotels > 3 ? 3 : similarhotels),
                    slidesToScroll: similarhotels === 1 ? 1 : (similarhotels > 3 ? 3 : similarhotels),
                    infinite: similarhotels > 1,
                    dots: true
                }
            },
            {
                breakpoint: 700,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 0
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };

    const popupssettings = {
        dots: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: true,
        autoplay: true,
        autoplaySpeed: 3000,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 700,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 1
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };

    const MaxOccupancy = (hotelGDS === "Juniper" && selectedRooms?.length > 0) ? Number(selectedRooms[0]?.HotelRoom?.MaxOccupancy) : 5;
    // console.log(MaxOccupancy);

    const [roomCount, setRoomCount] = useState(1);

    useEffect(() => {
        const handleData = debounce(async () => {
            await get_hotel_detail(dispatch,
                {
                    GiataId: hotelData?.GiataId ? hotelData?.GiataId : null,
                    provider: hotelData?.provider ? hotelData?.provider : hotelData?.Gds ? hotelData?.Gds : null,
                    HotelID: hotelData?.HotelID ? hotelData?.HotelID : null,
                    place_id: hotelData?.place_id ? hotelData?.place_id : null,
                });
            // await get_hotel_detail(dispatch, { Gds: hotelGDS, HotelID: hotel_id });
        }, 1000);
        handleData();
        return () => {
            handleData.cancel();
        };
    }, [hotel_id]);

    const [searchDates, setSearchDates] = useState({
        startDate: '',
        endDate: ''
    });

    const [optionsData, setOptionsData] = useState({
        Room: 1,
        Adults: 1,
        Children: 0,
        // Pets: 1
    });
    const inputRef = useRef();
    const [childsAge, setChildsAge] = useState([]);
    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = () => {
        setAnchorEl(inputRef.current);
    };

    const open = Boolean(anchorEl);
    const popid = open ? 'simple-popover' : undefined;
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const calenderRef = useRef();
    const [isCalender, setIsCalender] = useState(false);

    useEffect(() => {
        const handleClickOutside = (event) => {
            const dropdownElement = document.querySelector(".ant-picker-dropdown");
            if (
                calenderRef.current &&
                !calenderRef.current.contains(event.target) &&
                (!dropdownElement || !dropdownElement.contains(event.target))
            ) {
                setIsCalender(false);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    useEffect(() => {
        dispatch({ type: GET_SEARCHED_DATA, payload: null });
    }, []);

    const fetchConvertedPrice = async (curr, price) => {
        const data = {
            amount: price,
            from: curr,
            to: currency
        };
        const conversion = await currency_converter(data);
        return Math.round(conversion?.data);
    };

    const fetchPrice = async (currency, totalPrice, roomId) => {
        const price = await fetchConvertedPrice(currency, totalPrice);
        setConvertedPrices((prev) => ({ ...prev, [roomId]: price }));
    };

    useEffect(() => {
        selectedRooms?.forEach((item) => {

            if (hotelGDS === "Juniper") {
                if (item?.Price?.Currency && item?.Price?.Gross) {
                    fetchPrice(item?.Price?.Currency, item?.Price?.Gross, item?.RatePlanCode);
                }
            }

            if (hotelGDS !== "Juniper") {
                if (item?.Currency && item?.TotalPrice) {
                    fetchPrice(item?.Currency, item?.TotalPrice, item?.RatePlanID);
                }
            }
        });
    }, [selectedRooms]);

    const handleCancelPrice = async (currency, totalPrice) => {
        const price = await fetchConvertedPrice(currency, totalPrice);
        setCancelPrice(price);
    };

    useEffect(() => {

        selectedRooms?.forEach((item) => {

            if (hotelGDS === "Juniper") {
                setCancelPrice(0);
            }

            if (hotelGDS !== "Juniper") {
                if (item?.Currency && item?.RatePlanCancellationPolicyList[0]?.Amount) {
                    handleCancelPrice(item?.Currency, item?.RatePlanCancellationPolicyList[0]?.Amount);
                }
            }
        });
    }, [selectedRooms]);

    const occupancyData = (maxcount, childcount, adultcount) => {
        let data = [];
        for (let i = 1; i <= maxcount; i++) {
            data.push({
                ChildCount: childcount !== null ? childcount : 0,
                AdultCount: adultcount !== null ? adultcount : 0,
                RoomNum: i
            });
        }
        return data;
    };

    const handlePrice = async (roomdata) => {
        const data = {
            RatePlanID: hotelGDS === "Juniper" ? roomdata?.RatePlanCode : roomdata?.RatePlanID,
            provider: hotelGDS ? hotelGDS : 'DIDA',
            HotelID: hotelData?.HotelID,
            CheckInDate: searchData?.CheckInDate,
            CheckOutDate: searchData?.CheckOutDate,
            RoomCount: roomCount,
            Nationality: searchData?.Nationality,
            Currency: hotelGDS === "Juniper" ? roomdata?.Price?.Currency : roomdata?.Currency
        };

        if (hotelGDS === "JUNIPER") {
            data.AdultCount = searchData?.RealTimeOccupancy?.AdultCount;
            data.ChildCount = searchData?.RealTimeOccupancy?.ChildCount;
        }

        if (hotelGDS === 'DIDA') {
            data.OccupancyDetails = occupancyData(searchData?.RoomCount, searchData?.RealTimeOccupancy?.ChildCount, searchData?.RealTimeOccupancy?.AdultCount);
        }

        console.log("room data confirm ----->", data);
        const totalOccupancies = {
            totalRooms: searchData?.RoomCount,
            totalPersons: 0,
            totalChild: searchData?.RealTimeOccupancy?.ChildCount,
            totalAdult: searchData?.RealTimeOccupancy?.AdultCount
        };
        totalOccupancies.totalPersons = totalOccupancies.totalChild + totalOccupancies.totalAdult;

        let confirmdata = await hotel_room_price_confirm(dispatch, data, toast);
        if (confirmdata) {
            localStorage.setItem("hotelDetail", JSON.stringify({ ...hotelDetail, rating: hotelDetail?.rating, reviews: hotelReviews }));
            localStorage.setItem("selectedRoom", JSON.stringify(roomdata));
            localStorage.setItem("totalOccupancies", JSON.stringify(totalOccupancies));
            navigate('/hotel-booking/payment');
        }
    };

    // const handleRoom = async () => {
    //     const data = {
    //         RatePlanID: hotelGDS === "Juniper" ? selectedRooms[0]?.RatePlanCode : selectedRooms[0]?.RatePlanID,
    //         provider: hotelGDS ? hotelGDS : 'DIDA',
    //         HotelID: hotelData?.HotelID,
    //         CheckInDate: searchData?.CheckInDate,
    //         CheckOutDate: searchData?.CheckOutDate,
    //         RoomCount: roomCount,
    //         Nationality: searchData?.Nationality,
    //         Currency: hotelGDS === "Juniper" ? selectedRooms[0]?.Price?.Currency : selectedRooms[0]?.Currency
    //     };

    //     if (hotelGDS === "JUNIPER") {
    //         data.AdultCount = searchData?.RealTimeOccupancy?.AdultCount;
    //         data.ChildCount = searchData?.RealTimeOccupancy?.ChildCount;
    //     }

    //     if (hotelGDS === 'DIDA') {
    //         data.OccupancyDetails = occupancyData(searchData?.RoomCount, searchData?.RealTimeOccupancy?.ChildCount, searchData?.RealTimeOccupancy?.AdultCount);
    //     }

    //     console.log("room data confirm ----->", data);
    //     const totalOccupancies = {
    //         totalRooms: searchData?.RoomCount,
    //         totalPersons: 0,
    //         totalChild: searchData?.RealTimeOccupancy?.ChildCount,
    //         totalAdult: searchData?.RealTimeOccupancy?.AdultCount
    //     };
    //     totalOccupancies.totalPersons = totalOccupancies.totalChild + totalOccupancies.totalAdult;

    //     let confirmdata = await hotel_room_price_confirm(dispatch, data, toast);
    //     if (confirmdata) {
    //         localStorage.setItem("hotelDetail", JSON.stringify(hotelDetail));
    //         localStorage.setItem("selectedRoom", JSON.stringify(selectedRooms[0]));
    //         localStorage.setItem("totalOccupancies", JSON.stringify(totalOccupancies));
    //         navigate('/hotel-booking/payment');
    //     }
    // };

    const handleSearch = async (e) => {
        e.preventDefault();

        if (dateRange?.length === 0 || dateRange?.length === 1) {
            toast({ title: 'Please Select Checkin - Checkout Date.', status: "error", duration: 2000, position: "top-right", isClosable: true, });
            return;
        }

        if (childsAge?.length > 0 && childsAge?.find((item) => item?.age === '')) {
            setAnchorEl(inputRef.current);
            return;
        }

        const data = {
            HotelID: hotelData?.HotelID,
            CheckInDate: dateRange[0].format('YYYY-MM-DD'),
            CheckOutDate: dateRange[1].format('YYYY-MM-DD'),
            RoomCount: optionsData?.Room,
            RealTimeOccupancy: {
                AdultCount: optionsData?.Adults,
                ChildCount: optionsData?.Children,
                ChildAgeDetails: childsAge?.length > 0 ? childsAge?.map((item) => Number(item?.age)) : []
            },
            Nationality: nationalityData[currentTimeZone] ? nationalityData[currentTimeZone] : 'ES',
            Currency: currency,
            provider: hotelData?.provider,
        };

        const newData = { ...destinationDetail, ...data };
        //console.log("modify newData ====>", data);
        // dispatch({ type: GET_MODIFIED_DATA, payload: data });
        localStorage.setItem("searchedData", JSON.stringify(newData));

        await get_hotel_room_lists(dispatch, data);

        // let searched = await get_search_hotel_results(dispatch, data);
        // if (searched) {
        //     navigate('/hotel-results');
        // }
    };

    // console.log(selectedRooms);

    // useEffect(() => {
    //     if (searchData !== null) {
    //         const checkin = new Date(searchData?.CheckInDate);
    //         const checkout = new Date(searchData?.CheckOutDate);

    //         setStartDate(checkin.toLocaleDateString('en-CA'));
    //         setEndDate(checkout.toLocaleDateString('en-CA'));
    //         setOptionsData({
    //             Room: searchData?.RoomCount,
    //             Adults: searchData?.AdultCount,
    //             Children: searchData?.ChildCount,
    //         });
    //     }
    // }, []);


    useEffect(() => {
        if (!searchData) return;

        const handleUpdate = debounce(async () => {
            await get_search_hotel_results(dispatch, searchData, toast);
        }, 1000);
        handleUpdate();
        return () => {
            handleUpdate.cancel();
        };
    }, []);

    //console.log("searchData", searchData, hotels);

    // console.log(hotelDetail);

    const [currentTab, setCurrentTab] = useState('photos');
    const tabs = [
        { id: 'photos-tab', value: 'photos', label: `${t('photos')}` },
        { id: 'amenities-tab', value: 'amenities', label: `${t('amenties')}` },
        { id: 'rooms-tab', value: 'rooms', label: `${t('rooms')}` },
        { id: 'policies-tab', value: 'policies', label: `${t('policy')}` },
        { id: 'reviews-tab', value: 'reviews', label: `${t('reviews')}` },
        { id: 'similarpr-tab', value: 'similar-rooms', label: `${t('similar_hotels')}` },
    ];

    const scrollElement = (element_id) => {
        const element = document.getElementById(element_id);
        if (element) {
            element.scrollIntoView({
                behavior: 'smooth',
                block: 'start'
            });
        }
    };

    useEffect(() => {
        const fetchRooms = debounce(async () => {
            const roomdata = {
                HotelID: hotelData?.HotelID,
                CheckInDate: searchData?.CheckInDate ? searchData?.CheckInDate : dayjs().add(1, "day").startOf("day").format("YYYY-MM-DD"),
                CheckOutDate: searchData?.CheckOutDate ? searchData?.CheckOutDate : dayjs().add(2, "day").startOf("day").format("YYYY-MM-DD"),
                Currency: currency,
                Nationality: searchData?.Nationality ? searchData?.Nationality : nationalityData[currentTimeZone],
                RealTimeOccupancy: searchData?.RealTimeOccupancy ? searchData?.RealTimeOccupancy : { AdultCount: 1, ChildCount: 0, ChildAgeDetails: [] },
                RoomCount: searchData?.RoomCount ? searchData?.RoomCount : 1,
                provider: hotelGDS,
            };
            await get_hotel_room_lists(dispatch, roomdata);
        }, 1000);
        fetchRooms();
        return () => {
            fetchRooms.cancel();
        };
    }, [currency]);

    useEffect(() => {
        if(roomsLists?.length > 0){
            setSelectedRooms([roomsLists[0]]);
        }
    },[roomsLists]);

    useEffect(() => {
        if (searchData === null) return;
        const range = [moment(searchData?.CheckInDate, "YYYY-MM-DD"), moment(searchData?.CheckOutDate, "YYYY-MM-DD")];
        setDateRange(range);
        setOptionsData({
            Room: searchData?.RoomCount,
            Adults: searchData?.RealTimeOccupancy?.AdultCount,
            Children: searchData?.RealTimeOccupancy?.ChildCount,
        });
        setChildsAge(searchData?.RealTimeOccupancy?.ChildAgeDetails?.map((item) => ({ age: String(item) })));
    }, []);

    useEffect(() => {
        if (searchData === null) {
            const range = [dayjs().add(1, "day").startOf("day"), dayjs().add(2, "day").endOf("day")];
            setDateRange(range);
        }
    }, []);

    // console.log("datattataatw89797",hotelData);

    const [avgReviews, setAvgReviews] = useState(0);
    const [isReview, setIsReview] = useState(false);

    useEffect(() => {
        if (hotelReviews?.length === 0) return;
        const allRating = hotelReviews?.map((item) => item?.rating);
        const sum = allRating.reduce((acc, curr) => acc + curr, 0);
        setAvgReviews(sum / allRating?.length);
    }, [hotelReviews]);

    useEffect(() => {
        if (!hotelData?.CityCode) return;
        const fetchHotels = debounce(async () => {
            const hoteldata = {
                CityCode: hotelData?.CityCode,
                HotelID: hotelData?.HotelID,
                CheckInDate: searchData?.CheckInDate ? searchData?.CheckInDate : dayjs().add(1, "day").startOf("day").format("YYYY-MM-DD"),
                CheckOutDate: searchData?.CheckOutDate ? searchData?.CheckOutDate : dayjs().add(2, "day").startOf("day").format("YYYY-MM-DD"),
                Currency: currency,
                Nationality: searchData?.Nationality ? searchData?.Nationality : nationalityData[currentTimeZone],
                RealTimeOccupancy: searchData?.RealTimeOccupancy ? searchData?.RealTimeOccupancy : { AdultCount: 1, ChildCount: 0, ChildAgeDetails: [] },
                RoomCount: 1,
                provider: hotelData?.provider,
            };
            await get_similar_top_hotels_lists(dispatch, hoteldata);
        }, 1000);
        fetchHotels();
        return () => {
            fetchHotels.cancel();
        };
    }, []);

    return (
        <div className=''>
            <div className='hotel-details-banner'>
                <div className='container'>
                    <div className='top-home-detail-wrapper tab-description'>
                        <h2 className='mb-1'>
                            {hotelDetail && hotelDetail?.Name ? hotelDetail?.Name : ''}
                            {hotelDetail?.category && <span className='text-purple google-hotel-rating'>
                                {hotelDetail?.category && <StarRating rating={Number(hotelDetail?.category)} />}
                            </span>}
                            {/* Movenpick Hotel And Residences Riyadh */}
                        </h2>

                        {(hotelDetail?.total_reviews || hotelDetail?.rating) && <div class="reviews-box d-flex align-items-center gap-2 my-2">
                            {/* {hotelDetail?.rating && <StarRating rating={Number(hotelDetail?.rating)} />} */}
                            {hotelDetail?.rating > 0 && <p className='p-md mb-0 total-rating-container'>
                                {hotelDetail?.rating > 0 && hotelDetail?.rating}<i className="fas fa-star"></i>
                            </p>}
                            {/* <ul class="d-inline-block star-rating p-0 mb-0">
                                {Array.from({ length: Math.round(hotelDetail?.rating) }).map((_, index) => (
                                    <li class="d-inline-block" key={index}><i class="fas fa-star"></i></li>
                                ))}
                            </ul> */}
                            <p className='p-md mb-0'>
                                {hotelDetail && `(${hotelDetail?.total_reviews} ${t('hotel_review')})`}
                            </p>
                        </div>}

                        {hotelDetail?.address && <p className='font-size-15 font-weight-500 mb-0 d-flex align-items-start gap-2'>
                            <img src={MapImg} className='d-inline-block mt-1' alt='' />
                            {/* Diplomatic Enclave Chanakyapuri  */}
                            {hotelDetail?.address && hotelDetail?.address}
                        </p>}
                    </div>
                </div>

                <div className='container hotel-details-banner-inner-bg' id="photos-tab">
                    <div className='row w-100 mx-auto'>
                        <div className='col-lg-7 col-md-7 col-sm-12 p-0' data-bs-toggle="modal" data-bs-target="#slidermodal">
                            <div className='left-hotel-banner spacing-btm detail-first-img-wrap'>
                                <img src={hotelDetail && hotelDetail[0]?.images[2]?.ImageUrl ? hotelDetail[0]?.images[2]?.ImageUrl : HotelOne} alt='' className='img-fluid w-100' />
                            </div>
                            <div className='row bottom_banner_img w-100 mx-auto'>
                                <div className='col-lg-4 col-md-4 col-sm-12 p-0' data-bs-toggle="modal" data-bs-target="#slidermodal">
                                    <div className='detail-left-bottom-img-wrap'>
                                        <img src={hotelDetail && hotelDetail[0]?.images[0]?.ImageUrl ? hotelDetail[0]?.images[0]?.ImageUrl : HotelSix} className='img-fluid w-100' alt='' />
                                    </div>
                                </div>
                                <div className='col-lg-4 col-md-4 col-sm-12 p-0' data-bs-toggle="modal" data-bs-target="#slidermodal">
                                    <div className='spacing-ryt detail-left-bottom-img-wrap'>
                                        <img src={hotelDetail && hotelDetail[0]?.images[1]?.ImageUrl ? hotelDetail[0]?.images[1]?.ImageUrl : HotelsSeven} className='img-fluid w-100' alt='' />
                                    </div>
                                </div>
                                <div className='col-lg-4 col-md-4 col-sm-12 p-0' data-bs-toggle="modal" data-bs-target="#slidermodal">
                                    <div className='spacing-ryt-lg detail-left-bottom-img-wrap'>
                                        <img src={hotelDetail && hotelDetail[0]?.images[3]?.ImageUrl ? hotelDetail[0]?.images[3]?.ImageUrl : HotelEight} className='img-fluid w-100' alt='' />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-5 col-md-5 col-sm-12 ryt-col-spacing-tab p-0'>
                            <div className='row'>
                                <div className='col-lg-6 col-md-6 col-sm-12 p-0' data-bs-toggle="modal" data-bs-target="#slidermodal">
                                    <div className='detail-right-side-col-wrap'>
                                        <div className='spacing-equal'>
                                            <img src={hotelDetail && hotelDetail[0]?.images[4]?.ImageUrl ? hotelDetail[0]?.images[4]?.ImageUrl : HotelTwo} className='img-fluid w-100' alt='' />
                                        </div>
                                    </div>
                                </div>
                                <div className='col-lg-6 col-md-6 col-sm-12 p-0' data-bs-toggle="modal" data-bs-target="#slidermodal">
                                    <div className='detail-right-side-col-wrap'>
                                        <div className='spacing-equal'>
                                            <img src={hotelDetail && hotelDetail[0]?.images[5]?.ImageUrl ? hotelDetail[0]?.images[5]?.ImageUrl : HotelThree} className='img-fluid w-100' alt='' />
                                        </div>
                                    </div>
                                </div>
                                <div className='col-lg-6 col-md-6 col-sm-12 p-0' data-bs-toggle="modal" data-bs-target="#slidermodal">
                                    <div className='detail-right-side-col-wrap second-last-img-wrap'>
                                        <div className='spacing-equal'>
                                            <img src={hotelDetail && hotelDetail[0]?.images[6]?.ImageUrl ? hotelDetail[0]?.images[6]?.ImageUrl : HotelFour} className='img-fluid w-100' alt='' />
                                        </div>
                                    </div>
                                </div>
                                <div className='col-lg-6 col-md-6 col-sm-12 p-0'>
                                    <div className='detail-right-side-col-wrap last-img-wrap'>
                                        <div className='spacing-equal blur-img'>
                                            <div className='slider-image-popup'>
                                                <img src={hotelDetail && hotelDetail[0]?.images[7]?.ImageUrl ? hotelDetail[0]?.images[7]?.ImageUrl : HotelFive} className='img-fluid w-100' alt='' />
                                                <div className='modal-click-btn'>
                                                    <button type="button" class="bbtn btn-gradient-purple text-white " data-bs-toggle="modal" data-bs-target="#slidermodal">
                                                        {t('view_all')}
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='mobile-hotel-details-banner'>
                    <div className='container'>
                        <Slider {...settings}>
                            {hotelDetail && hotelDetail[0]?.images?.length > 0 ? hotelDetail[0]?.images?.map((item, index) => (
                                <div className='col-12' key={index}>
                                    <img src={item?.ImageUrl} className='img-fluid w-100' alt={`Hotel Image ${index + 1}`} />
                                </div>
                            )) : roomsData?.map((item, index) => (
                                <div className='col-12' key={index}>
                                    <img src={HotelOne} className='img-fluid w-100' alt='img not found' />
                                </div>
                            ))}
                            {/* {roomsData?.map((item, index) => (
                                <div className='col-12' key={index}>
                                    <img src={hotelDetail && hotelDetail[0]?.images[2]?.ImageUrl ? hotelDetail[0]?.images[2]?.ImageUrl : HotelOne} className='img-fluid w-100' />
                                </div>
                            ))} */}
                        </Slider>
                    </div>
                </div>

                <div className='hotel-details-wrapper'>
                    <div className='container'>
                        <ul className='p-0 border-bottom'>
                            {tabs?.map((item, idx) => (
                                <li className='d-inline-block' key={idx}>
                                    <span
                                        className={`nav-link page-id-tabs ${currentTab === item?.value ? 'active' : ''} cursor-pointer`}
                                        onClick={() => {
                                            setCurrentTab(item?.value);
                                            scrollElement(item?.id);
                                        }}
                                    >
                                        {item?.label}
                                    </span>
                                </li>
                            ))}
                            {/* <li className='d-inline-block'>
                                <a class="nav-link page-id-tabs" href='#similarpr-tab'>Similar Product</a>
                            </li> */}
                        </ul>
                    </div>
                </div>

                <div className='hotel_bottm_detail'>
                    <div className='container'>
                        {hotelDetail && hotelDetail?.content?.section ?
                            hotelDetail?.content?.section?.filter((_, idx) => idx !== 1 && idx !== 2 && idx !== 4 && idx !== 5)?.map((item, index) => (
                                <p className='font-size-15 font-weight-500' key={index}>
                                    {item?.para}
                                </p>
                            ))
                            :
                            <p className='font-size-15 font-weight-500'>
                                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.
                            </p>
                        }
                        {/* <p className='font-size-15 font-weight-500 mb-0'>
                            It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.
                        </p> */}
                    </div>
                </div>

                <div className='amnenities-wrapper' id="amenities-tab">
                    <div className='container'>
                        <h2>{t("amenties")}</h2>

                        {hotelDetail && hotelDetail?.content?.section && <div className='hotel_bottm_detail mb-0'>
                            <div className=''>
                                <p className='font-size-15 font-weight-500'>
                                    {hotelDetail?.content?.section[1]?.para}
                                </p>
                            </div>
                        </div>}

                        <ul className='p-0 list-box-amenities'>
                            {!isShow && hotelDetail && hotelDetail?.facilities?.slice(0, 11)?.map((item, index) => (
                                <li className='p-md d-inline-block' key={index}>
                                    <p className='d-flex align-items-center gap-2'>
                                        <img
                                            src={
                                                Amenities?.find((k) => k?.Description?.toLowerCase() === item?.toLowerCase())?.ImagePath ?
                                                    Amenities?.find((k) => k?.Description?.toLowerCase() === item?.toLowerCase())?.ImagePath :
                                                    GreenCheck
                                            }
                                            className='d-inline-block'
                                            alt=''
                                        />
                                        {item}
                                        {/* {t(`hotel_facilities.${item?.replace(' ', '_')}`)} */}
                                    </p>
                                </li>
                            ))}

                            {isShow && hotelDetail && hotelDetail?.facilities?.map((item, index) => (
                                <li className='p-md d-inline-block' key={index}>
                                    <p className='d-flex align-items-center gap-2'>
                                        <img
                                            src={
                                                Amenities?.find((k) => k?.Description?.toLowerCase() === item?.toLowerCase())?.ImagePath ?
                                                    Amenities?.find((k) => k?.Description?.toLowerCase() === item?.toLowerCase())?.ImagePath :
                                                    GreenCheck
                                            }
                                            className='d-inline-block'
                                            alt=''
                                        />
                                        {item}
                                        {/* {t(`hotel_facilities.${item?.replace(' ', '_')}`)} */}
                                    </p>
                                </li>
                            ))}

                            {(hotelDetail && hotelDetail?.facilities?.length > 11) && <li className='p-md d-inline-block cursor-pointer btn-gradient-purple w-auto' onClick={() => setIsShow(!isShow)}>
                                {isShow ? `${t('See less')}` : `${t('See more')}`}
                            </li>}

                            {/* <li className='p-md d-inline-block'>
                                <p className='d-flex align-items-center gap-2'>
                                    <img src={WiFi} className='d-inline-block' alt='' />
                                    Free Wi-fi
                                </p>
                            </li>
                            <li className='p-md d-inline-block'>
                                <p className='d-flex align-items-center gap-2'>
                                    <img src={RecepTion} className='d-inline-block' alt='' />
                                    24H. Reception
                                </p>
                            </li>
                            <li className='p-md d-inline-block'>
                                <p className='d-flex align-items-center gap-2'>
                                    <img src={SpaImg} className='d-inline-block' alt='' />
                                    Spa
                                </p>
                            </li>
                            <li className='p-md d-inline-block'>
                                <p className='d-flex align-items-center gap-2'>
                                    <img src={ClubImg} className='d-inline-block' alt='' />Club
                                </p>
                            </li>
                            <li className='p-md d-inline-block'>
                                <p className='d-flex align-items-center gap-2'>
                                    <img src={AirImg} className='d-inline-block' alt='' />Air Condition
                                </p>
                            </li>
                            <li className='p-md d-inline-block'>
                                <p className='d-flex align-items-center gap-2'>
                                    <img src={SmokeImg} className='d-inline-block' alt='' />Smoking Area
                                </p>
                            </li>
                            <li className='p-md d-inline-block'>
                                <p className='d-flex align-items-center gap-2'>
                                    <img src={GymImg} className='d-inline-block' alt='' />Gym
                                </p>
                            </li>
                            <li className='p-md d-inline-block'>
                                <p className='d-flex align-items-center gap-2'>
                                    <img src={FoodImg} className='d-inline-block' alt='' />Food & Drink
                                </p>
                            </li>
                            <li className='p-md d-inline-block'>
                                <p className='d-flex align-items-center gap-2'>
                                    <img src={AtmImg} className='d-inline-block' alt='' />ATM
                                </p>
                            </li>
                            <li className='p-md d-inline-block'>
                                <p className='d-flex align-items-center gap-2'><img src={ParkImg} className='d-inline-block' alt='' />Parking</p>
                            </li>
                            <li className='p-md d-inline-block'>
                                <p className='d-flex align-items-center gap-2'>
                                    <img src={OutdoorImg} className='d-inline-block' alt='' />
                                    Outdoor Pool
                                </p>
                            </li>
                            <li className='p-md d-inline-block'>
                                <p className='d-flex align-items-center gap-2'>
                                    <img src={GardImg} className='d-inline-block' alt='' />Garden
                                </p>
                            </li>
                            <li className='p-md d-inline-block'>
                                <p className='d-flex align-items-center gap-2'><img src={BreakfastImgg} className='d-inline-block' alt='' />Breakfast</p>
                            </li>
                            <li className='p-md d-inline-block'>
                                <p className='d-flex align-items-center gap-2'><img src={CafeImg} className='d-inline-block' alt='' />Café</p>
                            </li>
                            <li className='p-md d-inline-block'>
                                <p className='d-flex align-items-center gap-2'><img src={LaundryImg} className='d-inline-block' alt='' />Laundry </p>
                            </li>
                            <li className='p-md d-inline-block'>
                                <p className='d-flex align-items-center gap-2'><img src={LiftsImg} className='d-inline-block' alt='' />Lifts</p>
                            </li> */}
                        </ul>
                    </div>
                </div>

                {decodeURIComponent(location.pathname) === `/tour-detail/${id}` && <div className='daily-details-wrapper'>
                    <div className='container'>
                        <h2 className='mb-4'>Daily Details</h2>
                        <div className='row'>
                            <div className='col-lg-7 col-md-12 col-sm-12'>
                                <div className='daily-update-box'>
                                    <h4><span className='text-purple'>Day 1</span> - Arrival in Kolkata</h4>
                                    <ul className='box-list-details-wrap'>
                                        <li className='text-grey-light p-large'><img src={carImg} className='d-inline-block me-2' />Pickup from Airport </li>

                                        <li className='text-grey-light p-large'><img src={CheckinImg} className='d-inline-block me-2' />Check-in to Hotel </li>

                                        <li className='text-grey-light p-large'><img src={DinnerImg} className='d-inline-block me-2' />Dinner </li>
                                    </ul>
                                </div>

                                <div className='daily-update-box'>
                                    <h4><span className='text-purple'>Day 2</span> - Visit Temples in Kolkata</h4>
                                    <ul className='box-list-details-wrap'>
                                        <li className='text-grey-light p-large'><img src={carImg} className='d-inline-block me-2' />Breakfast </li>

                                        <li className='text-grey-light p-large'><img src={CheckinImg} className='d-inline-block me-2' />Temple in Kolkata </li>

                                        <li className='text-grey-light p-large'><img src={DinnerImg} className='d-inline-block me-2' />2nd Temple in Kolkata </li>

                                        <li className='text-grey-light p-large'><img src={DinnerImg} className='d-inline-block me-2' />Dinner </li>
                                    </ul>
                                </div>

                                <div className='daily-update-box'>
                                    <h4><span className='text-purple'>Day 2</span> - Free</h4>
                                    <ul className='box-list-details-wrap'>
                                        <li className='text-grey-light p-large'><img src={carImg} className='d-inline-block me-2' />Breakfast  </li>

                                        <li className='text-grey-light p-large'><img src={CheckinImg} className='d-inline-block me-2' />Free </li>

                                        <li className='text-grey-light p-large'><img src={DinnerImg} className='d-inline-block me-2' />Candle light Dinner </li>
                                    </ul>
                                </div>

                                <div className='daily-update-box'>
                                    <h4><span className='text-purple'>Day 3</span> - Departure</h4>
                                    <ul className='box-list-details-wrap'>
                                        <li className='text-grey-light p-large'><img src={carImg} className='d-inline-block me-2' />Breakfast  </li>

                                        <li className='text-grey-light p-large'><img src={CheckinImg} className='d-inline-block me-2' />Drop to Airport  </li>
                                    </ul>
                                </div>
                            </div>

                            <div className='col-lg-5 col-md-12 col-sm-12'>
                                <div className='tour-details-timming'>
                                    <div className='timming-txt-wrap d-flex justify-content-between'>
                                        <div className=''>
                                            <h4 className='mb-1'>Timing</h4>
                                            <p className='p-md mb-0'><img src={ClockImg} className='d-inline-block me-1' />3 Days, 4 Nights</p>
                                        </div>
                                        <div className='orange-box-price'>
                                            <p className='mb-0 p-sm'><strike>59,999</strike></p>
                                            <p className='mb-0 p-large text-color-theme font-weight-600'>₹55,750 <small className='p-sm text-grey-light fst-normal'>/Adult</small></p>
                                        </div>
                                    </div>
                                    <div className='flight-features-txt'>
                                        <ul className='p-0 flight-features-list m-0'>
                                            <li className='d-inline-block text-grey-light p-md lh-1'>1 Flight</li>
                                            <li className='d-inline-block text-grey-light p-md lh-1'>1 Hotel</li>
                                            <li className='d-inline-block text-grey-light p-md lh-1'>1 Transfer</li>
                                        </ul>
                                    </div>

                                    <div className='border-divider'></div>

                                    <div className='ryt-purple-box'>
                                        <h4>Travellers</h4>
                                        <div class="quantity-increase input-group d-flex align-items-start">
                                            <input type="button" value="-" class="button-minus rounded-circle-quantity" data-field="quantity" />
                                            <input type="number" step="1" max="" value="1" name="quantity" class="quantity-field d-inline-block mx-0 text-center font-weight-500" />
                                            <input type="button" value="+" class="button-plus rounded-circle-quantity" data-field="quantity" />
                                        </div>
                                    </div>

                                    <div className='border-divider'></div>

                                    <div className='mb-3'>
                                        <h4>Total</h4>
                                        <h3 className='font-25-hd font-family-poppins'>₹1,11,500</h3>
                                    </div>

                                    <div className='text-center'>
                                        <p className='p-md mb-1'>*Adult - Should be above 18 years</p>
                                    </div>

                                    <a onClick={() => navigate('/tour-payment')} className='bbtn btn-gradient-purple text-white w-100 d-block text-center'>Book Now</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>}

                {decodeURIComponent(location.pathname) === `/detail/${id}` && <div className='details-room-srch booking-tab-form'>
                    <div className='container'>
                        <div className='details_bg_lyt'>
                            <form className='w-100' onSubmit={(e) => handleSearch(e)}>
                                <div className='row gy-2 position-relative'>

                                    <div className='col-md-4'>
                                        <div className='position-relative h-100' ref={calenderRef}>
                                            <img
                                                src="../images/calendar-event.svg"
                                                className='calendar-icon'
                                                alt=''
                                                onClick={() => {
                                                    setIsCalender(true);
                                                }}
                                            />
                                            <RangePicker
                                                open={isCalender}
                                                placeholder={t("date_placeholder")}
                                                style={{ padding: "15px 25px 15px 50px" }}
                                                className='h-100 w-100'
                                                value={dateRange}
                                                onChange={(values) => {
                                                    setDateRange(values);
                                                    if (values && values.length === 2) {
                                                        setIsCalender(false);
                                                    }
                                                }}
                                                disabledDate={(current) => current && current < minDate}
                                                defaultPickerValue={[
                                                    dayjs().startOf("month"), // Default start view
                                                    dayjs().endOf("month"),   // Default end view
                                                ]}
                                                onClick={() => {
                                                    setIsCalender(true);
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div className='col-md-5'>
                                        <div className='destination-box first-box'>
                                            <img src={UserIcon} className='img-fluid' alt='' onClick={handleClick} />
                                            <input
                                                type='text'
                                                ref={inputRef}
                                                className='form-control'
                                                value={`${optionsData.Adults} ${t("adult_name")}, ${optionsData.Children} ${t("children_name")}, ${t("and_name")} ${optionsData.Room} ${t("room_name")}`}
                                                placeholder="2 Adult, 1 Children"
                                                onClick={handleClick}
                                            />
                                        </div>
                                    </div>
                                    <div className='col-md-3'>
                                        <div className='destination-box first-box button-last-box h-100'>
                                            <button type="submit" className='btn btn-gradient-purple text-white d-flex align-items-center justify-content-center gap-2'><i class="fas fa-search me-2"></i> {t('modify_search')}</button>
                                        </div>
                                    </div>
                                    {open && <SelectPopOver
                                        id={popid}
                                        open={open}
                                        anchorEl={anchorEl}
                                        setAnchorEl={() => setAnchorEl(null)}
                                        optionsData={optionsData}
                                        setOptionsData={setOptionsData}
                                        childsAge={childsAge}
                                        setChildsAge={setChildsAge}
                                    />}
                                </div>

                                {/* <div className='destination-box first-box'>
                                        <img src="../images/calendar-event.svg" className='calendar-icon' alt='' />
                                        <DatePicker
                                            className='form-control'
                                            selected={startDate}
                                            onChange={(date) => setStartDate(date)}
                                            minDate={new Date().setDate(new Date().getDate() + 5)}
                                            placeholderText='mm/dd/yyyy'
                                        />
                                    </div> */}
                                {/* <div className='destination-box first-box'>
                                        <img src="../images/calendar-event.svg" className='calendar-icon' alt='' />
                                        <DatePicker
                                            className='form-control'
                                            selected={endDate}
                                            onChange={(date) => setEndDate(date)}
                                            minDate={new Date().setDate(new Date().getDate() + 6)}
                                            placeholderText='mm/dd/yyyy'
                                        />
                                    </div> */}

                            </form>
                        </div>
                    </div>
                </div>}

                {decodeURIComponent(location.pathname) === `/detail/${id}` && <div id='rooms-tab' className='related_rooms_wrapper top-related_rooms_wrapper'>
                    <div className='container'>
                        {/* {hotelDetail && hotelDetail?.content?.section && <div className='hotel_bottm_detail mb-2'>
                            <div className='container'>
                                <p className='font-size-15 font-weight-500'>
                                    {hotelDetail?.content?.section[2]?.para}
                                </p>
                            </div>
                        </div>} */}
                        <div className='amnenities-wrapper mt-0'>
                            <h2 className='mb-0'>{t("rooms")}</h2>
                            {hotelDetail && hotelDetail?.roomfacilities?.length > 0 && <div className=''>
                                <ul className='p-0 list-box-amenities'>
                                    {!isRoom && hotelDetail && hotelDetail?.roomfacilities?.slice(0, 11)?.map((item, index) => (
                                        <li className='p-md d-inline-block' key={index}>
                                            <p className='d-flex align-items-center gap-2'>
                                                <img
                                                    src={
                                                        Amenities?.find((k) => k?.Description?.toLowerCase() === item?.toLowerCase())?.ImagePath ?
                                                            Amenities?.find((k) => k?.Description?.toLowerCase() === item?.toLowerCase())?.ImagePath :
                                                            GreenCheck
                                                    }
                                                    className='d-inline-block'
                                                    alt=''
                                                />
                                                {item}
                                            </p>
                                        </li>
                                    ))}
                                    {isRoom && hotelDetail && hotelDetail?.roomfacilities?.map((item, index) => (
                                        <li className='p-md d-inline-block' key={index}>
                                            <p className='d-flex align-items-center gap-2'>
                                                <img
                                                    src={
                                                        Amenities?.find((k) => k?.Description?.toLowerCase() === item?.toLowerCase())?.ImagePath ?
                                                            Amenities?.find((k) => k?.Description?.toLowerCase() === item?.toLowerCase())?.ImagePath :
                                                            GreenCheck
                                                    }
                                                    className='d-inline-block'
                                                    alt=''
                                                />
                                                {item}
                                            </p>
                                        </li>
                                    ))}

                                    {(hotelDetail && hotelDetail?.roomfacilities?.length > 11) && <li className='p-md d-inline-block cursor-pointer btn-gradient-purple w-auto' onClick={() => setIsRoom(!isRoom)}>
                                        {isRoom ? `${t('See less')}` : `${t('See more')}`}
                                    </li>}
                                </ul>
                            </div>}
                        </div>
                    </div>

                    <div className='container mt-4'>
                        {Array.isArray(roomsLists) && <div className='row'>
                            <Slider {...settings}>
                                {(Array.isArray(roomsLists) ? roomsLists : roomsData)?.map((item, index) => (
                                    <div className={`col-lg-4 col-md-4 col-sm-12 ${(index === 0 && selectedRooms?.length === 0) ? 'selected-room' : ''}`} key={index}>
                                        <RoomsCard
                                            item={item}
                                            hotelDetail={hotelDetail}
                                            roomslength={selectedRooms?.length}
                                            selectedRooms={selectedRooms}
                                            setSelectedRooms={setSelectedRooms}
                                            handlePrice={handlePrice}
                                        />
                                    </div>
                                ))}
                            </Slider>
                        </div>}
                    </div>
                </div>}

                {decodeURIComponent(location.pathname) === `/detail/${id}` && <div className='Selected_rooms'>
                    <div className='container'>
                        {/* <div className='d-flex align-items-center justify-content-between mb-3'>
                            <h2 className='mb-0'>Selected Rooms({selectedRooms?.length})</h2>
                            {selectedRooms?.length > 0 && <p className='mb-0 cursor-pointer' onClick={() => setSelectedRooms([])}>Remove all</p>}
                        </div> */}
                        {/* <form> */}
                        <div className='select-room_grid_row row'>
                            {/* {selectedRooms?.length > 0 && selectedRooms?.map((item) => (<div className='col-lg-3 col-md-6 col-sm-12 select-col-spacing'>
                                <div className='select-room_inner d-flex align-items-center justify-content-between gap-2'>
                                    <div className=''>
                                        <img src={RoomImg} className='d-inline-block' />
                                    </div>
                                    <div className=''>
                                        <p className='mb-0 dark-txt'>
                                            {hotelGDS === "Juniper" ? item?.HotelRoom?.Name : item?.RoomName}
                                        </p>
                                        <div className='d-grid align-items-center price-quantity-btn'>
                                            <p className='mb-0 d-inline-block'>
                                                <span className='yellow-txt-link me-2 font-weight-600'>
                                                    {currency && <CurrencyLogo currencyCode={currency} />}
                                                    {hotelGDS === "Juniper" ? convertedPrices[item?.RatePlanCode] : convertedPrices[item?.RatePlanID]}
                                                </span>
                                            </p>
                                            <div class="quantity-increase input-group d-flex align-items-start">
                                                <input type="button" value="-" class="button-minus rounded-circle-quantity" data-field="quantity"
                                                    onClick={() => {
                                                        let value = Math.max(roomCount - 1, 1);
                                                        setRoomCount(value);
                                                    }}
                                                />
                                                <input type="number" step="1" max="" value={roomCount} name="quantity" class="quantity-field d-inline-block mx-0 text-center font-weight-500" />
                                                <input type="button" value="+" class="button-plus rounded-circle-quantity" data-field="quantity"
                                                    onClick={() => {
                                                        let value = Math.min(roomCount + 1, 5);
                                                        setRoomCount(value);
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className='cursor-pointer' onClick={() => setSelectedRooms([])}>
                                        <i class="fas fa-times"></i>
                                    </div>
                                </div>
                            </div>))} */}

                            {/* <div className='col-lg-3 col-md-6 col-sm-12 select-col-spacing'>
                                <div className='select-room_inner d-flex align-items-center justify-content-between gap-2'>
                                    <div className=''>
                                        <img src={SelectOne} className='d-inline-block' />
                                    </div>
                                    <div className=''>
                                        <p className='mb-0 dark-txt'>Regular Room - Queen Bed</p>
                                        <div className='d-grid align-items-center price-quantity-btn'>
                                            <p className='mb-0 d-inline-block'><span className='yellow-txt-link me-2 font-weight-600'>$35</span> x</p>
                                            <div class="quantity-increase input-group d-flex align-items-start">
                                                <input type="button" value="-" class="button-minus rounded-circle-quantity" data-field="quantity" />
                                                <input type="number" step="1" max="" value="1" name="quantity" class="quantity-field d-inline-block mx-0 text-center font-weight-500" />
                                                <input type="button" value="+" class="button-plus rounded-circle-quantity" data-field="quantity" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className=''>
                                        <i class="fas fa-times"></i>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-3 col-md-6 col-sm-12 select-col-spacing'>
                                <div className='select-room_inner d-flex align-items-center justify-content-between gap-2'>
                                    <div className=''>
                                        <img src={SelectOne} alt='' className='d-inline-block' />
                                    </div>
                                    <div className=''>
                                        <p className='mb-0 dark-txt'>Regular Room - Queen Bed</p>
                                        <div className='d-grid align-items-center price-quantity-btn'>
                                            <p className='mb-0 d-inline-block'><span className='yellow-txt-link me-2 font-weight-600'>$35</span> x</p>
                                            <div class="quantity-increase input-group d-flex align-items-start">
                                                <input type="button" value="-" class="button-minus rounded-circle-quantity" data-field="quantity" />
                                                <input type="number" step="1" max="" value="1" name="quantity" class="quantity-field d-inline-block mx-0 text-center font-weight-500" />
                                                <input type="button" value="+" class="button-plus rounded-circle-quantity" data-field="quantity" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className=''>
                                        <i class="fas fa-times"></i>
                                    </div>
                                </div>
                            </div> */}
                            {/* {selectedRooms?.length > 0 && <div className='col-lg-3 col-md-6 col-sm-12 select-col-spacing'>
                                <button type="button" className='btn btn-gradient-purple' onClick={handleRoom}>Book Now</button>
                            </div>} */}
                        </div>
                        {/* </form> */}
                    </div>
                </div>}

                {(selectedRooms?.length > 0 && roomsLists?.length > 0) && <div className='policies-wrapper' id='policies-tab'>
                    <div className='container'>
                        <h2 className='mb-3'>
                            Room Policies
                            {/* {t("policy")} */}
                        </h2>
                        <div className='row'>
                            <div className='col-lg-4 col-md-6 col-sm-12 policy-mb-space'>
                                <div className='policies_box_inner'>
                                    <h3 className='mb-1'>Room information</h3>
                                    <p className='font-size-15 mb-2'>
                                        {selectedRooms[0]?.RoomName ? selectedRooms[0]?.RoomName : selectedRooms[0]?.RatePlanName}
                                    </p>
                                    <ul className='list-style-dots mb-0'>
                                        {selectedRooms[0]?.facility?.map((item, index) => (
                                            <li className='font-size-15 text-purple' key={index}>{item?.Name}</li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                            <div className='col-lg-4 col-md-6 col-sm-12 policy-mb-space'>
                                <div className='policies_box_inner'>
                                    <h3 className='mb-1'>Cancellation Charges </h3>
                                    <p className='font-size-15 font-weight-500 mb-0 d-flex align-items-center gap-2'>
                                        <span className='d-inline-block fw-normal'>Amount</span>

                                        :

                                        <span className='text-purple d-inline-block font-weight-500'>
                                            <CurrencyLogo currencyCode={currency} />{selectedRooms[0]?.TotalPrice}
                                        </span>
                                    </p>
                                </div>
                            </div>
                            <div className='col-lg-4 col-md-6 col-sm-12 policy-mb-space'>
                                <button type="button" className='btn btn-proceed-purple' onClick={() => handlePrice(selectedRooms[0])}>Proceed <i class="bi bi-arrow-right"></i></button>
                            </div>
                        </div>
                    </div>
                </div>}


                {(hotelDetail && hotelReviews?.length > 0) && <div className='related_rooms_wrapper similar_rooms_wrapper pt-0' id='reviews-tab'>
                    <div className='container'>
                        <h2 className='mb-2'>{t('top_reviews')}</h2>
                        <div className='d-flex gap-4'>
                            <div className='col-md-3' style={{ height: '70%', backgroundColor: "rgb(246,228,255)", padding: '3rem', borderRadius: '8%' }}>
                                <ul class="d-inline-block star-rating p-0 mb-0">
                                    {Array.from({ length: Math.round(avgReviews) })?.map((_, idx) => (
                                        <li class="d-inline-block" key={idx}><i class="fas fa-star"></i></li>
                                    ))}
                                </ul>
                                <h2 className=''> {avgReviews}/5</h2>
                                <p className='d-inline-block font-size-15 mb-0 font-weight-500'>
                                    {t('based_on')} {hotelReviews?.length} {t('hotel_review')}
                                </p>
                            </div>
                            <div className='col-md-9 d-flex flex-column gap-4'>
                                {!isReview && hotelReviews?.slice(0, 2)?.map((item, index) => (
                                    <div key={index}>
                                        <div className='d-flex align-items-center justify-content-between mb-2'>
                                            <div className='d-flex align-items-center align-start-top gap-3'>
                                                <div className='travel-top-img '>
                                                    <img className='' src={UserSqImg} alt='img not found' />
                                                </div>
                                                <div className=''>
                                                    <h3 className='font-family-poppins mb-0'>{item?.author_name}</h3>
                                                    <p className='mb-0'>
                                                        {item?.relative_time_description}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        {/* <div className='top-home-detail-wrapper tab-description'> */}
                                        <div class="reviews-box d-flex align-items-center gap-2 mb-2">
                                            <p className='d-inline-block font-size-15 mb-0 font-weight-500'>
                                                {item?.rating}
                                            </p>
                                            <ul class="d-inline-block star-rating p-0 mb-0">
                                                {Array.from({ length: Math.round(item?.rating) })?.map((_, idx) => (
                                                    <li class="d-inline-block" key={idx}><i class="fas fa-star"></i></li>
                                                ))}
                                            </ul>
                                        </div>
                                        <hr />
                                        <p className='font-size-15 font-weight-500 mb-0 d-flex align-items-center gap-2'>
                                            {item?.text}
                                        </p>
                                        {/* </div> */}
                                    </div>
                                ))}

                                {isReview && hotelReviews?.map((item, index) => (
                                    <div key={index}>
                                        <div className='d-flex align-items-center justify-content-between mb-2'>
                                            <div className='d-flex align-items-center align-start-top gap-3'>
                                                <div className='travel-top-img '>
                                                    <img className='' src={UserSqImg} alt='img not found' />
                                                </div>
                                                <div className=''>
                                                    <h3 className='font-family-poppins mb-0'>{item?.author_name}</h3>
                                                    <p className='mb-0'>
                                                        {item?.relative_time_description}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        {/* <div className='top-home-detail-wrapper tab-description'> */}
                                        <div class="reviews-box d-flex align-items-center gap-2 mb-2">
                                            <p className='d-inline-block font-size-15 mb-0 font-weight-500'>
                                                {item?.rating}
                                            </p>
                                            <ul class="d-inline-block star-rating p-0 mb-0">
                                                {Array.from({ length: Math.round(item?.rating) })?.map((_, idx) => (
                                                    <li class="d-inline-block" key={idx}><i class="fas fa-star"></i></li>
                                                ))}
                                            </ul>
                                        </div>
                                        <hr />
                                        <p className='font-size-15 font-weight-500 mb-0 d-flex align-items-center gap-2'>
                                            {item?.text}
                                        </p>
                                        {/* </div> */}
                                    </div>
                                ))}

                                <li className='col-md-2 cursor-pointer btn-gradient-purple h-auto' style={{ width: 'fit-content' }} onClick={() => setIsReview(!isReview)}>
                                    {isReview ? `${t('is_less')}` : `${t('view_all')}`}
                                </li>
                            </div>
                        </div>
                    </div>
                </div>}

                {(tophotels?.length > 0 || hotels?.length > 0) &&
                    <div className='related_rooms_wrapper similar_rooms_wrapper pt-0' id='similarpr-tab'>
                        <div className='container'>
                            <h2 className='mb-3'>{t("similar_hotels")}</h2>
                            <div className='row'>
                                <Slider {...productssettings}>
                                    {(hotelData?.CityCode ? tophotels : hotels?.filter((item) => item.HotelID !== hotelData?.HotelID))?.map((item, index) => (
                                        <div className='' style={{ width: similarhotels === 1 ? '30%' : '100%' }} key={index}>
                                            <Products item={item} />
                                        </div>
                                    ))}
                                </Slider>
                            </div>
                        </div>
                    </div>}

                <div class="hotel-details-modal modal fade" id="slidermodal" tabindex="-1" aria-labelledby="slidermodalLabel" aria-hidden="true">
                    <div class="modal-dialog">
                        <div class="modal-content">
                            <div class="modal-header border-0 position-absolute cross-icon">
                                {/* <h5 class="modal-title" id="slidermodalLabel">Modal title</h5> */}
                                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div class="modal-body border-0 desktop-slider-view p-0">
                                <Slider {...popupssettings}>
                                    {hotelDetail && hotelDetail[0]?.images?.length > 0 ? hotelDetail[0]?.images?.map((item, index) => (
                                        <div className="slider-image-container col-12" key={index}>
                                            <img src={item?.ImageUrl} className='img-fluid w-100' alt='img not found' />
                                        </div>
                                    )) : hotelsImages?.map((item, index) => (
                                        <div className='col-12' key={index}>
                                            <img src={item?.imagePath} className='img-fluid w-100' alt='img not found' />
                                        </div>
                                    ))}
                                    {/* {roomsData?.map((item, index) => (
                                        <div className='col-12' key={index}>
                                            <img src={HotelOne} alt='' className='img-fluid w-100' />
                                        </div>
                                    ))} */}
                                </Slider>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>


    )
}

const mapStateToProps = (state) => {
    return {
        hotel_id: state.bookings.hotel_id,
        hotelDetail: state.bookings.hotelDetail,
        hotelReviews: state.bookings.hotelReviews,
        hotelrating: state.bookings.hotelrating,
        currency: state.auth.currency,
        hotels: state.bookings.searchedhotels,
        roomsLists: state.bookings.roomsList,
        tophotels: state.bookings.similarTopHotels
    }
};
export default connect(mapStateToProps)(HotelDetail);