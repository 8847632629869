import React, { useEffect } from 'react'
import { connect, useDispatch } from 'react-redux'
import { get_all_user_lists, get_vender_id } from '../../reduxStore/User/userActions';
import { get_all_medium_agencies, get_all_small_agencies } from '../../reduxStore/vendors/vendorActions';
import GrayFlightIcon from './../../assets/gray-flight-icon.svg';
import GrayLocalTourIcon from './../../assets/gray-local-tour-icon.svg';
import GrayCarIcon from './../../assets/gray-car-icon.svg';
import GreyHotelIcon from './../../assets/grey-hotel-icon.svg';
import { useToast } from '@chakra-ui/react';

const Dashboard = ({ user, vendor_id, totalUsers, total_small, total_medium }) => {
    const dispatch = useDispatch();
    const toast = useToast();

    useEffect(() => {
        if (vendor_id) {
            get_all_user_lists(toast,user?.token, dispatch, vendor_id);
            get_all_small_agencies(user?.token, dispatch, vendor_id);
            get_all_medium_agencies(user?.token, dispatch, vendor_id);
        }
    }, [vendor_id]);

    useEffect(() => {
        get_vender_id("superadmin", dispatch);
    }, []);

    return (
        <div className='dashboard-page-wrapper'>
            {/* <div className='d-flex align-items-center justify-content-between mb-5 admin-name-with-home-btn-wrap'>
                <div className='admin-profile-name-heading-wrap'>
                    <h2 className='text-purple mb-0'>Hi Manpreet...</h2>
                </div>
                <div className='dashboard-home-btn-wrap'>
                    <a href='#' className='btn-gradient-purple'>Home</a>
                </div>
            </div> */}
            {/* <h1 className='text-center'>{user.role === "superadmin" ? 'Admin' : 'Agency'} Pannel</h1>
            <div className='d-flex justify-content-center gap-4 pt-3'>
                <div>
                    <h3>Total Users</h3>
                    <h4 className='text-center'>{totalUsers}</h4>
                </div>
                <div>
                    <h3>Total Agencies</h3>
                    <h4 className='text-center'>{total_small + total_medium}</h4>
                </div>
            </div> */}

            <div className='admin-booking-detail-containers'>
                <div className='total-booking-container-wrap'>
                    <p className='p-md text-white'>Total Customers</p>
                    <h1 className='text-white'>{totalUsers}</h1>
                </div>
                <div className='processing-booking-container-wrap'>
                    <p className='p-md text-white'>Total Agencies</p>
                    <h1 className='text-white'>{total_small + total_medium}</h1>
                </div>
                {/* <div className='completed-booking-container-wrap'>
                    <p className='p-md text-white'>Completed Bookings</p>
                    <h1 className='text-white'>45</h1>
                </div>
                <div className='cancel-booking-container-wrap'>
                    <p className='p-md text-white'>Cancel Bookings</p>
                    <h1 className='text-white'>45</h1>
                </div> */}
            </div>

            {/* =====table-heading-start===== */}
            <div className='table-header-wrapper'>
                <h5 className='text-white mb-0'>Upcoming List</h5>
                <a href='#' className='text-white text-decoration-underline'>View All</a>
            </div>
            {/* =====End===== */}


            {/* ====table-start=== */}
            <div className='upcoming-list-table-wrapper'>
                <table class="table">
                    <thead>
                        <tr>
                            <th scope="col">#</th>
                            <th scope="col">Type</th>
                            <th scope="col">Name</th>
                            <th scope="col">Reviews</th>
                            <th scope="col">Order Date</th>
                            <th scope="col">Amount</th>
                            <th scope="col">Status</th>
                            <th scope="col">Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <th scope="row">1</th>
                            <td>
                                <div className='d-flex align-items-center gap-1'>
                                    <img src={GrayFlightIcon} alt='GrayFlightIcon' />
                                    Flight
                                </div>
                            </td>
                            <td>
                                <div className='table-col-name-td'>
                                    Manpreet Singh
                                </div>
                            </td>
                            <td>
                                <div className='add-review-disable'>
                                    + Add Reviews
                                </div>
                            </td>
                            <td>10 Nov 2024</td>
                            <td>
                                <div className='table-amount-col-data'>
                                    $1899.00
                                </div>
                            </td>
                            <td>
                                <div className='status-label'>
                                    <span className='processing-label'>Processing</span>
                                </div>
                            </td>
                            <td>
                                <div className='action-icon-wrap d-flex align-items-center gap-2'>
                                    <a href='#' className='eye-icon-wrap text-decoration-none'>
                                        <i class="bi bi-eye"></i>
                                    </a>
                                    <a href='#' className='download-icon-wrap text-decoration-none'>
                                        <i class="bi bi-download"></i>
                                    </a>
                                    <a href='#' className='cross-icon-wrap text-decoration-none'>
                                        <i class="bi bi-x"></i>
                                    </a>
                                </div>
                            </td>
                        </tr>

                        <tr>
                            <th scope="row">2</th>
                            <td>
                                <div className='d-flex align-items-center gap-1'>
                                    <img src={GrayLocalTourIcon} alt='GrayLocalTourIcon' />
                                    Local Tour
                                </div>
                            </td>
                            <td>
                                <div className='table-col-name-td'>
                                    Vikas Kumar
                                </div>
                            </td>
                            <td>
                                <div className='add-review-disable'>
                                    + Add Reviews
                                </div>
                            </td>
                            <td>09 Nov 2024</td>
                            <td>
                                <div className='table-amount-col-data'>
                                    $3063.53
                                </div>
                            </td>
                            <td>
                                <div className='status-label'>
                                    <span className='processing-label'>Processing</span>
                                </div>
                            </td>
                            <td>
                                <div className='action-icon-wrap d-flex align-items-center gap-2'>
                                    <a href='#' className='eye-icon-wrap text-decoration-none'>
                                        <i class="bi bi-eye"></i>
                                    </a>
                                    <a href='#' className='download-icon-wrap text-decoration-none'>
                                        <i class="bi bi-download"></i>
                                    </a>
                                    <a href='#' className='cross-icon-wrap text-decoration-none'>
                                        <i class="bi bi-x"></i>
                                    </a>
                                </div>
                            </td>
                        </tr>

                        <tr>
                            <th scope="row">3</th>
                            <td>
                                <div className='d-flex align-items-center gap-1'>
                                    <img src={GrayCarIcon} alt='GrayLocalTourIcon' />
                                    Car Rentals
                                </div>
                            </td>
                            <td>
                                <div className='table-col-name-td'>
                                    Sahil Kumar
                                </div>
                            </td>
                            <td>
                                <div className='add-review-disable'>
                                    + Add Reviews
                                </div>
                            </td>
                            <td>07 Nov 2024</td>
                            <td>
                                <div className='table-amount-col-data'>
                                    $399.05
                                </div>
                            </td>
                            <td>
                                <div className='status-label'>
                                    <span className='confirmed-label'>Confirmed</span>
                                </div>
                            </td>
                            <td>
                                <div className='action-icon-wrap d-flex align-items-center gap-2'>
                                    <a href='#' className='eye-icon-wrap text-decoration-none'>
                                        <i class="bi bi-eye"></i>
                                    </a>
                                    <a href='#' className='download-icon-wrap text-decoration-none'>
                                        <i class="bi bi-download"></i>
                                    </a>
                                    <a href='#' className='cross-icon-wrap text-decoration-none'>
                                        <i class="bi bi-x"></i>
                                    </a>
                                </div>
                            </td>
                        </tr>

                        <tr>
                            <th scope="row">4</th>
                            <td>
                                <div className='d-flex align-items-center gap-1'>
                                    <img src={GreyHotelIcon} alt='GrayLocalTourIcon' />
                                    Hotel
                                </div>
                            </td>
                            <td>
                                <div className='table-col-name-td'>
                                    Vikram
                                </div>
                            </td>
                            <td>
                                <div className='add-review-disable'>
                                    + Add Reviews
                                </div>
                            </td>
                            <td>02 Nov 2024</td>
                            <td>
                                <div className='table-amount-col-data'>
                                    $269.80
                                </div>
                            </td>
                            <td>
                                <div className='status-label'>
                                    <span className='confirmed-label'>Confirmed</span>
                                </div>
                            </td>
                            <td>
                                <div className='action-icon-wrap d-flex align-items-center gap-2'>
                                    <a href='#' className='eye-icon-wrap text-decoration-none'>
                                        <i class="bi bi-eye"></i>
                                    </a>
                                    <a href='#' className='download-icon-wrap text-decoration-none'>
                                        <i class="bi bi-download"></i>
                                    </a>
                                    <a href='#' className='cross-icon-wrap text-decoration-none'>
                                        <i class="bi bi-x"></i>
                                    </a>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            {/* ====End=== */}
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        user: state.auth.user,
        vendor_id: state.auth.vendor_id,
        totalUsers: state.auth.total_users,
        total_medium: state.vendor.total_medium,
        total_small: state.vendor.total_small,
    }
}
export default connect(mapStateToProps)(Dashboard);