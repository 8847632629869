import axios from "axios";
import { GET_ALL_CURRENCIES_LIST, GET_ALL_LANGUAGES_LIST, GET_ALL_MEDIUM_AGENCIES, GET_ALL_SMALL_AGENCIES, GET_ALL_STAFF_MEMBERS, GET_ALL_USERS, GET_LANGUAGE_DATA, GET_TOTAL_MEDIUM, GET_TOTAL_SMALL, GET_TOTAL_STAFF_MEMBERS, GET_USERS_TOTAL,GET_DOWNPAYMENTS_DATA, GET_AGENCY_THEME,GET_CMS_PAGES, GET_CASHBACKS_DATA } from "./vendorTypes";
import { start_loading, stop_loading } from "../loader/loaderActions";
import { catch_errors_handle } from "../User/userActions";

const { REACT_APP_OTA_URL } = process.env;

export const admin_create_agency = async (vendorId, token, agencydata, toast) => {
    try {
        const url = `${REACT_APP_OTA_URL}agency/register/${vendorId}`;
        const HEADERS = {
            headers: {
                "x-access-token": token,
                "Content-Type": "multipart/form-data",
            }
        };
        const res = await axios.post(url, agencydata, HEADERS);
        if (res.data && res.data.status) {
            toast({ title: res.data.message, status: "success", duration: 2000, position: "top-right", isClosable: true, });
            return true;
        } else {
            toast({ title: res.data.message, status: "error", duration: 2000, position: "top-right", isClosable: true, });
            return false;
        }
    } catch (error) {
        toast({ title: error.response.data.message, status: "error", duration: 2000, position: "top-right", isClosable: true, });
        console.log("Err in Agency Create", error);
    }
};

export const admin_edit_agency = async (dispatch,vendor_id,token, data, toast) => { 
    dispatch(start_loading());
    try {
        const url = `${REACT_APP_OTA_URL}agency/edit`;
        const HEADERS = {
            headers: {
                "Content-Type": "multipart/form-data",
                "x-access-token": token
            }
        };
        const res = await axios.put(url, data, HEADERS);
        if (res.data && res.data.status) {
            toast({ title: res.data.message, status: "success", duration: 2000, position: "top-right", isClosable: true, });
            return true;
        } else {
            return false;
        }
    } catch (error) {
        catch_errors_handle(error, dispatch, toast);
        // console.log("Errror in edit user", error);
    } finally {
        dispatch(stop_loading());
    }
};

export const staff_create_agency = async (vendorId, token, agencydata, toast) => {
    try {
        const url = `${REACT_APP_OTA_URL}agency/create-staff/${vendorId}`;
        const HEADERS = {
            headers: {
                "x-access-token": token,
                "Content-Type": "application/json",
            }
        };
        const res = await axios.post(url, agencydata, HEADERS);
        if (res.data && res.data.status) {
            toast({ title: res.data.message, status: "success", duration: 2000, position: "top-right", isClosable: true, });
            return true;
        } else {
            toast({ title: res.data.message, status: "error", duration: 2000, position: "top-right", isClosable: true, });
            return false;
        }
    } catch (error) {
        console.log("Err in Staff Create", error);
    }
};

export const get_all_staff_members = async (token, dispatch, vendor_id) => {
    try {
        const url = `${REACT_APP_OTA_URL}agency/staff-list/${vendor_id}`;
        const HEADERS = {
            headers: {
                "x-access-token": token,
            }
        };
        const res = await axios.get(url, HEADERS);
        if (res.data && res.data.status) {
            dispatch({ type: GET_ALL_STAFF_MEMBERS, payload: res.data.data });
            dispatch({ type: GET_TOTAL_STAFF_MEMBERS, payload: res.data.length });
        }
    } catch (error) {
        console.log("Err in Small Lists", error);
    }
};

export const get_all_small_agencies = async (token, dispatch, vendor_id, searchval, page, limit, sortField, sortOrder) => {
    try {
        const url = `${REACT_APP_OTA_URL}agency/small-list/${vendor_id}?name=${searchval ? searchval : ''}&page=${page}&limit=${limit}${sortField ? `&sortField=${sortField}` :''}${sortOrder ? `&sortOrder=${sortOrder}`:''}`;
        const HEADERS = {
            headers: {
                "x-access-token": token,
            }
        };
        const res = await axios.get(url, HEADERS);
        if (res.data && res.data.status) {
            dispatch({ type: GET_ALL_SMALL_AGENCIES, payload: res.data.data });
            dispatch({ type: GET_TOTAL_SMALL, payload: res.data.length });
        }
    } catch (error) {
        console.log("Err in Small Lists", error);
    }
};

export const get_all_medium_agencies = async (token, dispatch, vendor_id, searchval, page, limit, sortField, sortOrder) => {
    try {
        const url = `${REACT_APP_OTA_URL}agency/medium-list/${vendor_id}?name=${searchval ? searchval : ''}&page=${page}&limit=${limit}${sortField ? `&sortField=${sortField}` :''}${sortOrder ? `&sortOrder=${sortOrder}`:''}`;
        const HEADERS = {
            headers: {
                "x-access-token": token,
            }
        };
        const res = await axios.get(url, HEADERS);
        if (res.data && res.data.status) {
            dispatch({ type: GET_ALL_MEDIUM_AGENCIES, payload: res.data.data });
            dispatch({ type: GET_TOTAL_MEDIUM, payload: res.data.length });
        }
    } catch (error) {
        console.log("Err in Medium Lists", error);
    }
};

export const create_lang_title_by_admin = async (token, data, toast) => {
    try {
        const url = `${REACT_APP_OTA_URL}page/add-key`;
        const HEADERS = {
            headers: {
                "Content-Type": "application/json",
                "x-access-token": token,
            }
        };
        const res = await axios.post(url, JSON.stringify(data), HEADERS);
        if (res.data && res.data.status) {
            toast({ title: res.data.message, status: "success", duration: 2000, position: "top-right", isClosable: true, });
            return true;
        }
    } catch (error) {
        console.log("Err in Lang Creation ---->", error);
    }
};

export const get_language_data = async (langtype, token, dispatch, toast, vendor_id) => {
    try {
        const url = `${REACT_APP_OTA_URL}page/${vendor_id}?language=${langtype}`;
        const HEADERS = {
            headers: {
                "x-access-token": token,
            }
        };
        const res = await axios.get(url, HEADERS);
        if (res.data && res.data.status) {
            dispatch({ type: GET_LANGUAGE_DATA, payload: res.data.data });
            return res.data.data;
        }
    } catch (error) {
        toast({ title: error.response.data.message, status: "error", duration: 2000, position: "top-right", isClosable: true, });
        // console.log("Err in Lang Data ---->", error);
    }
};

export const get_all_languages_lists = async (token, dispatch, type) => {
    try {
        const url = type ? `${REACT_APP_OTA_URL}agency/language-list?type=${type}` : `${REACT_APP_OTA_URL}agency/language-list`;
        const HEADERS = {
            headers: {
                "x-access-token": token,
            }
        };
        const res = await axios.get(url, HEADERS);
        if (res.data && res.data.status) {
            dispatch({ type: GET_ALL_LANGUAGES_LIST, payload: res.data.data });
            dispatch({ type: GET_ALL_CURRENCIES_LIST, payload: res.data.currency });
            return res.data;
        }
    } catch (error) {
        console.log("Error in Language List ---->", error);
    }
};

export const apply_language_active = async (token, data) => {
    try {
        const url = `${REACT_APP_OTA_URL}agency/language`;
        const HEADERS = {
            headers: {
                "Content-Type": "application/json",
                "x-access-token": token,
            }
        };
        const res = await axios.put(url, JSON.stringify(data), HEADERS);
        if (res.data && res.data.status) {
            return true;
        }
    } catch (error) {
        console.log("Error in Language List ---->", error);
    }
};

export const get_default_lang_and_currency = async (token) => {
    try {
        const url = `${REACT_APP_OTA_URL}agency/default`;
        const HEADERS = {
            headers: {
                "Content-Type": "application/json",
                "x-access-token": token,
            }
        };
        const res = await axios.get(url, HEADERS);
        if (res.data && res.data.status) {
            return res.data.data;
        }
    } catch (error) {
        console.log("Error in Set Default Language ---->", error);
    }
};

export const set_default_lang_and_currency = async (token, data, toast) => {
    try {
        const url = `${REACT_APP_OTA_URL}agency/set-default`;
        const HEADERS = {
            headers: {
                "Content-Type": "application/json",
                "x-access-token": token,
            }
        };
        const res = await axios.put(url, JSON.stringify(data), HEADERS);
        if (res.data && res.data.status) {
            toast({ title: res.data.message, status: "success", duration: 2000, position: "top-right", isClosable: true, });
        }
    } catch (error) {
        console.log("Error in Set Default Language ---->", error);
    }
};

export const user_deleted_by_admin = async (dispatch, vendor_id, token, user_id, type) => {
    dispatch(start_loading());
    try {
        const url = `${REACT_APP_OTA_URL}agency/delete-user/${vendor_id}?userID=${user_id}&type=${type}`;
        const HEADERS = {
            headers: {
                "Content-Type": "application/json",
                "x-access-token": token,
            }
        };
        const res = await axios.delete(url, HEADERS);
        if (res.data && res.data.status) {
            return true;
        } else {
            return false;
        }
    } catch (error) {
        console.log("Error in Set Default Language ---->", error);
    } finally {
        dispatch(stop_loading());
    }
};

export const enable_lang_and_currency = async (dispatch, token, data, toast) => {
      try {
        const url = `${REACT_APP_OTA_URL}agency/lang-and-curr`;
        const HEADERS = {
            headers: {
                "Content-Type": "application/json",
                "x-access-token": token,
            }
        };
        const res = await axios.put(url, JSON.stringify(data), HEADERS);
        if (res.data && res.data.status) {
            toast({ title: res.data.message, status: "success", duration: 2000, position: "top-right", isClosable: true, });
            return true;
        } else {
            return false;
        }
    } catch (error) {
        console.log("Error in Set Default Language ---->", error);
    }
};

export const create_customer_by_admin = async (dispatch, vendor_id, token, data, toast) => { 
    dispatch(start_loading());
    try {
        const url = `${REACT_APP_OTA_URL}agency/create-user/${vendor_id}`;
        const HEADERS = {
            headers: {
                "Content-Type": "application/json",
                "x-access-token": token
            }
        };
        const res = await axios.post(url, data, HEADERS);
        if (res.data && res.data.status) {
            toast({ title: res.data.message, status: "success", duration: 2000, position: "top-right", isClosable: true, });
            return true;
        } else {
            return false;
        }
    } catch (error) {
        catch_errors_handle(error, dispatch, toast);
    } finally {
        dispatch(stop_loading());
    }
};

export const get_user_details = async (dispatch, vendor_id, token, user_id, type) => {
    dispatch(start_loading());
    try {
        const url = `${REACT_APP_OTA_URL}agency/user-details/${vendor_id}?user_id=${user_id}&type=${type}`;
        const HEADERS = {
            headers: {
                "Content-Type": "application/json",
                "x-access-token": token
            }
        };
        const res = await axios.get(url, HEADERS);
        if (res.data && res.data.status) {
            return res.data.data;
        }
    } catch (error) {
        // console.log("Err in Create Customer", error);
        //catch_errors_handle(error);
    } finally {
        dispatch(stop_loading());
    }
};

export const edit_user_by_admin = async (dispatch,vendor_id,token, data, toast) => { 
    dispatch(start_loading());
    try {
        const url = `${REACT_APP_OTA_URL}agency/edit-user/${vendor_id}`;
        const HEADERS = {
            headers: {
                "Content-Type": "multipart/form-data",
                "x-access-token": token
            }
        };
        const res = await axios.put(url, data, HEADERS);
        if (res.data && res.data.status) {
            toast({ title: res.data.message, status: "success", duration: 2000, position: "top-right", isClosable: true, });
            return true;
        } else {
            return false;
        }
    } catch (error) {
        catch_errors_handle(error, dispatch, toast);
        // console.log("Errror in edit user", error);
    } finally {
        dispatch(stop_loading());
    }
};
export const get_downpayments = async (token, dispatch, userId, vendor_id, searchval, page, limit, sortField, sortOrder) => {
    dispatch(start_loading());
    try {
        const url = `${REACT_APP_OTA_URL}agency/advance?page=${page}&limit=${limit}${searchval ? `&name=${searchval}` : ''}` +
            `${userId ? `&userId=${userId}` : ''}` +
            `${sortField ? `&sortField=${sortField}` : ''}` +
            `${sortOrder ? `&sortOrder=${sortOrder}` : ''}`;

        // Defining headers for the request
        const headers = {
            "Content-Type": "application/json",
            "x-access-token": token,
        };

        // Making the API call
        const res = await axios.get(url, {headers});
        if (res.data && res.data.status) {
            dispatch({ type: GET_DOWNPAYMENTS_DATA, payload: res.data });
        } else {
            console.error("Unexpected response format:", res.data);
        }
    } catch (error) {
        console.error("Error in fetching downpayments:", error.message);
    }finally {
        dispatch(stop_loading());
    }
};

export const upload_customer_profile_pic = async (dispatch, picturedata) => {
    dispatch(start_loading());
    try {
        const url = `${REACT_APP_OTA_URL}agency/upload-picture`;
        const HEADERS = {
            headers: {
                "Content-Type": "multipart/form-data",
            }
        };
        const formdata = new FormData();
        formdata.append("picture", picturedata);
        const res = await axios.post(url, formdata, HEADERS);
        if (res.data && res.data.status) {
            return res.data;
        }
    } catch (error) {
        console.log("Err in Upload Picture", error);
    } finally {
        dispatch(stop_loading());
    }
};
export const theme_file_upload = async (dispatch,token, data, toast) => { 
    dispatch(start_loading());
    try {
        const url = `${REACT_APP_OTA_URL}agency/store/store-image-upload`;
        const HEADERS = {
            headers: {
                "Content-Type": "multipart/form-data",
                "x-access-token": token
            }
        };
        const res = await axios.post(url, data, HEADERS);
        return res.data
    } catch (error) {
        console.log(error);
        
        catch_errors_handle(error, dispatch, toast);
    } finally {
        dispatch(stop_loading());
    }
};
export const theme_setting_api = async (dispatch,token, data, toast) => { 
    dispatch(start_loading());
    try {
        const url = `${REACT_APP_OTA_URL}agency/store-theme`;
        const HEADERS = {
            headers: {
                "Content-Type": "application/json",
                "x-access-token": token
            }
        };
        const res = await axios.post(url, data, HEADERS);
        if (res.data && res.data.status) {
            dispatch({ type: GET_AGENCY_THEME, payload: res.data.data });
            toast({ title: res.data.message, status: "success", duration: 2000, position: "top-right", isClosable: true, });
            return true;
        } else {
            return false;
        }
    } catch (error) {
        catch_errors_handle(error, dispatch, toast);
    } finally {
        dispatch(stop_loading());
    }
};
export const get_theme_setting_api = async (dispatch,token, uid,toast) => { 
    dispatch(start_loading());
    try {
        const url = `${REACT_APP_OTA_URL}agency/store-data/${uid}`;
        const HEADERS = {
            headers: {
                "Content-Type": "application/json",
                "x-access-token": token
            }
        };
        const res = await axios.get(url,HEADERS);
        if (res.data && res.data.status) {
            dispatch({ type: GET_AGENCY_THEME, payload: res.data.data });
            return true;
        } else {
            return false;
        }
    } catch (error) {
        catch_errors_handle(error, dispatch, toast);
    } finally {
        dispatch(stop_loading());
    }
};
export const manage_store_pages = async (dispatch,token, data, toast) => { 
    dispatch(start_loading());
    try {
        const url = `${REACT_APP_OTA_URL}cms/create`;
        const HEADERS = {
            headers: {
                "Content-Type": "application/json",
                "x-access-token": token
            }
        };
        const res = await axios.post(url, data, HEADERS);
        if (res.data && res.data.status) {
            toast({ title: res.data.message, status: "success", duration: 2000, position: "top-right", isClosable: true, });
            get_cms_pages(dispatch,token,1,10,toast,null);
            return true;
        }else{
            return false;
        }
    } catch (error) {
        catch_errors_handle(error, dispatch, toast);
    } finally {
        dispatch(stop_loading());
    }
};
export const del_cms_pages = async (dispatch,token,toast,pid) => { 
    dispatch(start_loading());
    try {
        const url = `${REACT_APP_OTA_URL}cms/remove?page_id=${pid}`;
        const HEADERS = {
            headers: {
                "Content-Type": "application/json",
                "x-access-token": token
            }
        };
        const res = await axios.delete(url,HEADERS);
        if (res.data && res.data.status) {
            toast({ title: res.data.message, status: "success", duration: 2000, position: "top-right", isClosable: true, });
            get_cms_pages(dispatch,token,1,10,toast,null);
        }
    } catch (error) {
        catch_errors_handle(error, dispatch, toast);
    } finally {
        dispatch(stop_loading());
    }
};
export const get_cms_pages = async (dispatch,token,page,limit,toast,search) => { 
    dispatch(start_loading());
    try {
        const url = `${REACT_APP_OTA_URL}cms/list?page=${page}&limit=${limit}`+
        `${search ? `&search=${search}` : ''}`;
        const HEADERS = {
            headers: {
                "Content-Type": "application/json",
                "x-access-token": token
            }
        };
        const res = await axios.get(url,HEADERS);
        if (res.data && res.data.status) {
            dispatch({ type: GET_CMS_PAGES, payload: res.data });
        }
    } catch (error) {
        catch_errors_handle(error, dispatch, toast);
    } finally {
        dispatch(stop_loading());
    }
};

export const set_cashback_data = async (dispatch, token, data, toast) => { 
    dispatch(start_loading());
    try {
        const url = `${REACT_APP_OTA_URL}agency/store-theme`;
        const HEADERS = {
            headers: {
                "Content-Type": "application/json",
                "x-access-token": token,
            }
        };
        const res = await axios.post(url, JSON.stringify(data), HEADERS);
        if (res.data && res.data.status) {
            toast({ title: res.data.message, status: "success", duration: 2000, position: "top-right", isClosable: true, });
            return true;
        } else {
            return false;
        }
    } catch (error) {
        catch_errors_handle(error, dispatch, toast);
    } finally {
        dispatch(stop_loading());
    }
};

export const get_cashback_data = async (dispatch, toast, vendorId) => { 
    dispatch(start_loading());
    try {
        const url = `${REACT_APP_OTA_URL}agency/store-data/${vendorId}`;
        const res = await axios.get(url);
        if (res.data && res.data.status) {
            dispatch({ type: GET_CASHBACKS_DATA, payload: res.data.data });
        }
    } catch (error) {
        // catch_errors_handle(error, dispatch, toast);
    } finally {
        dispatch(stop_loading());
    }
};